@import '../../../../assets/style/colors';

.root {
  margin: 0;
  max-width: 100%;
  font-style: normal;
  font-weight: normal;
  word-wrap: break-word;
  font-size: 14px;

  color: $palette-text-primary;
}

.title {
  margin-bottom: 2px;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.357;
  color: $palette-text-primary;
}

.container {
  display: flex;
  justify-content: flex-start;
  align-content: center;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
}

.icon {
  width: 32px;
  height: 32px;
  margin-top: 4px;
  margin-right: 4px;
  color: $palette-secondary;
}

.statusWrapper {
  display: flex;
  width: 100%;
  min-width: 0;
  align-items: center;
  font-size: 12px;
  line-height: 1.333;
  color: $palette-success;

  &.accent {
    color: $palette-error;
  }
}

.callIcon {
  margin-right: 2px;
  width: 14px;
  height: 14px;
  color: $palette-success;
}

.status {
  display: block;
  width: 100%;
  margin-top: 2px;
  font-weight: 600;
  color: $palette-info-dark;
}

.yourMessage {
  &.root,
  .icon,
  .status,
  .statusWrapper,
  .title {
    color: $white;
  }
}

.footerRight {
  margin-left: auto;
}
