@import '../../../../assets/style/mixins';

.linkContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.linkBtn {
  border: 0;
  padding: 0;
  margin: 0;

  @include link(1.4em, 1.357);
  background: none;
}

.btn {
  cursor: pointer;
  color: $palette-secondary;
}

.disabledBtn {
  color: $palette-disable;
}
