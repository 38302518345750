@import '../../../../../assets/style/mixins';

.root {
  margin: 8px 0 0;
  font-size: 1.2em;
  line-height: 1.33;
  color: $palette-text-secondary-dark;

  @include media-phone() {
    margin-top: 12px;
  }
}
