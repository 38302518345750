@import '../../../../../../assets/style/mixins';

[class].wrapper {
  width: 100%;
  min-width: 0;
  padding: 8px 32px 16px 20px;
}

.kebab,
[class].checkbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content {
  max-width: 300px;
}

@include media-phone() {
  [class].wrapper {
    padding-right: 28px;
    padding-left: 16px;
  }
}
