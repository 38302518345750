@import '../../../../assets/style/mixins';

.skeleton {
  :global(.ant-skeleton-content) {
    :global(.ant-skeleton-title) {
      margin: 0 auto;
    }

    :global(.ant-skeleton-paragraph) {
      margin-top: 22px;
      margin-bottom: 0;
      width: 100%;

      & > li:first-child {
        width: 70%;
        height: 20px;
        margin: 0 auto 24px;
      }

      & > li:last-child {
        width: 135px !important;
        height: 48px;
        margin: 24px auto 0;
      }
    }
  }
}

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0;
  padding: 32px 16px;
  border: 1px solid $palette-stroke-primary;
  border-radius: 4px;
  border-top-width: 4px;
  background-color: $white;
  width: 100%;

  &.available {
    border-top-color: $palette-secondary-dark;
  }
}

.title {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  margin-bottom: 16px;
  text-align: center;
}

@include media-phone() {
  .root {
    position: relative;
    padding: 12px 16px 20px;
    align-items: baseline;
    max-width: unset;
  }

  .title {
    margin-bottom: 4px;
  }

  .future .details {
    margin-bottom: 0;
  }
}
