@import '../../../../../../assets/style/mixins';

.root {
  position: absolute;
  bottom: -2px;
  right: -2px;
}

.iconWrapper {
  background-color: $palette-dark-gray;
  border: 2px solid $palette-stroke-primary-dark;
  border-radius: 18px;
  width: 18px;
  height: 18px;

  svg[class].icon {
    color: $palette-text-primary;
  }
}

.iconOpen {
  transform: rotate(-180deg);
}

.popover {
  width: 280px;

  :global(.ant-popover-inner-content) {
    padding: 20px;
  }
}
