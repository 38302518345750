@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 8px;
  padding: 16px;
  background-color: $palette-disable-light;
  border-radius: 4px;
  width: 100%;
}

.avatars {
  display: flex;
  column-gap: 6px;
  align-items: center;
}

.accessible {
  cursor: pointer;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 16px;
  width: 100%;
}

.title {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
}

.subtitle {
  font-size: 12px;
  line-height: 14px;
}

@include media-phone() {
  .root {
    background: unset;
    padding: 0;
  }

  .title {
    font-size: 20px;
    line-height: 28px;
  }
}
