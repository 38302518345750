@import '../../../../assets/style/mixins';

.switch {
  height: 44px;
}

@include media-phone() {
  [class].actions {
    align-items: center;
    flex-direction: column-reverse;
  }
}
