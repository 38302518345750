@import '../../../../assets/style/mixins';

.root {
  padding: 16px 16px 16px 28px;
  border-bottom: 1px solid $palette-stroke-primary;
}

.action {
  border-radius: 2px;
  height: 34px;
  width: 34px;
  background-color: $white;
  justify-content: center;
  margin-right: 24px;
}

.icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: $white;
  margin-right: 12px;
  height: 34px;
  width: 34px;

  svg {
    width: 18px;
    height: 18px;
  }
}

.circle {
  border-radius: 50%;
}

.square {
  border-radius: 2px;
}

.accent {
  background-color: $palette-accent;
}

.secondary {
  background-color: $palette-secondary;
}

.title {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
}

@include media-tablet() {
  .root {
    padding: 16px 8px 16px 20px;

    background-color: rgba($palette-secondary-rgb, 0.08);
  }

  .square {
    width: 28px;
    height: 28px;

    svg {
      width: 14px;
      height: 14px;
    }
  }

  .circle {
    width: 30px;
    height: 30px;

    svg {
      width: 14px;
      height: 14px;
    }
  }

  .title {
    font-size: 16px;
    line-height: 22px;
  }
}

@include media-phone() {
  .root {
    padding: 8px;
  }

  .title {
    font-size: 14px;
    line-height: 19px;
  }

  .action {
    margin-right: 16px;
  }

  .circle {
    width: 20px;
    height: 20px;

    svg {
      width: 10px;
      height: 10px;
    }
  }
}

.controls {
  margin-left: auto;
  padding-left: 8px;
}

[class].createDepartmentButton {
  padding-left: 16px;
  padding-right: 16px;
  margin-left: 8px;
  height: 44px;

  svg {
    width: 20px;
    height: 20px;
  }
}
