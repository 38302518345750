@import '../../../../assets/style/mixins';

[class].root {
  display: flex;
  margin-right: auto;
  font-size: $main-font-size;
  color: $palette-text-primary;
  margin-bottom: 16px;

  & > span {
    display: flex;
  }

  :global {
    & .ant-breadcrumb-link {
      font-size: 1.3em;
      line-height: 1.846em;
      color: $palette-text-primary;

      a {
        font-weight: 600;
        color: $palette-secondary;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
          color: $palette-secondary-dark;
        }
      }
    }

    & .ant-breadcrumb-link,
    & .ant-breadcrumb-link a {
      display: inline-block;
      max-width: 295px;
      overflow: hidden auto;
      white-space: nowrap;
      text-overflow: ellipsis;

      @include media-phone() {
        max-width: 100px;
      }
    }

    & .ant-breadcrumb-separator {
      margin: 0;
      color: $palette-text-secondary-dark;
    }
  }
}
