.title {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
}

.mutedIcon {
  margin-left: 8px;
}

.container,
.nameWrapper {
  width: 100%;
  min-width: 0;
  display: flex;
}

.container {
  align-items: flex-start;
  flex-direction: column;
}

.nameWrapper {
  align-items: center;
}
