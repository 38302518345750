@import '../../../../../../assets/style/mixins';

.title {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  padding: 0;
  text-align: center;
  min-width: 0;
}

.count {
  color: $palette-text-secondary-dark;
  margin-left: 8px;
}

.headerInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  column-gap: 20px;
  border-bottom: 1px solid $palette-stroke-primary;
}

@include media-tablet() {
  [class].header {
    width: 100%;
    background-color: white;
    border: 1px solid $palette-stroke-primary;
    border-bottom: none;
    border-radius: 4px 4px 0 0;
  }
}

@include media-phone() {
  [class].title {
    display: flex;
    justify-content: center;
    padding: 28px 16px;
  }
}
