@import '../../../../../assets/style/colors';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.link {
  padding: 0;
  margin-top: 1.428em;
  cursor: pointer;
  font-weight: 600;
  font-size: 1.4em;
  line-height: 1.357;
  color: $palette-text-secondary-dark;
  background-color: transparent;
  border: 0;
}

.button {
  width: 245px;
}
