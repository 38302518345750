@import '../../../../assets/style/mixins';

.root {
  position: relative;
  display: flex;
  flex-direction: column;

  flex: 1 0;
  height: 100%;
  width: 100%;
  min-height: 0;
  min-width: 0;
}

.carousel {
  display: flex;
  height: 100%;
  width: 100%;
  flex: 1 0;

  &WithThumbnails {
    margin-bottom: 32px;
  }

  :global(.swiper-pagination) {
    display: flex;
    position: absolute;
    flex-direction: row;
    gap: 7px;
    bottom: 20px;
    left: 50%;
    z-index: 1;
    transform: translateX(-50%);
  }

  :global(.swiper-pagination-bullet) {
    width: 12px;
    height: 12px;
    display: block;
    cursor: pointer;
    border-radius: 50%;
    background: $palette-text-secondary;
  }

  :global(.swiper-pagination-bullet-active) {
    background: $palette-secondary;
  }
}

[class].thumbnails {
  width: 100%;
  flex-shrink: 0;

  :global(.swiper-slide) {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 80px;
    flex-shrink: 0;
  }
}

@include media-phone() {
  .carouselWithThumbnails {
    margin-bottom: 18px;
  }
}

.currentSlideIndex {
  position: absolute;
  bottom: 20px;
  left: 20px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  padding: 3px 8px;
  color: $palette-primary;
  background: $white;
  z-index: 1;
  border-radius: $secondary-border-radius;
  border: 1px solid $palette-stroke-primary;
}
