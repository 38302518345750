@import '../../../../../assets/style/mixins';

.root {
  flex: 2 0;
  @include paper();
  padding: 24px;
  row-gap: 40px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 19px;
  height: 100%;
}

.row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
}

.transfer {
  display: flex;
  align-items: center;
  gap: 12px;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 40px;
}

.label {
  display: flex;
  align-items: center;
  gap: 12px;
}

@include media-tablet() {
  .root {
    padding: 20px;
    row-gap: 36px;
  }

  .form {
    row-gap: 36px;
  }
}

@include media-phone() {
  .root {
    padding: 16px;
    row-gap: 22px;
    width: 100%;
  }

  .row {
    width: 100%;
    justify-content: flex-start;
    flex-direction: column;
    gap: 32px 0;
  }

  .transfer {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .form {
    row-gap: 22px;
  }

  .label {
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
    row-gap: 4px;
  }
}
