[class].fullscreenAvatar {
  margin: auto;
  border-radius: 50%;
  aspect-ratio: 1;
  height: auto;
  max-height: 100%;
  max-width: 100%;
  flex-grow: 0;

  &Image {
    object-fit: cover;
  }
}
