@import '../../../../../assets/style/colors';

.account:hover [class].accountName {
  color: $palette-secondary;
}

.header {
  color: $palette-text-secondary-dark;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  padding: 8px 0;
  text-transform: uppercase;
}
