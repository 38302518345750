@import '../../../../../assets/style/mixins';

.root {
  width: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.topLabel {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1.357em;
}

.highlightText {
  background-color: inherit;
  font-weight: bold;
}

@include media-phone() {
  .topLabel {
    font-size: 15px;
    line-height: 1.333em;
  }
}
