@import '../../../../assets/style/mixins';

.root {
  @include modalButtonGapLG();

  form {
    @include dialog();
  }
}

.button {
  @include modalButtonGapLG();
}

@include media-phone() {
  .buttonError {
    margin-top: 56px;
  }
}
