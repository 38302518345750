@import '../../../../../../assets/style/mixins';

.root {
  padding: 16px 0;

  &:not(:last-child) {
    border-bottom: 1px solid $palette-disable;
  }
}

.avatar {
  margin-right: 8px;

  &Square {
    margin-right: 12px;
  }
}

.root [class].button,
.root [class].input {
  height: 20px;
}

.button:global(.ant-skeleton-button) {
  margin-right: 40px;
}

@include media-phone() {
  .root {
    padding: 12px 0;
  }
}
