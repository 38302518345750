@import '../../../../assets/style/mixins';

.root {
  padding: 0 28px 28px;
  font-size: $main-font-size;
  @include paper();
}

@include media-tablet() {
  .root {
    padding: 0;
    background-color: transparent;
    border: none;

    :global(.ant-skeleton) {
      padding: 24px 20px;
      background-color: $white;
      border: 1px solid $palette-stroke-primary;
      border-radius: 0 0 4px 4px;
      border-top: none;
    }
  }
}

@include media-phone() {
  .root {
    :global(.ant-skeleton) {
      padding: 24px 16px 20px;
    }
  }
}
