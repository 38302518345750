@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 20px;
  font-size: 1.1em;
  line-height: 1.363;
  color: $palette-text-secondary-dark;
  text-align: right;

  &.preview {
    color: $white;
    background-color: rgba(#0d141e66, 0.4);
    border-radius: $main-border-radius;
    padding: 2px 4px;
  }

  // needs to cover the padding and keep the color
  &.outgoingMessage {
    color: rgba($white-rgb, 0.5);

    .pin {
      color: $white;
    }
  }

  .pin {
    color: $palette-text-secondary-dark;
  }

  @include media-phone() {
    font-size: 1em;
  }
}

.edited {
  margin-right: 4px;
}

.time {
  display: flex;
  align-items: center;
}

.icon {
  margin-left: 4px;
  display: inline-flex;

  svg {
    color: $white;
    width: 20px;
    height: 20px;

    &.failed {
      color: $palette-error;
      background-color: $white;
      border-radius: 50%;
    }

    @include media-phone() {
      width: 16px;
      height: 16px;
    }
  }
}
