@import 'src/assets/style/mixins';

.root {
  overflow: hidden;
  position: relative;
  display: flex;
  width: 100%;
}

.text {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;

  &TwoLine {
    @include media-phone() {
      white-space: normal;
      word-break: break-all;
      max-height: 2.769em;
    }
  }
}

.disabled {
  color: $palette-disable-dark;
}
