@import '../../../../../assets/style/mixins';

.pagination {
  margin-top: auto;
  padding: 20px 28px;
}

.skeletonWrapper {
  padding: 28px 0 0;
}

[class].skeleton {
  :global {
    .ant-skeleton-paragraph {
      margin-bottom: 12px !important;

      li {
        height: 48px;
        width: 100% !important;
      }
    }
  }
}

[class].collapse {
  margin-top: 16px;
  flex-grow: 1;
}

@include media-tablet() {
  .pagination {
    padding: 20px;
  }  
}

@include media-phone() {
  .pagination {
    padding: 20px 16px;
  }  
}