@import '../../../../../assets/style/mixins';

.avatar {
  margin-bottom: 1.6em;
  @include pulse();
}

.userName {
  display: flex;
  justify-content: center;
  width: 100%;
}

.truncatedTitle {
  margin-bottom: 0.8em;

  font-weight: bold;
  font-size: 1.6em;
  line-height: 1.5;
  color: $white;

  cursor: default;
}
