@import '../../../../assets/style/mixins';

.root {
  width: 100%;
  font-size: 12px;
}

.content {
  height: 48px;
  display: flex;
  gap: 12px;
  padding: 8px 20px 7px 12px;
  border-bottom: 1px solid $palette-stroke-primary-light;
}

.controls {
  display: flex;
}

.disabled {
  color: $palette-dark-gray;
}

@include media-tablet() {
  .root {
    border-left: 1px solid $palette-stroke-primary;
    border-right: 1px solid $palette-stroke-primary;
  }
}
