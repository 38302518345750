@import '../../../../../assets/style/mixins';

.mention {
  font-size: 14px;
  color: $palette-secondary;
  cursor: pointer;
}

.mentionSuggestions {
  display: flex;
  flex-direction: column;
  padding: 12px 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  background: $white;
  border-radius: 4px;
  max-width: 200px;
  max-height: 200px;
  overflow: hidden auto;
  z-index: 1;
}

.mentionSuggestionsEntry {
  cursor: pointer;
  padding: 8px 0;

  &:hover {
    font-weight: 600;
    color: $palette-secondary;
  }

  @include rowHover($palette-secondary-hover);
}

.mentionSuggestionsPopup {
  z-index: $zIndex-suggestion-popover;
}
