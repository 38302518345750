@import '../../../../../assets/style/mixins';

.root {
  margin-top: 24px;
  padding-bottom: 24px;

  @include media-tablet() {
    margin-top: 20px;
    padding-bottom: 20px;
  }

  @include media-phone() {
    margin-top: 16px;
    padding-bottom: 16px;
  }

  &:first-child {
    margin-top: 28px;

    @include media-tablet() {
      margin-top: 20px;
    }

    @include media-phone() {
      margin-top: 16px;
    }
  }

  &:nth-last-child(n + 2) {
    border-bottom: 1px solid $palette-stroke-primary;
  }
}
