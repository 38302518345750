@import '../../../../../assets/style/mixins';

.label {
  display: flex;
  justify-content: space-between;
}

.caption {
  color: $palette-text-secondary-dark;
}

.select {
  margin-bottom: 50px;
}

.buttons {
  display: flex;
  gap: 16px;
  flex-direction: column;
  align-items: center;
}

@include media-phone() {
  .select {
    margin-bottom: 40px;
  }
}
