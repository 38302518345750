@import '../../../../../assets/style/mixins';

$textLineHeight: 21;

$visibleHeight: $textLineHeight * 4;

.root {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  overflow: hidden;
  flex-shrink: 0;

  &.new {
    background-color: rgba($palette-secondary-rgb, 0.08);
  }
}

.header {
  display: flex;
  align-items: flex-start;
}

.author {
  display: flex;
  align-items: center;
}

.avatar {
  position: relative;
  cursor: pointer;
}

.type,
.badge {
  display: flex;
  align-items: center;
  justify-content: center;
}

.badge {
  width: 8px;
  flex-shrink: 0;
}

.type {
  position: absolute;
  border-radius: 50%;
  background-color: $palette-info;

  svg {
    color: $white;
  }
}

.image {
  object-fit: cover;
  margin-left: auto;
}

.description {
  display: flex;
  align-items: center;
  min-width: 0;
  font-size: 14px;
  overflow: hidden;
  line-height: #{$textLineHeight}px;
}

.authorDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 0;
  row-gap: 4px;
  font-weight: 600;
}

.sm {
  &.root {
    padding: 12px 30px 12px 12px;
    row-gap: 12px;
  }

  .header {
    column-gap: 8px;
  }

  .author {
    column-gap: 10px;
  }

  .type {
    width: 20px;
    height: 20px;
    right: -4px;
    bottom: -4px;

    svg {
      width: 12px;
      height: 12px;
    }
  }

  .image {
    width: 42px;
    height: 42px;
  }

  .description {
    padding-left: 56px;
  }

  .authorDetails {
    padding-right: 20px;
  }
}

.md {
  &.root {
    padding: 16px 56px 16px 28px;
    row-gap: 16px;
  }

  .header {
    column-gap: 16px;
    position: relative;
  }

  .author {
    column-gap: 12px;
  }

  .type {
    width: 26px;
    height: 26px;
    right: -8px;
    bottom: -8px;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  $image-size: 60px;
  $padding-right: calc(#{$image-size} + 20px);

  .image {
    width: $image-size;
    height: $image-size;
    position: absolute;
    top: 0;
    right: 0;
  }

  .description {
    padding-left: 78px;
    padding-right: $padding-right;
  }

  .authorDetails {
    padding-right: $padding-right;
  }
}

.authorName {
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;
  width: 100%;
}

.date {
  font-size: 12px;
  line-height: 16px;
  color: $palette-text-secondary-dark;
}

.comment {
  width: 100%;
  overflow: hidden;

  &Wrapper {
    font-size: 14px;
    line-height: #{$textLineHeight}px;
  }
}

@include media-tablet() {
  .md {
    &.root {
      padding-right: 28px;
    }
  }
}

@include media-phone() {
  .description {
    font-size: 12px;
    line-height: 19px;
  }

  .sm,
  .md {
    &.root {
      padding: 12px 16px;
      row-gap: 12px;
    }

    .image {
      position: static;
      width: 36px;
      height: 36px;
    }

    .badge {
      width: 30px;
    }

    .description {
      column-gap: 8px;
      padding-left: unset;
    }
  }

  .sm {
    .badge {
      width: 30px;
    }
  }

  .md {
    .badge {
      width: 30px;
    }

    .type {
      width: 20px;
      height: 20px;
      right: -4px;
      bottom: -4px;

      svg {
        width: 12px;
        height: 12px;
      }
    }

    .description {
      padding-right: unset;
    }

    .header {
      column-gap: 8px;
    }

    .authorDetails {
      padding-right: 20px;
    }
  }
}
