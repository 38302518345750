@import '../../../../assets/style/mixins';

.root {
  position: relative;
  display: flex;
  flex-shrink: 0;
  min-width: 0;
  padding: 0 2.8em;
  background-color: $white;
  font-size: $main-font-size;
  border: 1px solid $palette-stroke-primary;
  border-top: none;
  border-bottom: 1px solid $palette-stroke-primary;
  border-radius: 0 0 4px 4px;
  padding-top: 2.4em;

  &:first-of-type {
    padding-top: 1.4em;
  }

  &:nth-last-of-type(n + 2) {
    @include dividerBottom(2.8em);

    border-bottom: none;
    border-radius: unset;
  }

  &Intro {
    margin-bottom: 28px;
  }

  &Info {
    min-width: 0;
    padding-right: 52px;
    margin-left: 16px;
    margin-bottom: 28px;
  }
}

[class].accountRoot {
  font-size: 1.8em;
  line-height: 1.388;
}

.accountType {
  display: flex;
  align-items: center;
  font-size: 1.2em;
  line-height: 1.333;
  color: $palette-text-secondary-dark;

  svg {
    margin-right: 1px;
    color: $palette-secondary;
  }
}

.location {
  margin: 0.5em 0;
}

.menuContainer {
  position: absolute;
  top: 28px;
  right: 18px;
}

[class].contextMenuRoot {
  width: 250px;
}

[class].skeleton {
  @include skeletonListItems();
  background-color: $white;
  border: 1px solid $palette-stroke-primary;
  border-top: none;
  border-bottom: none;
  border-radius: unset;
}

.categories {
  margin-bottom: 24px;
}

.professions {
  margin-bottom: 10px;
}

@include media-tablet() {
  .root {
    padding: 2em 2em 0;

    &:nth-last-of-type(n + 2)::after {
      width: calc(100% - 4em);
      left: 2em;
    }

    &Intro {
      margin-bottom: 24px;
    }

    &Info {
      margin-bottom: 24px;
    }
  }

  [class].accountRoot {
    font-size: 1.6em;
    line-height: 1.375;
  }

  .location {
    margin: 0.8em 0;
  }

  .menuContainer {
    top: 24px;
    right: 20px;
  }
}

@include media-phone() {
  .root {
    padding: 1.6em 1.6em 0;

    &:first-of-type {
      padding-top: 1.2em;
    }

    &:nth-last-of-type(n + 2) ::after {
      width: calc(100% - 3.2em);
      left: 1.6em;
    }

    &Intro {
      position: absolute;
      top: 16px;
      left: 16px;
      right: 0;
      display: flex;
      align-items: flex-end;
      margin-bottom: 0;
    }

    &Info {
      margin-left: 0;
      margin-bottom: 16px;
    }
  }

  [class].account {
    margin-top: 5px;
    margin-left: 83px;
    margin-bottom: 20px;
  }

  .menuContainer {
    top: 43px;
    right: 19px;
  }
}
