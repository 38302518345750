@import '../../../../assets/style/variables';
@import '../../../../assets/style/colors';

.icon {
  width: 15px;
  height: auto;
  color: $palette-text-secondary-dark;
  cursor: pointer;

  &Enabled {
    color: $palette-primary-dark;
  }

  &Inactive {
    cursor: auto;
    user-select: none;
    color: $palette-disable;
  }
}

.tooltip {
  z-index: $zIndex-modal !important;
}

.btn {
  display: flex;
  padding: 0;
  background-color: transparent;
  border: none;
}
