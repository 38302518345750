@import '../../../../../assets/style/mixins';

.rootWrapper {
  min-height: 0;
  position: relative;
}

.icon {
  color: $palette-text-secondary-dark;
}

.mentionSuggestions {
  max-width: 100%;
  width: 100%;
  transform: unset !important;
  inset: auto auto calc(100% + 8px) !important;
  z-index: $zIndex-chat-header-content;
}
