@import '../../../../assets/style/mixins';

.root {
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 14px;
  line-height: 19px;
  color: $palette-text-primary;

  &:nth-last-child(n + 2) {
    margin-bottom: 16px;
  }
}

.icon {
  flex-shrink: 0;
  margin-right: 12px;
  display: flex;

  svg {
    width: 20px;
    height: 20px;
    color: $palette-text-primary;
  }
}

[class].link {
  width: 100%;
  max-width: 18ch;
  text-decoration: underline;
  color: $palette-primary;

  &:hover {
    color: #0056b3;
  }
}

@include media-phone() {
  .root {
    &:nth-last-child(n + 2) {
      margin-bottom: 20px;
    }
  }
}
