@import '../../../../assets/style/colors';

.root {
  display: flex;
  align-items: center;
  column-gap: 12px;

  &.reverse {
    flex-direction: row-reverse;
  }

  :global {
    .ant-switch,
    .ant-switch-checked {
      box-shadow: none;
    }

    .ant-switch {
      background-color: $palette-disable-dark;
    }

    .ant-switch-checked {
      background-color: $palette-primary;
    }
  }
}

.label {
  font-size: 14px;
  line-height: 19px;
}
