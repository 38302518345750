@import '../../../../../../assets/style/mixins';

.root {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.pid {
  background-color: $palette-stroke-primary;
  flex-shrink: 0;
  margin-right: 2px;

  height: 16px;
  width: 4px;

  border-radius: 2px;

  &Active {
    background-color: $palette-primary;
  }
}
