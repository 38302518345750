@import '../../../../assets/style/mixins';

.root {
  width: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  min-height: 0;
  flex-grow: 1;
}
