@import 'src/assets/style/colors';

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 0 31px;
}

[class].checkbox {
  width: initial;
  height: 24px;

  &Label {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: $palette-text-secondary-dark;
  }
}

[class].context {
  :global(.ant-popover-inner-content) {
    padding-top: 0;
  }

  &Addon,
  &Icon {
    color: $palette-text-secondary-dark;
  }

  &Addon {
    font-size: 14px;
    line-height: 19px;
    padding: 20px 20px 16px;
  }
}
