@import '../../../../../assets/style/colors';
@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  font-size: 1.4em;
  line-height: 1.5;
  color: $palette-text-primary;
  padding: 60px 0;
}

.img {
  max-width: 100%;
  margin-bottom: 24px;
}

.icon {
  width: 30px;
  height: 30px;
  margin-bottom: 19px;
  color: $palette-stroke-primary-dark;
}

.description {
  margin: 0 0 32px;
  text-align: center;
  max-width: 500px;
}

.info {
  display: flex;
  gap: 32px;
  max-width: 632px;
  width: 100%;
  margin-bottom: 32px;

  &Block {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    p {
      margin-bottom: 16px;
    }

    button {
      margin-top: auto;
      width: 100%;
    }
  }
}

.links {
  display: flex;
  gap: 39px;
}

@include media-tablet() {
  .info {
    gap: 19px;
  }
}

@include media-phone() {
  .root {
    padding: 0;
  }

  .info {
    gap: 21px;
    flex-wrap: wrap;

    &Block {
      width: 100%;
    }
  }
}
