@import '../../../../../assets/style/mixins';

.root {
  font-weight: 600;
  color: inherit;
  text-transform: capitalize;
}

.yourMessage {
  color: $palette-primary;
}

.draft {
  color: $palette-error;
}
