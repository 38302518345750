@import '../../../assets/style/mixins';

.itemsWrapper {
  overflow: hidden;
  padding-left: 58px;
}

.collapsible {
  display: flex;
  gap: 7px;
  align-items: center;
}

[class].checkboxWrapper {
  overflow: hidden;
  @include filterItemRow();
}

.icon {
  flex-shrink: 0;
  cursor: pointer;

  &.open {
    transform: rotate(180deg);
  }
  &.disabled {
    color: $palette-disable;
  }
}
