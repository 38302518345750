@import '../../../../assets/style/mixins';

[class].placeholderIcon {
  margin-right: 4px;
}

.icon {
  width: 20px;
  height: 20px;
}
