@import '../../../../../assets/style/mixins';

.group,
.form {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 23px;
}

.text {
  text-align: center;
}

.field {
  margin-bottom: 40px;
}

.group {
  row-gap: 28px;
  margin-bottom: 58px;
}

.alert {
  margin-bottom: 28px;
}

@include media-tablet() {
  .field {
    margin-bottom: 44px;
  }

  .group {
    row-gap: 32px;
  }
}

@include media-phone() {
  .field {
    margin-bottom: 18px;
  }

  .group {
    margin-bottom: 28px;
  }
}
