@import '../../../../../assets/style/mixins';

.description {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin: 0 0 28px;
}

.price {
  font-weight: bold;
  color: $palette-secondary-dark;
}

.list {
  display: flex;
  justify-content: space-around;
}

.item {
  &:nth-last-child(n + 2) {
    margin-right: 16px;
  }
}
