@import '../../../../assets/style/mixins';

.root {
  display: flex;
  width: 100%;

  padding: 1.2em 0;

  font-size: $main-font-size;

  &:nth-last-child(n + 2) {
    border-bottom: 1px solid $palette-stroke-primary;
  }
}

.container {
  flex: auto;
  min-width: 0;
  cursor: pointer;
}

.content {
  display: flex;
  color: $palette-text-secondary-dark;

  &Body {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &Title {
    color: $palette-primary;
  }

  &File {
    display: flex;
    width: 30px;
    height: 30px;
    margin-right: 8px;
    overflow: hidden;
    border-radius: $main-border-radius;

    &Inner {
      width: 30px !important;
      height: 30px !important;
      > * {
        width: 30px !important;
        height: 30px !important;
      }
    }
  }
}
