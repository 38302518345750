@import '../../../../assets/style/mixins';

.root {
  position: relative;
  padding: 28px;
  font-size: $main-font-size;
  display: flex;
  gap: 12px;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 93%;
    height: 1px;
    margin: 0 auto;
    background-color: $palette-stroke-primary;
  }

  &:last-child::after {
    display: none;
  }

  &Viewed {
    background: rgba($palette-secondary-light-rgb, 0.07);
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  min-width: 0;
}

[class].accountNameRoot {
  font-size: 18px;
  line-height: 25px;
}

.iconLabels {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-top: 8px;
}

.professions {
  margin-top: 12px;
}

.label {
  color: $palette-text-secondary-dark;
  font-weight: normal;
}

.actions {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.skeleton {
  @include skeletonListItems();
}

.message {
  margin: 16px 0 0;
  font-size: 14px;
  line-height: 22px;
  overflow-wrap: break-word;
}

@include media-tablet() {
  .root {
    padding: 20px;
  }

  [class].accountNameRoot {
    font-size: 16px;
    line-height: 22px;
  }
}

@include media-phone() {
  .root {
    padding: 20px 16px;
    flex-direction: column;
  }

  .wrapper {
    row-gap: 16px;
  }

  .iconLabels {
    margin-top: 12px;
  }

  .professions {
    margin-top: 8px;
  }

  .actions {
    flex-direction: column;
    row-gap: 16px;
  }

  [class].accountName {
    &Content {
      flex-direction: column;
      align-items: flex-start;
    }

    &ChipList {
      order: -1;
    }

    &Root {
      font-size: 14px;
      line-height: 19px;
    }
  }
}
