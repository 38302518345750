@import '../../../../assets/style/mixins';

.root {
  width: 100%;
  position: absolute;
  background: $white;
}

.content {
  display: flex;
  column-gap: 12px;
  padding: 16px 20px 16px 12px;
  border-bottom: 1px solid $palette-stroke-primary-light;
}

.actions {
  display: flex;
}

[class].action svg {
  width: 32px;
  height: 32px;
}

.input {
  display: flex;
  column-gap: 20px;
  flex-grow: 1;
}

[class].searchClearIcon,
[class].searchSuffix svg {
  color: $palette-text-secondary-dark;
}

@include media-tablet() {
  .root {
    position: relative;
    border-left: 1px solid $palette-stroke-primary-light;
    border-right: 1px solid $palette-stroke-primary-light;
  }
}
