@import '../../../../../../../assets/style/mixins';

.root {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  color: $palette-text-secondary-dark;
  background: $palette-disable-light;
  border: 1px solid $palette-stroke-primary;
}

.cross {
  color: $palette-text-primary;
  cursor: pointer;
}

.label {
  flex-shrink: 0;
}

.reply {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 0;
  column-gap: 4px;
}

.user {
  display: inline-flex;
  width: 100%;
  min-width: 0;
  color: $palette-secondary;
}

@include media-phone() {
  .root {
    padding: 8px 12px;
  }
}
