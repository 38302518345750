@import '../../../../../assets/style/mixins';

.root {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: auto;
  background-color: $white;
  backdrop-filter: blur(20px);
  font-size: $main-font-size;
  border-radius: $main-border-radius;
}

.label {
  font-size: 1.333em;
  font-weight: 500;
  text-align: center;
  margin-top: 1.2em;
}
