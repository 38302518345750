@import '../../../../../assets/style/mixins';

$custom-account-name-title-mobile-max-width: 550;

.root {
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;

  cursor: pointer;

  &Disabled {
    cursor: default;
  }

  .checkbox {
    width: auto;
    margin-right: 9px;
  }
}
