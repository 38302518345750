@import '../../../../../assets/style/colors';

.root {
  display: flex;
  align-items: center;
  column-gap: 4px;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  flex-shrink: 0;

  & svg {
    color: inherit;
    width: 14px;
    height: 14px;
  }
}

.primary {
  color: $palette-info-dark;
  background: $palette-info-light;
}

.primary-dark {
  color: $palette-primary-dark;
  background: $palette-chip-dark;
}

.success {
  color: $palette-success-dark;
  background: $palette-success-light;
}

.round {
  border-radius: 26px;
  padding: 2px 12px 2px 8px;
}

.square {
  border-radius: 4px;
  padding: 2px 8px;
}
