@import '../../../../../assets/style/mixins';

.connection {
  margin-left: 8px;
}

.icon {
  display: inline-block;
  vertical-align: top;
  flex-shrink: 0;
  margin-left: 8px;
}

@include media-phone() {
  .connection {
    margin-left: 4px;
  }
}
