@import '../../../../../assets/style/mixins';

$chat-header-wrapper-padding-top-phone: calc(#{$header-fixed-height-phone} + #{$layout-padding});
$chat-header-height-adaptive-tablet: 65px;
$chat-header-height-adaptive-phone: 144px;

[class].mainWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}

@include media-tablet() {
  [class].root {
    border-radius: 0;
    border-top: none;
    border-bottom: none;
  }

  [class].headerWrapper {
    position: fixed;
    z-index: $zIndex-chat-header + 1;
    top: 0;
    left: calc(#{$sider-fixed-width-tablet} + #{$layout-padding});
    right: $layout-padding;
    display: flex;
    align-items: flex-end;
    padding-top: calc(#{$header-fixed-height-tablet} + #{$layout-padding});
    background-color: $palette-body-background;
  }

  [class].main {
    height: auto;
    border: none;

    &Wrapper {
      padding-top: $chat-header-height-adaptive-tablet;
    }
  }
}

@include media-phone() {
  [class].headerWrapper {
    left: 0;
    right: 0;
    margin: 0 $layout-padding;
    padding-top: $chat-header-wrapper-padding-top-phone;

    @supports (height: max(0px)) {
      padding-top: unquote(
        'max(#{$chat-header-wrapper-padding-top-phone} , calc(#{$chat-header-wrapper-padding-top-phone} + env(safe-area-inset-top)))'
      );
    }
  }

  [class].mainWrapper {
    padding-top: $chat-header-height-adaptive-phone;
  }
}
