@import '../../../../assets/style/mixins';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 12px;
}

.root.md svg {
  width: 32px;
  height: 32px;
}

.root.xs svg {
  width: 20px;
  height: 20px;
}

.item {
  display: inline-flex;
}
