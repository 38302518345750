@import 'src/assets/style/mixins';

.root {
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
  border-radius: 50%;
  color: $palette-call-background;
  background-color: $palette-dark-gray;

  &:global(.swiper_nav_control_hidden),
  &:global(.swiper_nav_control_disabled) {
    display: none;
  }
}

.fixed {
  position: fixed;
  width: 40px;
  height: 40px;
}

.fixed.next {
  right: 30px;
}

.fixed.prev {
  left: 30px;
}

.absolute {
  position: absolute;
  width: 32px;
  height: 32px;
}

.absolute.next {
  right: 12px;
}

.absolute.prev {
  left: 12px;
}

@include media-tablet() {
  .fixed.next {
    right: 20px;
  }

  .fixed.prev {
    left: 20px;
  }
}

@include media-phone() {
  .fixed.next {
    right: 16px;
  }

  .fixed.prev {
    left: 16px;
  }
}
