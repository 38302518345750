@import '../../../../../assets/style/mixins';

[class].alert {
  padding-top: 16px;
  padding-bottom: 16px;
  margin: 28px auto 18px;
  max-width: 410px;
  text-align: left;
}

.title {
  font-size: 1.4em;
  line-height: 1.572;
  margin: 0;
}

[class].checkbox {
  margin-bottom: 50px;
}

[class].skeleton {
  width: 320px;

  :global(.ant-skeleton-paragraph) {
    margin-bottom: 0;

    li {
      height: 100px;
      width: 100% !important;

      &:first-child {
        height: 20px;
      }

      &:last-child {
        height: 40px;
      }
    }
  }
}

@include media-tablet() {
  [class].alert {
    margin-bottom: 24px;
  }
}

@include media-phone() {
  [class].alert {
    margin-bottom: 16px;
  }
}
