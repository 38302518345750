.root {
  display: flex;
  min-width: 0;
}

.text {
  overflow: hidden;

  text-overflow: ellipsis;

  &Truncated {
    width: 100%;
    text-align: left;
  }

  &.one {
    white-space: nowrap;
  }

  &.two {
    white-space: initial;
    line-break: anywhere;

    @supports (-webkit-line-clamp: 2) {
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}

.clickable {
  cursor: pointer;
}
