@import '../../../../../assets/style/mixins';

$button-size: 54;

.root {
  position: fixed;
}

.root.caseView {
  width: #{$button-size + 'px'};
  height: #{$button-size + 'px'};

  position: sticky;
  bottom: 0;
}

.badge {
  position: absolute;
  top: -2px;
  left: 36px;
}

.button {
  width: #{$button-size + 'px'};
  height: #{$button-size + 'px'};
  border-radius: 50%;
  justify-content: center;
  flex-shrink: 0;

  svg {
    width: 42px;
    height: 42px;
  }
}

@include media-tablet() {
  .root {
    z-index: $zIndex-modal - 1;
  }
}

:export {
  buttonSize: $button-size;
}
