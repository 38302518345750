@import '../../../../assets/style/colors';

.radioGroup {
  display: flex !important;
  flex-direction: column;
  row-gap: 12px;
}

.radioLabel {
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.icon {
  width: 16px;
  height: 16px;
  color: $palette-text-secondary-dark;
}

.description {
  font-size: 13px;
  line-height: 21px;
  color: $palette-text-secondary-dark;
}
