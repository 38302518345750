@import '../../../../assets/style/mixins';

.root,
.alert {
  display: flex;
  align-items: center;
}

.root {
  gap: 16px;
  flex-direction: column;
}

.alert {
  column-gap: 4px;

  svg {
    width: 20px;
    height: 20px;
  }
}

.status {
  color: $palette-warning;
  font-weight: 700;
  font-size: 14px;
}

@include media-tablet() {
  .root {
    flex-direction: row;
  }
}

@include media-phone() {
  .root {
    flex-direction: column;
  }
}
