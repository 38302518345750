@import '../../../../assets/style/mixins';

[class].tabMenu {
  margin: 0;
  padding: 20px 28px 24px 28px;
  flex-shrink: 0;
}

.header {
  display: flex;
  gap: 12px;
  padding: 16px 28px;
  flex-direction: row-reverse;
  border-bottom: 1px solid $palette-stroke-primary;
}

@include media-phone() {
  .header {
    justify-content: center;
  }
}
