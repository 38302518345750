@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  box-sizing: content-box;
  position: relative;

  &-lg {
    height: 178px;

    @include media-tablet() {
      height: 135px;
    }

    @include media-phone() {
      height: 112px;
    }
  }

  &-sm {
    width: 60px;
    height: 60px;

    @include media-phone() {
      width: 40px;
      height: 40px;
    }
  }

  &-xs {
    width: 40px;
    height: 40px;
  }

  &-xss {
    width: 28px;
    height: 28px;
  }
}

.thumbnail {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.fileIcon {
  margin: 0;

  &-lg {
    width: 100px;
    height: 100px;

    @include media-tablet() {
      width: 75px;
      height: 75px;
    }

    @include media-phone() {
      width: 56px;
      height: 56px;
    }
  }

  &-md {
    width: 80px;
    height: 80px;

    @include media-tablet() {
      width: 60px;
      height: 60px;
    }

    @include media-phone() {
      width: 56px;
      height: 56px;
    }
  }

  &-sm,
  &-xs,
  svg {
    width: 100%;
    height: 100%;
  }
}

.videoStatus {
  position: absolute;
  right: 12px;
  bottom: 12px;
}

.play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 46px;
  height: 44px;
  background-color: $palette-secondary;
  border-radius: 50%;

  &Icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 20px;
    height: 20px;
    color: $white;
  }
}

@include media-phone() {
  .videoStatus {
    right: 8px;
    bottom: 8px;
  }

  .play {
    width: 36px;
    height: 36px;

    &Icon {
      width: 16px;
      height: 16px;
    }
  }
}
