@import '../../../../../assets/style/mixins';

.root {
  cursor: pointer;
}

[class].secondary {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  padding: 10px;
  color: $palette-secondary-light;

  &:hover {
    background-color: rgba($palette-secondary-light-rgb, 0.16);
    color: $palette-secondary-light;
  }

  [class].badge {
    box-shadow: none !important;
  }

  &.active {
    background-color: rgba($palette-secondary-light-rgb, 0.16);
    color: $palette-secondary-light;

    &::before {
      display: none;
    }
  }
}

[class].horizontal {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: normal;
  padding: 10px;
  gap: 12px;

  [class].text {
    margin: 0;
    font-weight: normal;
  }

  [class].iconWrapper {
    line-height: 0;
  }
}
