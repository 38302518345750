@import '../../../../assets/style/colors';

.fileIcon {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  margin-right: 10px;
  font-weight: 800;
  font-size: 8px;

  &SubIcon {
    position: relative;
  }
}

.subIcon {
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: $palette-text-secondary-dark;
  border-radius: 50%;
  left: -6px;
  bottom: -6px;

  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    color: $white;
  }
}

[class].fileIcon.disabled {
  opacity: 0.3;
}
