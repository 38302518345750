@import '../../../../assets/style/mixins';

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 200px;

  background: $white;
  border: 1px solid $palette-stroke-primary;
  border-radius: 4px;

  @include media-tablet() {
    height: 358px;
  }

  @include media-phone() {
    position: relative;
    height: 574px;
  }

  &FullHeight {
    min-height: 420px;

    @include media-tablet() {
      height: 530px;
    }

    @include media-phone() {
      height: 717px;
    }
  }

  &Skeleton {
    overflow: hidden;
  }
}

.header {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  margin: 0 2em;
  border-bottom: 1px solid $palette-stroke-primary;

  @include media-phone() {
    margin: 0 1.6em 1.6em;
  }
}

.headerWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.actions {
  display: flex;
  align-items: center;
  max-height: 40px;

  svg {
    cursor: pointer;
  }
}

.title {
  flex-grow: 1;
  min-width: 0;
  word-wrap: break-word;
  align-self: center;
  padding: 10px 0;
  @include caseTitle();
}

[class].skeleton {
  padding: 2em;

  @include media-tablet() {
    padding: 1.6em;
  }
}
