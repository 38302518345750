@import '../../../../../assets/style/mixins';

.root {
  @include paper();
  width: 100%;
  padding: 20px 20px 0;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.highlight {
  border-color: $palette-error;
}
