@import '../../../../assets/style/mixins';

.skeleton {
  margin-bottom: 12px;

  &:global(.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title) {
    margin-top: 4px;
    margin-bottom: 4px;
  }
}

[class].collapseRoot {
  & :global {
    .ant-collapse-header {
      padding-right: 2em;
    }

    .ant-collapse-arrow {
      width: 20px;
      height: 20px;
    }

    .ant-collapse-content-active {
      margin-top: 0.8em;
    }
  }
}

.header {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 0;
  padding-right: 2em;
}

.date {
  flex-shrink: 0;
  margin-bottom: 0;
  margin-left: auto;
  padding-left: 1.666em;
}

.truncated {
  margin-left: 0.666em;
  font-size: 12px;
  line-height: 1.333;

  &Account {
    font-weight: bold;
    color: $palette-text-primary;
  }

  &Creator {
    font-weight: 600;
    color: $palette-text-secondary-dark;
  }
}

.author {
  display: flex;
  align-items: center;
}

@include media-phone() {
  .author {
    flex-wrap: wrap;
  }

  .truncatedCreator {
    font-weight: 400;
  }
}
