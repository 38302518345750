@import '../../../../assets/style/mixins';

.reactCrop {
  width: 100%;

  .imageWrapper {
    width: 100%;
    overflow: hidden;

    > img {
      object-fit: contain;
      width: 100%;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .withBlank {
    display: flex;
    justify-content: center;
    align-items: center;

    > img {
      object-fit: contain;
      width: auto;
    }
  }

  :global {
    .ReactCrop__crop-selection {
      border: none;
      -webkit-box-shadow: 0 0 0 9999em rgba(0, 0, 0, 0.5);
      box-shadow: 0 0 0 9999em rgba(0, 0, 0, 0.5);

      &::after {
        content: '';
        height: 100%;
        width: 100%;
        border: 1px solid $palette-stroke-primary-light;
        position: absolute;
        opacity: 0.4;
        left: 0;
        top: 0;
      }
    }

    .ReactCrop__drag-handle {
      &:after {
        background-color: $palette-stroke-primary-light;
        opacity: 0.6;
      }
    }
  }
}
