@import '../../../../../assets/style/colors';

.root {
  gap: 4px;
  display: inline-flex;
  color: $palette-primary;
}

.name {
  font-weight: 400;
  color: $palette-text-secondary-dark;
}
