@import '../../../../assets/style/mixins';

.root {
  margin-top: 2em;
  position: relative;

  &:nth-last-child(n + 2) {
    @include media-tablet() {
      margin-bottom: 1.6em;
    }

    @include media-phone() {
      margin-bottom: 0;
    }
  }

  @include media-tablet() {
    margin-top: 0;
  }

  @include media-phone() {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 0;

    &:nth-last-child(n + 2)::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 100%;
      transform: translate(-50%, -50%);
      width: 1px;
      height: 78%;
      background-color: $palette-stroke-primary;
    }
  }
}

.title {
  margin: 0 0 0.666em 1.666em;
  font-weight: bold;
  font-size: 1.2em;
  line-height: 1.33em;
  text-transform: uppercase;
  color: $palette-text-primary;

  @include media-tablet() {
    margin: 0 0 12px;
    text-align: center;

    span {
      display: none;
    }
  }

  @include media-phone() {
    margin: 1.3em 0 0.58em;
    text-align: center;
    font-size: 0.57rem;
    line-height: 1.37em;
  }
}

[class].navMenu {
  &Root {
    @include media-phone() {
      flex-direction: row;
      justify-content: space-between;
      flex: auto;
      min-height: 0;
    }
  }

  &Item {
    @include media-tablet() {
      padding: 0.857em 0;
      text-align: center;

      &:before {
        height: 36px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    @include media-phone() {
      flex-grow: 1;
      font-size: 0.5rem;
      line-height: 1.375em;

      &:hover,
      &:focus {
        border-bottom-left-radius: 4px;
      }

      &::before {
        height: 3px;
        width: 36px;
        top: unset;
        bottom: -2px;
        right: 0;
        margin: 0 auto;
        border-radius: 0;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
      }
    }
  }

  &Title {
    @include media-tablet() {
      display: block;
      margin: 0 auto;
    }
  }

  &Icon {
    color: $palette-secondary;
  }
}
