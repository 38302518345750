.root {
  display: flex;
  align-items: flex-start;
  flex-grow: 1;
  padding: 20px;
  column-gap: 8px;
}

.avatar {
  margin-right: 12px;
}

.content {
  display: flex;
  flex-direction: column;
  min-width: 0;
  row-gap: 8px;
}

[class].name {
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  overflow-wrap: break-word;
  line-break: unset;
}

[class].infoButton {
  font-weight: 700;
  align-self: flex-start;
}

.contextMenu {
  margin-left: auto;
}
