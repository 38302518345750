@import '../../../../assets/style/mixins';

.root {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.chip {
  color: $palette-info-dark;
}

.lead {
  margin-left: 10px;
  display: inline-flex;
  align-items: center;

  svg {
    margin: 0 4px 0 10px;
    width: 14px;
    height: 14px;
  }
}
