@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 286px;
  min-height: 288px;
  padding: 4em 2em;

  font-size: $main-font-size;
  background: $palette-primary-dark;
  border-radius: 4px;

  z-index: $zIndex-received-call;
  position: fixed;
  top: 25%;
  left: 45%;
  cursor: move;

  @include media-phone() {
    left: 0;
    right: 0;
    margin: 0 auto;
    cursor: default;
  }
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.avatar {
  margin-bottom: 1.6em;
}

.description {
  display: inline-flex;
  font-size: 12px;
  line-height: 1.833;
  text-align: center;
  align-items: center;
  color: $palette-stroke-primary-light;
  cursor: default;
  margin-bottom: 25px;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 4em;
}

[class].button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background: none;
  padding: 0;
  border: none;
  cursor: pointer;

  &Mute {
    background-color: $white;

    svg {
      color: $palette-text-primary;
    }
  }

  &Answer {
    background-color: $palette-success;

    svg {
      color: $white;
    }
  }

  &Reject {
    background-color: $palette-error;

    svg {
      color: $white;
    }
  }
}

.dots {
  bottom: 5px;
  right: -7px;
  @include dotElastic();
}
