@import '../../../../assets/style/mixins';

.root {
  @include paper();
  width: 100%;

  padding: 0 2.8em;
  font-size: $main-font-size;

  @include media-tablet() {
    padding: 0 2em;
  }

  @include media-phone() {
    padding: 0 1.6em;
  }
}

.item {
  padding: 2.8em 0 2em;
  @include dividerBottom(0px);

  @include media-tablet() {
    padding-top: 2em;
  }
}
