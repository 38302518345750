@import '../../../../../assets/style/mixins';

@include media-tablet() {
  .root {
    margin-top: 12px;
  }
}

@include media-phone() {
  [class].root {
    height: unset;
    padding: 0;
    margin-top: 10px;
  }
}
