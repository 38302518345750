@import '../../../../assets/style/mixins';

.root {
  display: inline-block;
  width: 100%;
  padding: 1.6em 0;
  border-bottom: 1px solid $palette-stroke-primary;

  @include media-phone() {
    border-color: rgba(255, 255, 255, 0.1);
  }

  &Item {
    display: flex;

    &:nth-last-child(n + 2) {
      margin-bottom: 16px;

      @include media-phone() {
        margin-bottom: 20px;
      }
    }

    &Disabled {
      cursor: default;
    }
  }
}
