@import '../../../../assets/style/mixins';

[class].root {
  padding: 0;

  &:global(.ant-collapse-item) {
    background-color: $white;
    border-radius: 4px;
    border: 1px solid $palette-stroke-primary-light;

    & > :global(.ant-collapse-header) :global(.ant-collapse-arrow) {
      right: 16px;
    }

    & :global(.ant-collapse-content.ant-collapse-content-active) {
      margin-top: 0;
    }
  }
}

[class].root.systemUser :global(.ant-collapse-header) {
  background-color: $palette-info-light;
}

:global(.ant-collapse-content.ant-collapse-content-active) > :global(.ant-collapse-content-box) {
  padding: 0;
}

:global(.ant-collapse-content.ant-collapse-content-active) {
  background: none;
}

.header {
  display: flex;
  align-items: center;
  min-width: 0;
  width: 100%;
  padding: 12px 16px;
  border-radius: $main-border-radius;
  font-size: $main-font-size;
  cursor: pointer;
  column-gap: 12px;

  &Highlight {
    background-color: $palette-info-light;
  }
}

.badge {
  min-width: 33px;
  display: flex;
}

[class].name {
  min-width: 0;
  font-weight: bold;
  font-size: 1.4em;
  line-height: 1.357;
  color: $palette-text-primary;
  width: unset;
}
