@import '../../../../assets/style/mixins';

[class].root {
  z-index: $zIndex-modal;
  font-size: $main-font-size;

  :global {
    .ant-drawer-content-wrapper {
      width: 398px !important;
    }

    $padding: 28px;

    .ant-drawer-wrapper-body {
      padding: 0 $padding;
    }

    .ant-drawer-header,
    .ant-drawer-body,
    .ant-drawer-footer {
      padding: 0;
    }

    .ant-drawer-body {
      margin-top: 24px;
      margin-bottom: $padding;

      @include drawerRightScrollPadding();
    }

    .ant-drawer-header {
      border: none;
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }

    .ant-drawer-title {
      font-weight: bold;
      font-size: 2em;
      line-height: 1.35;
      padding-right: 40px;
      color: $palette-text-primary;
    }

    .ant-drawer-header-title {
      position: relative;
      min-width: 0;
      padding-top: $padding;
    }

    .ant-drawer-close {
      position: absolute;
      right: 0;
      top: $padding;
      margin: 0;
    }

    .ant-drawer-extra {
      order: -1;
      padding: $padding 0;

      @include insetBackground(rgba($palette-secondary-rgb, 0.08));
    }

    .ant-drawer-footer {
      padding-bottom: $padding;
      border: none;
    }
  }
}

.icon {
  color: $palette-text-primary;
}

@include media-phone() {
  [class].root {
    :global {
      .ant-drawer-content-wrapper {
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 97.5vw !important;
        min-width: 312px;
        max-width: 398px;
        margin: 0 auto;
      }

      .ant-drawer-content {
        border-radius: 4px;
      }

      .ant-drawer-title {
        text-align: center;
      }
    }
  }
}
