@import '../../../../../../assets/style/colors';
@import '../../../../../../assets/style/mixins';

.wrapper {
  display: flex;
  font-size: $main-font-size;

  &:nth-last-child(n + 2) {
    margin-bottom: 1.2em;
  }
}

.label,
.value {
  width: 50%;
  font-size: 1.3em;

  @include media-phone() {
    width: 41.92%;
  }
}

.label {
  color: $palette-text-secondary-dark;
}

.value {
  color: $palette-text-primary;
}
