@import '../../../../assets/style/mixins';

.root {
  display: flex;
  align-items: center;

  &Outgoing {
    color: $white;

    .title {
      color: $white;
    }

    .wrapper:before {
      background-color: $white;
    }

    .messagePlaceholder {
      color: $white;
    }

    .mention {
      font-weight: 600;
      color: $white;
    }
  }
}

.cancelAction {
  margin: 0 12px 0 -2px;
}

.wrapper {
  display: flex;
  cursor: pointer;
  width: 100%;
  min-width: 0;

  &::before {
    content: '';
    display: block;
    width: 3px;
    flex-shrink: 0;
    background-color: $palette-secondary;
    border-radius: 4px;
  }
}

.content {
  width: 100%;
  padding-left: 1em;
}

.title {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: $palette-secondary;
  margin-bottom: 3px;

  &Icon {
    margin-right: 5px;
    width: 12px;
    height: 12px;
  }
}

.message {
  max-width: 470px;
  margin-bottom: 0;
  font-size: 12px;
  line-height: 1.333;
  color: inherit;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &Placeholder {
    color: $palette-text-secondary-dark;
  }
}

.mention {
  font-size: 12px;
}

@include media-tablet() {
  .message {
    max-width: 296px;
  }
}

@include media-phone() {
  .message {
    max-width: 202px;
  }
}
