@import '../../../../assets/style/mixins';

.root {
  flex: 1;
  background-color: $white;
  overflow-x: hidden;
  overflow-y: auto;
  min-width: 0;
  min-height: 0;
  padding: 40px;
}

.content {
  display: flex;
  column-gap: 50px;
}

.start,
.end {
  display: flex;
  flex-direction: column;
  flex: 1 0;
}

.start {
  max-width: 512px;
}

.alert,
.alertIconWrapper,
.screenshots,
.featuresIconWrapper,
.featuresTitle {
  display: flex;
  align-items: center;
}

.alertIconWrapper,
.featuresIconWrapper {
  justify-content: center;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: $white;
}

.alert {
  column-gap: 16px;
  background-color: $palette-info-light;
  padding: 24px 20px;
  border-radius: 4px;
  border: 1px solid $palette-stroke-primary;

  &IconWrapper {
    width: 44px;
    height: 44px;
  }

  &Icon {
    color: $palette-secondary;
  }

  &Description {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    line-height: 150%;
    max-width: 466px;
  }
}

.title {
  font-size: 28px;
  font-weight: 700;
  line-height: 38px;
  margin-bottom: 20px;
}

.description {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 24px;
}

.screenshot {
  background-color: $palette-layout-background;
  border-radius: 10px;

  &Img {
    width: 512px;
    height: 530px;
  }
}

.features {
  border-radius: 4px;
  padding: 32px;
  background-color: $palette-layout-background;

  &Title {
    column-gap: 24px;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 30px;
  }

  &IconWrapper {
    width: 60px;
    height: 60px;
    background-color: rgba($palette-secondary-rgb, 0.1);
  }

  &Icon {
    width: 32px;
    height: 32px;
    color: $palette-secondary;
  }

  &List {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 20px;
    padding-left: 12px;
  }
}

.feature {
  display: flex;
  align-items: flex-start;
  column-gap: 20px;

  &Title {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }

  &Description {
    font-size: 12px;
    line-height: 20px;
    max-width: 420px;
  }
}

@include media-tablet() {
  .root {
    padding: 30px;
  }

  .content {
    flex-direction: column;
  }

  .start,
  .end {
    flex: unset;
    width: 100%;
    max-width: unset;
  }

  .alert {
    margin-bottom: 24px;
  }

  .title {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 24px;
  }
}

@include media-phone() {
  .alert {
    margin-bottom: 16px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 12px;

    &Description {
      text-align: center;
    }
  }

  .root {
    text-align: center;
    padding: 20px;
  }

  .title {
    margin-bottom: 12px;
  }

  .description {
    margin-bottom: 16px;
  }

  .feature {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    row-gap: 10px;
  }

  .featuresTitle,
  .featureTitle {
    font-size: 14px;
  }

  .featuresList {
    row-gap: 16px;
    padding-left: 0;
  }

  .featuresTitle {
    display: flex;
    justify-content: center;
  }
}
