@import '../../../../assets/style/mixins';

.root {
  font-size: $main-font-size;
}

.button {
  display: flex;
  align-items: center;
  padding: 0;
  margin-top: 8px;
  color: $palette-secondary;
  font-weight: bold;
  font-size: 1.2em;
  line-height: 1.333;
  background-color: transparent;
  border: 0;
  cursor: pointer;

  svg {
    width: 18px;
    height: auto;
    margin-left: 0.333em;
    transform-origin: center;
    transition: transform 0.3s;
    transform: rotate(0);
  }

  &Expanded svg {
    transform: rotate(-180deg);
  }
}

.wrapper {
  overflow: hidden;
  transition: max-height 0.3s;

  &Text {
    color: $palette-text-primary;
    font-size: 1.3em;
    line-height: 1.615;
    word-break: break-all;
    text-overflow: ellipsis;

    > * {
      display: inline-block;
    }
  }

  &Expanded {
    overflow-y: auto;
    max-height: unset !important;

    &Limited {
      max-height: 32.3em !important;
    }
  }
}

@supports (display: -webkit-box) {
  .wrapper {
    &Text {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      word-break: break-all;
      overflow: hidden;
      transition: -webkit-line-clamp 0.3s;
    }

    &Expanded {
      overflow-y: auto;
      -webkit-line-clamp: unset !important;

      &Limited {
        -webkit-line-clamp: 20 !important;
      }
    }
  }
}
