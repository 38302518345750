@import '../../../../../assets/style/mixins';

[class].root {
  background: $white;
  border: 1px solid $palette-stroke-primary;
  box-sizing: border-box;
  border-radius: $main-border-radius;
  box-shadow: none;

  &:global([class].l),
  &:global([class].l .ant-input-affix-wrapper) {
    height: 44px;

    @include media-phone() {
      height: 50px;
    }
  }

  &:global([class].s),
  &:global([class].s .ant-input-affix-wrapper) {
    height: 32px;

    input {
      font-size: 13px;
      line-height: 1.384;

      @include media-phone() {
        font-size: 16px;
        line-height: 1.187;
      }
    }

    @include media-phone() {
      height: 50px;
    }
  }

  &:hover,
  &:focus {
    border-color: $palette-stroke-primary;
  }

  &:global([class]::placeholder),
  input::placeholder {
    @include placeholder();
  }

  :global(.ant-input-search-icon) {
    padding: 0 14px;
  }

  :global(.ant-input-search-icon::before) {
    border: 0;
    background: $palette-text-secondary-dark;
  }

  :global([class].ant-input-affix-wrapper:focus),
  :global([class].ant-input-affix-wrapper-focused) {
    box-shadow: none;
  }

  :global([class].ant-input-affix-wrapper),
  &:global([class].ant-input-affix-wrapper) {
    padding: 8px 16px;

    @include media-phone() {
      padding: 12px;
    }
  }
}

.clearIcon {
  cursor: pointer;
  color: $palette-text-primary;
  width: 20px;
  height: 20px;
}

[class].suffix {
  position: relative;
  width: 20px;
  height: 20px;
  color: $palette-text-secondary-dark;
}
