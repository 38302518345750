@import '../../../../assets/style/mixins';

.header {
  display: flex;
  margin-bottom: 2em;

  @include media-tablet() {
    margin-bottom: 1.6em;
  }
}

.search {
  flex-grow: 1;
}

.container {
  position: relative;
}
