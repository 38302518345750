@import '../../../../../assets/style/mixins';

[class].emptyList {
  justify-content: flex-start;
}

[class].skeleton {
  :global(.ant-skeleton-content) {
    padding-right: 2em;
  }

  :global(.ant-skeleton-content .ant-skeleton-paragraph) {
    li {
      width: 100% !important;
      height: 83px;
      border-radius: 0;

      & + li {
        margin-top: 0;
        padding: 0 20px;
        border-top: 1px solid $palette-stroke-primary;
        border-radius: 0;
      }

      &:last-child {
        border-radius: 0 0 4px 4px;
      }

      &:first-child {
        border-radius: 4px 4px 0 0;
      }
    }
  }
}
