@import './colors';
@import './variables.module';
@import './animations';

@mixin field($width, $marginBottom: 0) {
  width: $width;
  margin-bottom: $marginBottom;
}

@mixin button($min-width, $margin: 0) {
  min-width: $min-width;
  width: $min-width;
  margin: $margin;
}

@mixin modalCommonComponent() {
  .root {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: $main-font-size;
    margin-top: 1.8em;

    @include media-phone() {
      margin-top: 1em;
    }

    &Icon {
      margin-bottom: 2.8em;

      @include media-phone() {
        margin-bottom: 2em;
      }
    }

    &Title {
      margin: 0;
      font-size: 1.4em;
      line-height: 1.571;
      color: $palette-text-primary;
    }

    &Text {
      margin: 0;
      font-size: 1.4em;
      line-height: 1.571;
    }

    &Error {
      margin-bottom: 1.5em;
    }

    &Button {
      margin-top: 2em;

      @include media-tablet() {
        margin-top: 1.75em;
      }

      @include media-phone() {
        margin-top: 1.25em;
      }
    }

    &Link {
      margin-top: 1.428em;
      padding: 0;
      border: 0;
      background: none;

      @include link();
      font-weight: 400;
      font-size: 1.4em;
      line-height: 1.357;
    }
  }
}

@mixin modalTitleWithoutDescriptionGap() {
  margin-top: 30px;

  @include media-phone() {
    margin-top: 18px;
  }
}

@mixin modalButtonGapLG() {
  margin: 40px auto 0;
}

@mixin modalButtonGapReg() {
  margin: 28px auto 0;

  @include media-phone() {
    margin-top: 20px;
  }
}

@mixin link($font-size: inherit, $line-height: inherit) {
  text-decoration: none;
  color: $palette-secondary;
  font-weight: 600;
  font-size: $font-size;
  line-height: $line-height;
  border-bottom: 1px dashed $palette-secondary;
  cursor: pointer;

  &:hover {
    color: $palette-secondary-light;
    border-bottom-color: rgba($palette-secondary-light-rgb, 0.4);
  }

  &:active {
    color: $palette-secondary-dark;
    border-bottom-color: rgba($palette-secondary-dark-rgb, 0.4);
  }

  &:disabled {
    color: $palette-disable;
    border-bottom-color: $palette-disable;
  }
}

@mixin paper($border: 1px solid $palette-stroke-primary, $radius: 4px) {
  background-color: $white;
  border-radius: $radius;
  border: $border;
}

@mixin filter($width: 286px, $margin-right: 20px) {
  width: $width;
  min-width: $width;
  margin-right: $margin-right;
  margin-bottom: 50px;
  max-height: 822px;

  display: flex;
  flex-direction: column;

  position: sticky;
  top: 5px;
}

@mixin filterItemRow() {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 9px 3px;
  background-color: transparent;
  border: none;

  &:hover {
    background-color: $palette-secondary-hover;
  }

  &Title {
    color: $palette-text-primary;

    @include ellipsis();

    &Accented {
      font-weight: 600;
    }
  }
}

@mixin dividerBottom($parent-left-right-padding: 20px, $parent-position: relative, $bottom: 0) {
  position: $parent-position;

  &::after {
    content: '';
    position: absolute;
    width: calc(100% - #{$parent-left-right-padding * 2});
    height: 1px;
    background-color: $palette-stroke-primary;
    bottom: $bottom;
    left: $parent-left-right-padding;
  }

  &:last-child {
    &::after {
      display: none;
    }
  }
}

@mixin dividerTop($parent-left-right-padding: 20px, $parent-position: relative, $top: 0) {
  position: $parent-position;

  &::before {
    content: '';
    position: absolute;
    width: calc(100% - #{$parent-left-right-padding * 2});
    height: 1px;
    background-color: $palette-stroke-primary;
    top: $top;
    left: $parent-left-right-padding;
  }
}

@mixin listContainer($min-width: 600px) {
  min-width: $min-width;
  width: 100%;
  margin: 0;
  padding: 0;

  list-style: none;
  display: flex;
  flex-direction: column;
}

@mixin listContainerItem($min-height: 200px, $margin: 0 0 30px, $padding: 0 30px 30px) {
  min-height: $min-height;
  width: 100%;
  display: flex;
  margin: $margin;
  padding: $padding;

  @include dividerBottom();

  &:first-child {
    padding-top: 30px;
  }

  &:last-child {
    margin: 0;
  }
}

@mixin contentContainer() {
  display: flex;
  align-items: flex-start;
  width: 100%;
  min-height: calc(100vh - 180px);
}

@mixin contentContainerCenter($max-width: 1600px, $padding: 30px) {
  width: 100%;
  max-width: $max-width;
  margin: 0 auto;
  padding: $padding;
}

@mixin tabItem() {
  position: relative;
  padding: 0 15px 9px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  text-decoration: none;
  color: $palette-text-secondary-dark;

  &::after {
    content: '';
    position: absolute;
    top: 25px;
    left: -50%;
    width: 100%;
    height: 3px;
    background-color: transparent;
    border-radius: 100px;
    transform: translateX(50%);
  }

  &:hover {
    text-decoration: none;
    color: $palette-text-secondary-dark;
  }
}

@mixin tabItemActive() {
  color: $palette-text-primary;
  font-weight: bold;

  &::after {
    background-color: $palette-secondary;
  }

  &:hover {
    color: $palette-text-primary;
  }
}

@mixin dialog() {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@mixin paginationWrapper() {
  display: flex;
  justify-content: flex-end;
  margin-top: 2.8em;

  @include media-tablet() {
    margin-top: 2.4em;
  }

  @include media-phone() {
    justify-content: flex-start;
    margin-bottom: 5.6em;

    @supports (padding: max(0px)) {
      margin-bottom: unquote('max(5.6em, calc(env(safe-area-inset-bottom) + 5.6em))');
    }
  }
}

@mixin fileList() {
  user-select: none;

  :global {
    @include checkbox();
    @include table();
  }

  :global(.ant-table-tbody > tr.ant-table-row:hover > td) {
    background: transparent;
  }

  :global(.ant-table-tbody .ant-table-cell) {
    padding: 16px 0;
  }

  :global(.ant-table-thead .ant-table-cell) {
    padding: 6px 0;
  }

  :global(.ant-table-thead .ant-table-cell),
  :global(.ant-table-tbody .ant-table-cell) {
    &::before {
      display: none;
    }
  }

  :global(.ant-table-thead th.ant-table-column-has-sorters) {
    transition: none;
  }

  :global([class].ant-table-row-selected) {
    background: $palette-info-light;
  }

  @include media-tablet() {
    :global(.ant-table-layout-fixed) {
      padding: 0 20px;
      background-color: $white;
      border: 1px solid $palette-stroke-primary;
      border-bottom: none;
      border-radius: 4px 4px 0 0;
    }

    [class]:global(.ant-table-pagination.ant-pagination) {
      flex-wrap: wrap;
      margin-top: 0;
    }

    :global(.ant-table + .ant-pagination .ant-pagination-total-text) {
      position: static;
      left: unset;
      width: 100%;
      height: auto;
      padding: 20px 0 24px 20px;
      margin-right: 0;
      margin-bottom: 24px;

      line-height: 19px;
      background-color: $white;
      border: 1px solid $palette-stroke-primary;
      border-top: none;
      border-radius: 0 0 4px 4px;
    }
  }

  @include media-phone() {
    :global(.ant-table-layout-fixed) {
      padding: 0 16px;
    }

    :global(.ant-checkbox-inner) {
      width: 22px;
      height: 22px;
    }

    :global(.ant-table-thead .ant-table-cell) {
      padding-bottom: 4px;
    }

    :global(.ant-table-tbody .ant-table-cell) {
      padding: 16px 0;
    }

    :global(.ant-table + .ant-pagination .ant-pagination-total-text) {
      padding: 16px 0 20px 16px;
    }
  }
}

@mixin checkbox() {
  .ant-checkbox {
    top: 0;

    + span {
      display: flex;
      padding: 0;
      min-width: 0;
    }
  }

  .ant-checkbox-wrapper {
    align-items: center;
  }

  .ant-checkbox-inner {
    width: 18px;
    height: 18px;
    border: 2px solid $palette-stroke-primary-dark;
    border-radius: 2px;
    transition: none;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $palette-primary;
    border: none;
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    width: 6px;
    height: 12px;
    top: 45%;
    left: 20%;
  }

  .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: $palette-stroke-primary-dark;
  }

  .ant-checkbox-disabled .ant-checkbox-inner::after {
    border-color: $white !important;
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: $palette-primary;
    width: 6px;
    height: 6px;
  }
}

@mixin table() {
  .ant-table-cell {
    font-size: 13px;
    line-height: 1.384em;
    text-align: right;

    & .ant-table-column-sorter.ant-table-column-sorter-full {
      display: none;
    }

    & .ant-table-column-sorters {
      min-height: 46px;
    }

    &.ant-table-selection-column {
      text-align: left;
    }
  }

  .ant-table .ant-table-selection-col {
    width: 50px;
    text-align: left;
  }

  .ant-table-thead th.ant-table-column-sort {
    background: none;
  }

  .ant-table-column-sorter-inner {
    display: none;
  }

  .ant-table + .ant-pagination {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-end;

    .ant-pagination-total-text {
      position: absolute;
      left: 0;
      font-size: 14px;
      color: $palette-text-secondary-dark;
    }
  }

  .ant-table-thead > tr > th {
    font-weight: 600;
    font-size: 12px;
    line-height: 1.333em;
  }

  .ant-table-thead tr .ant-table-selection-column:first-child,
  .ant-table-tbody .ant-table-row .ant-table-selection-column:first-child {
    padding-left: 16px;
    padding-right: 16px;
  }

  @include media-tablet() {
    .ant-table .ant-table-selection-col {
      width: 34px;
    }

    .ant-table-thead tr .ant-table-selection-column:first-child,
    .ant-table-tbody .ant-table-row .ant-table-selection-column:first-child {
      padding-left: 0;
    }

    .ant-table-tbody .ant-table-row-selected .ant-table-cell {
      &:first-child,
      &:last-child {
        position: relative;

        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          width: 20px;
          height: calc(100% + 1px);
          background-color: $palette-info-light;
          border-bottom: 1px solid $palette-info-light;
        }
      }

      &:first-child::before {
        left: 0;
        transform: translate(-100%, 0);
      }

      &:last-child::before {
        right: 0;
        transform: translate(100%, 0);
      }
    }
  }

  @include media-phone() {
    .ant-table .ant-table-selection-col {
      width: 38px;
    }

    .ant-table-tbody .ant-table-row-selected .ant-table-cell {
      &:first-child,
      &:last-child {
        &::before {
          width: 16px;
        }
      }
    }
  }
}

@mixin widgetWrapper() {
  padding: 8px 0;
  background-color: $white;
  border: 1px solid $palette-stroke-primary;
  border-radius: 4px;
}

@mixin caseTitle() {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: $palette-text-primary;
  margin: 0;
}

@mixin ellipsis($width: 100%) {
  width: $width;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin disabledIcon($fill: $palette-disable) {
  cursor: not-allowed;

  path {
    fill: $fill;
  }
}

@mixin xxsImage() {
  width: 20px;
  min-width: 20px;
  height: 20px;
  font-size: 9px;
  line-height: 12px;
}

@mixin xsImage() {
  width: 30px;
  min-width: 30px;
  height: 30px;
  font-size: 12px;
}

@mixin smImage() {
  width: 36px;
  min-width: 36px;
  height: 36px;
  font-size: 15px;
}

@mixin mdImage() {
  width: 42px;
  min-width: 42px;
  height: 42px;
  font-size: 18px;
}

@mixin lmdImage() {
  width: 46px;
  min-width: 46px;
  height: 46px;
  font-size: 18px;
}

@mixin lgImage() {
  width: 70px;
  min-width: 70px;
  height: 70px;
  font-size: 26px;
}

@mixin llgImage() {
  width: 100px;
  min-width: 100px;
  height: 100px;
  font-size: 32px;
}

@mixin evenChatMessageWrapper() {
  div:nth-of-type(2n) > div > & {
    @content;
  }
}

@mixin sub-navigation-tablet-view() {
  @include media-tablet() {
    position: fixed;
    z-index: $zIndex-popup;
    top: $header-fixed-height-tablet;
    left: calc(#{$sider-fixed-width-tablet} + 1.6em);
    right: 1.6em;
    padding: 1.6em 0;
    background-color: $palette-body-background;
  }

  @include media-phone() {
    position: static;
    padding-top: 0;
    background-color: transparent;
  }
}

@mixin online($top: 0, $right: 0, $bottom: 0, $left: 0) {
  .online {
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
    border-radius: 8px;
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: #44b700;
    color: #44b700;
    box-shadow: 0 0 0 3px transparent;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    transition: all 0.25s;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 1px solid currentColor;
    }
  }

  .online.show {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
    transition: opacity 0.25s, transform 0.25s;

    &::after {
      animation: rippleOnline 1.8s 1 ease-in-out;
    }
  }

  @keyframes rippleOnline {
    0% {
      transform: scale(0.8);
      opacity: 1;
    }
    100% {
      transform: scale(2.4);
      opacity: 0;
    }
  }
}

@mixin skeletonListItems() {
  padding: 2.8em;

  &:nth-last-child(n + 2) {
    border-bottom: 1px solid $palette-stroke-primary;
  }

  @include media-tablet() {
    padding: 2em;
  }

  @include media-phone() {
    padding: 2em 1.6em;
  }
}

@mixin primaryButton($min-width, $margin: 0) {
  @include button($min-width, $margin);
  background-color: $palette-primary;
  border: 2px solid $palette-primary;

  &[class]:hover {
    background: $palette-primary-light;
    border: 2px solid $palette-primary-light;
  }

  &[class]:active {
    background: $palette-primary-dark;
    border: 2px solid $palette-primary-dark;
  }
}

@mixin chat-popover() {
  position: absolute;
  top: 51px;
  right: 0;
  z-index: $zIndex-popover;
  display: flex;
  flex-direction: column;

  width: 388px;
  min-height: 84px;
  font-size: $main-font-size;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  background-color: $white;

  @include media-phone() {
    position: fixed;
    top: calc(#{$header-fixed-height-phone} + 1.6em);
    bottom: calc(#{$sider-fixed-height-phone} + 1.6em);
    left: 16px;
    right: 16px;
    height: auto;
    width: auto;
    overflow: hidden;
    background-color: $white;
  }

  &Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1em 1.6em;
    background-color: $palette-primary;
    border-radius: 4px 4px 0 0;

    @include media-phone() {
      justify-content: flex-start;
      padding: 1.6em 0 1.4em 1.2em;
      background-color: $white;
      border-bottom: 1px solid $palette-stroke-primary;
    }
  }
}

@mixin media-tablet(
  $maxWidth: $media-desktop-min-width + 'px',
  $minWidthLandscape: $media-desktop-min-width
) {
  @media screen and (max-width: $maxWidth),
    screen and (min-width: $minWidthLandscape) and (max-width: $maxWidth) and (orientation: landscape) {
    @content;
  }
}

@mixin media-tablet-landscape(
  $maxWidth: $media-desktop-min-width + 'px',
  $minWidth: $media-desktop-min-width
) {
  @media screen and (min-width: $minWidth) and (max-width: $maxWidth) and (orientation: landscape) {
    @content;
  }
}

@mixin media-phone(
  $maxWidth: $media-mobile-max-width + 'px',
  $maxWidthLandscape: $media-desktop-min-width + 'px',
  $maxHeightLandscape: $media-mobile-max-height-landscape + 'px'
) {
  @media screen and (max-width: $maxWidth),
    screen and (max-height: $maxHeightLandscape) and (max-width: $maxWidthLandscape) and (orientation: landscape) {
    @content;
  }
}

@mixin media-phone-landscape(
  $maxWidth: $media-desktop-min-width + 'px',
  $maxHeight: $media-mobile-max-height-landscape + 'px'
) {
  @media screen and (max-height: $maxHeight) and (max-width: $maxWidth) and (orientation: landscape) {
    @content;
  }
}

@mixin media-custom($width) {
  @media screen and (max-width: ($width + 'px')) {
    @content;
  }
}

@mixin media-custom-landscape($width) {
  @media screen and (max-width: ($width  + 'px')) and (orientation: landscape) {
    @content;
  }
}

@mixin placeholder() {
  opacity: 1;
  color: $palette-text-secondary-dark;
}

@mixin hideRangePickerSecondCalendar() {
  :global(.ant-picker-panels > *:first-child button.ant-picker-header-next-btn) {
    visibility: visible !important;
  }

  :global(.ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn) {
    visibility: visible !important;
  }

  :global(.ant-picker-panels > *:nth-child(2)) {
    display: none;
  }

  :global(.ant-picker-footer-extra > div) {
    flex-wrap: wrap !important;
  }
}

@mixin tableContextMenuIcon() {
  margin-left: auto;
  margin-right: 6px;
  max-width: fit-content;

  @include media-tablet() {
    margin-right: 0;
  }
}

@mixin emoji($size: 19px) {
  font-size: 16px;
  line-height: $size;
  color: transparent;
  height: $size;
  width: $size;
  vertical-align: bottom;

  > span {
    height: $size !important;
    width: $size !important;
  }
}

@mixin insetBackground($color: $palette-info-light, $horizontalMargin: 100vmax) {
  background-color: $color;
  box-shadow: 0 0 0 100vmax $color;
  clip-path: inset(0 -#{$horizontalMargin});
}

@mixin rowHover($color: $palette-info-light, $horizontalMargin: 100vmax) {
  &,
  & > * {
    position: relative;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    inset: 0;
    transition: visibility 0.25s ease-in-out, opacity 0.25s ease-in-out;
    visibility: hidden;
    opacity: 0;

    @include insetBackground($color, $horizontalMargin);
  }

  &:hover {
    &::before {
      content: '';
      visibility: visible;
      opacity: 1;
    }
  }
}

@mixin drawerRightScrollPadding() {
  padding-right: 16px;
  margin-right: -20px;
}
