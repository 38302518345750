@import '../../../../assets/style/mixins';

.root {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.row {
  gap: 16px;
  display: flex;
  align-items: center;
}

@include media-tablet() {
  .row {
    gap: 12px;
  }
}

@include media-phone() {
  .row {
    flex-direction: column;
    align-items: flex-start;
  }
}
