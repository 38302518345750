@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  align-items: center;
  font-size: 1.6em;
  line-height: 1.5em;
  font-weight: 700;
  column-gap: 8px;
}

.icon {
  width: 20px;
  height: 20px;
  color: $palette-primary;
  flex-shrink: 0;
}
