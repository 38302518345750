.root {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

[class].field {
  width: 100%;
  margin-bottom: 20px;
}
