@import '../../../../assets/style/mixins';

.title {
  margin-bottom: 22px;
  font-weight: bold;
  font-size: 1.6em;
  line-height: 1.375;
  color: $palette-text-primary;

  @include media-tablet() {
    margin-bottom: 1em;
  }
}
