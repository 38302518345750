@import '../../../../../assets/style/mixins';

[class].wrapperRoot {
  padding: 12px 28px;
  border-bottom: 1px solid $palette-stroke-primary;
  min-height: $chat-header-desktop;

  &.clickable {
    cursor: pointer;
  }
}

[class].userName {
  font-size: 1.6em;
  line-height: 1.388;
}

[class].deactivateHeader {
  width: 100%;
  border-right: 1px solid $palette-stroke-primary;
}

[class].backButton {
  display: flex;
  align-items: center;
  font-weight: 600;
  margin-right: 16px;

  svg {
    width: 24px;
    height: 24px;
  }
}

@include media-tablet() {
  [class].wrapperRoot {
    width: 100%;
    padding: 15px;
    border: 1px solid $palette-stroke-primary;
    border-radius: 4px 4px 0 0;
  }
}

@include media-phone() {
  [class].wrapperRoot {
    border-left: 1px solid $palette-stroke-primary;
    border-radius: 4px 4px 0 0;
    min-height: $chat-header-phone;
  }

  [class].userName {
    font-size: 1.4em;
  }

  [class].deactivateHeader {
    border-left: 1px solid $palette-stroke-primary;
  }
}
