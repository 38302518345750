@import '../../../../assets/style/mixins';

.form {
  @include modalTitleWithoutDescriptionGap();

  form {
    @include dialog();
    width: 100%;
  }
}

.button {
  @include modalButtonGapLG();
}
