@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
  min-height: 44px;
  max-height: 76px;
  border: 1px solid $palette-stroke-primary;
  padding: 8px;
  border-radius: 4px;
  overflow: auto;
}

.root [class].search:global(.ant-input-affix-wrapper) {
  padding: 0;
  border: none;
  flex: 1 0;
  min-width: 70px;
}

.chip {
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  column-gap: 2px;
  padding: 6px 8px;
  border-radius: 4px;
  line-height: 16px;
  font-size: 12px;
  background-color: $palette-info-light;

  &Icon {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
}
