@import '../../../../assets/style/mixins';

.list,
.listEmpty {
  background: $white;
  border-radius: 4px;
  border: 1px solid $palette-stroke-primary;
}

.listEmpty {
  padding: 8em 0;
}

.list {
  background: $white;
  border-radius: 4px;
  border: 1px solid $palette-stroke-primary;

  &Skeleton {
    height: 100%;
    overflow-y: hidden;
  }
}

.pagination {
  @include paginationWrapper();
}
