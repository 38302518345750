@import '../../../../assets/style/mixins';

.editorContainer {
  position: relative;
  cursor: text;
  font-size: 1.3em;
  line-height: 1.461;

  &.focused {
    border-color: $palette-secondary;
  }

  &.invalid {
    border-color: $palette-error;
  }

  :global(.DraftEditor-root) {
    max-height: 120px;
    width: 100%;
    padding-right: 4px;
    font-size: inherit;
    line-height: inherit;
    overflow-y: scroll;
    color: $palette-text-primary;
  }

  :global(.DraftEditor-editorContainer) {
    width: 100%;
  }

  :global(.public-DraftEditorPlaceholder-inner),
  :global(.public-DraftEditor-content) {
    width: 100%;
  }

  :global(.public-DraftStyleDefault-block) {
    :global(.emoji-mart-emoji) {
      @include emoji();
    }
  }

  :global(.public-DraftEditorPlaceholder-root) {
    pointer-events: none;
    position: absolute;

    @include placeholder();
  }

  :global(.emoji-mart) {
    position: absolute;
    z-index: $zIndex-chat-header + 1;
    top: -370px;
    right: 0;
    cursor: default;
  }

  :global(.emoji-mart-search-icon) {
    transform: translateY(-50%);
    top: 50%;
  }

  :global(.emoji-mart-scroll) {
    padding: 0 0 6px 6px;
  }

  &.disabled {
    :global(.public-DraftEditor-content) {
      display: none;
    }

    :global(.public-DraftEditorPlaceholder-root) {
      position: static;
    }
  }
}

.editorHelperText {
  position: absolute;
  margin-top: 4px;

  &Hidden {
    display: none;
  }
}

.editorStartAdornment,
.editorEndAdornment {
  display: flex;
  align-items: center;
}

.editorEndAdornment {
  margin-left: auto;
}

[class].emojiWindow :global(.emoji-mart.emoji-mart-light) {
  left: 0;
  width: 100% !important;
  margin: 0 auto;
}

[class].emojiIcon {
  margin-right: 1.2em;
  font-size: $main-font-size;
  color: $palette-text-secondary-dark;
}

@include media-phone() {
  .editorContainer {
    font-size: 1.6em;
    line-height: 1.375;

    :global(.DraftEditor-root) {
      max-height: 100px;
    }
  }
}
