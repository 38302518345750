@import 'src/assets/style/colors';

[class].root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 149px;

  & > :global(.ant-skeleton) {
    padding: 0;
    background: none;
    border: none;
    border-radius: unset;
  }
}

.root [class].title > :global(.ant-skeleton-content) {
  display: flex;
  justify-content: center;

  & > :global(.ant-skeleton-title) {
    width: 100px;
    height: 20px;
    margin: 8px 0 0;
  }
}
