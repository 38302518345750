@import '../../../assets/style/colors';

.contentCenter {
  position: relative;

  width: 100%;
  height: 100%;
  margin: auto;
  min-width: 16px;
  min-height: 16px;
}

.root {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: $palette-secondary;
}
