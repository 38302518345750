@import '../../../../../assets/style/mixins';

[class].wrapper {
  align-items: center;
  justify-content: center;
  padding: 1em 2em;
}

.avatarContainer {
  position: relative;
}

.count {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  box-shadow: $white 0 0 0 2px;

  &Active {
    box-shadow: $palette-primary 0 0 0 2px;
  }

  &Bottom {
    top: unset;
    bottom: -10px;
  }
}

.skeleton {
  margin: 0;

  :global(.ant-skeleton-header) {
    padding: 0;
  }
}
