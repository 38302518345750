@import '../../../../assets/style/mixins';

$measurementDesktopWrapWidth: 1221;

.root {
  @include paper();

  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  font-size: $main-font-size;

  padding: 2em 2.8em;

  @include media-custom($measurementDesktopWrapWidth) {
    padding: 2em 1.6em;
  }

  @include media-tablet() {
    padding: 2em;
  }

  @include media-phone() {
    flex-direction: column;
    align-items: flex-start;
    padding: 2em;
  }
}

.icon {
  width: 56px;
  height: 56px;
  background-color: rgba($palette-secondary-rgb, 0.1);
  color: $palette-secondary-dark;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  svg {
    width: 50%;
    height: 50%;
  }

  @include media-custom($measurementDesktopWrapWidth) {
    width: 50px;
    height: 50px;
  }

  @include media-phone() {
    width: 36px;
    height: 36px;
  }
}

.info {
  display: flex;
  flex-direction: column;
  margin-left: 1.6em;
  color: $palette-text-primary;

  @include media-custom($measurementDesktopWrapWidth) {
    margin: 0 0 0 1.2em;
  }

  @include media-phone() {
    margin: 1.2em 0 0 0;
  }
}

.text {
  font-size: 1.5em;
  line-height: 1.571;
  overflow-wrap: break-word;
}

.title {
  margin-bottom: 1em;

  font-size: 1.2em;
  line-height: 1.333;
  font-weight: bold;
  text-transform: uppercase;

  @include media-phone() {
    margin-bottom: 0.666em;
  }
}

.cell {
  padding: 1.2em 2.8em;

  @include media-custom($measurementDesktopWrapWidth) {
    padding: 1.5em 1.6em;
  }

  @include media-tablet() {
    padding: 1.5em 2em;
  }

  @include media-phone() {
    padding: 1.5em 2em;
    flex-direction: row;
    align-items: center;
  }

  .info {
    @include media-custom($measurementDesktopWrapWidth) {
      margin: 0 0 0 0.8em;
    }

    @include media-phone() {
      margin: 0 0 0 1.6em;
    }
  }

  & .text {
    font-weight: bold;
  }

  & .title {
    margin-bottom: 0.285em;

    font-size: 1.4em;
    line-height: 1.571;
    font-weight: normal;
    text-transform: initial;

    @include media-phone() {
      margin-bottom: 0.285em;
    }
  }

  @include media-phone() {
    .icon {
      display: flex;
    }
  }
}
