@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
}

[class].infiniteScroll {
  overflow: unset !important;
}
