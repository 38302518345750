@import '../../../../../assets/style/mixins';
.root {
  @include modalButtonGapReg();
}

[class].steps {
  max-width: unset;
  margin-bottom: 28px;
}

.email {
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  margin-bottom: 28px;
}
