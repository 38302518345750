@import '../../../../assets/style/mixins';

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 20px;
  min-height: 76px;
  padding: 20px 0;
  transition: height 0.75s;

  &:nth-last-child(n + 2) {
    border-bottom: 1px solid $palette-stroke-primary;
  }

  &.editing {
    padding: 16px 0;
  }

  &.error {
    padding-bottom: 28px;
  }
}

.icon {
  width: 32px;
  height: 32px;
}

.label {
  font-size: 14px;
  line-height: 19px;
  font-weight: bold;
}

.field {
  width: 227px;
}

[class].deleteButton {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  color: $palette-text-primary;
}

.urlContainer,
.urlWrapper {
  min-width: 0;
}

.url {
  width: 100%;
  max-width: 36ch;
  font-size: 14px;
  line-height: 19px;
}

[class].skeleton {
  :global {
    .ant-skeleton-content .ant-skeleton-paragraph {
      margin: 0;
      display: flex;
      align-items: center;
      column-gap: 16px;

      > li {
        margin: 0;
        height: 20px;

        &:nth-child(1) {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          flex-shrink: 0;
        }

        &:nth-child(2) {
          width: 60px;
        }

        &:nth-child(3) {
          width: 290px;
          margin-left: auto;
        }

        &:nth-child(4) {
          width: 20px !important;
          flex-shrink: 0;
        }
      }
    }
  }
}

@include media-phone() {
  .root {
    flex-direction: column;
    row-gap: 20px;

    &.editing,
    &.error {
      padding: 20px 0;
    }
  }
}
