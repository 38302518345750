@import '../../../../assets/style/mixins';

.root {
  > div {
    padding: 16px 0;
    border-top: 1px solid $palette-stroke-primary;

    &:first-of-type {
      border: none;
    }
  }
}

.dayLabel {
  display: block;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  margin-bottom: 12px;
  color: $palette-text-secondary-dark;
  text-transform: uppercase;
}
