@import '../../../../../../assets/style/mixins';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}

.description {
  text-align: center;
  margin-bottom: 28px;
}

.button {
  margin-top: 40px;
}

[class].topLabel {
  font-weight: 400;
}

@include media-phone() {
  .button {
    margin-top: 28px;
  }
}
