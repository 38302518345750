@import '../../../../assets/style/mixins';

.root {
  border-radius: 4px;
  padding: 8px 20px;
  font-weight: 600;
  text-align: center;

  &.sm {
    font-size: 12px;
    line-height: 20px;
  }

  &.md {
    font-size: 14px;
    line-height: 22px;
  }

  &.success {
    background-color: $palette-success-light;
    color: $palette-success-dark;
  }

  &.info {
    background-color: $palette-info-light;
    color: $palette-info-dark;
  }

  &.warning {
    background-color: $palette-warning-light;
    color: $palette-warning-dark;
  }

  &.error {
    background-color: $palette-error-light;
    color: $palette-error-dark;
  }
}
