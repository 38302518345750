@import '../../../../../assets/style/mixins';

.root {
  max-width: 100%;
  max-height: 100%;
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba($palette-black-rgb, 0.5);
  }

  :global(.swiper-slide-thumb-active) & {
    &::after {
      display: none;
    }
  }
}

.slideIndex {
  z-index: 2;
  right: 4px;
  bottom: 4px;
  padding: 0 4px;
  position: absolute;
  background: $white;
  color: $palette-secondary;
  border-radius: $secondary-border-radius;
  border: 1px solid $palette-stroke-primary;
}
