@import '../../../../../assets/style/mixins';

[class].rootWrapper {
  display: flex;
  margin: 16px 0 28px;
  border: 1px solid $palette-stroke-primary;
  border-radius: 5px;
}

[class].editorContainer,
[class].editorContainerDisabled {
  width: 100%;

  :global(.DraftEditor-root) {
    margin: 12px 12px 13px;
  }
}

[class].editorContainerDisabled,
[class].editorContainerInactive {
  display: flex;
  justify-content: space-between;
  cursor: not-allowed;

  :global(.public-DraftEditorPlaceholder-inner) {
    color: $palette-error;
    word-break: normal;
  }
}

[class].editorWrapper {
  display: flex;
  padding: 0px 12px;
  width: 100%;

  &Inactive {
    padding: 0;

    :global(.DraftEditor-root) {
      margin: 12px 15px;
    }
  }
}

[class].editorEndAdornmentInactive {
  margin-right: 0.6em;
}

[class].audio {
  padding: 2px 8px;
}

[class].skeleton {
  min-height: 44px;

  &Title {
    &:global(.ant-skeleton-title) {
      margin: 0;
      height: 100%;
    }
  }
}

@include media-tablet() {
  [class].rootWrapper {
    position: relative;
    min-height: 44px;
    margin: 0 0 24px;
  }

  [class].editorContainerInactive {
    font-size: 1.4em;
  }
}

@include media-phone() {
  [class].rootWrapper {
    min-height: 50px;
    margin-bottom: 28px;

    &Inactive {
      margin-top: 0;
    }
  }

  [class].editorContainer,
  [class].editorContainerDisabled {
    :global(.DraftEditor-root) {
      margin: 0 0 12px;
      order: -1;
    }
  }

  [class].skeleton {
    min-height: 76px;
  }

  [class].editorWrapper {
    padding: 8px 12px 12px;
    flex-wrap: wrap;

    &Inactive {
      flex-wrap: wrap;

      :global(.DraftEditor-root) {
        text-align: center;
      }
    }
  }

  [class].editorEndAdornmentInactive {
    margin: 0 auto 12px;
  }
}

@include media-phone-landscape() {
  [class].rootWrapperInactive {
    margin-bottom: 0;
  }

  [class].editorContainer,
  [class].editorContainerDisabled {
    :global(.DraftEditor-root) {
      margin: 12px 12px 13px;
      order: unset;
    }
  }

  [class].editorWrapper {
    padding: 0px 12px;
    flex-wrap: nowrap;
  }
}
