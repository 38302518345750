@import '../../../../assets/style/mixins';

.search {
  margin: 16px 28px 0;
  display: flex;
  align-items: center;
  gap: 16px;    
}

.viewToggle {
  padding: 0;
  width: 52px;
  justify-content: center;
}

@include media-tablet() {
  .search {
    gap: 12px;
    margin: 16px 20px 0;
  }
}

@include media-phone() {
  .search {
    gap: 8px;
  }
}
