@import '../../../../../../assets/style/mixins';

.title {
  margin-bottom: 20px;
  font-size: 17px;
  line-height: 24px;
  font-weight: bold;
  text-transform: uppercase;
}

.filter {
  font-size: $main-font-size;
  display: flex;
  flex-shrink: 0;

  :global(.ant-input-affix-wrapper) {
    height: 40px;
  }
}

.contact {
  display: flex;
  flex-shrink: 0;
  margin: 1.6em 0;
  font-size: $main-font-size;

  &SubTitle {
    color: $palette-text-secondary-dark;
    font-size: 1.2em;
    line-height: 1.333em;
    font-weight: 600;

    &:first-child {
      margin-right: auto;
    }

    &:last-child {
      margin-left: 2.666em;
      margin-right: 0;
    }
  }
}

@include media-phone() {
  .filter {
    :global(.ant-input-affix-wrapper) {
      height: 50px;
    }
  }

  .contact {
    margin-top: 2em;
    padding-right: 0;
  }
}
