@import '../../../../../assets/style/mixins';

.container {
  position: fixed;
  top: 90px;
  right: 20px;
  z-index: $zIndex-call;
  width: 396px;
  height: 234px;
  background: none;

  &.static {
    position: static;
    width: 100%;
    height: 100%;
  }
}

.container.mobileFullScreen {
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  visibility: visible;
  opacity: 1;
}

.videoCallContainer,
.self {
  position: absolute;
  border-radius: 4px;

  background-color: $palette-call-background;

  &::after,
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    border-radius: 4px;
  }

  &::after {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 66.63%, rgba(0, 0, 0, 0.5) 100%);
    transform: rotate(-180deg);
  }

  &::before {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 56.21%, rgba(0, 0, 0, 0.5) 100%);
  }

  .static & {
    border-radius: 0;
  }
}

.videoCallContainer {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 4;

  .static &,
  .fullscreen & {
    display: flex;
    flex-direction: column;
  }
}

.self {
  top: 20px;
  right: 20px;
  width: 150px;

  height: 113px;
  z-index: 3;
  border: 2px solid $white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.waiting {
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: $palette-stroke-primary;
  z-index: 3;
}

.notSelf {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  border-radius: 4px;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.controlPanel {
  display: flex;
  align-items: center;
  padding: 0 12px 12px;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  z-index: 3;
  height: 50px;
  width: 100%;
  background-color: transparent;
}

.controlMediaWrapper {
  display: flex;
  min-width: 0;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
}

iframe,
.iframe {
  position: absolute;
  z-index: 5;
  border-radius: 4px;

  .static &,
  .fullscreen & {
    position: relative;
  }
}

.draggableBoxTop,
.draggableBoxLeft,
.draggableBoxRight,
.draggableBoxFooter {
  position: absolute;
  z-index: 6;
  cursor: move;
}

$draggableAreaSize: 56px;

.draggableBoxLeft,
.draggableBoxRight {
  width: $draggableAreaSize;
  height: 100%;
}

.draggableBoxTop,
.draggableBoxFooter {
  width: 100%;
  height: $draggableAreaSize;
}

.draggableBoxTop {
  top: 0;
}

.draggableBoxLeft {
  left: 0;
}

.draggableBoxRight {
  right: 0;
}

.draggableBoxFooter {
  bottom: 0;
}

.toggleFilmStrip {
  position: absolute;
  top: 50%;
  right: 4px;
  z-index: 7;
  padding: 0;
  margin: 0;
  border: none;
  background: none;

  svg {
    color: $white;
  }

  &Flip svg {
    transform: scale(-1);
  }
}

.callStatusWrapper {
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: $zIndex-secondary-menu-notification;
  color: $white;
}

@include media-tablet() {
  .container:not(.static):not(.mobileFullScreen) {
    width: 448px;
    height: 274px;
  }
}

@include media-phone() {
  .container:not(.static):not(.mobileFullScreen) {
    width: 1px;
    height: 1px;
    right: -9999px;
    top: -9999px;
    visibility: hidden;
    opacity: 0;
    cursor: default;
  }
}
