@import '../../../../../assets/style/mixins';

.grid {
  display: grid;
  grid-template-columns: 93px 93px 93px 93px;
  gap: 20px 12px;
  max-height: 448px;
  overflow: hidden auto;
  margin-right: -14px;
  padding-right: 6px;
}

@include media-phone() {
  .grid {
    grid-template-columns: 128px 128px;
    gap: 0px 16px;

    max-height: 540px;
  }
}
