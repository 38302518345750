@import '../../../../../assets/style/mixins';

.root {
  padding: 0 20px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 16px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
}

[class].button {
  font-weight: 700 !important;
  font-size: 14px;
  line-height: 19px;
}

@include media-phone() {
  .root {
    padding: 0 16px;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 16px;
    font-size: 14px;
    line-height: 19px;
  }

  [class].button {
    font-size: 12px;
    line-height: 16px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}
