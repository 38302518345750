.root {
  color: currentColor;
}

[class].skeleton {
  &:global(.ant-skeleton) {
    display: flex;
    flex-grow: 1;
  }

  & :global(.ant-skeleton-paragraph) {
    margin-bottom: 0;
  }
}
