@import '../../../../assets/style/mixins';

.root {
  &:global(.ant-popover-placement-bottom),
  &:global(.ant-popover-placement-bottomLeft),
  &:global(.ant-popover-placement-bottomRight),
  &:global(.ant-popover-placement-top),
  &:global(.ant-popover-placement-topLeft),
  &:global(.ant-popover-placement-topRight) {
    padding: 0;
  }

  &:global(.ant-popover) {
    z-index: $zIndex-popover;
  }

  :global {
    .ant-popover-inner {
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
      border-radius: 4px;
    }

    .ant-popover-inner-content {
      padding: 12px 0;
    }
  }
}

.hiddenArrow {
  & :global {
    .ant-popover-arrow {
      display: none;
    }
  }
}
