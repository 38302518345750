@import '../../../../../assets/style/mixins';

.root {
  padding: 2px;
  border-radius: 50%;
  border: 2px solid transparent;
}

.active {
  border-color: $palette-error;
}

.live {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}
