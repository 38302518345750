@import '../../../../../assets/style/colors';

.header {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.avatar {
  display: flex;
  align-items: flex-start;
  column-gap: 24px;
}

[class].account {
  min-width: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 8px;

  &Caption,
  &Name {
    font-size: 20px;
    line-height: 27px;
  }

  &Name {
    padding-right: 32px;
  }

  &Caption {
    color: $palette-text-primary;
    font-weight: normal;
    text-transform: lowercase;
  }
}

.actionButton {
  font-size: 16px;
  line-height: 22px;
}

.buttons {
  display: flex;
  column-gap: 12px;
}
