@import '../../../../assets/style/mixins';

.root {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

@include media-phone() {
  .root {
    height: calc(100vh - 280px);
  }

  .member {
    flex-direction: row;
    align-items: center;
  }
}

[class].skeleton {
  :global(.ant-skeleton-paragraph > li) {
    height: 30px;
  }
}
