@import '../../../../assets/style/colors';
@import '../../../../assets/style/mixins';

.skeleton {
  margin-bottom: 16px;

  :global(.ant-skeleton-content .ant-skeleton-title) {
    margin-top: 0;
  }

  :global(.ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph) {
    margin-top: 0;
    margin-bottom: 8px;
  }
}

[class].footer {
  border-top: 1px solid $palette-text-secondary-light;
  padding: 6px 0;
  display: flex;
  align-items: center;

  &Button {
    margin-left: auto;
    margin-right: 12px;
  }
}

.form,
[class].editor,
[class].editorWrapper {
  display: flex;
  flex-direction: column;
}

.form,
[class].editor,
[class].editorInput,
[class].editorInput textarea {
  height: 100%;
}

.form,
[class].editor {
  min-height: 0;
}

[class].editor {
  flex: 1 1;

  &Wrapper {
    flex: 1;
  }
}

@include media-phone() {
  [class].footer {
    flex-direction: column;
    align-items: stretch;
    padding: 12px 65px;

    &Button {
      order: 1;
      margin: 12px 0 0;
    }
  }
}
