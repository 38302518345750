[class].radioGroup {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

[class].radio {
  :global(.ant-radio + span) {
    overflow-wrap: break-word;
    min-width: 0;
  }
}
