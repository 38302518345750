@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 28px;
}

.title {
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  text-align: center;
}

.grid {
  gap: 0px 12px;
}

.item {
  margin-right: 12px;
}

.more {
  width: 91px;
  height: 91px;
  border: 1px solid $palette-stroke-primary-light;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &Wrapper {
    width: 93px;
  }

  &Container {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 70px;
  }

  &Text {
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
    text-align: center;
    color: $palette-secondary;
    text-decoration: underline;
  }
}

@include media-phone() {
  .grid {
    gap: 0px 16px;
  }

  .more {
    width: 112px;
    height: 112px;

    &Wrapper {
      width: 128px;
      padding: 12px 8px;
    }
  }

  .item {
    margin-right: 0;

    &:nth-child(odd) {
      margin-right: 16px;
    }
  }
}
