@import '../../../../assets/style/mixins';

[class].skeleton :global(.ant-skeleton-paragraph) {
  li {
    width: 100% !important;
    height: 50px;

    & + li {
      margin-top: 0.8em;
    }

    &:last-child {
      height: 28px;
      margin-top: 2.4em;
    }
  }
}

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  font-size: $main-font-size;
}

.wrapper {
  padding: 28px;
  height: 100%;
  min-height: 0;
  width: 100%;
  display: flex;
  flex-direction: column;

  @include paper();

  :global(.ant-table table) {
    overflow: hidden;
    width: 100% !important;
    min-width: 700px !important;
  }

  @include media-tablet() {
    padding: 0;
    background: none;
    border: none;
  }

  @include media-phone() {
    padding: 0;
    border: none;
    background: none;
  }
}

.text {
  margin-bottom: 0;
  font-size: 13px;
  line-height: 1.615;
  color: $palette-text-primary;
  line-break: anywhere;
}

.link {
  color: $palette-text-primary;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: $palette-text-primary;
    text-decoration: none;
  }

  &:active {
    color: $palette-text-primary;
    text-decoration: underline;
  }
}

.title {
  margin: 0 0 16px;
  font-weight: bold;
  font-size: 2em;
  line-height: 1.35;
  color: $palette-text-primary;

  @include media-phone() {
    font-size: 1.6em;
    line-height: 1.375;
  }

  &Wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.2em;
  }
}

.paginationWrapper {
  @include paginationWrapper();

  @include media-tablet() {
    > * {
      width: auto;
    }
  }
}

.content {
  &Skeleton {
    margin-top: 28px;
    overflow: hidden;
  }
}

[class].table {
  padding-right: 8px;

  @include media-tablet() {
    padding: 20px;
    @include paper();
  }
  @include media-phone() {
    padding: 0;
  }

  & :global {
    .ant-table-placeholder {
      td {
        border: none !important;
      }
    }

    .ant-table-cell {
      padding: 0 16px 0 0;
      border: none;

      &:last-child {
        padding-right: 0;
      }
    }

    .ant-table-expanded-row-fixed {
      margin: 0;
      padding: 0;
    }

    .ant-table-container {
      &::after,
      &::before {
        display: none;
      }
    }

    .ant-table-tbody {
      vertical-align: baseline;
    }

    .ant-table-tbody .ant-table-cell {
      border-bottom: 1px solid $palette-stroke-primary;
    }

    .ant-table-thead {
      & .ant-table-cell::before {
        display: none;
      }
    }

    .ant-table-filter-column {
      margin: 0;
      justify-content: start;

      .ant-table-column-title {
        flex: 0 0 auto;
      }
    }

    .ant-table-filter-column-title {
      flex: 0 1 auto;
      padding: 0.8em 0;
    }

    .ant-table-filter-trigger-container {
      position: static;
    }

    .ant-table-filter-trigger {
      display: flex;
      align-items: center;
      color: $palette-text-secondary-dark;

      &.active {
        color: $palette-secondary;
      }

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  &WithHiddenFilter {
    :global(.ant-table-filter-trigger) {
      display: none;
    }
  }
}

.detailWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.resetFilters {
  color: $palette-secondary;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  cursor: pointer;

  &Icon {
    width: 16px;
    height: 16px;

    margin-right: 4px;
  }
}

[class].showMoreDefault {
  max-height: 42px;
}

.cell {
  &:last-child {
    .cellContent {
      padding-right: 0;
    }
  }

  &Date {
    width: 149px;
    text-align: right;

    :global(.ant-table-column-title) {
      margin-left: auto;
    }
  }

  &ActionGroup {
    width: 154px;
    text-align: right;

    :global(.ant-table-column-title) {
      margin-left: auto;
    }
  }

  &Content {
    padding: 20px 0;

    & a {
      overflow-wrap: break-word;
    }
  }
}

.list {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
  line-height: 22px;
  border-radius: 4px;
  background-color: $white;

  li {
    padding: 16px;
    border-top: 1px solid $palette-stroke-primary;

    &:first-child {
      border: none;
    }
  }

  &Title {
    display: block;
    font-size: 13px;
    line-height: 17.7px;
    font-weight: 600;
    margin-bottom: 4px;
    color: $palette-text-secondary-dark;
  }

  &Item {
    margin-bottom: 8px;

    & a {
      overflow-wrap: break-word;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

[class].placeholder {
  min-height: 230px;
}
