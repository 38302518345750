@import '../../../../assets/style/mixins';

.root {
  padding-top: 12px;
  border-bottom: 1px solid $palette-stroke-primary;

  @include media-phone() {
    padding: 0;
    border: none;
  }
}

.wrapper {
  max-height: 92px;
  padding-right: 12px;
  margin-bottom: 12px;
  overflow: hidden scroll;

  @include media-tablet() {
    max-height: unset;
    overflow: unset;
    padding-right: 0;
  }

  @include media-phone() {
    margin-bottom: 0;
  }

  &EndAdornment {
    margin-bottom: 16px;
  }
}

[class].button {
  width: 100%;
  margin-bottom: 20px;
  padding: 0;

  font-size: 12px;
  line-height: 1.333;

  @include media-tablet() {
    height: 33px;
  }

  @include media-phone() {
    height: 44px;
    width: 100%;
    margin: 0;
    font-size: 14px;
    line-height: 1.357;
  }

  &Icon {
    width: 15px;
    height: auto;
    color: inherit;

    @include media-phone() {
      width: 20.6px;
    }
  }
}
