@import '../../../../../assets/style/mixins';

.audio {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &Time {
    display: flex;
    align-items: center;
    color: $palette-secondary;

    &Indicator {
      margin-left: 0.571em;
      font-size: 14px;
      line-height: 1.357;
      font-weight: bold;
      color: $palette-text-primary;
    }
  }

  &Listen {
    margin: 0 16px;
  }
}

[class].icon {
  &.stop {
    color: $palette-error;
  }

  svg {
    width: 40px;
    height: 40px;
  }

  &:hover {
    opacity: 0.8;
  }
}

.recordingIcon {
  width: 20px;
  height: 20px;
  color: $palette-secondary;
}
