@import '../../../../../assets/style/colors';

.root {
  display: flex;
  align-items: center;

  &.accepted {
    color: $palette-success;
  }

  &.pending,
  &.expired {
    color: $palette-warning-dark;
  }

  &.cancelled,
  &.rejected {
    color: $palette-error;
  }

  &.open {
    color: $palette-info-dark;
  }
}

.root.light {
  &.accepted {
    color: $palette-success-light;
  }

  &.pending,
  &.expired {
    color: $palette-warning-light;
  }

  &.cancelled,
  &.rejected {
    color: $palette-error-light;
  }

  &.open {
    color: $palette-info-light;
  }
}
