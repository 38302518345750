@import '../../../../../assets/style/colors';

.root {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  column-gap: 8px;

  &:hover {
    font-weight: 400;
    color: $palette-text-primary;
  }

  svg {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    color: $palette-secondary;
  }
}

.name {
  font-weight: 700;
}
