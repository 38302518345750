@import '../../../../../../../assets/style/mixins';

[class].root {
  padding: 0 12px 0 0;
  position: relative;

  svg {
    width: 20px;
    height: 20px;
    color: $palette-text-primary;
  }
}

.icon {
  padding: 0;
  transition: transform 0.24s;

  &Open {
    transform: rotate(-180deg);
  }
}
