@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.avatar {
  flex-shrink: 0;
  margin-right: 1.6em;
}

.nameWrapper {
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-direction: column;
  min-width: 0;
}

.link {
  display: flex;
  min-width: 0;
}

.title {
  display: flex;
  gap: 8px;
  width: 100%;
  overflow: hidden;
  align-items: center;
}

.name {
  font-weight: bold;
  font-size: 13px;
  line-height: 1.384;
  color: $palette-text-primary;

  &Root {
    max-width: 100%;
  }
}

@include media-phone() {
  .avatar {
    margin-right: 0.8em;
  }

  .link {
    width: 100%;
  }

  [class][class].name {
    width: 100%;
    margin-right: 0;
    font-size: 1.4em;
    line-height: 1.357;
  }
}
