@import 'src/assets/style/mixins';

.root {
  font-size: $main-font-size;
  border-bottom: 1px solid $palette-stroke-primary;
  padding-bottom: 1.2em;

  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }

  &:first-of-type > .header {
    padding-top: 0;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  padding-top: 1.2em;
}

.headerAvatarWrapper {
  display: flex;
  align-items: center;
}

.accountName {
  margin-left: 0.571em;
  font-size: 1.4em;
  line-height: 1.357;
}

.eventName {
  display: flex;
  flex-shrink: 0;
  font-size: 12px;
  line-height: 16px;
  color: $palette-secondary;
}

.link {
  display: flex;
  min-width: 0;
  padding-right: 1em;
  text-decoration: underline;
}

.bottom {
  display: flex;
  justify-content: space-between;
  font-size: 1.2em;
  line-height: 1.333;
  margin-top: 0.5em;
}

.source {
  font-weight: 600;
  color: $palette-text-primary;
}

.text {
  display: flex;
  flex-direction: column;

  &Time {
    align-items: flex-end;
  }

  &Account {
    flex: auto;
    min-width: 0;
    padding-right: 1em;
  }

  &Case {
    width: 20%;
  }
}
