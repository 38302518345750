@import 'src/assets/style/mixins';

.base {
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
}

.success {
  padding: 14.5px 0;
  color: $palette-success;
}

.alert {
  margin-bottom: 12px;
  color: $palette-error;
}

@include media-phone() {
  .base {
    padding: 0;
    font-size: 12px;
    line-height: 16px;
  }
}
