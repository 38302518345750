@import 'src/assets/style/mixins';

.fileList {
  @include fileList();

  :global(.ant-table-thead th.ant-table-column-has-sorters) {
    transition: none;
  }
}

.pagination {
  display: flex;
  justify-content: space-between;
  padding-top: 2.4em;
  align-items: center;
  font-size: $main-font-size;

  @include media-tablet() {
    flex-direction: column;
    padding-top: 0;

    &Total {
      width: 100%;
      padding: 2em 2em 2.4em;
      background-color: $white;
      border: 1px solid $palette-stroke-primary;
      border-top: none;
      border-radius: 0 0 4px 4px;
    }

    &Nav {
      margin: 2.4em 0 2.4em auto;
    }
  }

  @include media-phone() {
    flex-direction: column;
    padding-top: 0;

    &Total {
      width: 100%;
      padding: 1.6em 1.6em 2em;
      background-color: $white;
      border: 1px solid $palette-stroke-primary;
      border-top: none;
      border-radius: 0 0 4px 4px;
    }

    &Nav {
      margin: 2.4em 0 0 auto;
    }
  }
}
