[class].switch {
  font-weight: bold;
}

[class].name {
  max-width: 340px;
}

[class].removePhotoButton {
  font-size: 12px;
  line-height: 15px;
}

[class].removePhotoIcon {
  width: 16px;
  height: 16px;
}
