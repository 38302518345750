@import '../../../assets/style/mixins';

@include modalCommonComponent();

.form {
  width: 100%;
}

.topLabel {
  text-align: left;
}
