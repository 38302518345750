@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  align-items: center;
  column-gap: 12px;
  row-gap: 4px;
}

.item {
  flex-shrink: 0;

  svg {
    width: 20px;
    height: 20px;
  }
}

@include media-phone() {
  .root {
    flex-wrap: wrap;
  }
}
