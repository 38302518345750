@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  width: 100%;
  justify-content: space-between;
  column-gap: 16px;
  margin-top: 12px;
  padding: 9px 12px;
  font-size: $main-font-size;
  border-radius: $secondary-border-radius;
  background-color: $palette-info-light;
}

.label {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: $palette-info-dark;
}

@include media-phone() {
  .root {
    row-gap: 8px;
    flex-direction: column;
    align-items: flex-start;
  }
}
