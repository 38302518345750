@import '../../../assets/style/mixins';

.root {
  font-size: $main-font-size;
  position: relative;
  background-color: transparent;
  border: none;
}

.title {
  margin: 0 0 0.428em;
  font-weight: bold;
  font-size: 1.4em;
  line-height: 1.357;
  color: $palette-text-primary;
}
