@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  flex-shrink: 0;
  font-size: $main-font-size;
  border: none;
  background-color: transparent;

  &:focus {
    outline: 0;
    background-color: rgba($palette-primary-light-rgb, 0.08);
  }

  &.active {
    background-color: $palette-primary;
    color: $white;
  }
}
