@import '../../../../assets/style/colors';

.root {
  display: flex;
  column-gap: 8px;
}

.root.start {
  align-items: flex-start;
}

.root.center {
  align-items: center;
}

.root.end {
  align-items: flex-end;
}

.label {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}

.icon {
  display: flex;
  color: $palette-secondary-dark;

  svg {
    width: 20px;
    height: 20px;
  }
}
