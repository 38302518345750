@import '../../../../assets/style/mixins';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

[class].label {
  margin-bottom: 12px;
}

.active,
.title {
  font-weight: 600;
}

.active {
  font-size: 14px;
  line-height: 19px;
}

@include media-phone() {
  [class].label {
    margin-bottom: 8px;
  }

  .title {
    font-weight: 400;
  }
}
