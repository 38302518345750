@import '../../../../assets/style/mixins';

.root {
  font-size: 14px;
  line-height: 19px;
  font-weight: 700;
  padding: 12px 24px;
  color: $black;
  display: inline-flex;
  align-items: center;
  position: relative;
  width: 100%;

  &:hover {
    background-color: $palette-secondary-hover;
    color: $black;
  }
}

.end {
  display: inline-flex;
  align-items: center;
  margin-left: auto;
  column-gap: 4px;
}

.active {
  background-color: rgba($palette-secondary-rgb, 0.08);

  &::after {
    content: ' ';
    position: absolute;
    height: 100%;
    width: 5px;
    left: 0;
    background-color: $palette-secondary;
    border-radius: 0 $main-border-radius $main-border-radius 0;
  }
}

.wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
  margin-right: 8px;
}

.icons {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  gap: 2px;
  margin-left: 8px;

  &.invisible svg,
  &.muted svg {
    color: $palette-text-secondary-dark;
  }
}
