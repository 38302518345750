@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  width: 100%;
}

.content {
  display: flex;
  align-items: center;
  column-gap: 16px;
  min-width: 0;
  max-width: 100%;
}

.name {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0;
}

.title {
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
}

.label {
  color: $palette-text-secondary-dark;
  font-size: 12px;
  line-height: 20px;
}

@include media-phone() {
  .title {
    font-size: 20px;
    line-height: 28px;
  }
}
