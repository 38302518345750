@import '../../../../../assets/style/mixins';

$chat-header-wrapper-padding-top-phone: calc(#{$header-fixed-height-phone} + #{$layout-padding});
$chat-header-height-adaptive: 54px;

[class].main {
  padding: 0 4px 0 20px;

  &Wrapper {
    display: flex;
    width: 100%;
    overflow: hidden;
  }

  &.details {
    padding: 0;
  }
}

[class].footer {
  padding: 0 28px;
}

.defaultMessage {
  padding-right: 0;
}

[class].scrollInner {
  padding-right: 8px;
}

[class].footerAction {
  border-top: 1px solid $palette-stroke-primary-light;
  margin-top: 12px;
  padding: 20px;
  display: flex;
  justify-content: center;

  &Button {
    font-weight: 600;
  }
}

@include media-tablet() {
  .root {
    border-radius: 0;
    border-top: none;
    border-bottom: none;
  }

  [class].main {
    padding: 0 8px;
    height: auto;
    border: none;
  }

  [class].footer {
    width: 100%;
    padding: $layout-padding $layout-padding 0;
    border-radius: 0 0 4px 0;
    border: 1px solid $palette-stroke-primary;
    border-top: none;
    background-color: $white;

    &Wrapper {
      position: sticky;
      z-index: $zIndex-header;
      bottom: 0;
      display: flex;
      padding-bottom: $layout-padding;
      background-color: $palette-body-background;
    }
  }

  [class].header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: white;
    border-radius: 4px;

    &Wrapper {
      position: fixed;
      z-index: $zIndex-chat-header + 1;
      top: 0;
      left: calc(#{$sider-fixed-width-tablet} + #{$layout-padding});
      right: $layout-padding;
      display: flex;
      align-items: flex-end;
      padding-top: calc(#{$header-fixed-height-tablet} + #{$layout-padding});
      background-color: $palette-body-background;
    }
  }

  .defaultMessage {
    margin-top: 48px;
  }

  [class].scrollInner {
    padding-top: $chat-header-height-adaptive;
    padding-right: 0;
    overflow: unset;
  }

  [class].footerAction {
    border: 1px solid $palette-stroke-primary-light;
    padding: 16px;
    background: $white;
    width: 100%;
  }
}

.unpinAllMessagesPlaceholder {
  height: 28px;
}

@include media-phone() {
  [class].headerWrapper {
    left: 0;
    right: 0;
    margin: 0 $layout-padding;
    padding-top: $chat-header-wrapper-padding-top-phone;

    @supports (height: max(0px)) {
      padding-top: unquote(
        'max(#{$chat-header-wrapper-padding-top-phone} , calc(#{$chat-header-wrapper-padding-top-phone} + env(safe-area-inset-top)))'
      );
    }
  }

  [class].footer {
    border-radius: 0 0 4px 4px;
    border-left: 1px solid $palette-stroke-primary;

    &Wrapper {
      bottom: $sider-fixed-height-phone;
      @supports (bottom: max(0px)) {
        bottom: unquote(
          'max(#{$sider-fixed-height-phone}, calc(env(safe-area-inset-bottom) + #{$sider-fixed-height-phone}))'
        );
      }
    }
  }

  .defaultMessage {
    margin-top: 62px;
  }
}
