@import '../../../../../assets/style/mixins';

.root {
  @include dialog();
  padding-top: 18px;
}

.balance,
.description {
  margin-bottom: 24px;
}

.description {
  text-align: center;
  max-width: 275px;
}

.balance {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  gap: 4px;
  color: $palette-secondary;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}
