@import '../../../../../assets/style/mixins';

.root {
  flex-grow: 1;
  min-height: 0;
  padding-right: 1.6em;
  font-size: $main-font-size;
  overflow-y: auto;

  @include media-phone() {
    display: flex;
  }

  &.MD {
    padding-top: 2em;
  }

  &.LG {
    padding-top: 28px;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @include media-phone() {
    margin: auto;
  }
}

.time {
  margin-bottom: 1.142em;

  font-size: 1.4em;
  line-height: 1.357;
  text-align: center;
  color: $palette-text-secondary-dark;
}
