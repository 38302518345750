@import '../../../../../assets/style/mixins';

.root {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
}

.alert {
  margin-bottom: 20px;
}

.label {
  display: inline-block;
  font-size: 14px;
  line-height: 1.357;
  margin-bottom: 0.714em;
}
