@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 8px;

  &Active {
    color: $palette-success;
  }

  &Inactive {
    color: $palette-warning-dark;
  }

  &Interrupted {
    color: $palette-error;
  }

  &Restoring {
    color: $palette-disable;
  }
}
