@import '../../../../assets/style/colors';

[class].iframe {
  position: static;
  width: 100%;
  height: 100%;
  border: none;
  flex-grow: 1;
}

.preview {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  flex-grow: 1;
  height: 100%;
}

.thumbnail {
  object-fit: cover;
}

.placeholder {
  width: 100%;
  height: 100%;

  &Icon {
    width: 300px;
    height: 300px;
  }
}

.play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: rgb(24, 144, 255); // bunny.net play button color
  color: $white;

  svg {
    width: 32px;
    height: 32px;
  }  
}