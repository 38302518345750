@import '../../../../../../assets/style/mixins';

.root {
  padding: 28px 0;
}

@include media-phone() {
  .root {
    padding: 20px 0;
  }
}
