@import '../../../../assets/style/mixins';

.root {
  width: 100%;
  position: relative;
  text-align: center;

  @include media-phone() {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    text-align: left;
  }
}

.nextButton {
  margin: 0 auto;

  @include media-phone() {
    margin: 0;
  }
}

.rootWithoutSubmit {
  position: static;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;

  & .backButton {
    position: static;
    top: unset;
    left: unset;
    transform: unset;

    @include media-phone() {
      margin: 0;
    }
  }
}

.backButton {
  margin-right: 5.75em;
  padding: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.375;
  color: $palette-secondary;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: flex;
  align-items: center;

  svg {
    margin-right: 4px;
    width: 20px;
    height: 20px;
  }

  @include media-phone() {
    position: static;
    margin-right: 0;
    margin-top: 1.75em;
  }
}
