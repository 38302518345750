@import 'src/assets/style/mixins';

.root {
  padding: 60px;
  background-color: $white;
  border-radius: 4px;
}

.title,
.text {
  text-align: center;
  margin: 0 0 28px;
}

.title {
  font-size: 26px;
  font-weight: 700;
  line-height: 35px;
}

.text {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

@include media-phone() {
  .root {
    padding: 20px 16px;
  }

  .title {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 6px;
  }

  .text {
    font-size: 12px;
    line-height: 22px;
    margin-bottom: 16px;
    max-width: 212px;
  }

  [class].button {
    height: unset;
    padding: 0;
  }
}
