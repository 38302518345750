@import '../../../../../assets/style/colors';
@import '../../../../../assets/style/mixins';

.description,
.root {
  display: flex;
  align-items: center;
}

.root {
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  flex-wrap: wrap;
}

.label {
  font-weight: bold;
  margin-right: 12px;
  flex-shrink: 0;
}

.looking {
  color: $palette-success-dark;
}

.location {
  color: $palette-primary;
}

.icon {
  color: $palette-secondary-dark;
  width: 20px;
  height: 20px;
  margin-right: 4px;
}

@include media-phone() {
  .label {
    margin-right: 0;
    width: 100%;
    margin-bottom: 10px;
  }
}
