@import '../../../../assets/style/mixins';

.root {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 0;
  height: 100%;
}

.main {
  flex: 1 0;
  width: 100%;
  min-width: 0;
  min-height: 0;
  display: flex;
  flex-direction: column;
  padding: 16px 0 28px;
}

[class].nav {
  padding: 0 28px;
  margin: 0;
}

@include media-tablet() {
  [class].nav {
    padding: 0 20px;
  }
}