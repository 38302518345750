@import '../../../../assets/style/mixins';

.root {
  width: 100%;
  min-width: 300px;
  height: 100%;
  min-height: 200px;
}

.skeleton {
  padding: 0 20px;

  :global(.ant-skeleton-content .ant-skeleton-title) {
    height: 24px;
  }

  :global(.ant-skeleton-content .ant-skeleton-paragraph) {
    & > li {
      width: 40%;
      max-width: 200px;
      height: 40px;

      &:nth-child(4n + 1) {
        width: 70%;
        max-width: 350px;
      }

      &:nth-child(4n + 2) {
        width: 50%;
        max-width: 250px;
      }

      &:nth-child(4n + 3) {
        width: 60%;
        max-width: 300px;
        margin-left: auto;
      }
    }
  }
}

@include media-tablet() {
  .root {
    height: 358px;
  }
}

@include media-phone() {
  .root {
    height: 574px;
  }

  [class].cardHeader {
    margin: 0;
    border: none;
  }
}
