@import '../../../../assets/style/mixins';

[class].root {
  &:hover :global(.ant-slider-track),
  &:hover :global(.ant-slider-rail),
  :global(.ant-slider-track),
  :global(.ant-slider-rail) {
    background-color: $palette-stroke-primary;
  }

  :global(.ant-slider-track),
  :global(.ant-slider-step),
  :global(.ant-slider-rail) {
    height: 6px;
  }

  :global(.ant-slider-dot) {
    top: 0;
    border: none;
    width: 6px;
    height: 6px;
    background-color: $palette-disable-dark;
  }

  :global(.ant-slider-handle) {
    width: 24px;
    height: 24px;
    margin-top: -10px;
    background-color: $palette-secondary;
    border: none;
    box-shadow: none;
  }
}
