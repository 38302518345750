.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 7px;
}

.title {
  font-size: 15px;
  line-height: 1.333;
  font-weight: 700;
}
