@import 'src/assets/style/mixins';

[class].radio {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:last-of-type {
    margin-bottom: 20px;
  }

  &Item:not(:last-child) {
    margin: 0 0 12px;
  }
}

.divider {
  margin: 14px 0;
}

@include media-phone() {
  [class].radio:first-of-type {
    padding-top: 20px;
  }

  [class].radio:last-of-type {
    margin-bottom: 42px;
  }

  .divider {
    margin: 18px 0;
  }

  .button {
    display: block;
    margin: 0 auto;
  }
}
