@import '../../../../../assets/style/_mixins';

.root {
  width: 100%;
  margin-top: 20px;

  @include media-tablet() {
    margin-top: 1.6em;
  }
}

.help {
  position: relative;
}
