@import '../../../../../assets/style/mixins';

.skeleton {
  padding: 20px 28px;
}

.root {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  font-size: $main-font-size;

  @include media-phone() {
    position: relative;
    flex-direction: column;
  }

  &:nth-last-child(n + 2) {
    border-bottom: 1px solid $palette-stroke-primary;
  }
}

.leftColumn {
  display: flex;
  min-width: 0;

  @include media-phone() {
    flex-direction: column;
  }
}

.avatarWrapper {
  margin-right: 16px;
}

.infoWrapper {
  min-width: 0;

  @include media-phone() {
    margin-top: 16px;
  }
}

.headerWrapper {
  display: flex;
  align-items: center;
  margin: 0 1em 1em 0;
  padding-right: 10em;

  @include media-phone() {
    padding-right: 0;
    margin-right: 0;
  }
}

.account {
  &Name {
    display: flex;
    min-width: 0;
    font-weight: bold;
    font-size: 1.8em;
    line-height: 1.388;

    color: $palette-text-primary;

    &:hover,
    &:focus {
      color: $palette-text-primary;
    }
  }

  &Role {
    margin-left: 12px;
  }
}

.contextMenuIcon {
  margin-bottom: 1.6em;
}

.rightColumn {
  display: flex;
  flex-direction: column;
  text-align: end;
}

.pendingText {
  margin-bottom: 1.142em;
  font-size: 1.4em;
  font-weight: bold;
  line-height: 1.357;
  color: $palette-warning;
}

.contextMenuIcon,
.pendingText {
  @include media-phone() {
    position: absolute;
    top: 28px;
    right: 0;
    margin: 0;
  }
}

[class].button {
  @include media-phone() {
    height: 44px;
    margin: 16px 0 0;
  }

  &Withdraw {
    margin-bottom: 1.333em;

    @include media-phone() {
      margin: 16px 0 0;
    }
  }
}

.professions {
  margin: 12px 0 0;
}
