@import '../../../../assets/style/colors';

.senderName {
  margin-bottom: 2px;
  font-size: 12px;
  color: $palette-primary-dark;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
}
