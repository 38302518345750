@import '../../../../../assets/style/mixins';

.title {
  font-size: 14px;
  line-height: 1.357;
  font-weight: 700;
  text-transform: uppercase;
}

.text {
  margin-top: 2px;
  font-size: 12px;
  line-height: 1.3;
}

@include media-phone() {
  .text {
    font-size: 10px;
    line-height: 1.4;
    font-weight: 600;
  }
}
