@import '../../../../../assets/style/colors';

.upcoming {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  padding: 4px;
  border-radius: 50%;
  background-color: $palette-avatar-group;
}

.icon {
  flex-shrink: 0;
  color: $white;
  width: 16px;
  height: 16px;
}

.live {
  padding: 0 8px;
}
