@import '../../../../../assets/style/mixins';

.placeholder {
  display: flex;
  align-items: center;
  padding-right: 8px;
  color: $palette-secondary;

  &.outgoingMessage,
  &.outgoingMessage svg {
    color: $white;
  }

  &Icon {
    position: relative;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }

  &Text {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    flex-shrink: 0;

    &Case {
      font-size: 13px;
      line-height: 18px;
    }
  }
}
