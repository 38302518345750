@import '../../../../../assets/style/mixins';

.root {
  margin: 12px 0 4px;
  padding: 8px;
  background-color: $palette-warning-light;

  text-align: center;
  font-size: 12px;
  line-height: 1.333;
  font-weight: 600;
  color: $palette-warning-dark;
}

@include media-phone() {
  .root {
    margin-top: 20px;
    padding: 10px 22px;
  }
}
