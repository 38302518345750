@import '../../../../assets/style/mixins';

.root {
  background: $white;
  border: 1px solid $palette-stroke-primary;
  border-radius: 4px;
  color: $palette-text-primary;
  font-style: normal;
  font-weight: normal;
  padding: 28px;
}

.title {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  margin: 0;

  &Container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    padding-right: 16px;
  }
}

.amount {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;

  color: $palette-text-primary;

  &Plus {
    color: $palette-success;
  }
}

.table {
  & :global(.ant-table-tbody > tr > td),
  & :global(.ant-table-thead > tr > th) {
    border: none;
    border-bottom: 1px solid $palette-stroke-primary;

    &::before {
      display: none;
    }
  }

  & :global(.ant-table-tbody > tr > td) {
    padding: 16px 0;
    font-size: 13px;
    line-height: 18px;
  }

  & :global(.ant-table-row):last-of-type > td {
    border-bottom: none;
  }

  & :global(.ant-table-filter-column) {
    align-items: center;
    justify-content: flex-start;
  }

  & :global(.ant-table-column-title) {
    flex: unset;
  }

  & :global(.ant-table-filter-trigger) {
    margin: 0;
  }
}

.label,
.table :global(.ant-table-thead > tr > th) {
  padding: 0 0 16px 0;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: $palette-text-secondary-dark;
}

[class].skeleton {
  margin-right: 28px;
}

.filterContainer,
.filterButton {
  display: inline-flex;
}

.filterButton svg,
.filterIcon {
  width: 16px;
  height: 16px;
}

.pagination {
  padding-top: 26px;
}

[class].placeholder {
  min-height: 266px;
}

.cell {
  &Date {
    width: 160px;
  }

  &Type {
    width: 176px;
  }
}

@include media-tablet() {
  .root {
    padding: 20px;
  }

  .table :global(.ant-table-tbody > tr > td) {
    font-size: 14px;
  }

  .cellType {
    width: 108px;
  }
}

@include media-phone() {
  .wrapper {
    padding-bottom: 60px;
  }

  .titleContainer {
    margin-bottom: 12px;
  }

  .table :global(.ant-table-thead) {
    display: none;
  }

  .table :global(.ant-table-tbody > tr) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin-bottom: 12px;
    border-bottom: 1px solid $palette-stroke-primary;

    & > td {
      line-height: 22px;
      border: none;
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      margin-bottom: 8px;
    }

    & > td:last-child {
      margin-bottom: 12px;
    }

    &:last-child {
      border: none;
      margin-bottom: 0;
    }

    &:last-child > td {
      margin: 0;
    }
  }

  .label {
    padding: 0;
    margin-bottom: 4px;
    font-size: 13px;
  }

  .amount {
    line-height: 22px;
  }

  .filterButton svg {
    width: 24px;
    height: 24px;
    color: $palette-secondary;
  }

  .root {
    padding: 16px;
  }
}
