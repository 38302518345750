.root {
  display: flex;
  flex-grow: 1;
  overflow-y: auto;
  flex-direction: column;
  justify-content: space-between;
}

.room {
  flex-grow: 1;
}
