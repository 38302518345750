@import '../../../../assets/style/mixins';

.wrapper {
  position: absolute;
  padding: 0;
  margin: 0;
}

[class].icon {
  color: $palette-text-secondary-dark;

  &.outgoing {
    color: $white;
  }

  &Preview {
    color: $white;
  }
}

@include media-tablet() {
  .wrapper {
    margin-left: 0;
  }
}
