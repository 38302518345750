@import '../../../../../assets/style/mixins';

.xxs {
  @include xxsImage();
}

.xs {
  @include xsImage();
}

.sm {
  @include smImage();
}

.md {
  @include mdImage();
}

.lg {
  @include lgImage();
}

.root {
  background: $white;
  border-radius: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  padding: 0 6px;
  border: 1px solid $palette-secondary;
}

.text {
  font-weight: bold;
  text-align: center;
  color: $palette-secondary;
}
