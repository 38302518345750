@import '../../../../assets/style/mixins';

.root {
  display: flex;
  min-width: 0;
  height: 100%;
  font-size: $main-font-size;
  background-color: $white;
  border: 1px solid $palette-stroke-primary;
  border-radius: 4px;

  @include media-tablet() {
    background-color: transparent;
    border: none;
  }
}

.tabsWrapper {
  display: flex;
  flex-shrink: 0;
  width: 389px;

  @include media-tablet() {
    width: 100%;
    display: block;
    border: 1px solid $palette-stroke-primary;
    border-radius: 4px 4px 0 0;
    background-color: $white;

    @supports (margin: max(0px)) {
      margin-bottom: unquote('max(16px, calc(env(safe-area-inset-bottom) + 16px))');
    }
  }
}

.contentWrapper {
  display: flex;
  flex: auto;
  min-width: 0;

  @include media-tablet() {
    border: 1px solid $palette-stroke-primary;
    background-color: $white;
  }

  &Empty {
    @include media-tablet() {
      margin: 0;
      border: none;
    }
  }
}
