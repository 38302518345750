@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 1.4em;
  line-height: 1.3;
  color: $palette-text-primary;
}

.label,
.action {
  margin-right: 20px;
  min-width: 0;
  flex-shrink: 0;
}

.label {
  width: 20%;
  font-weight: bold;
}

.description {
  flex: auto;
  min-width: 0;
  line-break: anywhere;
  width: 100%;
}

.action {
  display: flex;
  width: 20%;
  justify-content: flex-end;
  margin: 0;
}

@include media-phone() {
  .root {
    flex-direction: column;
    align-items: stretch;
  }

  .label,
  .action {
    margin-right: 0;
  }

  .label {
    width: auto;
    margin-bottom: 0.571em;
  }

  .action {
    width: auto;
    justify-content: flex-start;
    margin-top: 1.142em;
  }
}
