@import '../../../../assets/style/mixins';

.root {
  margin-top: 30px;

  @include media-phone() {
    margin-top: 26px;
  }
}

.title {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 12px;

  @include media-phone() {
    font-size: 14px;
    margin-bottom: 16px;
  }
}

[class].author {
  margin-bottom: 12px;

  @include media-phone() {
    margin-bottom: 16px;
  }
}

[class].submit {
  display: block;
  margin: 40px auto 0;
}
