@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  height: 0;
  padding-right: 0.4em;
  overflow: auto scroll;
  margin: 1.333em 0.4em 0 2em;

  @include media-phone() {
    margin-left: 1.6em;
  }
}

.all2all {
  display: flex;
  flex-shrink: 0;
  width: 100%;
  justify-content: space-between;

  &List {
    width: 70%;
  }

  &Button {
    margin-right: 40px;
  }
}

.title {
  display: flex;
  align-items: center;
  column-gap: 4px;
  margin: 1.333em 0 1em;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.2em;
  line-height: 1.333em;
  color: $palette-text-secondary-dark;

  &:first-child {
    margin: 0 0 1em;
  }
}
