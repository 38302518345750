@import './../../../../../assets/style/mixins';

[class].skeleton {
  width: 100%;
  padding: 2.8em;

  @include paper();

  &:nth-last-child(n + 2) {
    margin-bottom: 2em;
  }

  & :global {
    .ant-skeleton-paragraph {
      margin: 0;
    }
  }
}

.title {
  display: inline-block;
  font-size: 1.2em;
  line-height: 1.333;
  font-weight: 700;
  color: $palette-primary;
  text-transform: uppercase;
}

.section {
  width: 100%;
  padding: 2.8em;

  &:nth-last-child(n + 2) {
    margin-bottom: 2em;
  }

  @include paper();
}

@include media-tablet() {
  .section {
    padding: 2em;

    &:nth-last-child(n + 2) {
      margin-bottom: 1.6em;
    }
  }
}

@include media-phone() {
  .section {
    padding: 2em 1.6em;
  }
}
