@import '../../../../../assets/style/colors';

.root {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 0 12px;
  padding: 0;
  background: none;
  border: none;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-decoration-line: underline;

  color: $palette-secondary-dark;
}

.you {
  color: white;
}
