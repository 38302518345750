@import '../../../../../../assets/style/mixins';

[class].checkbox {
  @include filterItemRow();

  padding: 10px 0;

  @include media-phone() {
    &:hover {
      background-color: transparent;
    }
  }

  &::after {
    display: none;
  }
}

.account {
  &Avatar {
    margin-left: 1.5em;
  }

  &Type {
    margin-left: 0.4em;
    color: $palette-dark-gray;
  }
}
