@import '../../../../../assets/style/mixins';

.root {
  width: 100%;
  height: 100%;
  align-content: flex-start;
}

.item {
  height: 146px;
}

@include media-phone() {
  .root {
    padding-bottom: 12px;
  }

  .item {
    height: 126px;
  }
}
