@import '../../../../../assets/style/mixins';

.root {
  position: relative;
  width: 100%;
  padding: 20px;
  font-size: inherit;

  @include paper();
}

.active {
  border-color: $palette-error;
}

@include media-phone() {
  .root {
    padding: 16px;
  }
}
