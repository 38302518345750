@import '../../../../assets/style/mixins';

[class].root :global(.ant-drawer-body) {
  display: flex;
  padding-right: 24px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  min-width: 0;
}

.footer {
  display: flex;
  justify-content: center;
}
