@import '../../../../../assets/style/mixins';

.skeleton {
  height: 100%;
  overflow-y: hidden;
}

[class].pagination {
  padding: 24px 28px 0;
  font-size: $main-font-size;
  
  @include paginationWrapper();

  margin-top: auto;
}

@include media-tablet() {
  [class].pagination {
    padding: 24px 20px 0;
    margin-bottom: 0;
    margin-top: auto;
  }
}

@include media-phone() {
  [class].pagination {
    padding: 24px 16px 0;
  }
}