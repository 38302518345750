.root {
  :global {
    .infinite-scroll-component__outerdiv {
      display: flex;
      flex-grow: 1;
    }

    .infinite-scroll-component {
      width: 100%;
      overflow: hidden !important;
    }
  }
}
