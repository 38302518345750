@import '../../../../assets/style/colors';

.link {
  font-weight: 600;
  font-size: inherit;
  line-height: inherit;
  text-decoration: underline;
  cursor: pointer;
  color: $palette-info-dark;

  &:hover {
    color: $palette-info;
  }

  &:active {
    color: $palette-info-dark;
  }
}
