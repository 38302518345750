@import '../../../../../assets/style/mixins';

.divider {
  align-self: stretch;
}

.startAdornment {
  font-size: 1.4em;
  line-height: 1.357;
  color: $palette-text-secondary-dark;
  padding: 8px 20px;
  white-space: nowrap;
}
