@import '../../../../assets/style/mixins';

.root {
  display: flex;
  padding: 0;
  font-size: 14px;
  align-items: center;
  border-width: 0;

  cursor: pointer;

  svg {
    height: auto;
  }

  &.xxs {
    width: 10px;
  }

  &.xs > svg {
    width: 14px;
  }

  &.sm > svg {
    width: 18px;
  }

  &.md > svg {
    width: 24px;

    @include media-phone() {
      width: 20px;
    }
  }

  &.lg > svg {
    width: 28px;
  }

  &.xl > svg {
    width: 32px;
  }

  &.xxl > svg {
    width: 36px;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.text {
  background-color: transparent;

  color: $palette-secondary;

  &:hover {
    color: $palette-secondary;
  }

  &:disabled {
    color: $palette-disable;
  }
}

.filled {
  border-radius: $secondary-border-radius;
  background-color: $palette-secondary;
  color: $white;

  &:hover {
    background-color: $palette-secondary-light;
  }

  &:disabled {
    background-color: $palette-disable-light;
    color: $palette-disable-dark;
  }
}

.outlined {
  border-radius: $main-border-radius;
  height: 100%;
  background-color: transparent;
  color: $palette-secondary;
  border: 1px solid $palette-secondary;

  &:hover {
    border-color: $palette-secondary-light;
    color: $palette-secondary-light;
  }

  &:disabled {
    border-color: $palette-disable-light;
    color: $palette-disable-dark;
  }
}
