@import '../../../../../assets/style/mixins';

input.input,
span.input {
  font-size: 13px !important;
  border: 1px solid $palette-stroke-primary;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 7px 8px;
  box-shadow: none !important;

  @include media-phone() {
    font-size: 16px !important;
  }

  &:global([class].l) {
    height: 43px;
  }

  &:global([class].s) {
    height: 32px;
  }

  &:global([class].xs) {
    height: 27px;
  }

  &:hover,
  &:focus,
  input:hover,
  input:focus {
    border-color: $palette-secondary;
  }

  &::placeholder,
  input::placeholder {
    @include placeholder();
  }

  &:read-only {
    color: $palette-text-secondary;
    border-color: $palette-text-secondary;
  }
}
