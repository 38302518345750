@import '../../../../../assets/style/colors';

[class].root:global(.table-header) {
  svg {
    width: 12px;
    height: auto;
    color: $palette-text-secondary-dark;
  }

  .active {
    color: $palette-secondary;
  }

  &:global(.asc > svg) {
    visibility: visible;
  }

  &:global(.desc > svg) {
    visibility: visible;
    transform: rotate(180deg);
  }

  & :global(.table-header-title) {
    margin-right: 6px;
  }
}
