@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  align-items: center;
  column-gap: 16px;
}

@include media-phone() {
  .root {
    align-items: stretch;
    row-gap: 12px;
    flex-wrap: wrap;
    justify-content: center;
  }
}
