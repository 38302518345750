@import '../../../../../../assets/style/mixins';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 18px 0 16px;
}

.title {
  font-weight: 700;
}

.cross {
  cursor: pointer;
  color: $palette-text-primary;
  width: 28px;
  height: 28px;
}

.item {
  color: $palette-secondary;
}

.comments {
  padding-left: 16px;

  &ScrollInner {
    padding-right: 4px;
  }
}

@include media-phone() {
  .cross {
    width: 24px;
    height: 24px;
  }
}
