@import '../../../../../../assets/style/mixins';

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 0;

  &:hover {
    background-color: rgba($palette-info-rgb, 0.08);
  }
}

.wrapper {
  display: flex;
  align-items: center;
  min-width: 0;
}

.account {
  width: auto;
  cursor: pointer;
}

@include media-tablet() {
  [class].context:global(.ant-popover) {
    z-index: $zIndex-popover;
  }
}
