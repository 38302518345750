@import '../../../../../assets/style/colors';

.label {
  font-weight: 700;
  font-size: 14px;
  color: $palette-secondary;
  cursor: pointer;
}

.linkField {
  margin-bottom: 28px;
}

.linkPopover {
  padding: 12px;
  width: 324px;
}
