@import '../../../../../assets/style/mixins';

.root {
  color: $palette-text-primary;

  @include modalButtonGapReg();
}

.description {
  margin-bottom: 2em;
  text-align: center;

  @include media-phone() {
    margin-bottom: 1.428em;
  }
}

.search {
  margin-bottom: 1.357em;
}

.list {
  overflow-x: hidden;
  overflow-y: auto;
  margin-bottom: 2.571em;
}

.button {
  display: block;
  margin: 0 auto;
}
