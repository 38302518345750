@import '../../../../assets/style/mixins';

.root {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 14px;
  line-height: 1.357;
  column-gap: 12px;
}

.plan {
  display: flex;
  align-items: center;
  color: $palette-text-primary;
  column-gap: 12px;
}

.icon {
  width: 20px;
  height: 20px;
  flex-shrink: 0;

  &.warning {
    color: $palette-warning-dark;
  }
}

.link {
  margin-left: auto; 

  @include link();
}

.skeleton :global(.ant-skeleton-paragraph) {
  margin-bottom: 0;
}

@include media-phone() {
  [class].root .plan {
    font-weight: normal;
    color: $white;
    column-gap: 8px;
  }
}
