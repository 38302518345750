@import '../../../../../assets/style/mixins';

.root {
  &:nth-last-child(n + 2) {
    margin-bottom: 24px;
  }

  &:global(.ant-skeleton) {
    padding: 0 30px;
  }
  :global {
    .ant-skeleton-header .ant-skeleton-avatar {
      width: 30px;
      height: 30px;
    }

    .ant-skeleton-content .ant-skeleton-title {
      margin-top: 0 !important;
    }

    .ant-skeleton-paragraph {
      margin-top: 16px !important;
      margin-bottom: 0;
    }
  }
}

@include media-phone() {
  .root {
    &:global(.ant-skeleton) {
      padding: 0 16px;
    }
  }
}