@import '../../../../assets/style/mixins';

.subtitle {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  text-align: center;
  margin: 28px 0 20px;
}

[class].slider {
  margin: 34px 0 0;
}

.markTitle {
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
}

.wrapper {
  border-radius: 2px;
  padding: 28px 24px 36px;
  background-color: $palette-skeleton;
}
