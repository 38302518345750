.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.account {
  max-width: 300px;
  justify-content: center;
  margin-bottom: 16px;
}

.content {
  margin: 0;
}

.controls {
  margin-top: 50px;
}
