@import '../../../../../../assets/style/mixins';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
}

.list {
  width: 100%;
  min-width: 0;
}
