@import '../../../../../assets/style/colors';
@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  font-size: $main-font-size;
  padding: 2em 0 0 2em;
  min-height: 0;
}

.title {
  margin-right: 2em;
  margin-bottom: 1.6em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.closeIcon {
  cursor: pointer;
  vertical-align: baseline;
  color: $palette-text-primary;
}

.tabsRoot :global(.ant-tabs-top .ant-tabs-nav) {
  margin: 0 20px 16px 0;
}

.permissionsTab {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.permissionListEmpty {
  padding: 0 20px 0 0;
  min-height: 0;
  color: $palette-text-primary;
}

.historyTab {
  display: flex;
  flex-direction: column;
}

.details {
  min-height: 0;
  overflow-y: scroll;

  &Title {
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 16px;
  }
}
