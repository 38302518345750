@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.requestsButton {
  display: flex;
  padding: 0;
  margin: 0;

  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: $palette-secondary;

  cursor: pointer;
  background: none;
  border: none;

  &:disabled {
    color: $palette-disable-dark;
    cursor: not-allowed;
  }

  svg {
    margin-left: 4px;
  }
}

.iconUp {
  transform: rotate(180deg);
}

.expiresIn {
  margin: 16px 0;
}

@include media-phone() {
  .root {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  .requestsButton {
    margin-bottom: 16px;
  }

  .expiresIn {
    margin-bottom: 12px;
  }
}
