@import '../../../../assets/style/mixins';

.root {
  position: relative;
  display: flex;
  gap: 12px;
  padding: 24px 0;
  margin: 0 28px;
  font-size: $main-font-size;

  &:nth-last-child(n + 2) {
    border-bottom: 1px solid $palette-stroke-primary;
  }
}

.account {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.unregisteredAvatar {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;

  svg {
    width: 40px;
    height: 40px;
  }
}

[class].status {
  font-size: 14px;
  line-height: 1.357;
}

.remove {
  display: flex;
  align-items: center;
  padding: 0;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.333;
  border: 0;
  background-color: transparent;
  color: $palette-text-secondary-dark;
  cursor: pointer;
  margin-bottom: 20px;

  svg {
    margin-left: 4px;
    width: 16px;
    height: 16px;
  }
}

[class].button {
  margin-top: 20px;
  max-width: 161px;
}

.skeleton {
  @include skeletonListItems();
}

.content {
  flex-grow: 1;
  display: flex;
  gap: 16px;
  flex-direction: column;
  min-width: 0;
}

.holder {
  flex-grow: 1;
  display: flex;
}

.details {
  min-width: 0;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  flex-grow: 1;
}

[class].name {
  font-size: 18px;
  line-height: 25px;
}

.email {
  font-size: 14px;
  line-height: 19px;
}

[class].label {
  font-size: 12px;
  line-height: 16px;
  color: $palette-text-secondary-dark;
  font-weight: normal;
}

.actions {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-shrink: 0;
}

.message {
  margin: 0;
  font-size: 14px;
  line-height: 22px;
  overflow-wrap: break-word;
}

@include media-tablet() {
  .root {
    padding: 20px 0;
    margin: 0 20px;
  }

  .actions [class].button {
    height: 33px;
    font-size: 12px;
    line-height: 1.333;
  }
}

@include media-phone() {
  .root {
    padding: 16px 0 12px;
    margin: 0 16px;
    align-items: flex-start;
    flex-direction: column;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
  }

  [class].name {
    font-size: 14px;
    line-height: 19px;
  }

  .email {
    font-size: 13px;
    line-height: 18px;
  }

  .actions {
    width: 100%;

    [class].button {
      margin: 0;
      width: 100%;
      height: 44px;
      max-width: unset;
    }
  }

  .remove {
    margin-bottom: 0;

    &Text {
      display: none;
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }

  .unregisteredAvatar {
    svg {
      width: 24px;
      height: 24px;
    }
  }
}
