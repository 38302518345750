@import '../../../../../assets/style/mixins';

.root {
  height: 100%;
  overflow: hidden;
}

.images {
  position: relative;
}

.root .cover {
  margin-bottom: 42px;

  & :global(.ant-skeleton-title) {
    aspect-ratio: 2.2;
    height: unset;
    margin: 0;
  }
}

.root .avatar {
  position: absolute;
  left: 50px;
  bottom: -22px;
  z-index: 1;
  border-radius: 50%;
  background-color: $white;

  & :global(.ant-skeleton-avatar) {
    height: 100px;
    width: 100px;
    border: 2px solid $white;
    margin: 0;
  }
}

.root .name {
  display: inline-block;
  width: unset;
}

.root .name :global(.ant-skeleton-title) {
  margin: 0;
  margin-left: 50px;
  height: 24px;

  width: 340px;
  margin-bottom: 40px;
}

.root .userInfo {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin: 0 0px 20px 50px;

  & > :global(.ant-row) {
    column-gap: 16px;
  }

  & :global(.ant-skeleton-avatar) {
    margin: 0;
  }

  &Name,
  &Name :global(.ant-skeleton-content),
  &Name :global(.ant-skeleton-title),
  &Chip,
  &Chip :global(.ant-skeleton-content),
  &Chip :global(.ant-skeleton-title) {
    display: inline-block;
    width: unset;
  }

  &Name :global(.ant-skeleton-title) {
    margin: 0;
    width: 128px;
    height: 19px;
  }

  &Chip :global(.ant-skeleton-title) {
    height: 28px;
    margin: 0;
    width: 83px;
  }

  &Chip:last-of-type :global(.ant-skeleton-title) {
    width: 63px;
  }
}

.root .info {
  &Item,
  &Item :global(.ant-skeleton-content),
  &Item :global(.ant-skeleton-title) {
    display: inline-block;
    width: unset;
  }

  &Item :global(.ant-skeleton-title) {
    margin: 0;
    width: 288px;
    height: 50px;
    margin-right: 50px;
  }
}
