.row {
  display: flex;
  align-items: center;
  justify-self: flex-start;
  column-gap: 8px;

  .active & {
    padding-top: 8px;
  }
}

[class].button {
  margin: 0;
  padding: 0;
  height: auto;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
}

.icon {
  width: 20px;
  height: 20px;

  transform-origin: center;
  transition: transform 0.3s;
  transform: rotate(0);

  .active & {
    transform: rotate(-180deg);
  }
}
