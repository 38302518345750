@import '../../../../../assets/style/mixins';

.root {
  width: 100%;
  padding: 24px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.name {
  text-align: center;
  font-weight: 600;
  font-size: 1.4em;
  line-height: 1.358;
  letter-spacing: 0.02em;
  margin: 8px 0 2px;
  word-break: break-all;
  cursor: pointer;
}

.headline {
  font-size: 1.4em;
  line-height: 1.571;
  text-align: center;
  margin-top: 8px;
  width: 100%;
  max-width: 560px;
}

.link {
  margin-top: 8px;

  @include link(1.4em, 1.358);
}

@include media-tablet() {
  .root {
    padding-top: 20px;
  }
}

@include media-phone() {
  .root {
    padding-top: 16px;
  }

  .headline {
    max-width: 256px;
  }
}
