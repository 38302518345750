@import '../../../../assets/style/colors';

.subTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
  color: $palette-text-secondary-dark;
}
