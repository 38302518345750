@import '../../../../assets/style/mixins';

.root {
  position: relative;
}

.wrapper {
  border: 1px solid $palette-stroke-primary-dark;
  padding: 0 16px;
  border-radius: 4px;
}

.input {
  padding: 12px 0;

  & :global(.ant-input) {
    padding: 0;

    color: $palette-text-primary;
    font-size: 14px;
    line-height: 22px;

    resize: none;
    border: none;

    @include media-phone() {
      font-size: 14px;
      line-height: 22px;
    }

    &:hover,
    &:focus {
      box-shadow: none;
    }

    &::placeholder {
      font-size: 14px;

      @include placeholder();
    }
  }
}

[class].footer {
  border-top: 1px solid $palette-text-secondary-light;
  padding: 6px 0;
  display: flex;
  align-items: center;

  @include media-phone() {
    padding: 12px 0;
  }

  &Btn {
    margin-left: auto;
  }
}
