@import '../../../../../assets/style/mixins';

.root {
  @include modalButtonGapReg();
}

[class].steps {
  margin-bottom: 40px;

  &.second {
    margin-bottom: 28px;
  }
}
