@import '../../../../assets/style/mixins';

.root {
  padding: 0 28px 60px 28px;
}

.title {
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
}

.description {
  font-size: 14px;
  line-height: 22px;
}

[class].selector {
  max-width: 748px;
  margin-right: auto;
}

@include media-tablet() {
  .root {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@include media-phone() {
  .root {
    padding-left: 20px;
    padding-right: 20px;
  }

  [class].selector {
    max-width: 100%;
  }
}
