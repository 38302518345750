@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  width: 100%;
  min-width: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  padding: 16px;
  background-color: $palette-disable-light;
  border-radius: 4px;
}

.organizer {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  row-gap: 8px;
  min-width: 0;
  max-width: 100%;
}

[class].socialLinks {
  column-gap: 8px;
  justify-content: flex-start;
  height: 40px;
  min-width: 0;
}

@include media-phone() {
  .root {
    padding: 0;
    background-color: unset;
    row-gap: 40px;
  }
}
