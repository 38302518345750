@import 'src/assets/style/mixins';

.header {
  margin-bottom: 12px;
}

[class].title {
  color: $white;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

.icon {
  cursor: pointer;
  color: $white;
  width: 28px;
  height: 28px;
}

.row {
  column-gap: 12px;
}

.toolbar {
  min-width: 0;
}

@include media-tablet() {
  .icon {
    width: 36px;
    height: 36px;
  }
}

@include media-phone() {
  .header {
    margin-bottom: 24px;
  }

  .icon {
    width: 32px;
    height: 32px;
  }
}
