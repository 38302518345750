@import '../../../../assets/style/mixins';

.root {
  margin-bottom: 20px;

  @include media-tablet() {
    margin-bottom: 16px;
  }
}

.col:nth-last-child(2) {
  margin-right: 8px;
}

[class].button {
  padding: 0;
  height: 100%;
}
