@import '../../../../../../assets/style/mixins';

.rootWrapper {
  min-height: 0;
}

.iconSending {
  color: $palette-secondary;

  &:disabled {
    color: $palette-text-secondary-dark;
  }
}
