@import '../../../../../../../assets/style/mixins';

.caption {
  margin: 0 0 12px !important;
}

[class].row {
  margin-bottom: 12px;
  padding-bottom: 12px;

  & > :global(.ant-skeleton-header) {
    vertical-align: middle !important;
  }

  @include dividerBottom(0px);
}

.title {
  height: 20px !important;
  margin: 0 0 4px !important;
}

.paragraph {
  margin: 0 !important;
}
