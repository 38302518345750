@import '../../../../assets/style/mixins';

@include media-tablet() {
  .iconFixed {
    $bottom-gap: 26px;
    $right-gap: 16px;

    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: $zIndex-nav;
    width: 80px;
    height: 80px;
    padding: 0;
    border: none;
    border-radius: 50%;
    background-color: $palette-secondary;
    box-shadow: 0px 2px 4px 2px $palette-shadow;
    color: $white;
    right: $right-gap;
    bottom: $bottom-gap;
    transition: bottom 0.3s;

    @supports (bottom: max(0px)) {
      bottom: unquote('max(#{$bottom-gap}, calc(env(safe-area-inset-bottom) + #{$bottom-gap}))');
    }

    @supports (right: max(0px)) {
      right: unquote('max(#{$right-gap}, calc(env(safe-area-inset-right) + #{$right-gap}))');
    }

    svg {
      width: 36px;
      height: 36px;
    }
  }
}

@include media-phone() {
  .iconFixed {
    $bottom-gap: 61px;

    width: 60px;
    height: 60px;
    bottom: $bottom-gap;

    @supports (bottom: max(0px)) {
      bottom: unquote('max(#{$bottom-gap}, calc(env(safe-area-inset-bottom) + #{$bottom-gap}))');
    }

    svg {
      width: 28px;
      height: 28px;
    }
  }
}
