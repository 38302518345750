@import '../../../../../assets/style/colors';

.root {
  cursor: pointer;
  color: $palette-secondary;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.3;
  display: flex;
  align-items: center;
  column-gap: 4px;

  &Icon {
    width: 20px;
    height: 20px;
  }
}
