.root {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  min-height: 0;
  font-size: 1.6em;
  text-align: center;
}

.content {
  position: relative;
  display: flex;
  flex-grow: 1;
  padding: 0;
  height: 100%;
  overflow: auto;
  flex-direction: column;
}
