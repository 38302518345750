@import '../../../../../../assets/style/mixins';

.root,
.row {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info,
.description,
.title {
  margin: 0;
  text-align: center;
}

.icon {
  width: 60px;
  height: 60px;

  &Success {
    color: $palette-success;
  }

  &Error {
    color: $palette-error;
  }
}

.root {
  row-gap: 28px;
}

.row {
  row-gap: 16px;
}

.title {
  font-size: 26px;
  font-weight: 700;
  line-height: 35px;
}

.info {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  color: $palette-secondary;
}
