@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  align-items: center;
  column-gap: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-transform: capitalize;

  svg {
    width: 16px;
    height: 16px;
  }
}
