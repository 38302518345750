@import '../../../../assets/style/mixins';

.root {
  margin-bottom: 28px;
}

.skeleton {
  :global(.ant-skeleton-content .ant-skeleton-paragraph > li) {
    height: 54px;
    margin-bottom: 4px;
    margin-top: 0px;
  }
}

[class].collapse {
  row-gap: 4px;
}

@include media-tablet() {
  .root {
    margin-bottom: 24px;
  }
}

@include media-phone() {
  .root {
    margin-bottom: 12px;
  }
}
