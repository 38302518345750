@import '../../../../../assets/style/mixins';

.root {
  width: 100%;
  padding: 28px 0 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  gap: 4px;
  align-items: center;
  display: inline-flex;
  margin: 8px 0 0;
}

.name {
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  word-break: break-all;
}

@include media-phone() {
  .root {
    padding-top: 20px;
    padding-bottom: 4px;
  }
}
