@import '../../../../../assets/style/mixins';

.root {
  font-size: $main-font-size;

  &WithSkeleton {
    overflow: hidden;
  }
}

[class].search {
  margin-bottom: 24px;
  width: 100%;
  max-width: 410px;
}

.title {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 12px;
}

@include media-tablet() {
  [class].search {
    width: auto;
    max-width: unset;
  }
}
