@import 'src/assets/style/mixins';

[class].root {
  padding: 12px 16px;
  border-bottom: 1px solid $palette-stroke-primary;
  cursor: pointer;
  width: 100%;
  flex-wrap: nowrap;

  &.context {
    background-color: $palette-info-light;
  }
}

[class]:global(.ant-skeleton-content) .skeleton {
  margin-bottom: 10px;

  & > li {
    height: 32px;
    border-radius: 0;

    @include media-phone() {
      height: 48px;
    }
  }
}

.root,
[class].fileName {
  font-size: 13px;
  line-height: 1.385;
}

.date,
.size {
  text-align: center;
}

.name {
  min-width: 0;
}