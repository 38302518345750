@import '../../../assets/style/mixins';

[class].addButton {
  height: 44px;
  padding: 0 8px 0 0;
  background: none;
}

.addButtonDisabled {
  background: rgba($palette-text-secondary-light-rgb, 0.5) !important;
}

.list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.item {
  display: flex;
  align-items: center;
  margin-bottom: 17px;
  font-weight: 600;
  justify-content: space-between;
}
