.skeleton {
  display: flex;
  flex-direction: column;
  gap: 13px;

  &Title {
    &:global(.ant-skeleton-element) {
      padding: 0 20px;
    }
    &:global(.ant-skeleton-input) {
      width: 100% !important;
    }
  }
}
