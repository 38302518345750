@import '../../../../../assets/style/mixins';

.root {
  width: 100%;
  min-height: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.headerWrapper {
  flex-shrink: 0;
  z-index: $zIndex-chat-header-content;
}

.main {
  flex-grow: 1;
  height: 0;
  display: flex;
}

.footerWrapper {
  flex-shrink: 0;
}
