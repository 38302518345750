@import '../../../../assets/style/mixins';

.popoverContent {
  padding: 8px 20px;
}

.title {
  display: block;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 15px;
  text-transform: uppercase;
  color: $palette-text-secondary-dark;
}

.skeleton {
  :global(.ant-skeleton-content .ant-skeleton-paragraph) {
    display: flex;
    align-items: center;
    column-gap: 8px;
    margin-bottom: 0;

    > li {
      height: 34px;
      max-width: 170px;
      margin: 0 !important;

      &:last-child {
        border-radius: 50%;
        width: 34px !important;
        flex-shrink: 0;
      }
    }
  }
}
