.root {
  display: flex;
  flex-direction: column;
  align-self: start;
  width: 100%;
  row-gap: 20px;
  margin-bottom: 24px;
}

.recipients {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;
}

[class].button {
  padding: 0;
  height: unset;
}
