@import '../../../../../assets/style/mixins';

.root {
  @include paper();
  font-size: 16px;
  line-height: 22px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  flex: 1 0;
  height: 100%;

  &Error {
    background-color: $palette-error-light;
  }
}

.row {
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
}

.amount {
  font-weight: 700;

  &Error {
    color: $palette-error-dark;
  }
}

@include media-tablet() {
  .root {
    padding: 16px;
    row-gap: 8px;
  }

  .row {
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
  }
}

@include media-phone() {
  .root {
    row-gap: 12px;
    width: 100%;
  }

  .row {
    flex-direction: row;
    gap: 8px;
  }
}
