@import 'src/assets/style/colors';

.list {
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-right: -14px;
  padding-right: 6px;
}

.empty {
  padding: 12px 0;
  min-height: unset;

  &Icon path:first-of-type {
    fill: $palette-stroke-primary-dark;
  }
}

.item {
  padding: 12px 8px 12px 0;
}

[class].checkbox {
  display: inline-flex;
  margin-left: 4px;

  &Label {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: $palette-text-secondary-dark;
  }
}

[class].search {
  margin-bottom: 24px;
}

.notFound {
  margin-top: 40px;
}

[class].filterPopover :global(.ant-popover-inner-content) {
  min-width: 224px;
  max-height: 240px;
  overflow: hidden auto;
}

.footerActions {
  margin-top: 40px;
}
