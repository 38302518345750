@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  flex-wrap: wrap;
  min-height: 550px;
  background-color: $white;
  border-radius: $main-border-radius;
  border: 1px solid $palette-stroke-primary;
}

.main {
  width: 50%;
  max-width: 50%;
  flex-shrink: 0;
  flex-grow: 1;
  display: flex;
  padding: 20px;
  flex-direction: column;

  &Comments {
    padding: 0;
  }
}

.header {
  display: flex;
  margin-bottom: 8px;
  flex-direction: column;
  align-items: flex-start;
}

.topHeader {
  display: flex;
  margin-bottom: 12px;
  justify-content: space-between;
  align-self: stretch;
}

.title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  display: block;
  margin-bottom: 8px;
  max-width: 100%;
  overflow-wrap: break-word;
}

.description {
  flex-grow: 1;
  font-size: 14px;
  margin-bottom: 8px;
  max-width: 100%;
  overflow-wrap: break-word;
}

.aside {
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  align-items: center;
  max-width: 50%;
  border-left: 1px solid $palette-stroke-primary;
}

.carousel {
  position: absolute;
}

.slide {
  object-fit: cover;
}

.categoriesList {
  justify-content: normal;
  margin-bottom: 14px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.comment {
  &Button {
    color: $palette-text-secondary-dark;
  }

  &IconWrapper {
    display: inline-flex;
    align-items: center;
    gap: 4px;
  }

  &Count {
    font-size: 14px;
    line-height: 17px;
    color: $palette-text-secondary-dark;
  }
}

.controls {
  display: inline-flex;
  align-items: center;
  gap: 12px;
}

.fullScreenButton {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 2;
}

.owner {
  width: auto;
  max-width: 100%;
}

@include media-tablet() {
  .main {
    width: 100%;
    max-width: 100%;
    order: 2;
  }

  .aside {
    width: 100%;
    height: 400px;
    max-width: 100%;
  }
}

@include media-phone() {
  .root:not(.showComments) {
    min-height: auto;
  }

  .aside {
    height: 280px;
  }

  .description {
    flex-grow: 0;
  }
}
