.container {
  display: flex;
  flex-direction: column;
}

.containerEmpty {
  row-gap: 12px;

  & .root {
    justify-content: flex-start;
  }
}

.root {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

[class].button {
  padding: 0;
  height: unset !important;
}
