@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.title {
  margin: 0 0 8px;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: $palette-secondary;
}

.message {
  font-size: 14px;
  line-height: 23px;
  word-break: break-all;
}

.button {
  margin-top: 16px;
}

@include media-phone() {
  .button {
    width: 100%;
  }
}
