@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  color: $palette-text-secondary-dark;
}

.icon {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

@include media-phone() {
  .icon {
    width: 20px;
    height: 20px;
  }
}
