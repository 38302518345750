@import '../../../../../../assets/style/mixins';

.root {
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  font-size: $main-font-size;

  &:nth-last-child(n + 2) {
    margin-bottom: 1.6em;
  }
}

.skeleton {
  :global(.ant-skeleton-avatar) {
    width: 20px !important;
    height: 20px !important;
  }

  :global(.ant-skeleton-title) {
    margin: 2px 0 0 !important;
    width: 100% !important;
  }

  :global(.ant-skeleton-paragraph) {
    display: none;
  }
}

.permissions {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.btnPermissions {
  width: 33px;
  margin-left: 2.966em;
  padding: 0;

  text-align: right;
  font-size: 1.2em;
  line-height: 1.333em;
  text-decoration: underline;

  background-color: transparent;
  border: none;
  cursor: pointer;

  &:disabled {
    text-decoration: none;
    color: $palette-text-secondary;
    cursor: default;
  }
}
