@import '../../../../../assets/style/colors';

.root {
  display: flex;
  font-size: 1.4em;
  line-height: 1.357;
  color: $palette-text-secondary-dark;
}

.total {
  margin-left: 4px;
  font-weight: 700;
}
