@import '../../../../assets/style/mixins';

.membersWrapper {
  display: flex;

  &.clickable {
    cursor: pointer;
  }

  &:not(.overlap) {
    &.xxs {
      .item {
        margin-right: 4px;
      }
    }

    &.sm {
      .item {
        margin-right: 6px;
      }
    }
  }
}

.overlap {
  .item:not(:first-child) {
    margin-left: -3px;
  }
}
