@import 'src/assets/style/mixins';

.root {
  width: 100%;
  padding: 8px 8px 8px 11px;
  margin-bottom: 8px;
  font-size: $main-font-size;
  border-radius: 4px;
  background: rgba($palette-stroke-primary-light-rgb, 0.5);
  position: relative;
}

.root.selected {
  background: $palette-info-light;
}

.wrapper {
  display: flex;
  align-items: center;
  column-gap: 16px;
}

[class].title {
  font-size: 13px;
  line-height: 18px;

  &Wrapper {
    flex-grow: 1;
  }

  &Icon {
    margin-right: 12px;
  }
}
