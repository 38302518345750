@import '../../../../../assets/style/mixins';

@include modalCommonComponent();

.root {
  text-align: left;

  @include modalButtonGapLG();
}

[class].group {
  display: flex;
  flex-direction: column;
  width: 100%;
}

[class].radio {
  margin-bottom: 12px;
  align-items: center;

  :global(.ant-radio) {
    margin-right: 16px;

    & + span {
      min-width: 0;
      padding: 0;
    }
  }
}
