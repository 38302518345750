$textLineHeight: 24;

$visibilityHeight: $textLineHeight * 6;

[class].about {
  font-size: 15px;
  line-height: #{$textLineHeight}px;
  overflow-wrap: break-word;
}

.professions {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.educations,
.publications {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

:export {
  visibilityHeight: $visibilityHeight;
}
