@import '../../../../assets/style/mixins';

[class].root {
  display: inline-flex;
  align-items: center;
  width: 100%;

  &:global(.ant-checkbox-wrapper) {
    & + & {
      margin-left: 0;
    }

    &::after {
      display: none;
    }
  }

  :global {
    @include checkbox();
  }

  &.reverse {
    flex-direction: row-reverse;
  }
}

.label {
  display: inline-flex;
  flex-grow: 1;
  min-width: 0;
  margin-left: 12px;
  font-size: 14px;
  line-height: 19px;
  color: $palette-text-primary;

  &.disabled {
    color: $palette-text-secondary-dark;
  }

  &Text {
    flex-grow: 1;
  }
}

.capitalized {
  text-transform: lowercase;

  &::first-letter {
    text-transform: capitalize;
  }
}

@include media-phone() {
  .root {
    & :global {
      .ant-checkbox-inner {
        width: 22px;
        height: 22px;
      }

      .ant-checkbox-checked .ant-checkbox-inner::after {
        width: 8px;
        height: 15px;
        top: 45%;
        left: 20%;
      }
    }
  }
}
