@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  align-items: flex-start;
  column-gap: 8px;
  padding: 12px 32px;

  &.new {
    background-color: rgba($palette-secondary-rgb, 0.08);
  }
}

[class].type {
  column-gap: 4px;

  svg {
    width: 16px;
    height: 16px;
  }
}

.authorDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 0;
  row-gap: 4px;
  font-weight: 600;
}

.authorName {
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;
  min-height: 0;
  max-width: 100%;
}

.date {
  display: flex;
  align-items: center;
  column-gap: 8px;
  font-size: 12px;
  line-height: 16px;
  color: $palette-text-secondary-dark;
}

.content {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  min-width: 0;
}

[class].description {
  font-size: 14px;
  line-height: 21px;
}

@include media-phone() {
  .root {
    padding: 12px 16px;
  }

  [class].description {
    font-size: 12px;
    line-height: 19px;
  }
}
