@import '../../../../../assets/style/mixins';

.text {
  display: inline-flex;
  flex-shrink: 0;
}

.name {
  display: inline-flex;
  max-width: 240px;

  &Label {
    font-weight: 600;
  }
}

@include media-tablet() {
  .name {
    max-width: 120px;
  }
}
