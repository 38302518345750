@import '../../../../../assets/style/mixins';

[class].emptyList {
  height: auto;
  padding: 80px 0 52px;
}

@include media-tablet() {
  [class].emptyList {
    padding-bottom: 80px;
    background-color: $white;
    border: 1px solid $palette-stroke-primary;
    border-radius: 0 0 4px 4px;
  }
}
