@import '../../../../assets/style/mixins';

[class].preview {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2em;
  padding: 1.6em 2.8em;
  background: $palette-secondary-dark;
  border-radius: 4px;

  @include media-tablet() {
    margin-bottom: 1.6em;
    padding: 1.6em;
  }

  @include media-phone() {
    flex-direction: column;
    padding: 1.6em 2.3em 2em;
    line-height: 1.571em;
    text-align: center;
  }

  &Title {
    font-size: 1.4em;
    line-height: 1.357;
    font-weight: bold;
    color: white;

    @include media-phone() {
      line-height: 1.571;
    }
  }

  &Button {
    background: inherit;
    border: 2px solid $white;
    color: $white;

    @include media-tablet() {
      height: 44px;
      padding: 0 60px;
    }

    @include media-phone() {
      margin-top: 1.2em;
    }

    &:hover {
      background: inherit;
      border-color: $white;
    }
  }
}
