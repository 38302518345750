@import '../../../../assets/style/mixins';

.root {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 50px;
  border-left: 1px solid $palette-nav-border;
  color: $white;
}

.container {
  position: relative;
  height: 100%;
  cursor: pointer;
}

.icon {
  flex-grow: 1;
  flex-shrink: 0;
  min-height: 100%;
}

$popoverOffset: 4px;

.popover {
  left: unset !important;
  right: $popoverOffset !important;
  top: calc(#{$header-fixed-height} + #{$popoverOffset}) !important;

  :global(.ant-popover-inner-content) {
    padding: 0;
  }
}

.modal {
  :global {
    .ant-modal-content,
    .ant-modal-body {
      padding: 0 !important;
    }
  }
}

@include media-tablet() {
  .root {
    padding: 0 40px;
  }

  .popover {
    top: calc(#{$header-fixed-height-tablet} + #{$popoverOffset}) !important;
  }
}

@include media-phone() {
  .root {
    padding: 0 24px 0 0;
    border: none;
  }
}
