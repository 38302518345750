@import '../../../../assets/style/mixins';

.root {
  display: flex;
  align-items: center;
  padding: 1.333em 0;
  margin: 0 1.666em;
  color: $palette-primary;
  font-weight: bold;
  font-size: 1.2em;
  line-height: 1.333em;
  text-transform: uppercase;
  border-top: 1px solid $palette-stroke-primary;
  cursor: pointer;

  svg {
    width: 16px;
    height: auto;
    margin-right: 0.666em;
    color: $palette-primary;
  }
}

@include media-tablet() {
  .root {
    justify-content: center;
    padding: 1.666em 0;
    margin: 0;
    border-top: none;
    border-bottom: 1px solid $palette-stroke-primary;
  }
}

@include media-phone() {
  .root {
    justify-content: center;
  }
}
