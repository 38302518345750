@import '../../../../assets/style/mixins';

[class].root:global(.ant-popover) {
  font-size: $main-font-size;
  user-select: none;
}

.icon {
  position: relative;
  color: $palette-text-secondary-dark;
  cursor: pointer;
  display: flex;
  align-items: center;
}

@include media-tablet() {
  [class].root:global(.ant-popover) {
    z-index: $zIndex-nav-bar;
  }
}
