@import '../../../../assets/style/mixins';

.root {
  display: flex;
  align-items: center;
  font-size: $main-font-size;
  padding: 16px 20px 12px;
  column-gap: 4px;
}

[class].popover {
  padding: 0;

  :global {
    .ant-popover-inner-content {
      padding: 20px 40px 20px 20px;
    }
  }
}

.root [class].filterButton {
  margin-left: 0;
  height: 44px;
}

.root [class].button {
  padding-left: 16px;
  padding-right: 16px;
  height: 44px;

  svg {
    width: 20px;
    height: 20px;
  }
}

@include media-tablet() {
  .root {
    padding: 20px 20px 0;
  }

  .root [class].filterButton,
  [class].button:first-of-type {
    margin-left: 4px;
  }
}

@include media-phone {
  .root {
    padding: 12px 16px 0;
  }

  .root [class].filterButton,
  .root [class].button {
    height: 50px;
  }

  .root [class].filterButton,
  [class].button:first-of-type {
    margin-left: 0;
  }
}
