@import '../../../../assets/style/mixins';

.root {
  margin: 40px 0 20px;
}

.title {
  display: block;
  margin-bottom: 20px;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  color: $palette-text-secondary-dark;
  text-transform: uppercase;
}
