@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 18px;
  padding: 0 16px 0 28px;
  margin-bottom: 10px;
  color: $palette-text-secondary-dark;

  svg {
    flex-shrink: 0;
    color: $palette-text-secondary-dark;
  }
}

.name {
  column-gap: 4px;
  display: flex;
  min-width: 0;
  font-weight: 600;
}

@include media-phone() {
  .root {
    padding-right: 12px;
    padding-left: 20px;
  }
}
