@import '../../../../assets/style/colors';

.label {
  font-size: 12px;
  line-height: 16px;
  display: inline-block;
  margin-bottom: 12px;
  color: $palette-text-secondary-dark;
}

.icon {
  cursor: pointer;
  margin-left: 14px;
  color: $palette-disable;
  width: 28px;
  height: 28px;

  &:first-child {
    margin-left: 0;
  }

  &.selected {
    color: $palette-primary;
  }
}

.icons {
  display: flex;
}
