@import 'src/assets/style/mixins';

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  font-size: $main-font-size;

  &Single {
    justify-content: center;
  }
}

[class].okButton {
  padding: 0 20px;
}

.reset {
  padding: 0;
  font-weight: normal;
}

@include media-tablet() {
  [class].okButton {
    height: 33px;
    font-size: 1.2em;
  }
}

@include media-phone() {
  [class].okButton {
    height: 54px;
    font-size: 1.6em;
    line-height: 1.375;
    padding: 0 2.5em;
  }
}
