@import 'src/assets/style/mixins';

.logo {
  flex-shrink: 0;
  width: 20.27vw;
  max-width: 227px;
  min-width: 180px;
  margin-right: auto;
  margin-right: 120px;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  @include media-tablet() {
    flex-shrink: unset;
    width: 23.43vw;
    min-width: 136px;
    margin-right: 0;
  }
}

.wrapper {
  display: flex;
  align-items: center;
}

.shortLogo {
  margin-right: 12px;

  @include media-phone() {
    flex-shrink: unset;
    width: initial;
    min-width: 30px;
  }
}
