@import '../../../../../../assets/style/mixins';

.root {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border: 1px solid $palette-stroke-primary;
  border-radius: 4px;
}

[class].recordingIcon svg {
  width: 32px;
  height: 32px;
}

[class].audioSend svg {
  color: $palette-primary;
}

.attachIcon {
  cursor: pointer;
  color: $palette-text-secondary-dark;
  margin-right: 24px;
}

.root [class].skeleton {
  & > li,
  & > li + li {
    display: block;
    margin-top: 0;
    padding: 0;
    flex-shrink: 0;
  }

  display: flex;
  align-items: center;
  margin: 0;
  width: 100%;

  li:nth-child(1) {
    width: 24px;
    height: 24px;
    margin-right: 28px;
  }

  li:nth-child(2) {
    height: 30px;
    margin-right: 10px;
    width: initial;
    flex-grow: 1;
  }

  li:nth-child(3) {
    height: 8px;
    margin-right: 10px;
    width: initial;
    flex-grow: 2;
  }

  li:nth-child(4) {
    height: 30px;
    width: initial;
    flex-grow: 1;
  }
}
