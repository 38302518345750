@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  flex-direction: column;
}

.empty {
  padding: 80px 0;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  border-top: 1px solid var(--palette-stroke-primary);
}

[class].skeleton {
  padding: 8px 0 14px;

  &:nth-last-child(n + 2) {
    border-bottom: 1px solid $palette-stroke-primary;
  }

  &Button {
    padding: 24px 0 28px;
    border-top: 1px solid $palette-stroke-primary;
  }
}

@include media-tablet() {
  .footer {
    padding: 6px 0;
  }

  [class].skeleton {
    &Button {
      padding: 20px 0;
    }
  }
}

@include media-phone() {
  .footer {
    padding: 2px 0;
  }
}
