@import '../../../../assets/style/mixins';

$iconRootSize: 60px;
$iconRootMargin: 16px;

$iconRootSizeMobile: 40px;
$iconRootMarginMobile: 12px;

.root {
  display: flex;
  flex-direction: column;
  padding: 28px;
  border: 1px solid $palette-stroke-primary;
  border-radius: 4px;
  background-color: $white;
}

[class].priceRoot {
  align-self: flex-start;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  width: 100%;
  min-width: 0;
  position: relative;

  &Container {
    display: flex;
    align-items: center;
    flex-grow: 1;
    min-width: 0;
    column-gap: 28px;
  }
}

.iconRoot {
  flex-shrink: 0;
  margin-right: $iconRootMargin;
}

.title {
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  flex-grow: 1;
  flex-shrink: 0;
  min-width: 0;

  &Wrapper {
    min-width: 0;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    max-width: 182px;
  }
}

.skeleton {
  :global(.ant-skeleton-header .ant-skeleton-avatar-lg) {
    width: 60px;
    height: 60px;
  }

  :global(.ant-skeleton-content .ant-skeleton-paragraph) {
    display: flex;
    flex-wrap: wrap;

    & > li:nth-child(1) {
      width: 25%;
      height: 20px;
    }

    & > li:nth-child(2) {
      width: 10%;
      height: 20px;
      margin-left: 65%;
      margin-top: 0;
    }

    & > li:nth-child(4),
    & > li:nth-child(5),
    & > li:nth-child(6) {
      width: 25%;
      margin-right: 20px;
    }

    & > li:nth-child(6) {
      margin-right: calc(25% - 40px);
    }

    & > li:nth-child(7) {
      margin-top: 20px;
      height: 48px;
      width: 135px !important;
    }
  }
}

.withoutButton {
  :global(.ant-skeleton-content .ant-skeleton-paragraph) {
    & > li:nth-child(7) {
      display: none;
    }
  }
}

@include media-tablet {
  .root {
    padding: 20px;
  }

  [class].button {
    padding-right: 20px;
    padding-left: 20px;
    max-height: 33px;
    font-size: 12px;
  }

  .date {
    flex-shrink: 0;
  }
}

@include media-phone {
  .skeleton :global(.ant-skeleton-content .ant-skeleton-paragraph) {
    flex-direction: column;

    & > li:nth-child(2) {
      margin: 10px 0;
    }

    & > li:nth-child(3) {
      margin-bottom: 16px;
    }

    & > li:nth-child(4),
    & > li:nth-child(5),
    & > li:nth-child(6) {
      width: 100%;
      margin-right: 0;
      height: 10px;
    }

    & > li:nth-child(3),
    & > li:nth-child(4),
    & > li:nth-child(5),
    & > li:nth-child(6),
    & > li:nth-child(7) {
      margin-left: -30%;
    }
  }

  .header {
    margin-bottom: 8px;
  }

  [class].icon {
    width: 20px;
    height: 20px;

    &Root {
      width: $iconRootSizeMobile;
      height: $iconRootSizeMobile;
      margin-right: $iconRootMarginMobile;
    }
  }

  .title {
    font-size: 16px;
    line-height: 22px;
  }

  [class].price {
    font-size: 18px;
    line-height: 25px;
  }

  [class].button {
    align-self: flex-start;
    margin-bottom: 16px;
  }

  .date {
    margin-bottom: 8px;
  }
}
