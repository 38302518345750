@import '../../../../assets/style/mixins';
@import '../../../../assets/style/colors';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 17px;
  line-height: 16px;
  border-radius: 4px;

  position: relative;

  width: 100%;
  z-index: 1;
  margin: 20px auto;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 50%;
    height: 1px;
    background-color: $palette-secondary;
    top: 50%;
    transform: translateY(-50%);
    left: 30px;
  }

  &::after {
    right: 30px;
    left: unset;
  }
}

.label {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  z-index: 2;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  height: 17px;

  border: none;
  margin: 0;
  background-color: #fff;
  color: $palette-secondary;

  cursor: default !important;
  outline: 0 !important;
}
