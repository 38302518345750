@import '../../../../../../../assets/style/mixins';

$borderRadius: 4px;

[class].root {
  will-change: transform;
  position: relative;
  box-sizing: border-box;
  border: 1px solid $palette-stroke-primary-dark;
  border-radius: $borderRadius;
  background: none;
  box-shadow: 0 4px 10px transparent;
  height: 44px;
  max-height: 44px;

  &:hover {
    border-color: $palette-stroke-primary-dark;
  }

  &.isMulti {
    height: unset;
    max-height: unset;

    .value {
      height: unset;
    }
  }

  &.focused .baseBorder {
    border-color: $palette-secondary;
  }

  &.isMulti .baseBorder {
    padding: 0 4px;
    flex-grow: 1;
    max-height: unset;
    height: 100%;
    min-height: 44px;
  }

  &.selected .label,
  &.focused .label {
    background: $white;
    font-size: 11.5px;
    transition: transform 0.3s;
    transform: translate(0, -26px);
  }

  &.focused .label {
    font-weight: 600;
    color: $palette-secondary;
  }

  &.invalid {
    border-color: $palette-error;

    .baseBorder {
      border-color: transparent;
    }
  }
}

.baseBorder {
  margin: 0;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: $borderRadius;
  max-height: 44px;
}

.label {
  position: absolute;
  left: 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  margin: 0 0 0 -7px;
  padding-left: 7px;
  padding-right: 7px;
  transition: all 0.3s;
  color: $palette-text-secondary-dark;
}

@include media-phone() {
  .root {
    height: 50px;
    max-height: 50px;
  }

  .baseBorder {
    max-height: 50px;
  }
}
