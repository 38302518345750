@import '../../../../assets/style/mixins';

[class].button {
  height: auto !important;
  padding: 0;
}

.successfulMessage {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  font-weight: 600;
  color: $palette-success;

  svg {
    width: 20px;
    height: 20px;
  }
}
