@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: $palette-stroke-primary 0 0 0 1px;
  border-radius: 4px;
  position: relative;
  aspect-ratio: 130/120;
  cursor: grab;
  transform: scale(1);
  transform-origin: 50% 50%;

  &.dragging {
    opacity: '0.5';
    cursor: 'grabbing';
    box-shadow: $palette-stroke-primary 0 0 0 3px;
    transform: scale(1.05);
    z-index: 1;
  }

  &.disabled {
    cursor: unset;
  }
}

.delete {
  top: 8px;
  left: 8px;
  color: $palette-text-secondary-dark;
}

@include media-phone() {
  .root {
    aspect-ratio: 126/120;
  }
}
