@import '../../../assets/style/mixins';

.root {
  @include dialog();
  @include modalTitleWithoutDescriptionGap();
  align-items: normal;
}

[class].steps {
  margin-bottom: 28px;
}

.button {
  @include modalButtonGapLG();
}

.searchWrapper {
  margin-bottom: 40px;
}

.subtitle {
  margin-bottom: 28px;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  color: $palette-text-primary;

  @include media-phone() {
    margin-bottom: 24px;
  }
}

.description {
  margin-bottom: 1.75em;
  font-weight: 600;
  font-size: 1.6em;
  line-height: 1.375;
  text-align: center;
  color: $palette-text-primary;
}

.category {
  &List {
    width: 100%;
    margin-top: 2em;
    margin-right: -0.8em;

    &Empty {
      margin-top: 2.8em;
    }
  }

  &Item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 99%;
    padding: 0.929em 0;
    font-size: 14px;
    line-height: 1.357;
    color: $palette-text-primary;
    background-color: transparent;
    border: none;

    &:first-child {
      border-top: 1px solid $palette-stroke-primary;
    }

    &:nth-last-child(n + 2) {
      border-bottom: 1px solid $palette-stroke-primary;
    }
  }
}
