@import '../../../../../assets/style/colors';
@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  flex-wrap: wrap;
  row-gap: 16px;

  :global {
    [class].ant-pagination-next,
    [class].ant-pagination-prev {
      width: 28px;
      min-width: 28px;
      height: 28px;
      min-height: 28px;
    }

    [class].ant-pagination-item,
    [class].ant-pagination.mini [class].ant-pagination-item {
      background: none;
      border: none;

      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 28px;
      min-width: 28px;
      height: 28px;
      margin: 0 2px;

      color: $palette-text-primary;

      &-active {
        background-color: $palette-primary;
        border-radius: 4px;
        font-weight: bold;
        color: $white;
      }
    }

    [class].ant-pagination-item a {
      color: inherit;
    }

    [class].ant-pagination-item-active {
      background-color: $palette-primary;
      border-radius: 4px;
      font-weight: bold;
      color: $white;
    }

    [class].ant-pagination-jump-prev,
    [class].ant-pagination-jump-next {
      min-width: 28px;
      width: 28px;
      height: 28px;
      line-height: 28px;
    }

    [class].ant-select.ant-pagination-options-size-changer .ant-select-selector {
      background-color: transparent;
      height: 28px;
      border: 1px solid $palette-stroke-primary;
      box-sizing: border-box;
      border-radius: 4px;
    }

    [class].ant-select.ant-pagination-options-size-changer .ant-select-selector input {
      height: 26px;
    }

    [class].ant-select.ant-pagination-options-size-changer .ant-select-arrow svg {
      * {
        fill: $palette-text-primary;
      }
    }

    [class].ant-select.ant-pagination-options-size-changer.ant-select-focused.ant-select-single:not(.ant-select-customize-input)
      .ant-select-selector {
      box-shadow: none;
    }

    [class].ant-pagination-options-size-changer.ant-select {
      margin-right: 0;
    }

    [class].ant-select.ant-pagination-options-size-changer
      .ant-select-selector
      .ant-select-selection-item {
      opacity: 1;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      padding: 4px 20px 6px 0;

      color: $palette-text-primary;
    }

    [class].ant-table-pagination.ant-pagination {
      margin: 24px 0 0 0;
    }

    [class].ant-pagination-simple-pager {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      color: $palette-text-primary;

      .ant-pagination-slash {
        margin-left: 0;
      }

      input {
        max-width: 40px;
        background-color: transparent;
        border: 1px solid $palette-stroke-primary;
        border-radius: 4px;
        margin-right: 10px;
        height: 28px;

        &:hover {
          border-color: $palette-stroke-primary;
        }
      }
    }
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.totalLabel {
  font-size: 14px;
  line-height: 19px;

  color: $palette-text-secondary-dark;
}

@include media-phone() {
  .root {
    :global(.ant-pagination-options) {
      display: inline-block;      
      margin-left: 16px;
    }
  }
}

@include media-phone() {
  .container {
    gap: 20px;
    margin-right: 57px;
    align-items: normal;
    flex-direction: column;
  }
}
