[class].root {
  :global(.ant-drawer-body) {
    display: flex;
    flex-direction: column;
    padding-right: 24px;
  }
}

[class].fileDetailsRoot {
  padding-top: 0;
  padding-bottom: 0;
}
