@import '../../../../../assets/style/mixins';

[class].status {
  margin-right: 16px;
}

@include media-phone() {
  [class].status {
    order: 1;
    margin-left: auto;
    margin-right: 0;
  }
}
