@import-normalize;
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap&subset=cyrillic');
@import 'mixins';

* {
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif !important;
  outline: 0 !important;
}

:root {
  background: $palette-layout-background;

  body {
    position: relative;
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif !important;
    font-size: 14px;
    line-height: 1.357;
    background-color: $palette-body-background;
    color: $palette-text-primary;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    .ant-dropdown-menu-item {
      display: flex;
      align-items: center;
    }
  }

  --chat-editor-margin-top: 100;
  --window-inner-height: 0;
}

.MuiIconButton-root {
  outline: 0 !important;
}

/*
 * Webkit Scrollbar
 */

/* width */
::-webkit-scrollbar {
  width: 8px;
  border-radius: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $palette-text-secondary;
  border-radius: 5px;
}

::-webkit-scrollbar:horizontal {
  height: 8px;
}

/* Modal window
  If the iOS Safari footer is hidden when the modal opens,
  iOS will reveal the footer if the user makes a vertical pan gesture,
  the 1px prevents this from happening
*/
html.is-locked,
html.is-locked body {
  /* want to fix the height to the window height */
  height: calc(var(--window-inner-height) - 1px);

  /* want to block all overflowing content */
  overflow: hidden;

  /* want to exclude padding from the height */
  box-sizing: border-box;
}

/* END */

@include media-tablet() {
  :root {
    background: $palette-layout-background;

    body {
      user-select: none;
      -webkit-touch-callout: none;
    }
  }
}

@include media-phone() {
  input[type='text'],
  input[type='textarea'] {
    font-size: 16px;
  }
}
