@import '../../../../assets/style/mixins';

.root {
  display: flex;
  font-weight: 700;
  align-items: center;
  text-transform: capitalize;

  &.success {
    color: $palette-success;
  }

  &.warning {
    color: $palette-warning-dark;
  }

  &.error {
    color: $palette-error;
  }

  &.info {
    color: $palette-info-dark;
  }

  &.fill {
    &.success {
      background-color: $palette-success-light;
    }

    &.warning {
      background-color: $palette-warning-light;
    }

    &.error {
      background-color: $palette-error-light;
    }

    &.info {
      background-color: $palette-info-light;
    }
  }

  &.chip {
    padding: 4px 8px;
    border-radius: $main-border-radius;

    &.success {
      background-color: $palette-success-light;
    }

    &.warning {
      background-color: $palette-warning-light;
    }

    &.error {
      background-color: $palette-error-light;
    }

    &.info {
      background-color: $palette-info-light;
    }
  }
}
