@import '../../../../../../assets/style/mixins';

.input {
  textarea {
    &:read-only {
      cursor: not-allowed;
      color: $palette-text-secondary-dark;
    }
  }
}
