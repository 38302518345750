@import '../../../../assets/style/mixins';

.root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: $main-font-size;
}

.icon {
  color: $palette-text-secondary;

  &.xs {
    svg {
      width: 24px;
      height: 24px;
    }
  }

  &.sm {
    svg {
      width: 32px;
      height: 32px;
    }
  }

  &.md {
    svg {
      width: 36px;
      height: 36px;
    }
  }
}

.text {
  text-align: center;
  font-size: 14px;
  line-height: 24px;
  margin: 16px 0 0;
  color: $palette-text-primary;
  max-width: 500px;

  &WithoutIcon {
    margin-top: 0;
  }
}

.children {
  margin-top: 20px;
}

@include media-phone() {
  .text {
    padding: 0 16px;
  }
}
