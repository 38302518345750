@import '../../../../../../assets/style/mixins';

.scrollList,
.root {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
}

.root {
  margin-top: 20px;
}

.placeholder {
  height: auto;
  align-self: center;
}

.search {
  margin: 18px 0 14px;
}

[class].groupedMembersRoot {
  @include drawerRightScrollPadding();

  :global {
    .infinite-scroll-component__outerdiv {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    .infinite-scroll-component {
      flex-grow: 1;
    }
  }
}

[class].groupedMembersItemRoot {
  padding: 0;
}

@include media-tablet() {
  .root {
    margin-top: 24px;
  }

  .search {
    margin-bottom: 16px;
  }
}

@include media-phone() {
  .root {
    margin-top: 20px;
  }

  .department {
    padding-right: 12px;
    padding-left: 20px;
  }

  .search {
    margin-top: 12px;
  }
}
