@import '../../../../../assets/style/mixins';

[class].root {
  height: 100%;
  min-height: 0;

  :global {
    .ant-tabs,
    .ant-tabs-content,
    .ant-tabs-content-holder {
      height: 100%;
    }

    .ant-tabs-nav-list {
      display: flex;
      width: 100%;
      flex-shrink: 0;
    }

    .ant-tabs-nav .ant-tabs-ink-bar-animated {
      height: 3px;
    }

    .ant-tabs-nav-more {
      display: none;
    }

    .ant-tabs-tab {
      display: flex;
      width: 50%;
      padding: 0 0 8px;
      text-align: center;
      align-items: center;
      justify-content: center;
      margin: 0;
      font-size: 13px;
      min-width: 0;
    }

    .ant-tabs-tab .ant-tabs-tab-btn {
      color: $palette-text-secondary-dark;
      font-size: 13px;
      padding: 0 14px;

      @include ellipsis();
    }

    .ant-tabs-tab-active .ant-tabs-tab-btn {
      color: $palette-text-primary;
      font-weight: bold;
    }
  }

  &.fill {
    & > :global(.ant-tabs > .ant-tabs-nav) {
      font-size: $main-font-size;
      flex-wrap: wrap;

      &::before {
        display: none;
      }

      & > :global(.ant-tabs-nav-wrap > .ant-tabs-nav-list > .ant-tabs-tab:not(.ant-tabs-ink-bar)) {
        padding: 8px 0;
        border: 1px solid $palette-stroke-primary-dark;
        font-size: $main-font-size;

        & > :global(.ant-tabs-tab-btn) {
          font-weight: 600;
          font-size: 1.4em;
          line-height: 1.357;
          color: $palette-disable-dark;

          @include media-phone() {
            font-size: 1.2em;
            line-height: 1.333;
          }
        }

        &:global(.ant-tabs-tab-active) {
          background-color: $palette-secondary;
          border-color: $palette-secondary;

          & > :global(.ant-tabs-tab-btn) {
            color: $white;
          }
        }

        &:first-child {
          border-radius: 4px 0 0 4px;
        }

        &:not(:nth-last-of-type(2)) {
          border-right: 0;
        }

        &:not(:first-of-type) {
          border-left: 0;
        }

        &:nth-last-of-type(2) {
          border-radius: 0 4px 4px 0;
        }
      }

      & > :global(.ant-tabs-nav-wrap > .ant-tabs-nav-list > .ant-tabs-ink-bar) {
        display: none;
      }
    }
  }
}
