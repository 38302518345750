@import '../../../../../assets/style/colors';

[class].iconButton {
  padding: 0.875em;

  [class].icon {
    width: 16px;
    color: $palette-text-secondary-dark;
  }
}
