@import '../../../../assets/style/mixins';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;

  .buttons {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  &.withImage {
    &:hover {
      .buttons {
        display: flex;
      }
    }

    .buttons {
      display: none;
      background-color: rgba($palette-black-rgb, 0.81);
    }
  }
}

.hidden {
  display: none;
}

.wrapper {
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: $white;
  border: 3px solid $white;
  width: 100%;
  height: 100%;
  border-radius: $main-border-radius;

  svg {
    width: 48px;
    height: 48px;
  }
}

.placeholder {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  background-color: #f0f3f6;

  .circle1 {
    position: absolute;
    content: ' ';
    width: 500px;
    height: 502px;
    top: 60%;
    right: 0;
    background-color: #ebf5ff;
    border-radius: 50%;
    mix-blend-mode: multiply;
  }
  .circle2 {
    position: absolute;
    content: ' ';
    width: 307px;
    height: 248px;
    top: 50%;
    right: -10%;
    background-color: #ebf5ff;
    border-radius: 50%;
    mix-blend-mode: multiply;
  }

  .circle3 {
    position: absolute;
    content: ' ';
    width: 220px;
    height: 221px;
    right: -10%;
    bottom: 10%;
    background-color: #f4f1fa;
    border-radius: 50%;
    mix-blend-mode: multiply;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.controlButton {
  display: flex;
  align-items: center;
  width: max-content;
  margin-top: 16px;
  padding: 0;
  column-gap: 4px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.4;
  color: $palette-secondary;
  cursor: pointer;
  border: 0;
  background-color: transparent;
}

.icon {
  width: 20px;
  height: 20px;
  color: $palette-secondary;
}

.contextMenu {
  position: absolute;
  right: 8px;
  top: 12px;
  border-radius: $main-border-radius;
  background-color: rgba($white-rgb, 0.8);
}

[class].skeleton {
  width: auto;

  & :global {
    .ant-skeleton-avatar {
      width: 70px;
      height: 70px;
    }

    .ant-skeleton-paragraph {
      display: none;
    }
  }
}

@include media-tablet() {
  .skeleton {
    display: flex;
    align-items: center;
    width: 100%;
  }
}
