@import '../../../../../assets/style/mixins';

$lineHeightDefault: 19px;
$lineHeightXS: 16px;
$lineHeightSM: 16px;
$lineHeightMD: 19px;

.btn {
  white-space: nowrap;
  color: $white;
  background: $palette-secondary;
  border: 2px solid $palette-secondary;
  position: relative;
  cursor: pointer;
  border-radius: 4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-align: center;
  padding: 0 40px;
  user-select: none;

  &:hover {
    color: $white;
    background-color: $palette-secondary-light;
    border-color: $palette-secondary-light;
  }

  &:active {
    color: $white;
    background-color: $palette-secondary-dark;
    border-color: $palette-secondary-dark;
  }

  &:disabled {
    cursor: not-allowed;
    pointer-events: none;
    color: $palette-disable-dark;
    background-color: $palette-disable-light;
    border-color: $palette-disable-light;
  }

  &WithIcon {
    padding-left: 36px;
    padding-right: 36px;
  }

  &[class].loading {
    cursor: default;
    padding-top: 0;
    padding-bottom: 0;
    pointer-events: none;
  }

  &.loading:not(.text):hover,
  &.loading:not(.text):active {
    background: $palette-secondary;
    border: 2px solid $palette-secondary;
  }

  &.btn-default {
    height: 48px;
    font-size: 14px;
    line-height: $lineHeightDefault;
  }

  &.btn-md {
    height: 44px;
    font-size: 14px;
    line-height: $lineHeightMD;
  }

  &.btn-sm {
    height: 33px;
    font-size: 12px;
    line-height: $lineHeightSM;
  }

  &.btn-xs {
    height: 33px;
    padding: 0 20px;
    font-size: 12px;
    line-height: $lineHeightXS;

    [class^='ButtonBaseContent'] {
      svg {
        width: $lineHeightXS;
        height: $lineHeightXS;
      }
    }
  }

  &.outlined {
    color: $palette-secondary;
    background: transparent;

    &:disabled {
      background: transparent;
      border-color: $palette-disable-dark;
    }
  }

  &.text {
    border: none;
    background: transparent;

    &:disabled {
      border: none;
      cursor: not-allowed;
      background: transparent;
    }
  }

  &.inline {
    border: none;
    background: transparent;
    padding: 0;
    font-weight: normal;
    display: inline-block;
    height: auto;

    &:disabled {
      border: none;
      cursor: not-allowed;
      background: transparent;
    }

    &.btn-default {
      .spinner {
        width: $lineHeightDefault;
        height: $lineHeightDefault;
      }
    }

    &.btn-md {
      .spinner {
        width: $lineHeightMD;
        height: $lineHeightMD;
      }
    }

    &.btn-sm {
      .spinner {
        width: $lineHeightSM;
        height: $lineHeightSM;
      }
    }

    &.btn-xs {
      .spinner {
        width: $lineHeightXS;
        height: $lineHeightXS;
      }
    }
  }

  &.underlined {
    padding: 0;
    background: transparent;
    border-width: 0 0 1px 0;
    border-style: dashed;
    border-radius: 0;
    height: auto;

    &.loading {
      border: none;
    }

    &:disabled {
      border: none;
      cursor: not-allowed;
      background: transparent;
    }
  }
}

[class].color- {
  &primary {
    &.inline,
    &.underlined,
    &.text {
      color: $palette-secondary;
      border-color: $palette-secondary;

      &:disabled {
        color: $palette-disable-dark;
        border-color: $palette-disable-dark;
      }
    }
  }

  &secondary {
    &.inline,
    &.underlined,
    &.text {
      color: $palette-text-secondary-dark;
      border-color: $palette-text-secondary-dark;

      &:disabled {
        color: $palette-disable-dark;
        border-color: $palette-disable-dark;
      }
    }
  }

  &error {
    &.inline,
    &.underlined,
    &.text {
      color: $palette-error;
      border-color: $palette-error;

      &:disabled {
        color: $palette-disable-dark;
        border-color: $palette-disable-dark;
      }
    }
  }
}

.btn[class].font-weight- {
  &semi-bold {
    font-weight: 600;
  }

  &bold {
    font-weight: bold;
  }

  &normal {
    font-weight: normal;
  }
}

@include media-tablet() {
  .btn-default,
  .btn-md,
  .btn-sm {
    &.adaptive {
      font-size: 16px;
      line-height: 1.375;

      &:not(.inline):not(.underlined) {
        height: 54px;
      }
    }
  }

  .btn-xs {
    &.adaptive {
      font-size: 14px;
      line-height: 19px;

      [class^='ButtonBaseContent'] {
        svg {
          width: 20px;
          height: 20px;
        }
      }

      &:not(.inline):not(.underlined) {
        height: 44px;
        padding: 0 40px;
      }
    }
  }
}

@include media-phone() {
  .btn-xs {
    &.adaptive {
      height: 54px;
      font-size: 16px;
      line-height: 1.375;
    }
  }
}
