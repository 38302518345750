@import '../../../assets/style/mixins';

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 0;
}

.wrapper {
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  min-height: 0;
}

.main {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  min-width: 0;
}

.header {
  display: flex;
  align-items: center;
  column-gap: 20px;
  justify-content: space-between;
  margin-bottom: 20px;

  &.withTabs {
    margin-bottom: 8px;
  }
}

.title {
  display: block;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.35;
  margin-bottom: 0;
}

[class].readAllButton {
  font-weight: 700;
}

[class].menuItem {
  gap: 0;
  justify-content: unset;
}

@include media-tablet() {
  .wrapper {
    flex-direction: column;
    margin-bottom: 16px;
  }
}

@include media-phone() {
  .header {
    margin-bottom: 16px;

    &.withTabs {
      margin-bottom: 4px;
    }
  }

  .title {
    font-size: 16px;
  }
}
