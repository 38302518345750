@import '../../../../../assets/style/mixins';

[class].root {
  word-break: initial;

  :global {
    .ant-collapse-content-active {
      font-size: $main-font-size;
      margin-top: 0.6em;
    }

    .ant-collapse-header {
      font-weight: bold;
      font-size: 14px;
      line-height: 1.357;
      color: $palette-info;
    }
  }

  &.highlighted {
    :global {
      .ant-collapse-header {
        color: $palette-primary;
        background: rgba($palette-info-light-rgb, 0.5);
      }
    }
  }
}

.disabled {
  :global {
    .ant-collapse-header {
      cursor: default !important;
    }
  }
}
