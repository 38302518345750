@import '../../../../assets/style/mixins';

.root {
  font-weight: bold;
  font-size: 16px;
  line-height: 1.375;
  text-transform: uppercase;

  @include media-tablet() {
    font-size: 14px;
  }
}
