@import 'src/assets/style/mixins';

.root {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba($palette-black-rgb, 0.8);
  z-index: $zIndex-modal + 1;
  padding: 70px 84px 78px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  min-width: 0;
}

@include media-tablet() {
  .root {
    padding: 15vh 50px 40px;
  }
}

@include media-phone() {
  .root {
    padding: 60px 32px 24px;
  }
}
