@import '../../../../../assets/style/mixins';

[class]:global(.ant-skeleton-content) .skeleton > li {
  margin: 0 auto;
  height: 20px;
}

[class].tabs {
  & :global {
    .ant-tabs {
      overflow: visible;
    }

    .ant-tabs-nav {
      $gutter: 12px;

      top: $chat-files-tabs-height;
      margin: 0;
      padding-bottom: $gutter;

      &::before {
        bottom: $gutter;
      }
    }

    .infinite-scroll-component__outerdiv {
      height: 100%;
    }

    .infinite-scroll-component {
      height: 100% !important;
    }
  }

  &.infiniteScroll {
    :global {
      .ant-tabs-tabpane {
        display: flex;
        justify-content: center;
      }

      .infinite-scroll-component__outerdiv {
        display: flex;
      }
    }
  }
}

[class].tabs :global(.ant-tabs-nav),
.dropdown {
  position: sticky;
  z-index: $zIndex-chat-header;
  background: $white;
  padding-bottom: 12px;
}

@include media-tablet() {
  [class].tabs:not(.tabsTop) {
    :global(.ant-tabs .ant-tabs-nav) {
      top: calc(
        #{$header-fixed-height-tablet} + #{$chat-header-desktop} + #{$chat-files-tabs-height} + 16px
      );
      z-index: $zIndex-header;
    }
  }
}

@include media-phone() {
  .dropdown:not(.tabsTop) {
    top: calc(
      #{$header-fixed-height-phone} + #{$chat-header-phone} + #{$chat-files-tabs-height} + 16px
    );
  }

  [class].tabs :global(.ant-tabs-nav) {
    display: none;
  }
}
