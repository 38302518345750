@import '../../../../../assets/style/mixins';

.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
  margin-top: 1.6em;
  margin-left: 2em;
}

.title {
  display: none;
  margin: 0;
  text-align: center;
  font-weight: 700;
  font-size: 1.4em;
  line-height: 1.357em;
  color: $palette-text-primary;

  svg {
    margin-left: 0.8em;
    transition: transform 0.3s;
  }

  &Open svg {
    transform: rotate(-180deg);
  }

  @include media-phone() {
    display: block;
  }
}

[class].tabs {
  margin-top: 16px;
  overflow: hidden;
  font-size: $main-font-size;
  flex-grow: 1;

  @include media-phone() {
    margin-left: 0em;
  }

  :global(.ant-tabs) {
    overflow: initial;
  }

  :global(.ant-tabs-nav) {
    margin-right: 20px;
    margin-left: 20px;

    @include media-phone() {
      display: none;
    }
  }

  :global(.ant-tabs-extra-content) {
    width: 100%;
  }

  :global(.ant-tabs-tabpane) {
    display: flex;
    flex-direction: column;
  }

  &.tabsEmpty {
    :global(.ant-tabs-nav) {
      margin-bottom: 0;
    }
  }
}
