@import '../../../../assets/style/mixins';

.root,
.wrapper {
  display: flex;
  flex-direction: column;
}

.wrapper {
  width: 100%;
  min-width: 0;

  margin: 20px 0 20px;
  row-gap: 12px;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
}

@include media-phone() {
  .wrapper {
    flex-direction: column;
  }
}
