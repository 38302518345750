.note {
  margin-bottom: 28px;
}

.content {
  font-size: 14px;
  line-height: 22px;
  text-align: left;
}

.controls {
  margin-top: 40px;
}
