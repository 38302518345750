@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  font-size: 14px;
  line-height: 19px;
}

.name {
  font-weight: 600;
}

.actions {
  display: flex;
  column-gap: 16px;
}
