@import 'src/assets/style/mixins';

.root {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.333em;
  padding: 0;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.333em;
  color: $palette-text-secondary-dark;
}

.inactive {
  justify-content: flex-start;
}
