@import '../../../../../assets/style/mixins';

.container {
  @include paper();
  @include fileList();
  padding: 12px 28px 0;

  :global(.ant-table-layout-fixed) {
    background: none;
    border: none;
    border-radius: unset;
  }

  :global(.ant-table-tbody) > tr:last-child > td {
    border: none;
  }
}

[class].pagination {
  margin-top: 8px;
}

[class].nameWrapper:global(.ant-table-cell) {
  width: 100%;
  text-align: left;
}

[class].cellWrapper {
  width: 135px;
}

.empty {
  padding: 68px 0 80px;
}

.lastCell {
  padding-right: 14px;
}

@include media-tablet() {
  .container {
    padding-left: 0;
    padding-right: 0;
  }
}