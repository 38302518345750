.root {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.player {
  display: block;
  margin: 0 auto;
  flex-grow: 1;
  max-width: 80%;
}
