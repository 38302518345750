@import '../../../../../../../assets/style/mixins';

[class].root {
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  padding: 5px;
  cursor: default;
  color: $palette-text-primary !important;
}
