[class].root {
  align-self: stretch;
}

.popoverContent {
  display: flex;
  padding: 0 14px;
  gap: 8px;
  overflow: hidden;
  min-width: 190px;
  align-items: flex-start;
  flex-direction: column;

  [class].button {
    padding: 0;
    height: auto;
    align-items: center;
    display: inline-flex;
  }
}
