@import '../../../../../assets/style/mixins';

.root {
  font-size: $main-font-size;
  padding: 10px 20px 20px;

  &Date {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 416px;
    padding: 20px;

    :global {
      .ant-picker-panel-container {
        border: none;
        box-shadow: none;

        .ant-picker-panel {
          border: none;
        }
      }

      .ant-picker-panel .ant-picker-panel-has-range {
        border: none;
      }

      .ant-picker-date-panel {
        width: 250px;

        .ant-picker-body {
          width: 250px;
          padding: 0;
        }
      }

      .ant-picker-header {
        width: 250px;
        border: none;
        padding: 0;
      }

      .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
      .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
      .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
        background: $palette-primary;
        border-radius: 4px;
      }

      .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
      .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
      .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
        background: $palette-primary;
        opacity: 0.1;
      }

      .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
        border: 1px solid $palette-primary;
      }
    }
  }
}

.list {
  max-height: 252px;
  overflow: auto;
}

.title {
  text-align: center;
  font-weight: bold;
  font-size: 1.8em;
  line-height: 1.222;
}

.buttons {
  padding: 1.4em 0 0;

  &Date {
    padding: 0;
  }
}
