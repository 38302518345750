@import '../../../../assets/style/mixins';

.separator {
  color: $palette-text-secondary-dark;
  transform: rotate(-90deg);
}

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  font-size: $main-font-size;

  &Wrapper {
    display: flex;
    width: 100%;
    min-height: 0;

    @include media-tablet() {
      flex-direction: column;
      padding-bottom: 1.6em;
    }
  }

  &Scrollable {
    width: 100%;
    max-width: 388px;
    min-width: 288px;

    @include media-tablet() {
      max-width: unset;
    }
  }
}

.info {
  align-self: flex-start;

  padding: 2.8em;
  text-align: center;

  @include paper();

  @include media-tablet() {
    display: flex;
    width: 100%;
    max-width: unset;
    margin: 0 auto 1.6em;
    padding: 2em;
    text-align: left;
  }

  @include media-phone() {
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
    padding: 2em 1.6em;
    margin-bottom: 1.6em;
    row-gap: 24px;
  }

  &Wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    min-width: 0;
    row-gap: 16px;

    @include media-tablet() {
      flex: unset;
    }

    @include media-phone() {
      width: 100%;
    }

    &Details {
      align-items: stretch;

      @include media-tablet() {
        flex: 1;
        margin-left: 2em;
      }

      @include media-phone() {
        margin-left: 0;
      }
    }
  }
}

.avatar {
  margin: 0 auto;

  @include media-phone() {
    margin-bottom: 16px;
  }
}

.buttons {
  display: flex;
  gap: 8px;
  margin: 0 auto;
}

.headline {
  overflow-wrap: break-word;
  font-size: 1.5em;
  line-height: 1.533;
  white-space: pre-wrap;
}

.details {
  &List {
    display: flex;
    flex-direction: column;
    padding: 0;
    padding-top: 20px;
    list-style: none;
    border-top: 1px solid $palette-stroke-primary;
  }
}

[class].editLink {
  margin: 2.8em auto 0;

  button {
    height: 44px;
  }

  @include media-tablet() {
    margin: 2em 0 0;
  }

  @include media-phone() {
    margin-top: 2.8em;
  }
}

[class].accountName {
  &Root {
    justify-content: center;
    margin-top: 20px;
    font-size: 1.6em;
    line-height: 1.375em;

    @include media-tablet() {
      margin-top: 0;
      justify-content: flex-start;
    }

    @include media-phone() {
      margin-top: 4px;
      justify-content: center;
    }
  }

  &Name {
    display: block;
  }

  &Icon {
    display: inline-block;
    vertical-align: text-top;
    margin-left: 1ch;
  }

  &AvatarWrapper {
    width: auto;
  }
}

[class].skeleton {
  display: flex;
  flex-direction: column;

  & :global {
    .ant-skeleton-header {
      padding: 0;
      margin-bottom: 16px;
    }

    .ant-skeleton-avatar {
      width: 100px;
      height: 100px;
    }

    li {
      height: 22px !important;
    }
  }
}

.header,
.nameAndLinks {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

@include media-tablet() {
  .header {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@include media-phone() {
  .invitationByLink {
    order: -1;
  }

  .header {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .headline {
    text-align: left;
  }
}

@include media-tablet() {
  [class].links {
    justify-content: flex-start;
    column-gap: 16px;
  }
}

@include media-phone() {
  [class].links {
    justify-content: center;
  }
}
