@import '../../../../assets/style/mixins';

.root {
  flex-shrink: 0;
  font-size: 12px;
  line-height: 1.333;
}

.default {
  color: $palette-text-secondary-dark;
}

.secondary {
  color: $palette-secondary-dark;
}
