@import '../../../../../assets/style/mixins';

.button,
.link {
  flex-shrink: 0;
  margin-left: 32px;
}

.link {
  display: flex;
  align-items: center;

  color: $palette-secondary;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  text-align: right;
  text-decoration-line: underline;
}

.icon {
  margin-left: 2px;
}

@include media-phone() {
  .button {
    width: 100%;
    margin-left: 0;
  }

  .button,
  .link {
    margin-left: 0;
  }
}
