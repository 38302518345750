@import '../../../../../assets/style/mixins';

.list {
  &Header {
    margin-bottom: 8px;
  }

  &Title {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: $palette-text-secondary-dark;
  }
}
