@import 'src/assets/style/mixins';

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 30px 0 24px;
}

.empty {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;

  .upload {
    margin-bottom: 16px;
  }
}

[class].button {
  height: unset;
  padding: 0;
  font-size: 14px;

  & svg {
    width: 20px;
    height: 20px;
  }
}

.upload {
  display: flex;
  align-items: center;
  font-weight: bold;
  color: $palette-secondary;
  cursor: pointer;
}

@include media-phone() {
  .root {
    flex-direction: column;
    justify-content: center;
    margin-top: 0;

    .upload {
      margin-bottom: 16px;
    }
  }
}
