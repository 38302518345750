@import '../../../../../assets/style/mixins';

.root {
  max-width: 360px;
  max-height: 360px;

  &Chat {
    max-width: 570px;
    max-height: 420px;
  }
}

.placeholderIcon {
  max-width: 60px;
  max-height: 60px;
}

@include media-tablet() {
  .root {
    &Chat {
      max-width: 420px;
    }
  }
}

@include media-phone() {
  .root {
    &Chat {
      max-width: 236px;
      max-height: 240px;
    }
  }
}
