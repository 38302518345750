@import '../../../../../assets/style/mixins';

.popover {
  display: flex;
  flex-direction: column;
  padding: 10px;

  .text,
  .iconWrapper {
    color: $palette-text-primary;
  }
}
