@import 'src/assets/style/mixins';

[class].root {
  min-height: 258px;
  position: relative;

  &:hover {
    background-color: $palette-skeleton;
  }

  &.selected {
    background-color: $palette-info-light;
  }

  &:hover,
  &.selected {
    .checkbox {
      visibility: visible;
    }
  }
}

.item {
  display: flex;
  flex-direction: column;
  flex: 1 0;
  height: 100%;
  justify-content: space-between;
}

.checkbox {
  visibility: hidden;
  position: absolute;
  z-index: 10;
  top: 27px;
  left: 27px;

  :global(.ant-checkbox-checked .ant-checkbox-inner) {
    background-color: $palette-primary;
    border: none;
  }
}

.containerName {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex: 1 0;
}

.fileName {
  font-size: 13px;
  white-space: normal;
  word-break: break-all;
  max-height: 2.769em;
}

.contextMenu {
  color: $palette-text-secondary-dark;
  margin-left: 12px;
}

.footer {
  display: flex;
  flex-direction: column;
  flex: 1 0;
  padding: 0 16px 16px;
}

.addonAfter {
  padding-top: 4px;
}

.image {
  padding: 16px 16px 0;
  margin-bottom: 12px;
}

@include media-tablet() {
  [class].root {
    min-height: 203px;
  }

  .checkbox {
    visibility: visible;
    top: 15px;
    left: 13px;
  }

  [class].contextMenuIcon {
    margin-left: 10px;
  }

  .footer {
    padding: 0 4px 12px 12px;
  }

  .image {
    padding: 12px 10px 0;
    margin-bottom: 8px;
  }
}

@include media-phone() {
  [class].root {
    min-height: 180px;
  }

  .checkbox {
    left: 11px;
  }

  .footer {
    flex: 1 0;
    padding: 0 0 12px 8px;
  }

  .addonAfter {
    padding-right: 6px;
  }

  .image {
    padding: 12px 8px 0;
  }

  [class].contextMenuIcon {
    margin-left: 4px;

    pointer-events: none;
  }
}
