@import "../../../../assets/style/colors";

[class].root {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 600;
  margin: 0;

  :global {
    .ant-radio-button-wrapper {
      flex: 1 1;
      text-align: center;
      border-color: $palette-secondary;
      color: $palette-secondary;

      &:first-child {
        border-radius: 4px 0 0 4px
      }

      &:last-child {
        border-radius: 0 4px 4px 0;
      }
    }

    [class].ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
      background: $palette-secondary;
      color: $white;

      &:hover {
        color: $white;
      }
    }
  }
}
