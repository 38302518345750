@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  width: 50%;
  align-items: center;

  &:last-child {
    justify-content: flex-end;
  }

  @include media-phone() {
    width: 100%;
    margin-bottom: 8px;

    &:last-child {
      justify-content: space-between;
      margin-bottom: 0;
    }
  }
}

.title {
  font-weight: 600;
}

.account {
  width: initial;
}

.tag {
  margin-left: 4px;
  font-size: 1.2em;
  line-height: 1.33em;
  color: $palette-text-secondary-dark;
}
