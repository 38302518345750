@import '../../../../../assets/style/mixins';

.root {
  padding: 16px;
  display: flex;
  align-items: flex-start;
  flex-grow: 1;
  border: 2px solid $palette-stroke-primary;
  border-radius: $main-border-radius;
  position: relative;
  cursor: pointer;
  column-gap: 12px;
  width: 100%;
  min-width: 0;

  &Selected {
    border-color: $palette-secondary;
  }

  svg {
    color: $palette-secondary;
  }
}

.icon {
  display: inline-flex;

  svg {
    height: 28px;
    width: 28px;
  }
}

.title {
  font-weight: 700;
  font-size: 14px;
  line-height: 1.5;
  margin: 0;
}

.description {
  margin: 0;
  font-size: 14px;
  line-height: 21px;
}

.selectedIcon {
  width: 28px;
  height: 28px;
  top: -14px;
  right: -14px;
  position: absolute;
}

@include media-phone() {
  .selectedIcon {
    display: none;
  }
}
