@import '../../../../../assets/style/mixins';

[class].root {
  border: none;

  :global {
    .ant-input-wrapper.ant-input-group {
      display: flex;
    }

    .ant-input-group-addon {
      border-color: $palette-stroke-primary;
      display: flex;
      width: auto;
      padding: 0;
      background-color: transparent;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    .ant-input-affix-wrapper {
      border: 1px solid $palette-stroke-primary;
      border-radius: 0 4px 4px 0;
      border-left: none;

      &:not(.ant-input-affix-wrapper-disabled):hover {
        border: 1px solid $palette-stroke-primary;
        border-left: none;
      }
    }
  }
}

[class].cascader {
  display: flex;
  align-items: center;
  width: auto;
  margin: 0 !important;

  :global {
    .ant-select-selector {
      padding: 0 !important;
      border: none !important;
      height: 100% !important;
    }

    .ant-select-selection-item {
      padding-right: 0 !important;

      @include media-phone() {
        display: flex;
        align-items: center;
      }

      &::after {
        display: none !important;
      }
    }
  }

  &List {
    padding: 16px;
    z-index: $zIndex-popover;
    text-align: left;

    box-shadow: 0px 4px 10px #00000026;
    border-radius: 0px 0px 5px 5px;

    @include media-phone() {
      padding: 20px;
    }

    :global {
      .ant-cascader-menus {
        align-items: stretch;
        max-width: 341px;
        width: 100%;

        @include media-phone() {
          flex-direction: column;
          width: 62vw;
          min-width: 216px;
          max-width: unset;
        }
      }

      .ant-cascader-menu-item-expand-icon {
        display: flex;
      }

      .ant-cascader-menu {
        min-width: 136px;
        height: auto;
        max-height: 160px;
        margin: 0;
        padding: 0 8px 0 0;

        overflow: hidden auto;
        border: none;

        @include media-phone() {
          width: 100%;
          padding: 0;
          max-height: 136px;
        }

        &:not(:first-child) {
          border-left: 1px solid $palette-stroke-primary;
          padding: 0 0 0 16px;
          min-width: 172px;

          @include media-phone() {
            min-width: unset;
            padding: 0;
            margin-top: 20px;
            border: none;
          }
        }
      }

      .ant-cascader-menu-item {
        padding: 0;
        margin-bottom: 16px;

        font-size: 14px;
        line-height: 19px;
        white-space: normal;
        color: $palette-text-primary;

        @include media-phone() {
          width: 100%;
          margin-bottom: 20px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &.ant-cascader-menu-item-disabled {
          height: 100%;
        }

        &-content {
          @include ellipsis()
        }
      }

      .ant-cascader-menu-item:hover,
      .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
      .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
        background-color: #fff;
        color: $palette-secondary;
        font-weight: normal;
      }

      .cascader-default {
        font-weight: bold;
        color: $palette-text-primary;
      }

      .cascader-placeholder {
        position: relative;
        top: 0;
        left: 0;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: $palette-text-primary;
        text-align: center;
        display: flex;
        align-items: center;
        padding: 5px 0 0;
        cursor: default;
        white-space: normal;
        overflow-wrap: break-word;
      }
    }
  }

  &Child {
    display: flex;
    padding: 4px 40px 4px 16px;
    margin: 8px 0;
    border-right: 1px solid $palette-stroke-primary;
    cursor: pointer;
  }

  &Title {
    max-width: 84px;
    font-weight: bold;
    font-size: 12px;
    line-height: 1.333;

    color: $palette-text-primary;

    @include ellipsis();

    @include media-phone() {
      max-width: 66px;
    }
  }
}

.arrowIcon {
  width: 20px;
  height: 20px;
  margin-left: 4px;
  color: $palette-text-primary;

  &Expand {
    transform: rotate(-90deg);

    @include media-phone() {
      transform: unset;
    }
  }
}
