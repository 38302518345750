@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-right: 1px solid $palette-stroke-primary;

  @include media-tablet() {
    height: 100%;
    border-right: none;
  }
}

[class].tabs {
  height: auto;
  min-height: unset;

  [class]:global(.ant-tabs) {
    height: fit-content;
  }

  [class]:global(.ant-tabs-content) {
    height: 0;
  }

  [class]:global(.ant-tabs-nav) {
    margin: 16px 20px 0;

    @include media-tablet() {
      margin-top: 20px;
    }
  }
}

.tab {
  display: flex;
  align-items: baseline;
  justify-content: center;
}
