@import '../../../../../../assets/style/mixins';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;

  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}

.description,
.email {
  text-align: center;
}

.description {
  margin-bottom: 20px;
}

.email {
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 28px;
}

[class].topLabel {
  font-weight: 400;
}

.button {
  margin-top: 40px;
}
