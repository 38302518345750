@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.143em;
  font-size: $main-font-size;

  @include media-phone() {
    position: relative;
    flex-direction: column;
    align-items: flex-start;

    &:first-child {
      margin-top: 0;
    }
  }
}

.wrapper {
  display: flex;
  align-items: center;
  min-width: 0;

  @include media-phone() {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;

    &:first-child {
      margin-bottom: 1em;
    }
  }
}

.user {
  padding-right: 2.4em;
}

.actions {
  flex-shrink: 0;
  font-size: 1.4em;
  line-height: 1.357;
}

[class].accountNameTitle {
  font-weight: normal;
}
