@import '../../../../assets/style/mixins';

[class].skeleton {
  & :global {
    .ant-skeleton-content {
      .ant-skeleton-title {
        height: 14px;
        margin: 0;
      }

      .ant-skeleton-paragraph {
        margin: 12px 0 0 !important;

        li {
          height: 12px;
        }
      }
    }

    .ant-skeleton-avatar {
      width: 36px;
      height: 36px;

      @include media-phone() {
        width: 40px;
        height: 40px;
      }
    }
  }
}

.wrapper:nth-last-of-type(n + 2) {
  margin-bottom: 1.2em;
}
