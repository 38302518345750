@import '../../../../../assets/style/mixins';

.root {
  position: relative;
  border-radius: 50%;
  box-sizing: content-box;
  background-color: $white;
  flex-shrink: 0;

  &.BUSINESS {
    background-color: $palette-avatar-business;
  }

  &.PROFESSIONAL {
    background-color: $palette-avatar-professional;
  }

  &.GROUP {
    background-color: $palette-avatar-group;
  }

  img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &.border {
    box-shadow: $white 0 0 0 2px;
  }

  &.llg {
    @include llgImage();

    .online {
      right: 9px;
      bottom: 9px;
    }

    > svg {
      width: 58px;
      height: 58px;
    }
  }

  &.lg {
    @include lgImage();

    .online {
      right: 5px;
      bottom: 6px;
    }

    > svg {
      width: 40px;
      height: 40px;
    }
  }

  &.lmd {
    @include lmdImage();
  }

  &.md {
    @include mdImage();

    > svg {
      width: 24px;
      height: 24px;
    }
  }

  &.sm {
    @include smImage();

    > svg {
      width: 20px;
      height: 20px;
    }
  }

  &.xs {
    @include xsImage();

    .online {
      width: 6px;
      height: 6px;
      border-radius: 6px;
    }

    > svg {
      width: 16px;
      height: 16px;
    }
  }

  &.xxs {
    @include xxsImage();

    .online {
      width: 4px;
      height: 4px;
      border-radius: 4px;
    }

    &.border {
      box-shadow: $white 0 0 0 1px;
    }

    > svg {
      width: 12px;
      height: 12px;
    }
  }

  &[class].muted {
    background-color: $palette-text-secondary-dark;
  }
}

.clickable {
  cursor: pointer;
}

@include online(initial, 0, 2px, initial);
