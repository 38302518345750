@import '../../../../assets/style/mixins';

.root {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  margin-top: 28px;
}

.categories {
  justify-content: stretch;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 20px;
}

.draftButton {
  padding: 0;
  border: 0;
  background: none;
  @include link(14px, 19px);
}

.submitSkeleton,
.draftSkeleton {
  &:global(.ant-skeleton) {
    width: unset;
  }

  :global(.ant-skeleton-content .ant-skeleton-paragraph) {
    margin: 0 !important;

    > li {
      width: 124px !important;
    }
  }
}

.submitSkeleton {
  :global(.ant-skeleton-content .ant-skeleton-paragraph > li) {
    height: 48px;
  }
}

.draftSkeleton {
  :global(.ant-skeleton-content .ant-skeleton-paragraph > li) {
    height: 22px;
  }
}

@include media-phone() {
  .root {
    row-gap: 20px;
    margin-top: 20px;
  }
}
