@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.actions {
  margin: 32px 0 10px;
}
