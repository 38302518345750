@import '../../../../assets/style/colors';

.root {
  display: flex;
  flex-direction: column;
  margin: 8px 20px 0;
  padding-bottom: 10px;
  border-top: 1px solid $palette-stroke-primary;
}

.title {
  margin-top: 16px;
  font-size: 12px;
  line-height: 16px;
  color: $palette-text-secondary-dark;
}

.status {
  margin-top: 12px;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  white-space: nowrap;

  &Sender {
    color: $palette-warning-dark;
  }

  &Receiver {
    color: $palette-secondary;
  }
}
