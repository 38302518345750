@import '../../../../../../assets/style/colors';

.link {
  display: flex;
  align-items: center;
  color: $palette-secondary;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.428;
  cursor: pointer;

  &Icon {
    margin-right: 4px;
    width: 20px;
    height: 20px;
  }
}
