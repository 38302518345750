@import '../../../../assets/style/colors';

.root {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
}

.holder {
  display: flex;
  flex-wrap: nowrap;
  min-width: 0;
  flex-shrink: 1;
}

.list {
  display: flex;
  flex-wrap: nowrap;
  min-width: 0;
  flex-shrink: 1;
}

.item {
  margin-right: 4px;
  max-width: 175px;
}

.badge {
  padding: 0 10px;
  font-size: 14px;
  line-height: 31px;
  font-weight: 700;
  color: $palette-secondary;
  border: 1px solid $palette-secondary;
  border-radius: 34px;
  min-width: 33px;
  text-align: center;
  cursor: pointer;
  flex-shrink: 0;
}
