@import '../../../../../assets/style/mixins';

[class].rootWrapper {
  margin: 28px 16px 24px;
}

[class].editorWrapper {
  display: flex;
  align-items: center;
  padding: 0 16px;
  border: 1px solid $palette-stroke-primary;
  border-radius: $main-border-radius;
}

[class].editorContainer,
[class].editorContainerDisabled {
  :global(.DraftEditor-root) {
    padding: 10px 0;
  }
}

[class].editorContainer {
  &Disabled {
    cursor: not-allowed;

    :global {
      .public-DraftEditorPlaceholder-root {
        color: $palette-error;
        pointer-events: none;
        position: static;
      }

      .DraftEditor-root {
        padding: 12px 15px;
      }
    }
  }

  &Inactive {
    .editorWrapper {
      flex-direction: column;
    }

    :global(.public-DraftEditorPlaceholder-inner) {
      color: $palette-error;
      word-break: normal;
    }

    :global(.DraftEditor-root) {
      padding: 10px 20px;
      text-align: center;
    }
  }
}

[class].editorEndAdornment {
  &Inactive {
    margin: 0 auto;

    button {
      margin-bottom: 12px;
    }
  }
}

[class].audio {
  padding: 4px 20px;

  &TimeIndicator {
    font-size: 13px;
    line-height: 1.384;
  }

  :global(.rhap_time) {
    font-size: 13px;
  }
}

[class].skeleton {
  display: flex;
  width: unset;
  min-height: 40px;

  &Title {
    &:global(.ant-skeleton-title) {
      margin: 0;
      height: 100%;
    }
  }
}

@include media-tablet() {
  [class].editorEndAdornment {
    &Inactive {
      button {
        margin-bottom: 0;
      }
    }
  }

  [class].editorContainerInactive {
    .editorWrapper {
      flex-direction: row;
    }
  }
}

@include media-phone() {
  [class].rootWrapper {
    margin-top: 0;
  }

  [class].skeleton {
    min-height: 44px;
  }

  [class].editorEndAdornment {
    &Inactive {
      button {
        margin-bottom: 12px;
      }
    }
  }

  [class].editorContainerInactive {
    font-size: 1.3em;
    line-height: 1.461;

    .editorWrapper {
      flex-direction: column;
    }
  }
}
