@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  margin-bottom: 20px;
  background-color: $palette-success-light;
  padding: 16px 20px;
  border-radius: $main-border-radius;
  align-items: center;
}

.content {
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-right: 12px;

  svg {
    margin-right: 12px;
  }
}

@include media-phone() {
  .root {
    flex-direction: column;
  }

  .content {
    text-align: center;
    margin-bottom: 20px;
    margin-right: 0;
    flex-direction: column;

    svg {
      margin-bottom: 12px;
    }
  }
}
