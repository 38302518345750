@import '../../../../../../assets/style/mixins';

[class].root,
[class].radio {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

[class].radio {
  margin-bottom: 20px;

  &Item:not(:last-child) {
    margin: 0 0 12px;
  }
}

.title {
  margin-bottom: 20px;
  font-size: 12px;
  line-height: 1.333;
  font-weight: 700;
  text-transform: uppercase;
}

[class].button {
  height: auto;
  padding: 0;
  font-weight: 400;

  @include media-tablet() {
    height: auto;
  }
}
