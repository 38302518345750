
/*
 * Palette Colors Theme
 */

:root {
  /* White */
  --palette-white:                                             #FFFFFF;
  --palette-white-rgb:                                         255,255,255;

  /* Black */
  --palette-black:                                             #000000;
  --palette-black-rgb:                                         0,0,0;

  /* Text Colors */
  --palette-text-primary:                                      #0D141E;
  --palette-text-primary-rgb:                                  13, 20, 30;

  --palette-text-secondary:                                    #CBCFD8;
  --palette-text-secondary-dark:                               #96999F;
  --palette-text-secondary-light:                              #EDEFF6;

  --palette-text-secondary-rgb:                                203, 207, 216;
  --palette-text-secondary-dark-rgb:                           150, 153, 159;
  --palette-text-secondary-light-rgb:                          237, 239, 246;
  /* END */


  /* Disabled Colors */
  --palette-disable:                                           #DEE0E9;
  --palette-disable-light:                                     #EDEFF6;
  --palette-disable-dark:                                      #B6B8BD;

  --palette-disable-rgb:                                       222, 224, 233;
  --palette-disable-light-rgb:                                 237, 239, 246;
  --palette-disable-dark-rgb:                                 182, 184, 189;
  /* END */


  /* Stroke Colors */
  --palette-stroke-primary:                                    #E3E6F0;
  --palette-stroke-primary-light:                              #EDEFF6;
  --palette-stroke-primary-dark:                               #CBCFD8;

  --palette-stroke-primary-rgb:                                227, 230, 240;
  --palette-stroke-primary-light-rgb:                          237, 239, 246;
  --palette-stroke-primary-dark-rgb:                           203, 207, 216;
  /* END */

  /* Gray */
  --palette-gray:                                              #f6f7fa;
  /* END */

  /* Accent color */
  --palette-accent:                                            #EA4C3A;
  --palette-accent-light:                                      #EA6152;
  --palette-accent-dark:                                       #EA3723;
  /* END */

  /* Error Colors */
  --palette-error:                                             #F44336;
  --palette-error-dark:                                        #c62828;
  --palette-error-light:                                       #FDE5E5;

  --palette-error-rgb:                                         244, 67, 54;
  --palette-error-dark-rgb:                                    198, 40, 40;
  --palette-error-light-rgb:                                   253, 229, 229;
  /* END */


  /* Warning Colors */
  --palette-warning:                                           #ffa726;
  --palette-warning-dark:                                      #F57C00;
  --palette-warning-light:                                     #FFF2DF;

  --palette-warning-rgb:                                       255, 167, 38;
  --palette-warning-dark-rgb:                                  245, 124, 0;
  --palette-warning-light-rgb:                                 255, 242, 223;
  /* END */


  /* Info Colors */
  --palette-info:                                             #1e88e5;
  --palette-info-dark:                                        #1976d2;
  --palette-info-light:                                       #D8ECFD;

  --palette-info-rgb:                                         30, 136, 229;
  --palette-info-dark-rgb:                                    25, 118, 210;
  --palette-info-light-rgb:                                   216, 236, 253;
  /* END */


  /* Success Colors */
  --palette-success:                                          #4CAF50;
  --palette-success-dark:                                     #43A047;
  --palette-success-light:                                    #DFF1E0;

  --palette-success-rgb:                                      76, 175, 80;
  --palette-success-dark-rgb:                                 67, 160, 71;
  --palette-success-light-rgb:                                223, 241, 224;
  /* END */

  /* Avatar Colors */
  --palette-avatar-business:                                  #64B5F6;
  --palette-avatar-business-rgb:                              100, 181, 246;


  --palette-avatar-professional:                              #81C784;
  --palette-avatar-professional-rgb:                          129, 199, 132;


  --palette-avatar-news:                                   #C781B3;
  --palette-avatar-news-rgb:                               199, 129, 179;


  --palette-avatar-group:                                     #F57530;
  --palette-avatar-group-rgb:                                 245, 117, 48;
  /* END */

  /* Chip Colors */
  --palette-chip-light:                                       #d6ebf2;
  --palette-chip-light-rgb:                                   214, 235, 242;

  --palette-chip-dark:                                        #d9dcf0;
  --palette-chip-dark-rgb:                                    217, 220, 240;
  /* END */

  /* Notification colors */

  --palette-notification-violette:                             #EAE4F5;

  /* END */


  /*
 ******************
 * Dynamic Colors *
 ******************
 */


  /* Primary Colors */
  --palette-primary:                                          #3F51B5;
  --palette-primary-dark:                                     #283593;
  --palette-primary-light:                                    #5c6bc0;

  --palette-primary-rgb:                                      55, 80, 178;
  --palette-primary-dark-rgb:                                 40, 53, 147;
  --palette-primary-light-rgb:                                92, 107, 192;
  /* END */


  /* Secondary Colors */

  --palette-secondary:                                        #3299BF;
  --palette-secondary-dark:                                   #2F8BAD;
  --palette-secondary-light:                                  #4EB0D4;
  --palette-secondary-hover:                                  #EFF7FA;

  --palette-secondary-rgb:                                    50, 153, 191;
  --palette-secondary-dark-rgb:                               47, 139, 173;
  --palette-secondary-light-rgb:                              78, 176, 212;
  /* END */

  /* Seldom colors */

  --palette-nav-border:                                       #5262BC;
  --palette-call-background:                                  #455a64;
  --palette-body-background:                                  #F9FAFB;
  --palette-shadow:                                           rgba(0, 0, 0, 0.15);
  --palette-layout-background:                                #f7f8fa;
  --palette-dark-gray:                                        #C4C4C4;
  --palette-broadcast:                                        #08979C;
  /* END */

  /* Skeleton colors */
  --palette-skeleton:                                         #F3F4F9;

  --palette-skeleton-rgb:                                     95, 96, 98;
  /* END */

  /* Icons */
  --palette-group-icon-background:                             #8C98FF;
  --palette-profile-icon-background:                           #56CCF2;

  /* Files */
  --palette-files-spreadsheet:                                 #79A036;
}
