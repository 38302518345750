@import '../../../../../assets/style/mixins';

.looking {
  margin-bottom: 8px;
}

.location {
  margin-bottom: 16px;
}

@include media-phone() {
  .looking {
    margin-bottom: 12px;
  }
}
