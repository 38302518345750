
@import '../../../../../assets/style/mixins';

.timer {
  margin-left: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.357;
  min-width: 0;

  @include ellipsis();
}
