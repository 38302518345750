@import '../../../../assets/style/mixins';

.root {
  font-size: 12px;
  line-height: 1.333;
  color: $palette-secondary-dark;
  white-space: nowrap;
  margin: 0;
}

.container {
  width: 100%;
}
