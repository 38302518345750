@import '../../../../../assets/style/mixins';

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 93px;
}

.remove {
  padding: 0;
  margin: 0;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: $white;
  cursor: pointer;

  svg {
    width: 15px;
    height: 15px;
    color: $palette-text-secondary-dark;
  }
}

[class].image {
  width: 90px;
  height: 90px;
  border: 1px solid $palette-stroke-primary-light;
  border-radius: 4px;
  margin-bottom: 8px;

  img {
    border-radius: 4px;
  }
}

[class].fileIcon svg {
  width: 40px;
  height: 40px;
}

.fileName {
  font-size: 13px;
  line-height: 18px;
  white-space: normal;
  word-break: break-all;
  max-height: 2.769em;
}

@include media-phone() {
  .root {
    padding: 12px 8px;
    width: 128px;
  }

  .fileName {
    padding-right: 28px;
  }

  [class].image {
    width: 110px;
    height: 110px;
  }

  [class].fileIcon svg {
    width: 56px;
    height: 56px;
  }

  .remove {
    top: 16px;
    left: 12px;
    width: 24px;
    height: 24px;

    svg {
      width: 18px;
      height: 18px;
    }
  }
}
