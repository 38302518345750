@import 'src/assets/style/colors';
@import 'src/assets/style/mixins';

.wrapper {
  position: relative;
  @include online(-4px, -4px, initial, initial);
}

.text {
  border-radius: 4px;
  font-weight: bold;
  font-size: 10px;
  line-height: 16px;
  padding: 0 8px;
  height: 16px;
}

.admin {
  background: $palette-chip-light;
  color: $palette-secondary-dark;
}

.superAdmin {
  background: $palette-chip-dark;
  color: $palette-primary-dark;
}
