@import '../../../../assets/style/mixins';

.root {
  display: flex;
  gap: 28px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 18px;
}

.description {
  margin: 0;
}

@include media-phone() {
  .root {
    gap: 20px;
    padding-top: 10px;
  }
}
