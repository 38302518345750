@import '../../../../../assets/style/mixins';

[class].root {
  margin-right: 16px;

  svg {
    @include media-phone() {
      width: 24px;
    }
  }
}

[class].root.case {
  margin-right: 8px;
}
