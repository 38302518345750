.root {
  position: relative;
  display: flex;
  height: 100%;
  overflow: hidden;
  flex-grow: 1;
}

.image {
  display: block;
  min-width: 0;
  margin: 0 auto;
  object-fit: contain;
  flex-grow: 1;
}
