@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $white;
  padding: 24px 0 12px;

  @include dividerBottom(0px);
}

.sort {
  color: $palette-text-secondary-dark;
  cursor: pointer;
  column-gap: 4px;

  &Icon {    
    color: inherit;

    & > svg {
      width: 16px;
      height: 16px;
    }
  }
}

[class].popover {
  padding: 0;
  min-width: 256px;

  :global {
    .ant-popover-inner-content {
      padding: 20px;
    }
  }
}

@include media-tablet() {
  .root {
    border: 1px solid $palette-stroke-primary;
    border-top: none;
    border-bottom: none;
    padding: 20px;

    @include dividerBottom(20px);
  }

  [class].popover {
    min-width: 208px;
  }
}

@include media-phone() {
  .root {
    padding: 20px 16px 16px;

    @include dividerBottom(16px);
  }
}
