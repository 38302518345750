@import '../../../../assets/style/mixins';

.root {
  color: $palette-text-secondary-dark;
  flex-shrink: 0;

  &.md {
    width: 20px;
    height: 20px;
  }

  &.sm {
    width: 16px;
    height: 16px;
  }
}
