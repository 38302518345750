@import '../../../../assets/style/mixins';

$columnGap: 16px;

.root {
  position: relative;
  display: flex;
  column-gap: $columnGap;
}

.avatar {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 16px;
}

.details {
  min-width: 0;
  flex-grow: 1;

  &List {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    align-items: flex-start;
  }
}

.header {
  min-width: 0;
}

.account {
  margin-bottom: 4px;
}

.accountName {
  display: flex;
  align-items: center;
  column-gap: 20px;
  min-width: 0;
}

[class].accountRoot {
  font-size: 18px;
  line-height: 24px;
  min-width: 0;
}

[class].contextMenu {
  display: flex;
  column-gap: 16px;
}

@include media-phone() {
  .account {
    display: flex;
    column-gap: $columnGap;
    margin-bottom: 16px;
  }

  .accountName {
    margin-bottom: 12px;
  }
}
