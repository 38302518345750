@import 'src/assets/style/mixins';

[class].fileNameWrapper:global(.ant-table-cell) {
  width: 100%;
  text-align: left;
}

.sortable {
  padding-right: 1.384em;

  @include media-phone() {
    padding-right: 0;
  }
}

[class].ownerWrapper {
  width: 130px;

  div {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  @include media-tablet() {
    display: none;
  }
}

[class].permissionWrapper {
  width: 100px;

  button {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  @include media-phone() {
    display: none;
  }
}

.sharedAtWrapper {
  width: 100px;

  @include media-phone() {
    display: none;
  }
}

.fileSizeWrapper {
  width: 100px;

  @include media-phone() {
    display: none;
  }
}

[class].kebabMenuWrapper {
  width: 40px;
  color: $palette-text-secondary-dark;
}

[class].fileName {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.videoStatus {
  margin-left: 18px;
}

.contextMenuIcon {
  @include tableContextMenuIcon();
}
