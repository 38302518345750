@import '../../../../assets/style/mixins';

.form {
  display: flex;
  flex-direction: column;
  width: 100%;

  &Row {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &:nth-last-child(n + 3) {
      margin-bottom: 2.4em;
    }

    @include media-phone() {
      flex-direction: column;
    }

    &Skeleton:nth-last-child(n + 3) {
      margin-bottom: 2em;

      @include media-tablet() {
        margin-bottom: 2.4em;
      }
    }
  }

  &Icon {
    position: relative;
    left: 4px;
    transform: translateY(-50%);
    cursor: pointer;
  }
}

.emailLabel {
  display: flex;
  justify-content: space-between;
}

.successIcon {
  width: 14px;
  height: 14px;
}

.input {
  width: 100%;
  flex: 1;
  min-width: 0;
  margin-right: 20px;

  @include media-phone() {
    margin-right: 0;
    margin-bottom: 24px;
  }

  &:last-child {
    margin-right: 0;

    @include media-phone() {
      margin-bottom: 0;
    }
  }
}

.field {
  height: 44px;
}

.button {
  margin: 36px auto 0;

  @include media-phone() {
    margin-top: 40px;
  }
}

.emailConfirmedText {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: $palette-success;
  margin-right: 4px;
}

.changeButton {
  @include link();
  font-size: 12px;
  margin: 0 16px 0 4px;
  cursor: pointer;
}

[class].skeletonButton {
  margin-top: 36px;
}
