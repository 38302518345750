@import '../../../../../assets/style/mixins';

.root,
.main,
.aside {
  display: flex;
  align-items: center;
  column-gap: 12px;
}

.root {
  justify-content: space-between;
  margin-bottom: 10px;
}

.main {
  flex: 1;
  row-gap: 4px;
  flex-wrap: wrap;
}

.aside {
  align-self: flex-start;
}

.title {
  display: inline-block;
  font-size: 1.6em;
  line-height: 1.375;
  font-weight: bold;
  word-break: break-word;
  word-wrap: break-word;

  color: $palette-text-primary;

  &:hover,
  &:focus {
    color: $palette-secondary;
  }
}

[class].status {
  text-transform: unset;
}

.autoApprove {
  background: $palette-info-light;
  border-radius: 4px;
  padding: 4px 12px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: $palette-info-dark;
  flex-shrink: 0;
}

@include media-phone() {
  .title {
    margin-top: 12px;
    line-height: 1.5;
  }
}
