@import '../../../../../assets/style/mixins';

[class].root {
  margin: 24px 0 0;
  padding: 0 0 24px;

  &:nth-last-child(n + 1) {
    @include dividerBottom(0px);
  }
}

.workInformation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 12px;
}

@include media-tablet() {
  [class].root {
    margin: 16px 0 0;
    padding: 0 0 16px;
  }
}
