@import 'src/assets/style/mixins';

[class].fileNameWrapper:global(.ant-table-cell) {
  width: 100%;
  text-align: left;
}

.sortable {
  padding-right: 1.384em;

  @include media-phone() {
    padding-right: 0;
  }
}

.updatedAtWrapper {
  width: 120px;

  @include media-phone() {
    display: none;
  }
}

.fileSizeWrapper {
  width: 100px;

  @include media-phone() {
    display: none;
  }
}

[class].kebabMenuWrapper {
  width: 40px;
  color: $palette-text-secondary-dark;
}

[class].fileName {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.videoStatus {
  margin-left: 18px;
}

.contextMenuIcon {
  @include tableContextMenuIcon();
}
