@import '../../../../assets/style/colors';
@import '../../../../assets/style/mixins';

.root {
  padding: 16px 36px 16px 20px;
  position: fixed;
  right: 31px;
  bottom: 15px;
  z-index: $zIndex-push_notification;
  display: flex;
  align-items: center;
  min-width: 420px;
  background: $palette-text-secondary-light;
  color: $palette-primary;
  border-radius: 4px;

  &:last-child {
    margin-right: 0;
  }
}

.label {
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;
}

.close {
  margin-left: 0.5em;
  position: absolute;
  right: 8px;
  top: 8px;

  svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}

@include media-phone() {
  .root {
    bottom: calc(#{$sider-fixed-height-phone} + 16px);
    left: 16px;
    right: 16px;
    min-width: unset;
  }

  .label {
    max-width: 180px;
  }
}
