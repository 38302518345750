@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  flex-wrap: wrap;
}

.purpose {
  row-gap: 8px;
  column-gap: 8px;
}
