@import '../../../../assets/style/mixins';

.root {
  display: flex;
  flex-direction: column;
  max-height: 520px;
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 4px;
}

[class].skeleton {
  width: calc(100% - 100px);
  height: 100%;
  margin: 0 16px;
  display: flex;
  align-items: center;

  &Root {
    padding: 20px 0;
  }

  :global(.ant-skeleton-paragraph) {
    margin: 0;
  }
}

[class].row {
  padding-right: 2em;
}
