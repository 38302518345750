@import '../../../../../../assets/style/colors';

.label {
  font-weight: 600;
  color: $palette-secondary;
  margin-bottom: 12px;
}

.list {
  padding-left: 16px;
  margin: 0;

  & > li {
    font-weight: 400;
  }
}
