@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.author {
  margin: 18px 0 16px;
}

@include media-phone() {
  .root {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 16px;
  }
}
