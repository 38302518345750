@import '../../../../../assets/style/colors';

.root {
  color: $palette-text-secondary-dark;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: right;

  &Success {
    color: $palette-success;
  }

  &Fail {
    color: $palette-error;
  }
}
