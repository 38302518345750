@import '../../../../assets/style/mixins';

.wrapper {
  position: relative;
}

.cover {
  aspect-ratio: 2.2;
}

.avatar {
  margin-top: -84px;
  margin-left: 60px;
  align-items: start;
}

.content {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 70px 60px 35px;
  justify-content: space-between;
}

.info {
  display: flex;
  gap: 40px;
  font-size: 14px;

  &Block {
    display: flex;
    flex-direction: column;

    span {
      color: $palette-text-secondary-dark;
    }
  }
}

@include media-phone() {
  .avatar {
    margin-left: 20px;
  }

  .content {
    gap: 40px;
    padding: 40px 20px;
    justify-content: space-around;
    flex-direction: column;
  }
}
