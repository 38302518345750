@import '../../../../assets/style/mixins';

.root {
  @include modalTitleWithoutDescriptionGap();

  :global(.steps-footer-actions) {
    margin-top: 34px;
  }
}

[class].steps {
  max-width: unset;
  margin-bottom: 28px;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.input {
  & + & {
    margin-top: 24px;
  }
}

.termsLink {
  text-decoration: underline;
  text-transform: none;
  margin-left: 4px;
}

.email {
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  margin-bottom: 28px;
}

.emailDescription {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-bottom: 24px;

  &Title,
  &Value {
    display: block;
    font-size: 14px;
  }

  &Title {
    font-weight: 700;
  }
}

.checkboxLabel {
  align-items: baseline;
}
