@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.8em 2em;
  column-gap: 8px;

  border: none;
  background-color: transparent;
  cursor: pointer;

  &:not(.disabled):hover {
    color: $palette-secondary-dark;
  }
}

.disabled {
  color: $palette-text-secondary-dark;
  cursor: default;
}

.content {
  font-size: 1.4em;
  line-height: 1.357;
  white-space: nowrap;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;

    .root:not(.disabled):not(:hover) & {
      color: $palette-text-primary;
    }
  }
}

.innerIconRight {
  width: 20px;
  height: 20px;
  margin-left: auto;
}
