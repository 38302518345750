@import 'src/assets/style/colors';

.link {
  font-size: 12px;
  white-space: nowrap;

  color: $white;

  &:hover,
  &:focus {
    color: $white;
  }
}

.text {
  text-decoration: underline;
}
