@import '../../../../../assets/style/mixins';

.root,
.account {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.root {
  padding: 28px;
}

.header {
  display: flex;
  align-items: center;
  column-gap: 24px;
}

[class].avatar :global(.ant-skeleton-avatar) {
  width: 100px;
  height: 100px;
}

[class].details {
  display: flex;
  align-items: center;

  &:nth-last-child(n + 2) {
    margin-bottom: 16px;
  }

  &Title {
    margin: 0 !important;
    width: 50% !important;
    height: 24px !important;
  }
}

@include media-tablet() {
  .root {
    padding: 0;
  }
}
