@import '../../../assets/style/mixins';

.root {
  display: flex;
  width: 100%;
  height: 100%;
  font-size: $main-font-size;
}

.content {
  @include paper();
}

.main {
  width: 100%;
  min-width: 0;
  display: flex;
  flex-direction: column;
}

[class].nav {
  margin-bottom: 2em;
  padding: 0;
}

.addonBeforeAside {
  @include dividerBottom();
}

@include media-tablet() {
  .root {
    flex-direction: column;
  }

  .content {
    padding: 2em;
  }

  [class].nav {
    margin-top: 2em;
  }
}

@include media-phone() {
  .content {
    padding: 0;
    border: none;
  }

  [class].nav {
    margin-top: 0;
  }
}
