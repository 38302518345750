@import '../../../../../../assets/style/mixins';

.root {
  border: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  column-gap: 4px;
  background-color: $palette-secondary-dark;
  padding: 4px 4px 4px 8px;
  border-radius: 28px;

  &:disabled {
    cursor: not-allowed;
  }
}

.messageOutgoing {
  background-color: $palette-secondary;
}

.outgoing.messageOutgoing {
  background-color: $white;
}

.outgoing.messageOutgoing.messageImage,
.outgoing {
  background-color: $palette-secondary-light;
}

.popover :global(.ant-popover-inner-content) {
  padding: 4px 0;
}

@include media-tablet() {
  [class].popover {
    &:global(.ant-popover) {
      z-index: $zIndex-nav-bar;
    }
  }
}
