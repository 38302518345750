@import 'src/assets/style/mixins';

.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 20px 8px;
}

.title {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 12px;
  color: $palette-text-secondary-dark;
}
