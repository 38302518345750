@import 'src/assets/style/mixins';

[class].icon {
  color: $palette-secondary;
  cursor: pointer;

  margin-left: 16px;

  &Disable,
  &Disable svg {
    @include disabledIcon();
  }

  svg {
    color: inherit;
  }

  &:first-child {
    margin-left: 0;
  }
}
