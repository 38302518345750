@import '../../../../assets/style/mixins';

.root {
  display: flex;
  column-gap: 16px;
  min-width: 0;
  padding: 4px 0;
  font-size: $main-font-size;
  width: 100%;

  &Processing {
    min-width: 251px;
  }
}

.name {
  font-weight: 700;
}

.size {
  color: $palette-text-secondary-dark;
}

.name,
.size {
  font-size: 1.4em;
  line-height: 1.571;
  color: $palette-text-primary;
}

.outgoingMessage {
  .name {
    color: $white;
  }

  .size {
    color: $white;
    opacity: 0.5;
  }
}

.imagePreview {
  padding: 0;
  border-radius: 4px;
  overflow: hidden;
}

.info {
  display: flex;
  flex-direction: column;
  max-width: 191px;
  min-width: 0;
}

@include media-tablet() {
  .root {
    &Processing {
      min-width: 228px;
    }
  }

  .name,
  .size {
    line-height: 1.5;
  }
}

@include media-phone() {
  .root {
    &Processing {
      min-width: 180px;
    }
  }

  .size {
    margin-top: 4px;
  }

  .name,
  .size {
    font-size: 1.2em;
    line-height: 1.3;
  }
}
