@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  flex-wrap: wrap;
  row-gap: 14px;
  width: min-content;
  margin: 12px auto 0;

  @include media-phone() {
    margin: 12px 0 0;
    flex-wrap: nowrap;
    column-gap: 10px;
  }
}