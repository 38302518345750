@import '../../../../../assets/style/colors';

.root {
  display: flex;
  align-items: center;
  color: $palette-text-secondary-dark;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}
