@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  align-items: center;
  column-gap: 16px;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
}

.size {
  color: $palette-secondary;

  &.error {
    color: $palette-error-dark;
  }

  &.initial {
    color: $palette-text-secondary-dark;
  }
}

.amount {  
  display: flex;
  align-items: center;
  column-gap: 8px;
  color: $palette-secondary;

  svg {
    width: 20px;
    height: 20px;
  }

  &.disabled svg {
    color: $palette-text-secondary-dark;
  }
}

@include media-phone() {
  .root {
    justify-content: space-between;
    flex-grow: 1;
  }
}
