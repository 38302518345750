@import '../../../../../assets/style/mixins';

.root {
  position: relative;
  z-index: $zIndex-secondary-menu-notification;
  display: flex;
  justify-content: space-between;
  align-items: center;

  :global {
    .ant-skeleton-content .ant-skeleton-paragraph {
      margin: 0 !important;
      display: flex;
      align-items: center;
      column-gap: 16px;      

      > li {
        width: 16px !important;
        margin-top: 0 !important;
        

        &:first-child {
          width: 30% !important;
          flex-shrink: 0;          
        }

        &:nth-child(2) {
          margin-left: auto;      
        }
      }
    }
  }
}

.title {
  flex-grow: 1;
  min-width: 0;
  padding-right: 64px;
}

.actions {
  flex-shrink: 0;
  margin-left: auto;
  align-self: center;
}

@include media-phone() {
  [class][class].root {
    position: static;
    padding: 7px 12px;
    margin: 0;
    align-items: center;
  }

  .title {
    padding-right: 0;
  }
}
