@import '../../../../assets/style/mixins';

.root {
  width: 388px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  font-size: $main-font-size;

  background-color: $white;
  z-index: 1;

  @include media-phone() {
    width: 95vw;
    min-width: 304px;
    max-width: 388px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 0.6em 0 1.5em;
  border-radius: 4px 4px 0px 0px;

  background-color: $palette-primary;

  &Collapse {
    border-radius: 4px;
  }
}

.actions {
  flex-shrink: 0;
  display: flex;

  &Icon {
    display: flex;
    align-items: center;
    margin: 0 0.5em;
  }
}

.icon {
  color: $white;
}

.list {
  max-height: 75vh;
  min-height: 54px;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0;
  padding: 0.8em 0;
  list-style-type: none;
}
