@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px; // Need fixed value;
  padding-right: 4px;
  font-size: $main-font-size;
}

.user {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  padding-right: 2em;
}

.userName {
  min-width: 0;
  margin-left: 0.571em;
  font-weight: normal;
}

[class].skeleton {
  & :global {
    .ant-skeleton-paragraph {
      display: flex;
      align-items: center;

      &:nth-last-child(n + 2) {
        margin-bottom: 16px;
      }

      li {
        &:first-child {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin: 0 8px 0 0;
        }

        &:nth-child(2) {
          width: 106px;
          margin: 0;
        }

        &:nth-child(3) {
          width: 44px !important;
          margin: 0 0 0 auto;
        }
      }
    }
  }
}
