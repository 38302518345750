@import '../../../../assets/style/mixins';

[class].nav {
  margin: 16px 28px 0;
  padding: 0;
  flex-shrink: 0;
}

@include media-tablet() {
  [class].nav {
    margin: 16px 20px 0;
  }
}

@include media-phone() {
  [class].nav {
    margin: 16px 0 0;
    padding: 0 20px;
  }
}
