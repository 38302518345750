@import '../../../../../assets/style/mixins';

.root {
  background-color: $white;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
  min-width: 0;
}

.header {
  padding: 22px 20px 12px;
}

.footer {
  flex-shrink: 0;
  border-top: 1px solid $palette-stroke-primary;
}

@include media-tablet() {
  .header {
    padding-top: 20px;
  }
}

@include media-phone() {
  .header {
    padding: 16px 16px 12px;
  }
}
