@import '../../../../../../assets/style/mixins';

.button {
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  font-weight: 700;
  padding: 1.17em 0;
  line-height: 1.333;
  gap: 0.333em;
  font-size: 12px;
  position: relative;
}

.active {
  color: $white;
  background-color: $palette-primary-dark;

  &::before {
    content: ' ';
    position: absolute;
    width: 5px;
    left: 0;
    top: 15%;
    height: 70%;
    background: $white;
    border-radius: 0 $main-border-radius $main-border-radius 0;
  }

  @include media-phone() {
    &::before {
      top: unset;
      right: 0;
      width: 100%;
      bottom: 0;
      height: 3px;
      margin: 0 auto;
      border-radius: 4px 4px 0 0;
    }
  }
}

.iconWrapper {
  position: relative;
  display: inline-block;

  svg {
    width: 20px;
    height: 20px;
  }
}

.popover {
  :global(.ant-popover-inner-content) {
    padding: 0;
  }
}
