@import '../../../../assets/style/mixins';

.root {
  padding-top: 16px;

  @include media-tablet() {
    padding: 0;
    margin-bottom: 1.6em;
  }
}
