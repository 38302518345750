@import '../../../../../assets/style/mixins';

.root {
  padding: 20px 0;

  &:first-child {
    padding-top: 0;
  }

  &:nth-last-of-type(n + 2) {
    border-bottom: 1px solid $palette-stroke-primary;
  }
}

.title {
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  color: $palette-primary;
  text-transform: uppercase;
  margin-bottom: 16px;
}
