@import '../../../../../assets/style/mixins';

[class].root {
  margin: auto;
  position: relative;
  max-width: 92px;
  height: 136px;

  &:not(&.disabled):hover {
    background-color: $palette-skeleton;
  }

  &.clickable {
    cursor: pointer;
  }

  &.disabled {
    cursor: not-allowed;
  }
}

.item {
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  flex: 1 0;
  width: 100%;
  height: 100%;
  justify-content: space-between;
}

[class].checkbox {
  position: absolute;
  z-index: 10;
  top: 6px;
  left: 6px;
  width: initial;
}

.imageWrapper {
  position: relative;
  width: 92px;
  height: 92px;
  margin-bottom: 8px;
  border-radius: 4px;
}

.uploadError {
  position: absolute;
  z-index: 10;
  top: 4px;
  left: 4px;

  & svg {
    width: 20px;
    height: 20px;
  }
}

.cancel {
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  column-gap: 4px;
}

.button {
  padding: 0;
}

.image {
  height: 100%;
}

.fileIcon {
  width: 30px;
  height: 30px;
}

[class].videoStatus {
  right: 4px;
  bottom: 4px;
}

[class].skeleton {
  width: 92px;

  :global(.ant-skeleton-title) {
    height: 36px;
    margin: 0;
  }
}

[class].skeletonImg {
  width: 92px;
  height: 92px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;

  :global(.ant-skeleton-image) {
    width: 100%;
    height: 100%;
  }
}

[class].fileName {
  font-size: 13px;
  white-space: normal;
  word-break: break-all;
  max-height: 2.769em;
}

@include media-phone() {
  [class].root {
    max-width: 128px;
    height: 180px;
    padding: 12px 8px 12px 8px;

    &.selected {
      background-color: $palette-info-light;
    }
  }

  .item,
  .skeleton {
    width: 112px;
  }

  .imageWrapper,
  .skeletonImg {
    width: 112px;
    height: 112px;
  }
}
