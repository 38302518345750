@import '../../../../../assets/style/mixins';

.root {
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.title {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subTitle {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 16px;

  @include media-phone() {
    text-align: center;
  }
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;

  @include media-phone() {
    padding-bottom: 5em;
  }
}

.closeIcon {
  cursor: pointer;
}
