@import '../../../../../assets/style/colors';
@import '../../../../../assets/style/variables';

.root {
  padding: 13px 16px 12px;
  color: $white;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: $main-border-radius;

  &:hover,
  &:active {
    color: $white;
  }
}
