.root {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-direction: column;

  p {
    margin-bottom: 12px;
  }
}
