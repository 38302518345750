@import '../../../../assets/style/mixins';

.root {
  display: flex;
  flex-grow: 1;
  overflow-y: auto;
  flex-direction: column;
  justify-content: space-between;
}

.voiceRoom {
  flex-grow: 1;
}
