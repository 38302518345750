@import '../../../../../assets/style/mixins';

[class].root {
  :global {
    .ant-picker {
      .ant-picker-input input {
        font-size: 13px !important;
        line-height: 1.384em;
      }

      &.ant-picker-range {
        max-width: 243px;
        height: 35px;
        padding: 8px 8px 8px 18px;
        border-radius: 4px;

        &.ant-picker-focused {
          border: 2px solid $palette-secondary !important;
          box-shadow: none;
        }
      }

      .ant-picker-suffix {
        display: flex;
        align-items: center;
      }
    }

    .ant-picker-panel-container,
    .ant-picker-panels,
    .ant-picker-panel,
    .ant-picker-date-panel,
    .ant-picker-month-panel,
    .ant-picker-year-panel,
    .ant-picker-decade-panel,
    .ant-picker-header,
    .ant-picker-body,
    .ant-picker-content {
      width: 100% !important;
    }

    .ant-picker-content th,
    .ant-picker-content td {
      width: 28px;
      height: 26px;
      font-weight: 600;
    }

    .ant-picker-cell-inner {
      width: 28px;
      height: 26px;
      line-height: 26px;
      border-radius: 4px !important;
    }

    .ant-picker-decade-panel,
    .ant-picker-month-panel,
    .ant-picker-year-panel {
      table.ant-picker-content {
        height: auto;
      }

      .ant-picker-cell {
        padding: 10px 0;

        &::before {
          height: auto;
        }
      }

      .ant-picker-cell-inner {
        width: 45px;
        height: 42px;
        line-height: 42px;
      }
    }

    .ant-picker-decade-panel .ant-picker-cell-inner {
      width: 84px;
    }

    .ant-picker-cell-range-start::before,
    .ant-picker-cell-in-range::before,
    .ant-picker-cell-range-end::before {
      background-color: #3f51b5 !important;
      opacity: 0.1;
    }

    @include hideRangePickerSecondCalendar();

    .ant-picker-range-arrow {
      display: none;
    }

    .ant-picker-dropdown-range {
      right: 20px;
      padding: 0;

      // Overwrite dynamic value
      top: 72px !important;
      left: 20px !important;
    }

    .ant-picker-header {
      margin-bottom: 12px;
    }

    .ant-picker-header-view,
    .ant-picker-header button {
      line-height: 22px;
    }

    .ant-picker-header-view {
      font-size: 16px;
      font-weight: 600;
      color: $palette-text-primary;
    }

    .ant-picker-cell {
      padding: 4px 0;
    }

    .ant-picker-active-bar {
      display: none;
    }

    .ant-picker-range-separator {
      padding: 0 14px;
    }

    .ant-picker-cell::before {
      height: 30px;
    }

    .ant-picker-header > button {
      color: $palette-text-primary;

      &:hover {
        color: $palette-secondary;
      }
    }
  }
}

.suffix {
  width: 16px;
  height: 16px;
  color: $palette-dark-gray;
}

.icon {
  color: $palette-text-secondary-dark;

  &Active {
    color: $palette-text-primary;
  }
}

@include media-phone() {
  [class].root {
    position: relative;

    :global {
      .ant-picker {
        width: 100%;
        padding: 6px 11px;

        &.ant-picker-range {
          max-width: unset;
          padding-left: 48px;

          & .ant-picker-input:nth-of-type(1) {
            margin-right: 41px;
          }
        }
      }
      
      .ant-picker-body {
        padding: 0 12px;
      }

      .ant-picker-cell-inner {
        width: 38px;
        height: 38px;
        line-height: 38px;
      }

      .ant-picker-header-view {
        font-size: 16px;
        font-weight: 600;
      }

      .ant-picker-dropdown-range {
        right: 0;
        top: 58px !important;
      }

      .ant-picker-header {
        padding: 0;
      }

      .ant-picker-header-view,
      .ant-picker-header button {
        line-height: 24px;
      }

      .ant-picker-cell {
        padding: 2px 0;
      }

      .ant-picker-range-separator {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        padding: 0;
      }
    }
  }
}
