@import 'src/assets/style/mixins';

.root {
  @include dialog();
  @include modalTitleWithoutDescriptionGap();
  margin-top: 0;
}

.button {
  margin-top: 28px;
}

.error .button {
  margin-bottom: 15px;
}

.search {
  width: 100%;
  margin-bottom: 24px;
}

.skeleton {
  :global(.ant-skeleton-content) {
    padding-top: 8px;
  }

  :global(.ant-skeleton-content .ant-skeleton-paragraph > li) {
    height: 23.7px;
  }
}

.empty {
  width: 100%;
  min-height: auto;
  border: 2px dashed $palette-stroke-primary-dark;
  padding: 60px 40px;

  svg {
    width: 32px;
    height: 32px;
  }
}

.emptySearch {
  padding-top: 16px;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
}

@include media-phone() {
  .button {
    margin-top: 20px;
  }
}
