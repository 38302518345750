@import '../../../../assets/style/colors';
@import '../../../../assets/style/mixins';

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 20px;
  column-gap: 8px;
  width: 100%;
}

.text {
  text-wrap: nowrap;
  position: relative;
  color: $palette-text-primary;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
}
