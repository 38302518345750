@import '../../../../../assets/style/mixins';

.root {
  position: relative;

  &.dragAndDrop::before {
    content: '';    
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $palette-secondary;
    color: $white;
    z-index: 1;
    background-color: rgba($palette-secondary-rgb, 0.08);
    border: 2px solid $palette-secondary;
  }
}

.list,
.skeleton :global(.ant-skeleton-content .ant-skeleton-paragraph) {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  font-weight: bold;
  margin-bottom: 0;

  & > li {
    border-radius: 4px;
    aspect-ratio: 130/120;
    width: unset !important;
    height: unset;
    margin: 0 !important;
  }
}

.placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 12px;
  padding: 54px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='rgb(203, 207, 216)' stroke-width='2' stroke-dasharray='20%2c 24' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.image {
  width: 60px;
  height: 60px;
  border-radius: 2px;
}

[class].button {
  height: unset;
  padding: 0;
}

@include media-phone() {
  .root {
    gap: 4px;
  }
}
