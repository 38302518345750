@import '../../../../assets/style/colors';

.root {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 0;
  font-size: 14px;
  line-height: 1.357;
  color: $palette-text-primary;

  &Wrap {
    line-break: anywhere;
  }

  &Disabled {
    color: $palette-disable-dark;
  }

  &.clickable {
    cursor: pointer;
  }
}

.md {
  font-weight: bold;
}

.avatar {
  margin-right: 8px;

  &Disabled {
    opacity: 0.65;
  }

  &Wrapper {
    width: 100%;
  }
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.icon {
  flex-shrink: 0;

  color: $palette-text-secondary-dark;
  display: flex;
  column-gap: 8px;

  &Disabled {
    color: $palette-disable-dark;
  }

  &Inline {
    display: inline-flex;
  }

  svg {
    width: 20px;
    height: 20px;
  }
}

.content,
.name,
.chipList {
  display: flex;
  align-items: center;
  gap: 8px;
}

.name {
  min-width: 0;
  flex-shrink: 1;
}

.twoLine .icon {
  align-self: flex-end;
  margin-bottom: 2px;
}

.chipList {
  flex-wrap: wrap;
  flex-shrink: 0;
}

.caption {
  font-size: 12px;
  line-height: 1.333;
  color: $palette-text-secondary-dark;
}

.link {
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  cursor: pointer;
  color: $palette-secondary;
  text-decoration: underline;
}

.accountType {
  display: flex;
  align-items: center;
  justify-content: center;
}

.postfix {
  font-weight: 400;
}
