@import '../../../../../assets/style/mixins';

.avatarsWrapper {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-height: 106px;
  padding-top: 10px;
  margin: 0 auto;
  overflow: hidden scroll;
}

.emptyContent {
  margin-bottom: 8px;
  text-align: center;
  font-size: 1.4em;
  line-height: 1.571;
}

.unselect {
  position: absolute;
  top: -7px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0;
  border-radius: 50%;
  border: none;
  background: $white;
  cursor: pointer;

  svg {
    height: auto;
    width: 10px;
    color: $palette-text-primary;
  }
}

.clearAll {
  display: flex;
  align-items: center;
  width: fit-content;
  margin: 0 0 14px auto;
  padding: 0;

  font-size: 1.4em;
  line-height: 1.357;
  font-weight: bold;
  color: $palette-secondary;

  border: none;
  background-color: transparent;
  cursor: pointer;

  svg {
    width: 15px;
    height: 15px;
    margin-left: 0.4em;
  }
}

[class].list {
  max-height: 96px;
  margin: 0 -12px 0 0 !important;
  padding-right: 4px;
  overflow: hidden scroll;
}
