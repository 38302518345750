@import '../../../../../assets/style/mixins';

.root {
  width: 100%;
  margin: 16px 0;
  padding: 0 28px;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  overflow-wrap: break-word;
  color: rgba($palette-text-primary-rgb, 0.8);
}
