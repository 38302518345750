@import '../../../../assets/style/mixins';

.root {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.text {
  color: $palette-secondary;
}
