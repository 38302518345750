@import '../../../.././../assets/style/colors';

.root {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: $palette-text-primary;

  &Icon {
    margin-right: 8px;
    color: $palette-text-secondary-dark;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}
