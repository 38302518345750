@import '../../../../../../assets/style/mixins';

.root {
  max-height: 420px;

  &Scroll {
    overflow: auto scroll;
    margin-right: -6px;
  }
}

.item {
  position: relative;
  display: block;
  height: 204px;
  width: 342px;
  border: 3px solid transparent;
  border-radius: 4px;
  margin-bottom: 12px;
  cursor: pointer;

  input {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    width: 1px;
    height: 1px;
    left: -9999px;
  }

  &Active {
    border-color: $palette-secondary-light;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.label {
  position: absolute;
  bottom: 8px;
  left: 50%;
  transform: translateX(-50%);
  width: 166px;
  color: $white;
  padding: 8px 12px;
  background: $palette-text-primary;
  opacity: 0.7;
  border-radius: 4px;
}
