@import '../../../../assets/style/mixins';

[class].root {
  flex-grow: 1;

  &.empty {
    min-height: 0;

    :global(.ant-tabs-nav) {
      margin-bottom: 0;
    }
  }
}

.title {
  margin: 0;
  font-size: 1.2em;
  line-height: 1.333;
  font-weight: bold;
  color: $white;
}

[class].tabs {
  > :global(.ant-tabs) {
    overflow: visible;

    > :global(.ant-tabs-nav) {
      margin: 0;
      padding: 16px 0;
      position: sticky;
      top: 0;
      z-index: $zIndex-chat-header;

      @include insetBackground($white, $chat-details-margin-desktop);
    }

    > :global(.ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane) {
      display: flex;
      flex-direction: column;
    }
  }
}

@include media-tablet() {
  [class].tabs:not(.tabsTop) {
    > :global(.ant-tabs > .ant-tabs-nav) {
      top: calc(#{$header-fixed-height-tablet} + #{$chat-header-desktop} + 16px);
      z-index: $zIndex-header;

      @include insetBackground($white, $chat-details-margin-tablet);
    }
  }
}

@include media-phone() {
  [class].tabs:not(.tabsTop) {
    > :global(.ant-tabs > .ant-tabs-nav) {
      top: calc(#{$header-fixed-height-phone} + #{$chat-header-phone} + 16px);

      @include insetBackground($white, $chat-details-margin-mobile);
    }
  }

  .title {
    margin-left: 1.142em;
    font-size: 1.4em;
    line-height: 1.357;
    color: $palette-text-primary;
  }
}
