@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  font-size: $main-font-size;
}

.wrapperCloud,
.wrapperCase {
  height: 0;
  padding-right: 1.6em;
  flex: 1 1 auto;
  overflow: auto scroll;
}

.wrapperCloud {
  @include drawerRightScrollPadding();
}

@include media-phone() {
  .wrapperCloud {
    height: auto;
    flex: unset;
    overflow: unset;
  }
}
