@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  flex-shrink: 0;
}

.avatar {
  &Remove {
    font-size: 10px;
  }

  &RemoveIcon {
    width: 12px;
    height: 12px;
  }
}

@include media-tablet() {
  .root {
    flex-direction: column;
  }

  .avatar {
    flex-direction: row;

    &Remove {
      margin-top: 0;
      margin-left: 24px;
      font-size: 12px;
      line-height: 1.333;
    }

    &RemoveIcon {
      width: 20px;
      height: 20px;
      margin-right: 2px;
    }
  }
}
