@import '../../../../assets/style/mixins';

.root {
  display: flex;
  flex-direction: column;

  font-size: $main-font-size;
}

.item {
  width: 100%;
  padding: 28px 0 44px;

  @include media-phone() {
    padding-top: 48px;
  }

  &:first-child {
    padding-top: 31px;

    @include media-tablet() {
      padding-top: 24px;
    }

    @include media-phone() {
      padding-top: 28px;
    }
  }

  &:nth-last-child(n + 2) {
    border-bottom: 1px solid $palette-stroke-primary;
  }

  &Skeleton {
    padding: 24px 0;

    &:first-child {
      padding-top: 28px;

      @include media-tablet() {
        padding-top: 24px;
      }
    }
  }
}

.button {
  display: flex;
  justify-content: center;
  margin-bottom: 14px;
  padding-top: 10px;
  border-top: 1px solid $palette-stroke-primary;

  @include media-phone() {
    padding-top: 8px;
    margin-bottom: 8px;
  }
}

[class].skeleton {
  flex: 1;
  max-width: 457px;

  @include media-tablet() {
    max-width: 340px;
  }

  @include media-phone() {
    max-width: 256px;
  }

  & :global {
    .ant-skeleton-title {
      height: 22px;
      width: 368px !important;
      margin: 0;

      @include media-tablet() {
        height: 24px;
        width: 148px !important;
      }
    }

    .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
      margin: 8px 0 0 32px;

      @include media-tablet() {
        margin: 12px 0 0;
      }

      @include media-phone() {
        margin-top: 16px;
      }

      li {
        width: 100% !important;

        &:first-child {
          height: 22px;

          @include media-tablet() {
            height: 20px;
            width: 296px !important;
          }

          @include media-phone() {
            width: 100px !important;
          }
        }

        &:last-child {
          margin-top: 8px;
          height: 40px;

          @include media-tablet() {
            height: 44px;
            margin-top: 10px;
          }
        }
      }
    }
  }

  &Button {
    margin: 0;
    padding: 24px 0 28px;
    border-top: 1px solid $palette-stroke-primary;

    @include media-tablet() {
      padding-bottom: 24px;
    }

    @include media-phone() {
      padding: 20px 0;
    }
  }
}
