@import '../../../../assets/style/mixins';

.root {
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  line-height: 1.333;
  line-break: anywhere;
}

.chatStatus {
  color: $palette-warning;
}
