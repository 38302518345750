@import 'src/assets/style/mixins';

.list {
  &Wrapper {
    max-height: 460px;

    &Scroll {
      overflow: auto scroll;
      margin-right: -14px;
      padding-right: 6px;
    }
  }

  &Item {
    &:nth-last-child(n + 2) {
      margin-bottom: 8px;
    }
  }

  &Empty {
    height: auto;
  }

  &Skeleton {
    overflow: hidden;
    margin: 0;
    padding-right: 0;
  }
}

.empty {
  padding-top: 40px;
  margin-bottom: 16px;
  min-height: unset;
}

.search {
  margin-bottom: 8px;
}

.notFound {
  margin-top: 40px;
}

.actions {
  &Wrapper,
  &EmptyWrapper {
    display: flex;
    align-items: center;
  }

  &Wrapper {
    justify-content: space-between;
    margin: 40px 0 24px;
  }

  &EmptyWrapper {
    flex-direction: column;

    .button {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &Center {
    justify-content: center;
  }
}

[class].button {
  height: unset;
  padding: 0;

  & svg {
    width: 20px;
    height: 20px;
  }
}

[class].filterPopover :global(.ant-popover-inner-content) {
  min-width: 224px;
  max-height: 240px;
  overflow: hidden auto;
}

@include media-phone() {
  .noFound {
    margin-top: 48px;
  }

  .actionsWrapper {
    flex-direction: column;
    margin-top: 28px;

    .button:first-child {
      margin-bottom: 16px;
    }

    .button:last-child {
      margin-bottom: 0;
    }
  }
}
