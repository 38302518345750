@import '../../../../../assets/style/mixins';

.title {
  width: 90%;
  width: calc(100% - var(--connector-item-header-title, 200px) - 20px);
  // - 20px Instead of margin-right: 20px, because postfix have position: absolute.
  // 200px This default value
  // 90% if calc or var is not supported

  &Full {
    width: 100%;
  }
}

.title,
.titleFull {
  word-wrap: break-word;
}

.root {
  position: relative;
  font-weight: bold;
  font-size: 16px;
  margin: 0;
  line-height: 24px;
  color: $palette-text-primary;
}

.date {
  font-size: 12px;
  line-height: 16px;
  font-weight: normal;
  color: $palette-text-secondary-dark;
}

.postfix {
  display: none;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  top: -8px;
  right: 0;
}

.postfix.showRequestStatus {
  top: 0;
}

.contextIcon {
  right: -8px;
}

.status {
  display: flex;
  position: relative;
  font-size: 14px;
  line-height: 19px;
  top: 8px;
  column-gap: 4px;

  &.approved,
  &.posted {
    color: $palette-success;
  }

  &.invisible {
    color: $palette-warning;
  }

  &.rejected,
  &.deactivated {
    color: $palette-error;
  }

  .help svg {
    width: 16px;
    height: 16px;
  }
}

.show {
  display: flex;
}

[class].remove {
  height: unset;
  padding: 0;
  margin-bottom: 10px;
}

@include media-tablet() {
  .title {
    max-width: 440px;
  }

  [class].remove {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
  }
}

@include media-phone() {
  .root {
    display: flex;
    flex-direction: column-reverse;
  }

  .title {
    max-width: 100%;
    width: 100%;
    word-break: break-all;
  }

  .postfix {
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    position: static;
    margin-bottom: 8px;
    order: 2;
  }

  .postfix.showRequestStatus {
    margin-bottom: 12px;
  }

  .status {
    margin-top: 0;
    position: static;
    font-size: 12px;
    line-height: 16px;

    .help {
      order: 2;
    }
  }

  [class].remove {
    font-size: 12px;
    line-height: 16px;

    &Icon {
      width: 16px;
      height: 16px;
    }
  }

  .contextIcon {
    top: -4px;
  }

  .date {
    margin-bottom: 8px;
    order: 1;
  }
}
