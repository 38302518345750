@import '../../../../../assets/style/mixins';

[class].skeleton {
  flex: 1;

  &:nth-last-child(n + 2) {
    margin-right: 20px;

    @include media-phone() {
      margin: 0;
    }
  }

  &:first-child {
    @include media-phone() {
      margin-bottom: 24px;
    }
  }

  & :global {
    .ant-skeleton-content {
      display: flex;
      flex-direction: column;
    }

    .ant-skeleton-title {
      width: auto !important;
      max-width: 321px;
      height: 22px;
      margin: 0 40px 0 0;

      @include media-tablet() {
        height: 20px;
        max-width: 100px;
      }
    }

    .ant-skeleton-title + .ant-skeleton-paragraph {
      margin: 8px 0 0 0;

      li {
        height: 44px;
        width: 100% !important;

        @include media-phone() {
          height: 50px;
        }
      }
    }
  }
}

[class].textArea {
  & :global {
    .ant-skeleton-title + .ant-skeleton-paragraph li {
      height: 112px;
    }
  }
}
