@import '../../../../../assets/style/mixins';

.workInformation {
  font-size: 14px;
  line-height: 23px;
}

@include media-phone() {
  .workInformation {
    font-size: 13px;
  }
}
