@import '../../../../assets/style/mixins';

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  font-size: $main-font-size;
  padding: 2.8em 0;
  text-align: center;

  @include dividerBottom();
}

.title {
  margin: 0;
  font-size: 1.6em;
  line-height: 1.312;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  flex-shrink: 0;
  color: $palette-secondary-dark;
  background-color: rgba($palette-secondary-rgb, 0.1);

  svg {
    width: 32px;
    height: 32px;
  }
}

@include media-tablet() {
  .wrapper {
    flex-direction: row;
    max-width: unset;
    padding: 0;
    border: 0;
  }

  .title {
    margin-right: auto;
    font-weight: 600;
    font-size: 1.5em;
  }

  .icon {
    width: 44px;
    height: 44px;
    margin: 0;

    svg {
      width: 24px;
      height: 24px;
    }
  }
}
