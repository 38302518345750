@import '../../../assets/style/mixins';

.root {
  display: flex;
  min-width: 0;
  height: 100%;
  flex-direction: column;
  padding: 16px;
  overflow: auto;
  font-size: $main-font-size;
  background-color: $white;
  border: 1px solid $palette-stroke-primary;
  border-radius: 4px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}

.image {
  margin-bottom: 27px;
}

.title {
  font-size: 26px;
  line-height: 35px;
  font-weight: 700;
  margin-bottom: 20px;
}

.description {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 28px;
}
