@import '../../../../../assets/style/mixins';

.root {
  position: relative;
  padding: 0 8px 2px;

  &:first-of-type {
    margin-top: auto;
  }
}

.message {
  display: flex;
  align-items: flex-start;
  margin: 4px 0 15px;

  &:last-of-type {
    margin-bottom: 0;
  }

  &Focus {
    animation: massageFocusAnimation 3s 1 ease-out;
  }

  &Outgoing {
    flex-direction: row-reverse;
  }

  &.groupStart {
    margin-top: 8px;
  }
}

@keyframes massageFocusAnimation {
  50% {
    background-color: rgba($palette-secondary-dark-rgb, 0.2);
  }
}

.sendError {
  margin: -10px 30px 0 0;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: right;

  span {
    color: $palette-error;
  }

  button {
    border: none;
    background: none;
    cursor: pointer;
    color: $palette-primary;
    padding: 0;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.date {
  margin: 2em auto 1.428em;
  font-size: 14px;
  line-height: 1.357;
  text-align: center;
  color: $palette-text-secondary-dark;
}

@include media-phone() {
  .date {
    margin: 1.714em auto 1.142em;
  }

  .message {
    margin-top: 2px;

    &.groupStart {
      margin-top: 4px;
    }
  }
}
