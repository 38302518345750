@import 'src/assets/style/colors';

.date {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  margin-bottom: 12px;
}

.itemsWrapper {
  padding: 12px 20px;
  margin: 12px 0 20px;
  background: rgba($palette-stroke-primary-light-rgb, 0.5);
  border-radius: 4px;
}
