@import '../../../../../assets/style/mixins';

.fileList {
  padding: 0 28px 28px;
  @include fileList();

  @include media-tablet() {
    padding: 0;
  }
}

.root {
  font-size: $main-font-size;
  @include paper();

  @include media-tablet() {
    background-color: transparent;
    border: none;

    :global(.ant-skeleton) {
      padding: 2.4em 2em;
      background-color: $white;
      border: 1px solid $palette-stroke-primary;
      border-radius: 0 0 4px 4px;
      border-top: none;
    }
  }

  @include media-phone() {
    :global(.ant-skeleton) {
      padding: 2.4em 1.6em 2em;
    }
  }
}
