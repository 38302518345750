@import 'src/assets/style/mixins';

[class].checkbox {
  align-items: center;
}

div[class].grid {
  min-height: 190px;

  .containerThumbnail {
    height: 122px;
    padding: 12px 8px 0;
    margin-bottom: 8px;
  }

  .checkbox {
    top: 15px;
    left: 11px;
  }

  .footer {
    padding: 0 4px 12px 8px;
  }

  .contextMenu {
    margin-left: 8px;
  }

  .addonDate,
  .addonName {
    font-size: 10px;
    line-height: 14px;
  }

  .videoStatus {
    right: 8px;
    bottom: 8px;
  }
}
