@import '../../../../../../assets/style/mixins';

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 36px;
  border-top: 1px solid $palette-text-secondary-light;
}

.icon {
  color: $palette-text-secondary-dark;
}

.sendIcon {
  margin-left: auto;
  color: $palette-secondary;
}

.sendIcon,
.icon {
  cursor: pointer;
}
