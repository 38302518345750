@import '../../../../assets/style/mixins';

.root {
  padding: 20px 20px 60px;
  overflow-y: auto;
  border-radius: $main-border-radius;
  border: 1px solid $palette-stroke-primary;
  height: 100%;
}

.content {
  margin: 0 auto;
  max-width: 748px;
}

.title {
  font-size: 28px;
  line-height: 38px;
  font-weight: 700;
  margin-bottom: 30px;
}
