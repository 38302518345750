@import '../../../../../assets/style/mixins';

$textLineHeight: 23;

$visibilityHeight: $textLineHeight * 2;
$visibilityHeightTablet: $textLineHeight * 3;

.rootSkeleton {
  overflow: hidden;
}

[class].about {
  margin: 0;
  font-weight: 400;
  font-size: 15px;
  line-height: #{$textLineHeight}px;
  color: $palette-text-primary;
  overflow-wrap: break-word;

  &Title {
    margin-bottom: 16px;
  }
}

:global(.show-more-wrapper) {
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.profession {
  &Title {
    margin-bottom: 8px;
  }

  &List {
    display: flex;
    flex-wrap: wrap;
  }

  &:last-child {
    margin-right: 0;
  }

  &Item {
    margin: 10px 10px 0 0;
  }
}

:export {
  visibilityHeight: $visibilityHeight;
  visibilityHeightTablet: $visibilityHeightTablet;
}