@import '../../../../assets/style/mixins';

[class].root {
  max-width: 260px;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  overflow-wrap: break-word;

  :global(.ant-popover-inner-content) {
    padding: 12px;
  }
}

.icon {
  color: $palette-text-secondary-dark;
}

.default.icon {
  width: 14px;
  height: 14px;
}

.md.icon {
  width: 20px;
  height: 20px;
}
