@import '../../../../../../../assets/style/mixins';

.root {
  padding: 8px 0;
  list-style: none;
  display: flex;
  align-items: center;

  @include rowHover($palette-info-light, $chat-details-margin-desktop);
}

.root.online:last-child::after {
  display: block;
}

.contextIcon {
  margin-right: 18px;
}

.userName {
  font-size: 14px;
  line-height: 19px;
}

@include media-tablet() {
  .root {
    @include rowHover($palette-info-light, $chat-details-margin-tablet);
  }
}

@include media-phone() {
  .root {
    padding: 10px 0;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 8px;

    @include rowHover($palette-info-light, $chat-details-margin-mobile);
  }

  .contextIcon {
    margin-right: 0;
  }
}
