@import '../../../../../assets/style/mixins';

.icon {
  color: $palette-text-secondary-dark;

  &Sending {
    color: $palette-secondary;

    &:disabled {
      color: $palette-text-secondary-dark;
    }
  }
}

[class].requestButton {
  @include primaryButton(160px);

  @include media-tablet {
    height: 33px;
    font-size: inherit;
  }
}
