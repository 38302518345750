@import '../../../../../../assets/style/mixins';

.root {
  padding: 12px 20px 12px 24px;
}

.title {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: $palette-text-secondary-dark;
  margin-bottom: 12px;
}

@include media-phone() {
  .root {
    padding: 12px 14px 12px 12px;
  }
}
