@import '../../../../../assets/style/colors';

.cameraIcon {
  position: absolute;
  right: 0;
  bottom: -5px;
  width: 27px;
  height: 27px;
  color: $white;
  background-color: $palette-secondary;
  border: 3px solid $white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 14px;
    height: 14px;
  }
}

[class].placeholderImg {
  width: 34px;
  height: 34px;

  &Wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $palette-info-light;
    color: $white;
  }
}
