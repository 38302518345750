@import '../../../assets/style/mixins';

.filters {
  padding: 0 20px;

  &ItemWrapper {
    padding: 16px 0;
  }
}

@include media-tablet() {
  .filtersItemWrapper {
    padding: 20px 0;

    &:first-child {
      padding-top: 14px;
    }
  }
}
