@import '../../../../../assets/style/mixins';

.root {
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 347px;
  text-align: left;
  gap: 12px;
  max-width: 240px;
}

.title {
  font-weight: 700;
  text-transform: uppercase;
  color: $palette-text-secondary-dark;
}

.list {
  display: flex;
  flex-direction: column;

  &.chip {
    gap: 8px;
  }

  &.accountName {
    gap: 16px;
  }
}

.item {
  display: flex;
  align-items: center;
  min-width: 0;
  width: 100%;
  column-gap: 16px;
}

.accountItem {
  min-width: 0;
}

.remove {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  cursor: pointer;
  margin-left: auto;
}
