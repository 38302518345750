@import '../../../../../assets/style/colors';

.root {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  color: $palette-primary-dark;
}

.location {
  overflow-wrap: break-word;
}
