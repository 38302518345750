@import '../../../../assets/style/mixins';

.root {
  height: auto;

  @include media-tablet() {
    height: auto;
    @include sub-navigation-tablet-view();
    z-index: $zIndex-chat-header-content + 1 !important;

    padding-bottom: 0;
  }

  @include media-phone() {
    margin-bottom: 1.6em;
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2em;
  padding-right: 0.8em;

  @include media-tablet() {
    padding: 0;
    margin-bottom: 0;
  }

  @include media-phone() {
    flex-direction: column;
    align-items: flex-start;
  }

  @include media-phone-landscape() {
    flex-direction: row;
  }
}

.headerActionsWrapper {
  display: flex;
  align-items: center;

  @include media-phone() {
    width: 100%;
  }

  :global(.icon-label) {
    margin-right: 20px;
  }

  &Icon {
    color: $palette-text-primary;

    @include media-phone() {
      margin-left: auto;
    }
  }
}

[class].contextMenuRoot {
  position: fixed;
  top: 136px !important;

  @include media-phone() {
    position: absolute !important;
  }

  :global(.ant-popover-inner-content) {
    padding-top: 0;
  }
}

[class].statusTitle {
  padding: 0.533em 0;
  margin-bottom: 0.4em;
  font-size: 1.5em;
  line-height: 1.333;
  justify-content: center;
  border-radius: 4px 4px 0 0;
  text-transform: unset;
}

[class].skeleton :global(.ant-skeleton-paragraph) {
  display: flex;
  justify-content: space-between;
  margin: 0;

  @include media-phone() {
    flex-direction: column;
  }

  li {
    height: 32px;
    width: 30% !important;
    margin: 0;

    @include media-phone() {
      width: 60% !important;
      height: 23px;

      &:first-child {
        margin-bottom: 1.4em;
      }
    }
  }
}

.viewSelect {
  padding: 8px 0;

  &Holder {
    border-top: 1px solid $palette-stroke-primary;
    padding: 16px 0 0;
    margin: 0 20px;
  }
}

[class].breadcrumbs {
  margin-bottom: 0;
}
