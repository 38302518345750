@import '../../../../assets/style/mixins';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;

  .avatarWrapper {
    width: 94px;
    height: 94px;
    border: 3px solid $white;
  }
}

.hidden {
  display: none;
}

.wrapper {
  cursor: pointer;
}

.remove {
  display: flex;
  align-items: center;
  width: max-content;
  margin-top: 16px;
  padding: 0;
  column-gap: 4px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.4;
  color: $palette-primary;
  cursor: pointer;
  border: 0;
  background-color: transparent;
}

.icon {
  width: 20px;
  height: 20px;
  color: $palette-primary;
}

[class].skeleton {
  width: auto;

  & :global {
    .ant-skeleton-avatar {
      width: 100px;
      height: 100px;
    }

    .ant-skeleton-paragraph {
      display: none;
    }
  }
}

@include media-tablet() {
  .skeleton {
    display: flex;
    align-items: center;
    width: 100%;
  }
}
