@import '../../../../assets/style/mixins';

.root {
  display: flex;
  flex-grow: 1;
  overflow-y: auto;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 12px;
}

.item {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  row-gap: 12px;
}

.start,
.end {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.start {
  padding: 20px 0 0;
}

.addonBefore {
  padding: 20px 20px 0;
}

@include media-tablet() {
  .addonBefore {
    padding: 20px 24px 0;
  }
}

@include media-phone() {
  .addonBefore {
    padding: 16px 28px 0;
  }
}
