@import '../../../../assets/style/mixins';

.root {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  margin-bottom: 26px;
}

.department {
  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &Name {
    padding: 0;
    margin-bottom: 8px;
  }
}

.member {
  padding: 8px 0;
}

.root [class].member::after {
  display: none;
}

.skeleton {
  &[class] :global(.ant-skeleton-paragraph) {
    margin: 0;
  }
}

.alert {
  margin-bottom: 16px;
}

.list {
  row-gap: 24px;
}

@include media-tablet() {
  .root {
    height: calc(100vh - 234px);
    margin-bottom: 0;
    flex: unset;
    min-height: unset;
  }

  .list {
    padding-right: 8px;
  }
}

@include media-phone() {
  .root {
    height: calc(100vh - 280px);
  }

  .member {
    flex-direction: row;
    align-items: center;
  }
}
