@import '../../../assets/style/mixins';

.popoverContent {
  display: flex;
  padding: 0 14px;
  gap: 8px;
  overflow: hidden;
  min-width: 190px;
  align-items: flex-start;
  flex-direction: column;

  [class].button {
    padding: 0;
    height: auto;
    align-items: center;
    display: inline-flex;
  }
}

.linkBlock {
  display: flex;
  gap: 8px;
  width: 300px;
  overflow: hidden;
  flex-direction: column;
}

.settings {
  display: flex;
  gap: 4px;
  max-width: 100%;
  flex-wrap: wrap;
  overflow: hidden;
  align-items: center;
}

.expiration {
  margin-left: auto;
}

.skeleton {
  :global(.ant-skeleton-paragraph) {
    li {
      width: 100% !important;
      height: 50px !important;
    }
  }
}

@include media-phone() {
  .popoverContent {
    width: 288px;
  }

  .linkBlock {
    width: 100%;
  }
}
