@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  min-width: 0;
  width: 100%;
  column-gap: 16px;
}

@include media-phone() {
  .root {
    row-gap: 20px;
    flex-direction: column;
  }
}
