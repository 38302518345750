@import '../../../../assets/style/mixins';

.root {
  display: inline-flex;
  flex-shrink: 0;
  width: 34px;
  height: 34px;
  align-items: center;
  justify-content: center;
  color: $white;
  border-radius: $secondary-border-radius;

  svg {
    width: 18px;
    height: 18px;
  }

  &:hover {
    color: $white;
  }

  &.gutter {
    margin-right: 12px;
  }

  &.news {
    background-color: $palette-avatar-news;
  }

  &.events {
    background-color: $palette-files-spreadsheet;
  }

  &.chat {
    background-color: $palette-avatar-business;
  }

  &.voice {
    background-color: $palette-avatar-professional;
  }

  &.invitation {
    background-color: $palette-accent;
  }

  &.info,
  &.administration {
    background-color: $palette-secondary;
  }

  &.members {
    background-color: $palette-group-icon-background;
  }

  &.invisible,
  &.muted {
    background-color: $palette-text-secondary-dark;
    opacity: 0.5;
  }

  &.broadcast {
    background-color: $palette-broadcast;
  }

  &.networking {
    background-color: $palette-group-icon-background;
  }

  &.profile {
    background-color: $palette-profile-icon-background;
  }
}

@include media-tablet() {
  .root {
    width: 28px;
    height: 28px;
  }
}
