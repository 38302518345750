@import '../../../../assets/style/mixins';

.root {
  padding: 2.8em;
  margin-bottom: 2em;
  font-size: $main-font-size;
  color: $palette-text-primary;

  background: $white;
  border: 2px solid $palette-secondary-dark;
  border-radius: $main-border-radius;
}

.end,
.balanceWrapper,
.amountWrapper,
.content {
  display: flex;
  align-items: center;
  min-width: 0;
}

.wrapper {
  gap: 32px;
  display: flex;
  flex-direction: column;
}

.amountWrapper {
  flex-direction: column;
  align-items: flex-end;
  flex-shrink: 0;
}

.balanceWrapper {
  align-items: flex-start;
  flex-direction: column;
  padding-right: 2em;
}

.usernameWrapper {
  width: 100%;
}

.username {
  width: 100%;
  font-weight: bold;
  font-size: 1.6em;
  line-height: 1.375;
  margin-bottom: 6px;
}

.icon {
  width: 32px;
  height: 32px;
  margin: auto;
  color: $palette-secondary-dark;

  &Wrapper {
    display: flex;
    flex-shrink: 0;
    width: 60px;
    height: 60px;
    margin-right: 16px;
    background: rgba($palette-secondary-light-rgb, 0.1);
    border-radius: 50%;
  }
}

.description {
  font-size: 1.4em;
  line-height: 1.357;
  color: $palette-text-secondary-dark;
}

.amount {
  margin-top: 8px;
  font-weight: bold;
  font-size: 2.4em;
  line-height: 1.375;
  display: flex;
  width: 100%;
  color: $palette-secondary-dark;
}

.end {
  gap: 28px;
  margin-left: auto;
  flex-shrink: 0;
}

.topUpButton {
  align-self: flex-end;
  text-transform: uppercase;
}

@include media-phone() {
  .root {
    padding: 16px;
  }

  .amount {
    margin-top: 4px;
    font-size: 2em;
    line-height: 1.35;
  }

  .description {
    text-align: left;
    font-size: 1.2em;
    line-height: 1.333;

    &Balance {
      color: $palette-text-primary;
    }
  }

  .username {
    margin-bottom: 4px;
    font-size: 1.4em;
    line-height: 1.357;
  }

  .amountWrapper {
    align-items: flex-start;
  }

  .balanceWrapper {
    width: 100%;
    padding: 0;
    margin-bottom: 1.6em;
  }

  .content {
    flex-direction: column;
    align-items: flex-start;
  }

  .end {
    flex-direction: row-reverse;
    justify-content: space-between;
    gap: 0;
    margin: 0;
    width: 100%;
  }
}
