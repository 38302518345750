[class].root {
  width: 100%;
  gap: 12px;
  display: flex;
  align-items: center;
  min-height: 22px;
}

[class].checkbox {
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  width: 50%;
  min-width: 50%;
  align-items: center;
}

[class].switch {
  font-weight: bold;
  flex-grow: 1;
  width: 50%;
  min-width: 50%;
}
