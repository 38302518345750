@import '../../../../../assets/style/mixins';

.root {
  width: 100%;
  overflow: auto scroll;
  padding: 0 $chat-details-margin-desktop;
  display: flex;
  flex-direction: column;
  position: relative;
}

@include media-tablet() {
  .root {
    overflow: unset;
    padding: 0 $chat-details-margin-tablet;
    margin-top: $chat-header-desktop;
  }
}

@include media-phone() {
  .root {
    padding: 0 $chat-details-margin-mobile;
    margin-top: $chat-header-phone;
  }
}
