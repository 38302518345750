@import '../../../../../assets/style/colors';

.root {
  display: flex;
  align-items: flex-start;
}

.icon {
  flex-shrink: 0;
  margin-right: 8px;
  color: $palette-text-secondary-dark;

  & svg {
    width: 20px;
    height: 20px;
  }
}

.label {
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  min-width: 0;
  word-wrap: break-word;
  color: $palette-text-primary;
}

.primary {
  .icon,
  .label {
    color: $palette-primary;
  }
}
