.root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

[class].backButton {
  padding: 0 0 30px;
}

[class].menu {
  padding-bottom: 0;
}
