@import '../../../../../../assets/style/mixins';

.icon {
  flex-shrink: 0;

  &Success {
    color: $palette-success-dark;
  }

  &Pending {
    color: $palette-stroke-primary;
  }

  &Alert {
    color: $palette-error-dark;
  }
}

.alert {
  color: $palette-error-dark;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

