@import '../../../../../assets/style/mixins';

.caseLabel,
.icon {
  cursor: pointer;
  display: inline-block;
}

.caseLabel {
  background-color: $palette-primary;
  border-radius: 4px;
  font-weight: 600;
  font-size: 9px;
  line-height: 12px;
  text-decoration-line: underline;
  color: $white;
  padding: 2px 8px;
  margin-right: 6px;

  &.yourMessage {
    background-color: $palette-stroke-primary-light;
    color: $palette-text-primary;
  }
}

.icon {
  display: flex;
  margin-right: 8px;
  color: $palette-secondary-dark;

  svg {
    width: 20px;
    height: 20px;
  }

  &.yourMessage {
    color: $white;
  }
}

@include media-tablet() {
  .icon {
    color: $palette-secondary;
  }
}
