@import '../../../../assets/style/colors';

.link {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: $palette-secondary;

  &Icon {
    margin-right: 8px;
    width: 16.5px;
    height: 16.5px;
  }
}

.placeholder {
  margin: 0;
}
