@import '../../../../assets/style/mixins';

.root {
  display: flex;
  flex-direction: column;
  min-height: 0;
  height: 100%;
  width: 100%;
  font-size: $main-font-size;

  &Wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 0;
  }
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-width: 0;
}

.title {
  margin-bottom: 1em;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  color: $palette-text-primary;
}

.content {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  &.paper {
    @include paper();
  }
}

@include media-tablet() {
  .root {
    &Wrapper {
      flex-direction: column;
      row-gap: 20px;
      padding-bottom: 16px;
    }
  }

  [class].menu {
    position: static;
    padding: 0;
  }
}

@include media-phone() {
  .title {
    text-align: center;
    font-size: 16px;
    line-height: 22px;
  }
}
