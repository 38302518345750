@import '../../../../assets/style/mixins';

.count {
  z-index: $zIndex-secondary-menu-notification;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  padding: 0 4px;
  font-weight: bold;
  line-height: 1;
  color: $white;
  border-radius: 16px;

  &.sm {
    min-width: 16px;
    height: 16px;
    font-size: 9px;
  }

  &.md {
    min-width: 20px;
    height: 20px;
    font-size: 12px;
  }

  &.primary {
    background-color: $palette-primary;
  }

  &.secondary {
    background-color: $palette-error;
  }

  &.muted {
    background-color: $palette-text-secondary-dark;
  }

  &.gutter {
    margin-left: 8px;

    &.dot {
      margin-left: 4px;
    }
  }

  &.dot {
    min-width: 8px;
    width: 8px;
    height: 8px;
    padding: 0;

    &.lg {
      min-width: 12px;
      height: 12px;
    }

    &.sm {
      min-width: 6px;
      width: 6px;
      height: 6px;
    }
  }
}
