@import "../../../../../assets/style/colors";
@import "../../../../../assets/style/mixins";

.wrapper {
  position: relative;
  flex: 1 1 auto;
  height: 0;
  padding-right: 1.6em;
  margin-right: 0.4em;
  font-size: $main-font-size;
  overflow: hidden;
  overflow-y: scroll;
  overflow-wrap: break-word;

  @include media-phone() {
    margin-left: 1.6em;
    padding-right: 1.2em;
  }
}

.footer {
  font-size: $main-font-size;
  margin: 1em 2em 0.4em 0;

  @include media-phone() {
    margin: 1.6em 1.6em 0.8em;
  }
}

