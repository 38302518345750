@import '../../../../assets/style/mixins';

.root {
  display: flex;
  width: 300px;
  gap: 23px;
  padding-top: 12px;
}

.code {
  flex-shrink: 0;
}

.buttons {
  display: flex;
  flex-shrink: 0;
  gap: 20px;
  flex-direction: column;
  align-items: center;

  &Wrapper {
    display: flex;
    gap: 12px;
    flex-direction: column;
  }
}

.downloadable {
  position: absolute;
  top: -999999px;
  left: -999999px;
}

@include media-phone() {
  .root {
    width: 100%;
    align-items: center;
    flex-direction: column;
  }

  .buttons {
    width: 100%;
    align-items: normal;

    &Wrapper {
      flex-direction: row;

      button {
        flex-grow: 1;
        height: 33px !important;
        padding: 0 10px !important;
      }
    }
  }
}
