@import '../../../../assets/style/mixins';

.root {
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.header {
  margin-bottom: 8px;
}

.timeInfo {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 16px;
  row-gap: 6px;
}

.date,
.location {
  flex-shrink: 0;
  font-size: 12px;
  line-height: 22px;
  color: $palette-text-secondary-dark;
  display: flex;
  align-items: flex-start;
  column-gap: 8px;
  max-width: 100%;

  svg {
    width: 22px;
    height: 22px;
    color: $palette-secondary;
    flex-shrink: 0;
  }

  .disabled & svg {
    color: $palette-text-secondary-dark;
  }
}

.location {
  span {
    min-width: 0;

    &,
    a {
      display: inline-block;
      max-width: 100%;
      overflow-wrap: break-word;
    }
  }
}

.title {
  font-weight: 600;
}

.senderName {
  font-size: 12px;
  line-height: 16px;
}

[class].messageOutgoing {
  .label,
  .title,
  .date,
  .location,
  .duration,
  [class].timeInfo svg {
    color: $white;
  }
}

@include media-phone() {
  .root {
    align-items: flex-start;
  }

  .header {
    row-gap: 4px;
    flex-wrap: wrap;
  }

  .title {
    font-weight: 400;
  }
}
