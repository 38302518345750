@import '../../../../../assets/style/colors';

.root {
  display: flex;
  align-items: center;
  margin-top: 4px;
  color: $palette-text-secondary-dark;
  font-size: 14px;
  line-height: 1.357;
}

.icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
