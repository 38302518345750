@import 'src/assets/style/mixins';

.root {
  width: 100%;
  height: 100%;
  font-size: $main-font-size;
  padding-right: 2em;

  svg {
    width: 32px;
    height: 32px;
  }

  @include media-tablet() {
    padding-right: 0;
  }
}
