@import '../../../../assets/style/mixins';

.root {
  display: flex;
  height: 100%;
  width: 100%;
  background-color: $white;
  min-width: 0;
}

.sidebar {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 270px;
  min-width: 0;
  border-right: 1px solid $palette-stroke-primary;
  background-color: $white;
  flex-shrink: 0;

  &AdminView {
    max-width: 350px;
  }
}

.header {
  border-bottom: 1px solid $palette-stroke-primary;
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
}

@include media-tablet() {
  .sidebar {
    max-width: unset;
    border: none;
  }
}
