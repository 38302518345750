@import "../../../../assets/style/colors";
@import "../../../../assets/style/variables";


.root {
  &:global([class].ant-tooltip) {
    z-index: $zIndex-modal + 50;
  }

  :global {
    .ant-tooltip-inner {
      min-height: 14px;
      border-radius: 4px;
      padding: 2px 6px 3px;
      background-color: rgba($palette-text-primary-rgb, 0.7);
      box-shadow: none;
      text-align: center;

      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      line-height: 14px;
    }

    .ant-tooltip-arrow-content {
      width: 8px;
      height: 8px;
      background-color: rgba($palette-text-primary-rgb, 0.7);
    }
  }
}
