@import 'src/assets/style/colors';

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $palette-text-secondary-dark;
}

.separator,
.date,
.name {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.separator {
  margin: 0 4px;
  flex-shrink: 0;
}
