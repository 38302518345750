@import '../../../../assets/style/mixins';

.root {
  @include modalTitleWithoutDescriptionGap();
  font-size: $main-font-size;

  form {
    @include dialog();
  }
}

.text {
  margin-bottom: 2em;

  font-weight: normal;
  font-size: 1.4em;
  line-height: 1.571;
  color: $palette-text-primary;
  text-align: center;
}

.button {
  @include modalButtonGapLG();
}

.error .button {
  margin-bottom: 15px;
}
