/* Text Colors */
$palette-text-primary:                                      var(--palette-text-primary);

$palette-text-primary-rgb:                                  var(--palette-text-primary-rgb);

$palette-text-secondary:                                    var(--palette-text-secondary);
$palette-text-secondary-dark:                               var(--palette-text-secondary-dark);
$palette-text-secondary-light:                              var(--palette-text-secondary-light);

$palette-text-secondary-rgb:                                var(--palette-text-secondary-rgb);
$palette-text-secondary-dark-rgb:                           var(--palette-text-secondary-dark-rgb);
$palette-text-secondary-light-rgb:                          var(--palette-text-secondary-light-rgb);
/* END */


/* Disabled Colors */
$palette-disable:                                           var(--palette-disable);
$palette-disable-light:                                     var(--palette-disable-light);
$palette-disable-dark:                                      var(--palette-disable-dark);

$palette-disable-rgb:                                       var(--palette-disable-rgb);
$palette-disable-light-rgb:                                 var(--palette-disable-light-rgb);
$palette-disable-dark-rgb:                                  var(--palette-disable-dark-rgb);
/* END */


/* Stroke Colors */
$palette-stroke-primary:                                    var(--palette-stroke-primary);
$palette-stroke-primary-dark:                               var(--palette-stroke-primary-dark);
$palette-stroke-primary-light:                              var(--palette-stroke-primary-light);

$palette-stroke-primary-rgb:                                var(--palette-stroke-primary-rgb);
$palette-stroke-primary-dark-rgb:                           var(--palette-stroke-primary-dark-rgb);
$palette-stroke-primary-light-rgb:                          var(--palette-stroke-primary-light-rgb);
/* END */

 /* Gray */
$palette-gray:                                              var(--palette-gray);
 /* END */

/* Accent color */
$palette-accent:                                            var(--palette-accent);
$palette-accent-light:                                      var(--palette-accent-light);
$palette-accent-dark:                                       var(--palette-accent-dark);
/* END */


/* Error Colors */
$palette-error:                                             var(--palette-error);
$palette-error-dark:                                        var(--palette-error-dark);
$palette-error-light:                                       var(--palette-error-light);

$palette-error-rgb:                                         var(--palette-error-rgb);
$palette-error-dark-rgb:                                    var(--palette-error-dark-rgb);
$palette-error-light-rgb:                                   var(--palette-error-light-rgb);
/* END */


/* Warning Colors */
$palette-warning:                                           var(--palette-warning);
$palette-warning-dark:                                      var(--palette-warning-dark);
$palette-warning-light:                                     var(--palette-warning-light);

$palette-warning-rgb:                                       var(--palette-warning-rgb);
$palette-warning-dark-rgb:                                  var(--palette-warning-dark-rgb);
$palette-warning-light-rgb:                                 var(--palette-warning-light-rgb);
/* END */


/* Info Colors */
$palette-info:                                              var(--palette-info);
$palette-info-dark:                                         var(--palette-info-dark);
$palette-info-light:                                        var(--palette-info-light);

$palette-info-rgb:                                          var(--palette-info-rgb);
$palette-info-dark-rgb:                                     var(--palette-info-dark-rgb);
$palette-info-light-rgb:                                    var(--palette-info-light-rgb);
/* END */

/* Notification colors */
$palette-notification-violette:                              var(--palette-notification-violette);
 /* END */


/* Success Colors */
$palette-success:                                           var(--palette-success);
$palette-success-dark:                                      var(--palette-success-dark);
$palette-success-light:                                     var(--palette-success-light);

$palette-success-rgb:                                       var(--palette-success-rgb);
$palette-success-dark-rgb:                                  var(--palette-success-dark-rgb);
$palette-success-light-rgb:                                 var(--palette-success-light-rgb);
/* END */

/* Avatar Colors */
$palette-avatar-business:                                   var(--palette-avatar-business);
$palette-avatar-business-rgb:                               var(--palette-avatar-business-rgb);

$palette-avatar-news:                                    var(--palette-avatar-news);
$palette-avatar-news-rgb:                                var(--palette-avatar-news-rgb);

$palette-avatar-professional:                               var(--palette-avatar-professional);
$palette-avatar-professional-rgb:                           var(--palette-avatar-professional-rgb);

$palette-avatar-group:                                      var(--palette-avatar-group);
$palette-avatar-group-rgb:                                  var(--palette-avatar-group-rgb);
/* END */

/* Chip Colors */
$palette-chip-light:                                        var(--palette-chip-light);
$palette-chip-light-rgb:                                    var(--palette-chip-light-rgb);

$palette-chip-dark:                                         var(--palette-chip-dark);
$palette-chip-dark-rgb:                                     var(--palette-chip-dark-rgb);
/* END */

/*
 ******************
 * Dynamic Colors *
 ******************
 */

/* Primary Colors */
$palette-primary:                                           var(--palette-primary);
$palette-primary-dark:                                      var(--palette-primary-dark);
$palette-primary-light:                                     var(--palette-primary-light);

$palette-primary-rgb:                                       var(--palette-primary-rgb);
$palette-primary-dark-rgb:                                  var(--palette-primary-dark-rgb);
$palette-primary-light-rgb:                                 var(--palette-primary-light-rgb);
/* END */


/* Secondary Colors */

$palette-secondary:                                         var(--palette-secondary);
$palette-secondary-dark:                                    var(--palette-secondary-dark);
$palette-secondary-light:                                   var(--palette-secondary-light);
$palette-secondary-hover:                                   var(--palette-secondary-hover);

$palette-secondary-rgb:                                     var(--palette-secondary-rgb);
$palette-secondary-dark-rgb:                                var(--palette-secondary-dark-rgb);
$palette-secondary-light-rgb:                               var(--palette-secondary-light-rgb);
/* END */

/* White */

$white:                                                     var(--palette-white);
$white-rgb:                                                 var(--palette-white-rgb);
/* END */

/* Black */

$black:                                                     var(--palette-black);
$palette-black-rgb:                                         var(--palette-black-rgb);
/* END */

/* Seldom colors */
$palette-call-background:                                   var(--palette-call-background);
$palette-body-background:                                   var(--palette-body-background);
$palette-layout-background:                                 var(--palette-layout-background);
$palette-nav-border:                                        var(--palette-nav-border);
$palette-shadow:                                            var(--palette-shadow);
$palette-dark-gray:                                         var(--palette-dark-gray);
$palette-broadcast:                                         var(--palette-broadcast);
/* END */


/* Skeleton colors */
$palette-skeleton:                                          var(--palette-skeleton);

$palette-skeleton-rgb:                                      var(--palette-skeleton-rgb);
/* END */

/* Icons */
$palette-group-icon-background:                             var(--palette-group-icon-background);
$palette-profile-icon-background:                           var(--palette-profile-icon-background);

  /* Files */
$palette-files-spreadsheet:                             var(--palette-files-spreadsheet);
