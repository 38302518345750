@import '../../../../assets/style/colors';

.top-menu-wrapper {
  background: $palette-layout-background !important;

  &.ant-menu-horizontal {
    border-bottom: none;

    .ant-menu-item-selected {
      color: $palette-text-primary;
      font-weight: bold;
      font-size: 15px;
    }

    .ant-menu-item:not(.ant-menu-item-selected) {
      color: $palette-text-secondary-dark;
      font-size: 15px;
    }
  }
}
