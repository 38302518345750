@import '../../../../../assets/style/colors';

.root {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid $palette-stroke-primary;
}

.avatar {
  margin-right: 12px;
}

.name {
  font-size: 18px;
  line-height: 1.38;
  font-weight: 700;
}

[class].location {
  margin-top: 8px;
  font-size: 12px;
  line-height: 16px;
  color: $palette-text-secondary-dark;

  &Icon {
    color: $palette-secondary-dark;

    &Wrapper {
      margin-right: 4px;
    }
  }
}
