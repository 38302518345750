@import '../assets/style/mixins';

.toast :global {
  .Toastify__toast {
    min-width: 420px;
    padding-left: 66px;
    cursor: default;
  }

  .ToastCustomCloseButton {
    cursor: pointer;
  }
}

:global([class].ant-radio-disabled [class].ant-radio-inner) {
  border-color: $palette-disable !important; // Is set to "important" because "important" is the default value in ant
}

:global([class].ant-radio-disabled [class].ant-radio-inner:after) {
  background-color: $palette-disable-light;
}

:global([class].ant-radio-wrapper.ant-radio-wrapper-disabled) {
  cursor: not-allowed;
}

:global([class].ant-radio-checked [class].ant-radio-inner) {
  border-color: $palette-primary;
}

:global([class].ant-radio-checked [class].ant-radio-inner:after) {
  background-color: $palette-primary;
}

:global([class].ant-radio:hover [class].ant-radio-inner) {
  border-color: $palette-primary;
}

:global([class].ant-radio-checked [class].ant-radio-inner:focus) {
  border-color: $palette-primary;
}

:global([class].ant-radio-wrapper [class].ant-radio + span) {
  padding: 0 0 0 12px;
}

:global(.link) {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.hidden {
  display: none;
}

@include media-phone() {
  body {
    -ms-overflow-style: none;
    scrollbar-width: none;
    width: 100% !important;

    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }
  }

  .toast :global {
    .Toastify__toast {
      min-width: unset;
      max-width: 300px;
      margin-left: auto;
    }
  }
}
