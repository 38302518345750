@import 'src/assets/style/mixins';

[class].filterButton {
  height: 44px;
  padding: 0 14px;
  margin-left: 8px;

  svg {
    width: 20px;
    height: 20px;
  }
}

.container {
  position: relative;
}

.inline {
  cursor: pointer;
}

.badge {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: $palette-error;
  box-shadow: 0 0 0 2px $palette-secondary;
  right: -2px;
  top: 0;
}

.inline .badge {
  box-shadow: 0 0 0 2px $white;
  right: 2px;
}

.filterButton:hover .badge {
  box-shadow: 0 0 0 2px $palette-secondary-light;
}

.filterButton:active .badge {
  box-shadow: 0 0 0 2px $palette-secondary-dark;
}

[class].disabled {
  @include disabledIcon();
}

@include media-tablet() {
  [class].filterButton {
    margin-left: 16px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
}

@include media-phone() {
  [class].filterButton {
    height: 50px;
  }
}
