.root {
  display: flex;
  flex-direction: column;
}

[class].button {
  padding: 0;
  height: auto;
}

.staffs {
  display: flex;
  justify-content: space-between;
}
