@import '../../../../assets/style/mixins';

.root {
  padding: 28px;
}

[class].button {
  font-size: 16px;
  line-height: 24px;

  svg {
    width: 24px;
    height: 24px;
  }
}

@include media-tablet() {
  .root {
    padding: 20px;
  }
}
