@import '../../../assets/style/mixins';

.root {
  display: flex;
  width: 100%;
  height: 100%;
  font-size: $main-font-size;

  @include media-tablet() {
    flex-direction: column;
  }
}

.content {
  @include paper();

  @include media-tablet() {
    padding: 2em;
  }

  @include media-phone() {
    padding: 0;
    border: none;
  }
}

.main {
  width: 100%;
  min-width: 0;
  display: flex;
  flex-direction: column;
}

[class].nav {
  margin-bottom: 2em;

  @include media-tablet() {
    margin-top: 2em;
  }

  @include media-phone() {
    margin-top: 0;
  }
}
