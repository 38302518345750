@import '../../../../../assets/style/mixins';

.root {
  gap: 28px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .card {
    margin-bottom: 12px;
  }
}

.description {
  margin: 0;
  text-align: center;
}

.status {
  text-transform: capitalize;
  text-align: center;
  color: $palette-success;
}

.grace {
  color: $palette-error;
}
