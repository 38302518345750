@import '../../../../../assets/style/mixins';
@import '../../../../../assets/style/colors';

.wrapper {
  margin: 12px 0;
  display: flex;
  align-items: center;
}

.hint {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: $palette-warning-dark;
  margin-right: 28px;
}

@include media-phone() {
  .wrapper,
  .button {
    width: 100%;
  }

  .wrapper {
    flex-direction: column;
    margin: 16px 0;
  }

  .hint {
    margin-right: 0;
  }

  .button {
    margin-top: 8px;
  }
}
