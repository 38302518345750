@import '../../../../assets/style/mixins';

.root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
  background: $white;
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow-y: scroll;
}

.container {
  width: 100%;
  max-width: 720px;
  margin: 16px auto;
  padding: 20px;
  font-size: 14px;
  line-height: 19px;
  border: 2px solid $palette-stroke-primary;
  flex-grow: 1;
}
