@import '../../../../assets/style/colors';

.root {
  display: flex;
  align-items: center;

  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  max-width: 256px;
}

.primary {
  color: $palette-primary-dark;
  background-color: #f2f3fa;
}

.secondary {
  color: $palette-text-primary;
  background: $palette-stroke-primary;
}

.light {
  color: $palette-text-primary;
  background: $palette-info-light;
}

.error {
  color: $palette-text-primary;
  background: $palette-error-light;
}

.success {
  color: $palette-success;
  background: $palette-success-light;
}

.outlined {
  background: transparent;
  border: 1px dashed $palette-secondary-light;
}

.notTruncated {
  max-width: none;
}

.round {
  border-radius: 26px;
  padding: 6px 10px;
}

.square {
  border-radius: 4px;
  padding: 6px 12px;
}
