@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 24px;
}

.title {
  display: flex;
  align-items: center;
  column-gap: 8px;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
}

.title,
[class].status {
  margin-bottom: 12px;
}

[class].status {
  font-size: 14px;
  line-height: 19px;
  text-transform: unset;
}

.owner {
  margin-bottom: 8px;
  justify-content: center;
}

.humanReadableId {
  margin: 0 0 16px;
}

[class].goToCase {
  padding: 0;
  height: auto;
  border-bottom: 1px dashed $palette-secondary;
}

@include media-tablet() {
  .root {
    padding-top: 20px;
  }

  .title,
  [class].status {
    margin-bottom: 8px;
  }

  .owner {
    margin-bottom: 4px;
  }

  .humanReadableId {
    margin-bottom: 8px;
  }
}

@include media-phone() {
  .root {
    padding-top: 16px;
  }

  .owner {
    margin-bottom: 8px;
  }

  .humanReadableId {
    margin-bottom: 12px;
  }
}
