@import '../../../../../assets/style/mixins';

.pagination {
  margin-top: auto;
  padding: 20px 28px;
}

[class].skeleton {
  padding: 24px 28px;

  &:nth-last-child(n + 2) {
    border-bottom: 1px solid $palette-stroke-primary;
  }

  &:first-child {
    padding-top: 28px;
  }

  & :global {
    .ant-skeleton-title {
      height: 22px;
      width: 457px !important;
      margin: 0;
    }

    .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
      margin: 8px 0 0;
      max-width: 762px;

      li {
        height: 22px;

        & + li {
          margin-top: 10px;
        }

        &:first-child {
          width: 228px !important;
        }
      }
    }
  }

  &Button {
    padding: 24px 0 28px;
    border-top: 1px solid $palette-stroke-primary;
  }
}

@include media-tablet() {
  .pagination {
    padding: 20px;
  }

  [class].skeleton {
    padding-left: 20px;
    padding-right: 20px;

    &:first-child {
      padding-top: 20px;
    }

    & :global {
      .ant-skeleton-title {
        width: 566px !important;
        height: 24px;
      }

      .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
        max-width: unset;

        li {
          &:nth-child(1),
          &:nth-child(3) {
            width: 296px !important;
            height: 20px;
          }

          &:nth-child(2) {
            height: 20px;
            width: 427px !important;
          }

          &:nth-child(n + 4) {
            height: 24px;
            width: 100% !important;
          }
        }
      }
    }

    &Button {
      padding: 20px 0;
    }
  }
}

@include media-phone() {
  .pagination {
    padding: 16px;
  }

  [class].skeleton {
    padding-left: 16px;
    padding-right: 16px;

    & :global {
      .ant-skeleton-title {
        width: 100% !important;
      }

      .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph li {
        &:nth-child(1),
        &:nth-child(3) {
          height: 24px;
          width: 168px !important;
        }

        &:nth-child(2) {
          height: 24px;
          width: 100% !important;
        }
      }
    }
  }
}
