@import '../../../../../assets/style/mixins';

[class].wrapperRoot {
  padding: 10px 0;
  margin: 0 20px;
  height: 40px;
}

[class].deactivateHeader {
  padding: 8px 0;
  border-top: 1px solid $palette-stroke-primary;
}

[class].icons svg {
  width: 20px;
  height: 20px;
}
