@import '../../../../assets/style/mixins';

.root {
  display: flex;
  flex-grow: 1;
  height: 0;
}

.scrollContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 0;
}

.scrollInner {
  overscroll-behavior: contain;
  padding-right: 8px;
  margin-right: 4px;
  overflow: hidden scroll;
  display: flex;
  flex-direction: column;
  min-height: 0;
  flex-grow: 1;

  &Skeleton {
    overflow: hidden;
    padding-right: 0;
  }
}
