@import '../../../../assets/style/colors';

.skeleton {
  margin-bottom: 16px;

  :global(.ant-skeleton-content .ant-skeleton-title) {
    margin-top: 0;
  }

  :global(.ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph) {
    margin-top: 0;
    margin-bottom: 8px;
  }
}

.divider {
  margin: 1.2em auto;

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }
}
