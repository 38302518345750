@import '../../../../assets/style/colors';
@import '../../../../assets/style/mixins';

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 1.25;

  text-transform: uppercase;
  color: $palette-text-primary;

  padding-right: 1.6em;
  margin-right: 0.4em;
  margin-bottom: 1em;

  @include media-phone() {
    margin-right: 0;
    padding: 0 16px;
  }
}

.text {
  padding-right: 1.5em;
}
