@import '../../../../../assets/style/colors';

.root {
  display: flex;
  align-items: center;
  color: $palette-secondary;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}

.warning {
  color: $palette-warning-dark;
}

.icon {
  width: 20px;
  height: 20px;
  color: inherit;
  margin-right: 6px;
}
