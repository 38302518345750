@import '../../../../assets/style/mixins';

@include media-tablet() {
  [class].main {
    flex-grow: 1;
  }

  [class].backButton {
    padding: 0;
  }
}

@include media-phone() {
  [class].backButton {
    padding: 0 0 20px;
  }
}
