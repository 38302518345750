@import '../../../../assets/style/mixins';

.root {
  display: flex;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;

  @include media-phone() {
    justify-content: center;
    width: 100%;
    height: auto;
  }
}

.iconWrapper {
  display: flex;
  align-items: center;
  padding-right: 10px;
  color: $white;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 4px 0;

  @include media-phone() {
    flex-grow: 1;
    padding: 8px 0 7px;
  }
}

.title {
  font-weight: bold;
  font-size: 12px;
  line-height: 1.333;
}

.progress {
  background-color: $palette-stroke-primary;
  min-width: 150px;
  width: 100%;
  height: 3px;
  margin-top: 7px;
  margin-bottom: 2px;
}

.progressBackground {
  height: 3px;
  transition: width 0.3s;
}

.footer {
  font-size: 10px;
  line-height: 14px;

  @include media-phone() {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 13px;
    line-height: 18px;
  }
}

.cloud {
  &Root {
    height: 50px;
    min-width: 215px;
    padding: 0 15px;

    background-color: rgba(#f1f1f1, 0.1);

    @include media-phone() {
      height: 62px;
    }
  }

  &Container {
    padding: 0.4em 0;

    @include media-phone() {
      flex-grow: 1;
      padding: 8px 0 7px;
    }
  }

  &Title {
    text-transform: uppercase;
    color: $white;
  }

  &Capacity {
    color: $white;
  }
}

.bin {
  &Root {
    padding: 12px 20px;
    margin: 20px 20px 0 0;

    background: $white;
    border: 1px solid $palette-stroke-primary;

    @include media-tablet() {
      margin: 0 0 16px;
    }
  }

  &Icon {
    margin-right: 12px;
    color: $palette-secondary-dark;
  }

  &Content {
    flex: 1;
    padding: 0;
  }

  &Title {
    text-transform: none;
    color: $palette-text-primary;
  }

  &Footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &Capacity {
    color: $palette-text-primary;
  }
}

.low {
  background-color: $palette-secondary-light;
}

.middle {
  background-color: $palette-warning;
}

.high {
  background-color: $palette-error;
}

.menu {
  &Root {
    padding: 0 16px;
    background-color: rgba($palette-info-light-rgb, 0.5);
  }

  &Icon {
    color: $palette-secondary-light;
  }

  &Title {
    text-transform: uppercase;
  }

  &Title,
  &Footer {
    color: $palette-secondary;
  }

  &Progress {
    background-color: $white;
  }
}
