@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  text-align: left;
  align-items: flex-start;

  &.row {
    flex-wrap: wrap;
    column-gap: 42px;
    row-gap: 16px;
  }

  &.column {
    flex-direction: column;
    row-gap: 16px;
  }
}

.addonBefore {
  width: 100%;
  min-width: 0;
  margin-bottom: 16px;
}

.title {
  display: flex;
  align-items: center;
  column-gap: 8px;
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  color: $palette-secondary;
  margin-bottom: 4px;
}

.column .title svg {
  width: 16px;
  height: 16px;
}

.property {
  position: relative;
  padding-left: 20px;

  font-size: 13px;
  line-height: 21px;
  font-weight: 600;

  &::before {
    content: '';
    border-radius: 50%;
    background: $palette-secondary;
    width: 4px;
    height: 4px;
    position: absolute;
    top: 8px;
    left: 8px;
  }
}

@include media-tablet() {
  .row {
    column-gap: 28px;
  }
}

@include media-phone() {
  .addonBefore {
    margin-bottom: 8px;
  }
}
