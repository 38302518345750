@import '../../../../assets/style/mixins';

[class].root {
  & :global {
    .ant-tabs,
    .ant-tabs-nav {
      width: 100%;
    }

    .ant-tabs-tab {
      display: block;
      width: 100%;
      padding: 0 !important;
      margin: 0 !important;
    }

    .ant-tabs-content-holder,
    .ant-tabs-ink-bar {
      display: none;
    }

    .ant-tabs-tab-active .ant-tabs-tab-btn {
      font-weight: normal;
    }

    .ant-tabs-nav-list {
      @include media-phone() {
        flex-direction: row !important;
      }
    }
  }

  &--left :global(.ant-tabs-nav .ant-tabs-nav-operations) {
    display: none;
  }

  &--top {
    height: auto;
    margin: 0;

    :global {
      .ant-tabs-nav {
        margin: 0;
      }

      .ant-tabs-tab {
        padding-bottom: 10px;
      }

      .ant-tabs-nav-more {
        height: 28px;
        width: 45px;
        padding: 0 !important;
      }
    }
  }

  &.device {
    :global(.ant-tabs-nav .ant-tabs-nav-operations) {
      display: none;
    }
  }
}

:global([class].ant-tabs-dropdown) {
  z-index: $zIndex-nav-bar;
}

:global([class].ant-tabs-dropdown-menu) {
  padding: 12px 0;

  .item--top {
    padding-bottom: 0;
  }
}

:global() {
  [class].ant-tabs-dropdown-menu-item {
    padding: 8px 0;
  
    &-active:hover {
      background-color: transparent;
    }
  }
}

[class].item {
  display: flex;
  align-items: center;
  column-gap: 8px;
  position: relative;
  padding: 10px 0 10px 20px;
  text-align: left;
  color: $palette-text-primary;
  transition: background-color 0.3s ease;
  cursor: pointer;
  justify-content: space-between;

  .sm & {
    font-size: 14px;
    line-height: 1.357;
  }

  .md & {
    font-size: 16px;
    line-height: 1.25;
  }

  &WTitle {
    padding: 10px 20px 10px 32px;

    @include media-phone() {
      text-align: center;
      padding-right: 0;
      padding-left: 0;
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    width: 5px;
    height: 100%;
    background-color: $palette-secondary;
    border-radius: 0px 4px 4px 0px;
  }

  &:hover,
  &:focus {
    background-color: #eff7fa;
    color: $palette-text-primary;
  }

  &--top:hover,
  &--top:focus {
    background-color: transparent;
    color: $palette-text-primary;
  }

  &--top {
    padding: 0 24px 10px;
    color: $palette-text-secondary-dark;

    &::before {
      width: 100%;
      height: 3px;
      top: unset;
      bottom: 0;
      border-radius: 100px;
    }
  }

  &Selected {
    font-weight: 700;
    color: $palette-text-primary;
    background-color: #eff7fa;

    &::before {
      display: block;
    }

    &--top {
      background-color: transparent;
    }
  }
}

.icon {
  margin-bottom: 0.39em;

  @include media-phone() {
    svg {
      width: 18px;
    }
  }
}

[class].notification {
  &--default {
    position: absolute;
    top: 8px;
    right: 12px;
  
    @include media-phone() {
      right: 10%;
      top: 0;
    }
  }

  &--dot {
    align-self: flex-start;
    margin-left: 4px;
  }
}
