@import '.../../../../../../../assets/style/mixins';

.root {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba($palette-black-rgb, 0.7);
  display: flex;
  padding: 16px;
  z-index: $zIndex-popup;
}

.title {
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  color: $white;
}

.cross {
  position: absolute;
  top: 16px;
  right: 16px;
  color: $white;
  cursor: pointer;
}

.container {
  display: flex;
  flex: 1 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
