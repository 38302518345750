@import '../../../assets/style/mixins';

.root {
  display: flex;
  flex-direction: column;
  font-size: $main-font-size;

  padding: 16px 0 28px;

  :global {
    .ant-skeleton {
      padding: 0;
    }

    .ant-skeleton-paragraph {
      margin-bottom: 0;
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
}

.link {
  display: flex;
  align-items: center;
  padding: 0;
  background-color: transparent;
  border: none;
  color: $palette-secondary;

  span {
    @include link();
    font-size: 1.2em;
    line-height: 1.333;
  }

  svg {
    height: auto;
    width: 19px;
    margin-right: 3px;
  }
}

[class].row {
  @include filterItemRow();

  &:hover .rowActions {
    visibility: visible;
    opacity: 1;
  }

  &Title {
    max-width: 13ch;
  }
}

.rowActions {
  display: flex;
  column-gap: 16px;
  margin-right: 1.5em;
  margin-left: auto;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.icon {
  color: $palette-text-secondary-dark;

  &:hover {
    color: $palette-secondary;
  }
}

.createCategoryButton {
  display: flex;
  justify-content: center;
  margin-bottom: 0.4em;
}

.title,
.emptyTitle {
  font-size: 1.4em;
  line-height: 1.357;
  color: $palette-text-primary;
}

.title {
  margin: 0;
  font-weight: 700;
}

.emptyTitle {
  margin: 2em auto 0.571em;
  text-align: center;
}

@include media-tablet() {
  .root {
    padding: 20px 0 0;

    :global(.ant-skeleton) {
      padding: 20px 20px 0;
    }
  }

  .header {
    margin-bottom: 10px;
  }

  .rowActions {
    visibility: visible;
    opacity: 1;
  }
}
