@import '../../../../../../assets/style/mixins';

$textLineHeight: 24;

$visibilityHeight: $textLineHeight * 5;

[class].about {
  font-size: 15px;
  line-height: #{$textLineHeight}px;
  overflow-wrap: break-word;
}

.workInformation {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  align-items: flex-start;
}

.interests {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

@include media-phone() {
  .workInformation {
    align-items: stretch;
  }

  [class].openForNetworking {
    max-width: 100%;
    justify-content: center;
  }
}

:export {
  visibilityHeight: $visibilityHeight;
}
