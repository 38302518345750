@import '../../../../../assets/style/mixins';

[class].title,
[class].description,
.subtitle {
  margin-bottom: 12px;
}

.subtitle {
  font-size: 12px;
  text-transform: uppercase;
}

.account span {
  cursor: text;
}

[class].button {
  margin-top: 20px;

  @include media-tablet() {
    margin-top: 16px;
  }
}
