@import '../../../../assets/style/mixins';

.root {
  font-size: 13px;
  line-height: 18px;
  white-space: pre-wrap;
  overflow-wrap: break-word;

  ul,
  ol {
    margin-bottom: 0;
  }
}
