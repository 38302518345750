@import '../../../../assets/style/mixins';

.root {
  position: relative;
  width: 100%;
  font-size: $main-font-size;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 1.4em;
  line-height: 1;
  font-weight: 700;
  color: $palette-text-primary;

  svg {
    width: 20px;
    height: 20px;
    color: $palette-text-primary;
    margin-left: 0.285em;
    transition: transform 0.3s;
  }

  &Active svg {
    transform: rotate(-180deg);
  }
}

[class].popover {
  :global(.ant-popover) {
    z-index: $zIndex-popup;
    top: 30px !important;
    left: 0 !important;
    right: 0 !important;
  }

  :global(.ant-popover-inner) {
    border-radius: 0px 0px 4px 4px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  }

  :global(.ant-popover-inner-content) {
    padding: 0;
    min-width: 254px;
  }
}

.dropdownMenu {
  display: flex;
  flex-direction: column;
  padding: 1em;
  border-radius: 0px 0px 4px 4px;
  background-color: $white;

  &Button {
    padding: 1em 0;
    font-size: 14px;
    line-height: 1.357em;
    color: $palette-text-primary;
    border: 0;
    background-color: transparent;

    &:disabled {
      font-weight: 700;
    }
  }
}
