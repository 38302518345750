@import '../../../../assets/style/mixins';

.header {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  text-align: start;
  margin-bottom: 0;
  text-transform: uppercase;
  color: $palette-text-secondary-dark;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
}

.collapse {
  :global(.ant-collapse-content-active) {
    margin-top: 12px;
  }

  &:global(.ant-collapse > .ant-collapse-item > .ant-collapse-header) {
    padding: 0 24px;
    column-gap: 8px;

    & > div:first-of-type {
      order: 0;
      margin-left: unset;
    }

    & :global(.ant-collapse-arrow) {
      margin-right: 0;
      width: 20px;
      height: 20px;
      color: $palette-text-secondary-dark;
    }
  }
}

@include media-tablet() {
  [class].collapse :global(.ant-collapse-content-active) {
    margin-top: 10px;
  }

  .header {
    justify-content: flex-start;
  }
}

@include media-phone() {
  [class].collapse :global(.ant-collapse-content-active) {
    margin-top: 6px;
  }

  .header {
    font-size: 12px;
  }
}
