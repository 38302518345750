@import '../../../../../assets/style/mixins';

.audioPlayer {
  width: 100%;
  max-width: 500px;

  :global(.rhap_container) {
    padding: 0;
    box-shadow: none;
    background: transparent;
  }

  :global(.rhap_volume-controls),
  :global(.rhap_additional-controls) {
    display: none;
  }

  :global(.rhap_main) {
    flex-direction: row-reverse;
    flex: auto;
  }

  :global(.rhap_progress-section) {
    width: calc(100% - 24px);
  }

  :global(.rhap_main-controls-button) {
    display: flex;
  }

  :global(.rhap_play-pause-button),
  :global(.rhap_controls-section) {
    color: $palette-secondary;
    width: 28px;
    height: 28px;

    svg {
      width: 100%;
      height: 100%;
    }

    @include media-phone() {
      width: 32px;
      height: 32px;
    }
  }

  :global(.rhap_controls-section) {
    margin-top: 0 !important;
    margin-right: 12px;
  }

  :global(.rhap_play-pause-button) {
    margin: 0;
  }

  :global(.rhap_progress-indicator) {
    width: 10px;
    height: 10px;
    margin-left: 0;
    top: -4px;
    background: $palette-secondary;
  }

  :global(.rhap_progress-bar) {
    height: 2px;
  }

  :global(.rhap_progress-filled) {
    background: $palette-secondary;
  }

  :global(.rhap_time) {
    font-weight: bold;
    font-size: 14px;
    color: $palette-text-primary;
    margin-left: 9px;
  }

  :global([aria-valuenow='0']) + :global(.rhap_total-time) + :global(.rhap_current-time) {
    display: none;
  }

  :global(.rhap_progress-container) {
    &:not([aria-valuenow='0']) + :global(.rhap_total-time) {
      display: none;
    }
    margin-left: 0;
    margin-right: 12px;
  }
}

.white {
  :global(.rhap_button-clear) {
    svg path {
      fill: $white;
    }
  }

  :global(.rhap_play-pause-button) {
    svg path {
      fill: $white;
    }
  }

  :global(.rhap_progress-indicator),
  :global(.rhap_progress-filled) {
    background: $white;
  }

  :global([id]#rhap_current-time),
  :global(.rhap_total-time) {
    color: $white !important;
  }

  :global(.rhap_download-progress) {
    background: $white;
    opacity: 0.3;
  }
}

.small {
  :global(.rhap_progress-section) {
    display: block;
    margin-top: -2px;
  }

  :global(#rhap_current-time),
  :global(.rhap_total-time) {
    color: $palette-secondary;
  }

  :global(.rhap_main) {
    width: 200px;
  }

  :global(.rhap_current-time),
  :global(.rhap_total-time) {
    margin: 0;
    font-size: 11px;
  }

  :global(.rhap_progress-container) {
    margin-right: 0;
    width: 100%;
  }
}

.audioPlayer.small {
  width: auto;
  display: flex;

  :global(.rhap_container) {
    width: auto;
  }
}
