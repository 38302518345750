@import '../../../../../assets/style/mixins';

[class].editorContainer {
  width: 100%;
  border: solid 1px $palette-stroke-primary-dark;
  border-radius: 4px;

  :global(.DraftEditor-root) {
    margin: 0;
    padding: 0;
    height: 100%;
  }
}

.editorWrapper {
  height: 90px;
  padding: 12px 16px;
  font-size: 14px;
  line-height: 19px;
}
