@import '../../../../../assets/style/mixins';

$itemHeight: 45px;

.root {
  min-height: $itemHeight;
  font-size: $main-font-size;

  &WithSkeleton {
    overflow: hidden;
  }
}

.spinnerContainer {
  height: $itemHeight;
}

[class].search {
  margin-bottom: 24px;
  width: 100%;
  max-width: 410px;
}

.list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  padding: 0;
}

.window {
  margin-bottom: 30px;
}

@include media-tablet() {
  .search {
    width: auto;
    max-width: unset;
  }
}

@include media-phone() {
  .search {
    :global {
      .ant-input-affix-wrapper {
        height: 50px;
        width: 100%;
        font-size: 14px;
        line-height: 1.357;
      }
    }
  }
}
