@import '../../../../assets/style/mixins';

.root {
  font-size: $main-font-size;
  padding: 0.8em 1.6em;

  &:nth-child(2n) {
    background-color: $palette-gray;
  }
}

.row {
  display: flex;
  align-items: center;
  width: 100%;
}

.name {
  margin: 0;
  max-width: 60%;
  display: flex;
  align-items: center;
  overflow: hidden;
  font-size: 1.3em;
  line-height: 1.384em;

  @include media-phone() {
    font-size: 1.6em;
    line-height: 1.312;
  }
}

.rowEnd {
  display: flex;
  align-items: center;
  margin-left: auto;
  column-gap: 8px;
}

.cancel {
  padding: 0;
  margin: 0 0 0 auto;
  border: none;
  background-color: transparent;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: $palette-secondary;
}

.errors {
  display: flex;
  margin-top: 0.8em;
}
