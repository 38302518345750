@import 'src/assets/style/mixins';

.root {
  color: $white;
  font-weight: 600;
  font-size: 14px;
}

.dots {
  bottom: 3px;
  margin-left: 4.5px;
  @include dotElastic();
}

.calling {
  display: inline-flex;
  align-items: center;
}
