@import '../../../../../assets/style/mixins';

.root {
  padding: 20px 20px 0;
}

.title {
  margin-bottom: 8px;
}

.id {
  margin-bottom: 10px;
}

.general {
  margin-bottom: 16px;
}

@include media-phone() {
  .root {
    padding: 16px 16px 0;
  }
}
