@import '../../../../assets/style/colors';

.root {
  background-color: $palette-stroke-primary;

  &.vertical {
    width: 1px;
    height: 100%;
  }

  &.horizontal {
    height: 1px;
    width: 100%;
  }
}
