@import '../../../../assets/style/mixins';

.root {
  position: relative;
  font-size: $main-font-size;
}

[class].checkbox {
  display: inline-flex;
  font-weight: 700;
  margin-bottom: 10px;
}

[class].input {
  &Wrapper {
    position: relative;
    display: flex;
    align-items: flex-end;
  }

  &Label {
    font-weight: bold;
  }
}

[class].addButton {
  height: unset;
  padding: 12px;
  font-weight: 600;
  font-size: 1.4em;
  line-height: 1.357em;
  color: $palette-primary;
  cursor: pointer;

  &:disabled {
    color: $palette-text-secondary-dark;
    cursor: default;
  }
}

.removeBtn {
  position: absolute;
  right: 0;
  top: -4px;
  display: flex;
  align-items: center;
  padding: 0;
  font-weight: 600;
  font-size: 1.2em;
  line-height: 1.333em;
  color: $palette-text-secondary-dark;
  cursor: pointer;
  border: none;
  background-color: transparent;

  &Icon {
    width: 16px;
    height: 16px;
    margin-left: 4px;
    color: $palette-text-secondary-dark;
  }
}

.spec {
  &Wrapper {
    width: 340px;
    padding-left: 32px;

  }

  &WrapperSpecializations {
    width: 340px;
    padding-left: 32px;
    margin-top: 10px;
    
    form {
      width: 100%;
    }
  }

  &List {
    padding: 0;
    margin: 32px -12px 0 0;
    list-style: none;
    max-height: 127px;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  &Item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:nth-last-child(n + 2) {
      margin-bottom: 16px;
    }
  }

  &Name {
    margin: 0;
    padding-right: 1.142em;
    overflow-wrap: break-word;
    font-size: 1.4em;
    line-height: 1.357;
    min-width: 0;
  }

  &Icon {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    margin-right: 12px;
    color: $palette-secondary;
    cursor: pointer;
  }
}

@include media-tablet() {
  .removeBtnIcon {
    margin-left: 2px;
  }
}

@include media-phone() {
  .root {
    display: flex;
    flex-direction: column;
  }

  [class].checkbox {
    margin-bottom: 18px;
  }

  [class].inputWrapper {
    flex-direction: column;
    align-items: center;
  }

  [class].addButton {
    bottom: 0;
    padding: 14px 12px;
    font-size: 1.6em;
    line-height: 1.375em;
  }

  .removeBtn {
    top: -28px;
  }

  .spec {
    &Wrapper {
      width: auto;
      padding: 0;
    }

    &List {
      max-height: 134px;
    }

    &Item {
      &:nth-last-child(n + 2) {
        margin-bottom: 12px;
      }
    }

    &Name {
      padding-right: 1em;
    }

    &Icon {
      width: 24px;
      height: 24px;
      margin-right: 4px;
    }
  }
}
