@import '../../../../../../assets/style/mixins';

.root {
  display: flex;
  height: 100%;
  width: 100%;
  min-width: 0;
  min-height: 0;
  flex-direction: column;
  row-gap: 4px;
}

.header {
  padding: 0 20px;
}

.title {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: $palette-text-secondary-dark;
}

.root [class].button {
  padding: 0;
  height: initial;
}

.list {
  min-height: 0;

  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
}

[class].infiniteScroll {
  overflow-x: hidden !important;

  :global {
    .infinite-scroll-component__outerdiv {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    .infinite-scroll-component {
      flex-grow: 1;
    }
  }
}

@include media-phone() {
  .header {
    padding: 0 16px;
  }
}
