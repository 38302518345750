@import '../../../../../assets/style/mixins';

$column-gap: 8px;

[class].panel {
  &:global(.ant-collapse-item > .ant-collapse-header) {
    column-gap: $column-gap;
    padding: 0 28px !important;
    color: $palette-info;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 14px 0;
}

.indicators {
  display: flex;
  align-items: center;
  column-gap: $column-gap;
}

[class].contextMenu {
  color: $palette-info;
}

.collapseArrow {
  display: flex;
}

.icon {
  color: inherit;
}

@include media-tablet() {
  [class].panel {
    &:global(.ant-collapse-item > .ant-collapse-header) {
      padding: 0 20px !important;
    }
  }
}

@include media-phone() {
  [class].panel {
    &:global(.ant-collapse-item > .ant-collapse-header) {
      padding: 0 16px !important;
    }
  }
}
