@import '../../../../../assets/style/mixins';

.cards {
  display: flex;
  column-gap: 20px;

  &Item {
    max-width: 226px;
  }
}

.text {
  margin: 0;
}

@include media-phone() {
  .cards {
    flex-direction: column;
    text-align: left;
    row-gap: 16px;
  }

  .title {
    margin-bottom: 12px;
  }
}
