@import '../../../../../assets/style/colors';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $palette-skeleton;
}

.icon {  
  max-width: 100%;  
  max-height: 100%;
}