@import '../../../../../assets/style/colors';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 28px;

  svg {
    color: $palette-success;
  }
}

.title {
  font-weight: 700;
  font-size: 26px;
  line-height: 35px;
}
