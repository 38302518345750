@import '../../../assets/style/mixins';

$desktopMiddleMediaWidth: 1112;

.root {
  display: flex;
  flex-direction: column;
  flex: auto;
  min-height: 0;
  width: 100%;
  font-size: $main-font-size;
  line-height: 1.333;
  background-color: $palette-body-background;
}

.wrapper {
  display: flex;
  flex: auto;
  min-height: 0;
}

.main {
  position: relative;
  display: flex;
  flex: auto;
  min-width: 0;
  max-height: calc(100vh - #{$header-fixed-height});
  padding: $main-padding;
  z-index: $zIndex-nav-bar;

  @include media-tablet() {
    max-height: unset;
    padding: 16px 16px 0 calc(#{$sider-fixed-width-tablet + 16px});
    position: sticky;
    top: $header-fixed-height-tablet;
  }

  @include media-phone() {
    padding: 16px 1.6em $sider-fixed-height-phone;
    top: $header-fixed-height-phone;
    z-index: unset;

    @supports (padding: max(0px)) {
      padding-bottom: unquote(
        'max(#{$sider-fixed-height-phone}, calc(env(safe-area-inset-bottom) + #{$sider-fixed-height-phone}))'
      );
    }
  }

  &Content {
    width: 100%;
    max-width: 1204px;
    margin: 0 auto;
  }
}

.aside {
  flex-shrink: 0;
  min-width: 0;
  width: $sider-fixed-width;
  background: $palette-primary;

  @include media-tablet() {
    bottom: 0;
    position: fixed;
    z-index: $zIndex-header;
    width: $sider-fixed-width-tablet;
  }

  @include media-phone() {
    z-index: $zIndex-header;
    position: fixed;
    bottom: 0;
    width: 100%;
    // without height iPhone XS increases height when bottom bar is hidden
    height: 50px;
  }
}

.nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: calc(100vh - #{$header-fixed-height});
  width: 100%;
  padding-top: 8px;
  overflow: hidden;

  .group {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 0;
    position: relative;
    overflow: hidden;

    &:first-child {
      height: 100%;
    }

    &:last-child::before {
      content: '';
      display: block;
      height: 1px;
      margin: 0 15px;
      background-color: rgba($white-rgb, 0.1);
    }
  }

  @include media-tablet() {
    $padding-top: 6px;
    height: calc(100vh - #{$header-fixed-height-tablet});
    padding-top: $padding-top;
  }

  @include media-phone() {
    flex-direction: row;
    justify-content: unset;
    height: unset;
    padding: 0;

    .group {
      position: static;
      flex-direction: row;

      &:last-child::before {
        display: none;
      }
    }
  }

  &Link {
    width: 100%;
    padding: 1.17em 0;
    font-size: 1.2em;
    line-height: 1.333;
    text-align: center;
    color: $white;

    &:hover {
      color: $white;
    }

    @include media-tablet() {
      font-size: 1em;
      line-height: 1.4;
      padding: 1.22em 0;
    }

    @include media-phone() {
      padding: 0.6em 0 0.7em;
      transition: padding-bottom 0.3s;

      @supports (padding: max(0px)) {
        padding-bottom: unquote('max(0.7em, env(safe-area-inset-bottom) + 0.7em)');
      }
    }
  }

  &LinkIconWrapper {
    display: inline-block;
    position: relative;

    svg {
      height: 20px;
      width: 20px;
    }
  }

  &LinkText {
    display: block;
    margin-top: 0.333em;
    font-weight: bold;
    font-size: inherit;
    line-height: inherit;

    @include media-tablet() {
      margin-top: 0;
      font-size: 1em;
      line-height: 1.3em;
      font-weight: 600;
    }
  }

  &LinkActive {
    position: relative;
    color: $white;
    background-color: $palette-primary-dark;

    &::before {
      content: ' ';
      position: absolute;
      width: 5px;
      left: 0;
      top: 15%;
      height: 70%;
      background: $white;
      border-radius: 0 $main-border-radius $main-border-radius 0;
    }

    @include media-phone() {
      &::before {
        top: unset;
        right: 0;
        width: 100%;
        bottom: 0;
        height: 3px;
        margin: 0 auto;
        border-radius: 4px 4px 0 0;
      }
    }
  }
}
