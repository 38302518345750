@import '../../../../assets/style/mixins';

.root {
  display: inline-flex;
  gap: 4px;
  font-size: 14px;
  font-weight: 600;
  color: $palette-text-secondary-dark;

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
