@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;

  &.skeleton {
    column-gap: 40px;
  }
}

.amountSkeleton {
  flex-basis: 50%;
}

.sizeSkeleton {
  flex-grow: 1;
  max-width: 220px;
}

.amountSkeleton,
.sizeSkeleton {
  :global(.ant-skeleton-content .ant-skeleton-paragraph > li) {
    height: 22px;
  }
}

@include media-phone() {
  .root {
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
}
