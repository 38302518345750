@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 16px;
  font-size: 14px;
  line-height: 19px;
}

.actions {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

@include media-tablet() {
  .root {
    align-items: flex-end;
    row-gap: 12px;
  }
}

@include media-phone() {
  .root {
    align-items: center;
    gap: 16px;
  }
}
