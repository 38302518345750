@import '../../../../assets/style/mixins';

.icon {
  color: $palette-secondary-dark;
  width: 20px;
  height: 20px;

  &White {
    color: $white;
  }

  @include media-phone() {
    width: 16px;
    height: 16px;
  }
}
