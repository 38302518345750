@import 'src/assets/style/mixins';

.icon {
  color: $palette-text-secondary-dark;
  cursor: pointer;
}

[class].disabled {
  @include disabledIcon();
}
