@import '../../../../../../assets/style/mixins';

.picker {
  :global(.emoji-mart) {
    border: none;
  }
}

.shortPicker {
  border-radius: 4px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
  background-color: $white;
  padding: 2px 12px;
  column-gap: 12px;

  :global(.emoji-mart) {
    width: 216px !important;
    border: none;
  }

  :global(.emoji-mart-bar) {
    display: none;
  }

  :global(.emoji-mart-search) {
    display: none;
  }

  :global(.emoji-mart-category-label) {
    display: none;
  }

  :global(.emoji-mart-scroll) {
    padding: 0;
    overflow: hidden;
    height: 36px;
  }
}

.popover {
  &:global(.ant-popover-placement-bottomLeft),
  &:global(.ant-popover-placement-bottomRight) {
    top: -56px !important;
  }

  :global(.ant-popover-inner) {
    background: none;
    box-shadow: none;
  }

  :global(.ant-popover-inner-content) {
    padding: 0 0 20px;
  }
}

.icon {
  cursor: pointer;
  width: 24px;
  height: 24px;
  color: $palette-text-secondary-dark;
}
