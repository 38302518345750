@import '../../../../../../../assets/style/mixins';

[class].root {
  font-size: 14px;
  line-height: 19px;
  margin: 0;
  color: $palette-text-primary;
}

@include media-phone() {
  [class].root {
    font-size: 15px;
    line-height: 20px;
  }
}
