@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  position: relative;
  align-items: center;

  &Inline {
    flex-grow: 1;
  }
}

.badge {
  top: -8px;
  right: -8px;
  position: absolute;

  &Dot {
    top: 4px;
    right: 4px;
  }

  &Inline {
    margin-left: auto;
    position: static;
  }
}
