@import '../../../assets/style/colors';

.label {
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  color: $palette-text-secondary-dark;
  font-weight: normal;
}

