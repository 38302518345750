@import '../../../../../assets/style/colors';
@import '../../../../../assets/style/variables';

.root {
  display: flex;
  padding: 12px 18px;
  background-color: $palette-info-light;
  border-radius: $main-border-radius;
  font-size: 14px;
  line-height: 1.357;
  font-weight: 600;
  &Folded {
    background-color: $palette-disable-light;
  }
  &:nth-last-child(n + 2) {
    margin-bottom: 20px;
  }
}
