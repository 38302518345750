@import '../../../../assets/style/mixins';

.root {
  padding: 16px 16px 16px 28px;
  border-bottom: 1px solid $palette-stroke-primary;
}

.start,
.end {
  min-width: 0;
}

.end {
  flex-shrink: 0;
}

.action {
  flex-shrink: 0;
  border-radius: 2px;
  height: 34px;
  width: 34px;
  background-color: $white;
  justify-content: center;
  margin-right: 24px;
}

.title {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  margin-right: 28px;
}

.chip {
  color: $palette-info-dark;
}

.watchingIcon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.actions {
  column-gap: 24px;
}

@include media-tablet() {
  .root {
    padding: 16px 8px 16px 20px;
    background-color: rgba($palette-secondary-rgb, 0.08);
  }

  .title {
    font-size: 16px;
    line-height: 22px;
  }

  .actions {
    column-gap: 16px;
  }

  [class].kebabMenu:global(.ant-popover) {
    z-index: $zIndex-popover;
  }
}

@include media-phone() {
  .root {
    padding: 8px;
  }

  .title {
    font-size: 14px;
    line-height: 19px;
    margin-right: 18px;
  }

  .action {
    margin-right: 16px;
  }
}
