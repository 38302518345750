@import '../../../../assets/style/mixins';

[class].root {
  position: fixed;
  z-index: $zIndex-nav-bar;
  right: 31px;
  bottom: 15px;
  display: flex;
  align-items: flex-end;

  @include media-phone() {
    right: 8px;
    bottom: 65px;
  }
}
