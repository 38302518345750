@import 'src/assets/style/mixins';

.title {
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  margin: 28px 0 24px;
}

.footerActions {
  margin-top: 48px;
}
