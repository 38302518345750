@import 'src/assets/style/mixins';

.root {
  height: 100%;
  border: 1px solid $palette-stroke-primary-light;
  border-radius: 4px;
  cursor: pointer;
}

.image {
  height: 100%;
}

[class].skeleton {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  :global(.ant-skeleton-image) {
    width: 100%;
    height: 100%;
  }
}

[class].fileName {
  font-size: 1.4em;
  line-height: 1.357;
  color: $palette-text-secondary-dark;
  padding: 8px 20px;
}

[class].contextMenu {
  max-width: 245px;
}

@include media-tablet() {
  [class].skeleton {
    :global(.ant-skeleton-image-svg) {
      width: 100%;
      height: 100%;
    }

    :global(.ant-skeleton-image) {
      width: 60px;
      height: 60px;
    }
  }
}

@include media-phone() {
  [class].contextMenuIcon {
    pointer-events: none;
  }
}
