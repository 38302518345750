@import 'src/assets/style/mixins';

.root {
  position: absolute;
  z-index: $zIndex-popover;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: block;

  &.left {
    right: calc(50% + 0.8em);
  }

  &.right {
    left: calc(50% + 0.8em);
  }
}

.actions svg {
  & + & {
    margin-left: 14px;
  }
}

[class].carousel {
  padding: 28px 20px;
}

.icon {
  height: auto;
  color: $palette-text-primary;
}

[class].headerWrapper {
  align-items: center;
}

[class].header {
  margin-top: 1em;
  border-bottom: none;
}

[class].title {
  margin-right: 30px;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}
