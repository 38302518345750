@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  flex-grow: 1;
}

.content,
[class].placeholder {
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  min-height: 0;

  @include paper();
}

[class].placeholder {
  flex-grow: 1;
}

[class].skeleton {
  padding: 24px 0;
}

@include media-tablet() {
  .content {
    padding-left: 20px;
    padding-right: 20px;
  }

  [class].skeleton {
    padding: 16px 0;
  }
}

@include media-phone() {
  .content {
    padding-left: 16px;
    padding-right: 16px;
  }
}
