@import '../../../../../assets/style/mixins';

[class].root {
  display: flex;
  flex-direction: column;
  position: relative;

  .topLabel {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 1.357em;
  }

  :global(.ant-picker .ant-picker-clear) {
    opacity: 1;
  }

  :global(.ant-picker-input input::placeholder) {
    color: $palette-text-secondary-dark;
  }

  &:global([class].l.ant-picker-input) {
    height: 43px;
  }

  &:global([class].s.ant-picker-input) {
    height: 32px;
  }

  :global(.ant-picker) {
    font-size: 13px !important;
    border: 1px solid $palette-stroke-primary-dark;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 7px 8px;
    height: 44px;
    box-shadow: none;

    input {
      color: $palette-text-primary;

      &::placeholder {
        @include placeholder();
      }
    }

    &:hover {
      border: 1px solid $palette-stroke-primary-dark;
    }
  }

  &.error :global(.ant-picker) {
    &,
    &:hover {
      border: 1px solid $palette-error;
    }
  }

  :global(.ant-picker-focused) {
    box-shadow: none;
    border: 2px solid $palette-secondary;

    &:hover {
      border: 2px solid $palette-secondary;
    }
  }

  :global(.ant-picker-suffix) {
    color: $palette-text-primary;
  }

  :global(.ant-picker-active-bar) {
    display: none;
  }
}

[class].dropdown {
  &:global(.ant-picker-dropdown) {
    z-index: $zIndex-date-picker;
  }

  :global(.ant-picker-header-view) {
    font-size: 16px;
    font-weight: 600;
  }

  :global(.ant-picker-month-panel) {
    width: 215px;
  }

  :global(.ant-picker-cell-range-start .ant-picker-cell-inner),
  :global(.ant-picker-cell-range-end .ant-picker-cell-inner),
  :global(.ant-picker-cell-selected .ant-picker-cell-inner) {
    background-color: $palette-primary;
    font-weight: 600;
  }

  :global(.ant-picker-cell-in-view.ant-picker-cell-range-end),
  :global(.ant-picker-cell-in-view.ant-picker-cell-in-range),
  :global(.ant-picker-cell-in-view.ant-picker-cell-range-start) {
    &::before {
      background: $palette-primary;
      opacity: 0.1;
    }
  }

  :global(.ant-picker-cell-in-view.ant-picker-cell-range-start::before),
  :global(.ant-picker-cell-in-view.ant-picker-cell-in-range::before) {
    color: $palette-text-primary;
  }

  :global(.ant-picker-range-arrow) {
    display: none;
  }

  :global(.ant-picker-header) {
    padding: 0 20px;
    border-bottom: none;
  }

  :global(.ant-picker-header-view button:hover) {
    color: $palette-text-primary;
  }

  :global(.ant-picker-header > button) {
    color: $palette-text-primary;

    &:hover {
      color: $palette-secondary;
    }
  }

  &Date {
    & :global {
      .ant-picker-content th,
      .ant-picker-content td {
        width: 28px;
        height: 26px;
        font-weight: 600;
      }

      .ant-picker-cell-inner {
        width: 28px;
        height: 26px;
        line-height: 26px;
        border-radius: 4px !important;
      }
    }
  }

  &Month,
  &RangeMonth {
    & :global {
      .ant-picker-cell-inner {
        width: 45px;
        height: 42px;
        padding: 0;
        text-align: center;
        font-size: 16px;
        line-height: 42px;
        color: $palette-text-primary;
        border-radius: $main-border-radius !important;
      }
    }
  }
  &RangeMonth {
    & :global {
      .ant-picker-cell-in-range .ant-picker-cell-inner {
        color: $palette-text-primary;
      }

      .ant-picker-cell-in-view {
        color: $palette-text-secondary-dark;
      }
    }
  }

  :global(.ant-picker-decade-panel .ant-picker-cell-inner) {
    width: 84px;
  }

  :global(.ant-picker-panel) {
    border: none;
  }
}

:global(.ant-picker-range) {
  width: 100%;
}

.suffix {
  width: 20px;
  height: 20px;
  color: $palette-dark-gray;
}

.separator {
  color: $palette-dark-gray;
  width: 16px;
  height: 16px;
}

.footer {
  display: flex;
  padding: 20px 0;
  justify-content: space-between;

  button {
    padding-left: 20px !important;
    padding-right: 20px !important;
    height: 33px !important;
    font-size: 12px !important;
    line-height: 16px !important;
  }
}

@include media-tablet() {
  .dropdown {
    @include hideRangePickerSecondCalendar();
  }

  :global(.ant-picker-panel-container .ant-picker-panels) {
    max-width: 280px;
  }
}

@include media-phone() {
  [class].root {
    .topLabel {
      font-size: 16px;
      line-height: 1.333em;
    }

    :global(.ant-picker) {
      &,
      input {
        font-size: 16px !important;
      }
    }
  }

  [class].dropdown {
    &Date {
      :global(.ant-picker-cell-inner) {
        width: 38px;
        height: 38px;
        line-height: 38px;
      }
    }
  }
}
