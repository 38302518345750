.page,
.step {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 28px;
}

.separator {
  cursor: default;
}
