@import '../../../../../assets/style/mixins';

.root {
  padding: 20px;
  @include dividerBottom();

  &.highlight {
    background-color: rgba($palette-secondary-rgb, 0.1);
  }
}

.profession,
.message,
.actions {
  padding-left: 62px;
}

.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 8px;
}

.time {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: $palette-text-secondary-dark;
  margin-left: 32px;
  flex-shrink: 0;
}

.profession {
  margin-bottom: 12px;
}

.message {
  font-size: 14px;
  line-height: 23px;
  margin: 0 0 16px;
  word-break: break-all;
}

.actions {
  display: flex;
  align-items: center;

  & button:first-child {
    margin-right: 20px;
  }
}

@include media-tablet() {
  .profession,
  .message,
  .actions {
    padding-left: 0;
  }
}

@include media-phone() {
  .root {
    padding: 16px;
  }

  .header {
    flex-direction: column-reverse;
  }

  .time {
    margin: 0 0 12px;
    font-size: 12px;
    line-height: 16px;
  }

  [class].actions {
    flex-direction: column;

    & button {
      width: 100%;
    }

    & button:first-child {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }
}
