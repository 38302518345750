@import '../../../../../assets/style/mixins';

.root {
  position: relative;
  padding: 2.8em;
  font-size: $main-font-size;

  @include media-tablet {
    padding: 2em;
  }

  @include media-phone() {
    padding: 2em 1.6em;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 93%;
    height: 1px;
    margin: 0 auto;
    background-color: $palette-stroke-primary;
  }

  &:last-child::after {
    display: none;
  }

  &Viewed {
    background: rgba($palette-secondary-light-rgb, 0.07);
  }
}

.header {
  display: flex;
  justify-content: space-between;

  @include media-phone() {
    flex-direction: column;
  }

  &Title {
    margin: 0;
    padding-right: 0.888em;
    font-weight: bold;
    font-size: 1.8em;
    line-height: 1.388;
    color: $palette-text-primary;
    overflow-wrap: break-word;

    @include media-tablet() {
      font-size: 1.6em;
      line-height: 1.375;
    }

    @include media-phone() {
      padding-right: 0;
      font-size: 1.4em;
      line-height: 1.571;
    }
  }
}

.subTitle {
  width: 100%;
  margin-top: 0.25em;
  margin-bottom: 1.333em;
  font-size: 1.2em;
  line-height: 1.333;
  color: $palette-text-secondary-dark;

  @include media-phone() {
    margin: 0 0 1em;
  }
}

.avatar {
  display: flex;
  align-items: center;

  @include media-phone() {
    margin: 1.2em 0;
  }

  &Title {
    margin-right: 1em;
    font-weight: 600;
    font-size: 1.2em;
    line-height: 1.333;
    color: $palette-text-primary;

    @include media-phone() {
      margin-right: 0.666em;
    }
  }
}

.info {
  display: flex;
  align-items: center;
  margin-bottom: 1.7em;

  @include media-phone() {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1.3em;
  }

  &Item {
    &:nth-last-child(n + 2) {
      margin-right: 1.333em;

      @include media-phone() {
        margin-right: 0;
        margin-top: 0.5em;
      }
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }
}

.description {
  margin-bottom: 2.8em;
}

.actions {
  display: flex;

  @include media-phone() {
    flex-direction: column;
    align-items: center;
  }

  &Btn {
    &Approve {
      margin-right: 1.428em;

      @include media-phone() {
        margin-right: 0;
        margin-bottom: 1.25em;
      }
    }
  }
}
