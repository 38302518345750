@import '../../../../../assets/style/mixins';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: $header-fixed-height;
  padding: 1em 2em;
  z-index: $zIndex-header;
  background: $palette-primary;
  position: sticky;
  top: 0;

  @include media-tablet() {
    height: $header-fixed-height-tablet;
    padding: 0.8em 1.6em;

    @supports (padding: max(0px)) {
      padding-left: unquote('max(1.6em, calc(env(safe-area-inset-left) + 1.6em ))');
      padding-right: unquote('max(1.6em, calc(env(safe-area-inset-right) + 1.6em ))');
    }
  }

  @include media-phone() {
    height: $header-fixed-height-phone;
  }
}

.select {
  width: 80px !important;
}
