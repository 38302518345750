@import "../../../../assets/style/colors";
@import "../../../../assets/style/mixins";

.time {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: $palette-text-secondary-dark;
  margin-left: 5px;
}
