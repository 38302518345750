@import '../../../assets/style/mixins';

.root {
  display: flex;
  height: 100%;
  width: 100%;
  font-size: $main-font-size;

  &Container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.fileTableWrapper {
  width: 100%;
}

.sidebar {
  @include paper();

  padding-bottom: 28px;
}

.alert {
  margin-bottom: 20px;
}

.filters {
  margin-top: 20px;
}

[class].upload::after {
  display: block;
}

@include media-tablet() {
  .root {
    flex-direction: column;
  }

  .sidebar {
    padding: 20px;
  }

  [class].upload::after {
    display: none;
  }

  .shared {
    padding: 0;
    border: none;
  }
}

@include media-phone() {
  .root {
    &Container {
      display: block;
    }

    &Wrapper {
      padding-bottom: 4em;

      @supports (padding: max(0px)) {
        padding-bottom: unquote('max(4em, calc(env(safe-area-inset-bottom) + 4em))');
      }
    }
  }

  .sidebar {
    padding: 0;
    border: none;
  }
}
