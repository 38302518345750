@import '../../../../assets/style/mixins';

.root {
  display: flex;
  height: 100%;
  min-height: 0;
  border-radius: $main-border-radius;
  border: 1px solid $palette-stroke-primary-light;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

@include media-tablet() {
  .root {
    height: calc(100% - 16px);
  }
}
