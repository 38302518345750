@import '../../../../../assets/style/colors';

.root {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  width: 24px;
  height: 24px;
  box-shadow: $palette-stroke-primary 0 0 0 1px;
  border-radius: 1px;
  background: $white;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: $palette-secondary;

  svg {
    width: 16px;
    height: 16px;
  }
}
