@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  padding-top: 12px;
  margin-top: 12px;
  font-size: $main-font-size;
  column-gap: 24px;
}

.user {
  margin-bottom: 8px;
  font-size: 1.2em;
  font-weight: 600;
  line-height: 1.33em;
  color: $palette-text-primary;
}

.membersWrapper {
  display: inline-flex;
  flex-direction: column;
}

@include media-phone() {
  .root {
    border-top: 1px solid $palette-stroke-primary;
    margin-top: 20px;
    padding-top: 20px;
  }
}
