@import '../../../../../assets/style/mixins';

:global([class].ant-picker-dropdown) {
  z-index: 5000;
}

.topLabel {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1.357em;

  @include media-phone() {
    font-size: 15px;
    line-height: 1.333em;
  }
}

.separator {
  color: $palette-dark-gray;
  width: 16px;
  height: 16px;
}
