@import '../../../assets/style/mixins';

[class].menu {
  padding-bottom: 0;
}

[class].content {
  padding: 0 28px;
}

@include media-tablet() {
  [class].content {
    padding: 0 20px;
  }
}
