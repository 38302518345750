@import '../../../../../assets/style/colors';

.root {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  min-width: 0;
}

.title {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  word-wrap: break-word;
  width: 100%;
  min-width: 0;
}
