@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  flex-wrap: wrap;
  row-gap: 14px;
  width: min-content;
}

@include media-phone() {
  .root {
    flex-wrap: nowrap;
    column-gap: 10px;
  }
}
