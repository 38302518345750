.root {
  display: flex;
  align-items: center;
  column-gap: 12px;
  font-size: 14px;
  line-height: 19px;
}

.placeholder,
.thumbnail {
  width: 32px;
  height: 32px;
  border-radius: 2px;
}
