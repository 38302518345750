@import '../../../../assets/style/mixins';

.root {
  padding: 20px 0 0;
}

.wrapper {
  padding: 0 28px 20px;
}

.root,
.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: 100%;
}

.list {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
}

[class].pagination {
  margin-top: 28px;
}

[class].backButton {
  @include dividerBottom(0px);
}

[class].inviteButton {
  margin-left: auto;
  margin-bottom: 24px;
}

@include media-tablet() {
  .root {
    padding: 0;
  }

  .wrapper {
    padding: 20px;
  }
}

@include media-phone() {
  [class].inviteButton {
    margin-left: unset;
  }
}
