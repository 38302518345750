@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  align-items: center;
  column-gap: 12px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 4px;
  }
}

@include media-phone() {
  .root {
    column-gap: 24px;
  }
}
