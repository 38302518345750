@import '../../../../assets/style/mixins';

.root {
  z-index: $zIndex-call;
}

.fixed {
  position: fixed;
}

.container {
  position: fixed;
  top: 90px;
  right: 20px;
  z-index: $zIndex-call;
  width: 436px;
  height: 310px;
  background: none;
  display: flex;
  flex-direction: column;

  &.static {
    position: static;
    width: 100%;
    height: 100%;
  }
}

.container.mobileFullScreen {
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  visibility: visible;
  opacity: 1;
}

.videoContainer {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 4;
  border-radius: 0 0 4px 4px;
  background-color: $palette-text-primary;
}
