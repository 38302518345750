@import '../../../../../../../assets/style/mixins';

[class].root {
  padding: 0 8px;
  line-height: 31px;
  border-radius: 4px;
  background-color: $palette-info-light;
  font-size: 14px;

  div:last-child {
    cursor: pointer;
  }

  &:hover,
  &:hover > div {
    color: currentColor;
    background-color: $palette-info;
  }
}
