@import '../../../../../assets/style/_mixins';

.indicators {
  margin-left: 20px;
}

[class].header {
  flex-shrink: 0;
  border-bottom: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
}

[class].main {
  padding: 0 4px 0 20px;
}

.pinnedFooter {
  padding-top: 16px;
}

@include media-tablet() {
  .footer {
    padding-top: 16px;
  }
}
