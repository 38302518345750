@import '../../../../assets/style/mixins';

.root {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  font-size: $main-font-size;

  &.disabled {
    cursor: not-allowed;
    opacity: 0.65;
  }
}

.details {
  min-width: 0;
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.name {
  display: flex;
  align-items: baseline;
  font-weight: bold;
  margin-bottom: 2px;

  @include media-phone() {
    color: $white;
  }
}

.role {
  font-size: 12px;
  line-height: 16px;
  color: $palette-text-secondary-dark;
}

[class].accountNameRoot {
  margin-bottom: 2px;
}

.badge {
  margin-left: auto;

  &Content {
    margin-left: 8px;
  }
}
