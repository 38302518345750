@import '../../../../../assets/style/colors';

.root,
.container {
  min-width: 0;
}

.root {
  display: flex;
  align-items: flex-start;
  width: 100%;
  font-size: 13px;
  line-height: 23px;
}

.avatar {
  flex-shrink: 0;
  margin-right: 20px;
}

.container {
  display: flex;
  flex-direction: column;
}

.link {
  cursor: pointer;
  margin-bottom: 8px;
  align-items: flex-start;

  &Title {
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
  }

  &Icon {
    font-size: 0;
  }
}

.location {
  color: $palette-text-secondary-dark;
  font-size: 12px;
  line-height: 16px;

  svg {
    width: 20px;
    height: 20px;
  }
}
