@import '../../../../../assets/style/mixins';

.root {
  margin-left: 16px;

  @include media-phone {
    position: absolute;
    top: 3px;
    right: 0;
    margin: 0;
  }
}
