@import '../../../../../assets/style/mixins';

.grid {
  width: 100%;
  height: 100%;
  align-content: flex-start;
}

@include media-phone() {
  .grid {
    padding-bottom: 12px;
  }
}
