@import '../../../assets/style/colors';

.skeleton {
  :global(.ant-skeleton-content .ant-skeleton-paragraph) {
    display: flex;
    align-items: center;
    column-gap: 8px;
    margin-bottom: 0;

    > li {
      height: 34px;
      max-width: 170px;
      margin: 0 !important;

      &:last-child {
        border-radius: 50%;
        width: 34px !important;
        flex-shrink: 0;
      }
    }
  }
}

.popoverContent {
  width: 270px;
  max-height: 368px;
  overflow: auto;
}

.contacts {
  background: $palette-notification-violette;
}
