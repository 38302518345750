@import '../../../../../assets/style/mixins';

[class].skeleton {
  & :global {
    .ant-skeleton-paragraph {
      display: flex;
      justify-content: center;
      margin: 0;
    }
  }
}

[class].m {
  & :global {
    .ant-skeleton-paragraph li {
      height: 48px;
      width: 113px !important;

      @include media-tablet() {
        height: 54px;
        width: 118px;
      }
    }
  }
}

[class].s {
  & :global {
    .ant-skeleton-paragraph li {
      height: 22px;
      width: 194px !important;

      @include media-tablet() {
        height: 20px;
        width: 130px !important;
      }
    }
  }
}
