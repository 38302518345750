@import '../../../../../../assets/style/mixins';

.emoji {
  $size: 16px;

  display: inline-block;
  width: $size;
  height: $size;

  & > :global(.emoji-mart-emoji) {
    @include emoji($size);
  }
}
