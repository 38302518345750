@import '../../../../assets/style/colors';

.root {
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.default {
  color: $palette-secondary-dark;
}

.white {
  color: $white;
}
