@import './colors';

/**
 * ==============================================
 * Dot Elastic
 * ==============================================
 */

@mixin dotElastic($dotColor: white, $size: 0.2em) {
  $borderRadius: calc(#{$size} / 2);
  $offset: calc(#{$size} * 1.5);
  $neg: -1;

  align-self: flex-end;
  position: relative;
  width: $size;
  height: $size;
  border-radius: $borderRadius;
  background-color: $dotColor;
  color: $dotColor;
  animation: dotElastic 1s infinite linear;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  &::before {
    left: calc(#{$neg} * #{$offset});
    width: $size;
    height: $size;
    border-radius: $borderRadius;
    background-color: $dotColor;
    color: $dotColor;
    animation: dotElasticBefore 1s infinite linear;
  }

  &::after {
    left: $offset;
    width: $size;
    height: $size;
    border-radius: $borderRadius;
    background-color: $dotColor;
    color: $dotColor;
    animation: dotElasticAfter 1s infinite linear;
  }
}

@keyframes dotElasticBefore {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1.5);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes dotElastic {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 1.5);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes dotElasticAfter {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}

/**
 * ==============================================
 * Dot Elastic ======== END ========
 * ==============================================
 */

/**
 * ==============================================
 * Pulse
 * ==============================================
 */

@mixin pulse() {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    border-radius: 50%;

    box-shadow: 0 0 0 rgba(var(--palette-white-rgb), 0.4);
    animation: pulse 2s infinite;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(var(--palette-white-rgb), 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(var(--palette-white-rgb), 0.4);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(var(--palette-white-rgb), 0.4);
  }
}

/**
 * ==============================================
 * Pulse ======== END ========
 * ==============================================
 */
