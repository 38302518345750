@import '../../../../assets/style/mixins';

$rowGap: 16px;

.root {
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 22px 11px;
  background: rgba($palette-secondary-rgb, 0.08);
  border-right: 1px solid $palette-stroke-primary-light;
}

.nav {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $rowGap;
  overflow-x: hidden;
}

.controls {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-top: 1px solid $palette-stroke-primary;
  padding-top: $rowGap;

  &.bottom {
    margin-top: auto;
    padding-top: 22px;
  }
}

.controlIcon {
  border-radius: 50%;
  padding: 2px;
  width: 50px;
  height: 50px;
  background-color: $palette-secondary;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
}
