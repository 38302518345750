@import '../../../../../assets/style/mixins';

.root {
  margin: 0;
  display: flex;
  align-items: center;
  font-size: $main-font-size;
  padding: 0 20px;

  @include media-phone() {
    padding: 0 16px;
  }
}

.title {
  font-weight: 700;
  font-size: 12px;
  line-height: 1.8;
  text-transform: uppercase;
  margin-right: 20px;
}
