@import '../../../../assets/style/mixins';

.root {
  &Skeleton {
    height: 100%;
    overflow-y: hidden;
    border-radius: 4px;
    border-top-width: 1px;
  }

  &Empty {
    display: flex;
    flex-grow: 1;

    @include paper();
  }
}

.list {
  display: flex;
  flex-direction: column;
}

.pagination {
  @include paginationWrapper();
}
