@import '../../../../assets/style/mixins';

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: $palette-success;
}

.balance {
  display: flex;
  align-items: center;
}

.icon {
  flex-shrink: 0;
  margin-right: 12px;
  width: 20px;
  height: 20px;
}

.skeleton {
  :global(.ant-skeleton-content .ant-skeleton-paragraph) {
    margin-bottom: 0;

    & > li + li {
      margin-top: 12px;
    }
  }
}

@include media-phone() {
  .root {
    color: $white;
  }

  .balance {
    font-weight: normal;
  }

  .icon {
    margin-right: 8px;
  }
}
