@import '../../../../../../assets/style/mixins';

[class].skeleton :global(.ant-skeleton-input) {
  height: 22px;
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
}

.title {
  font-size: 16px;
  line-height: 1.25;
  text-transform: uppercase;
  font-weight: 700;
  color: $palette-text-primary;
}

.contextIcon {
  color: $palette-secondary;
}
