@import '../../../../assets/style/mixins';

.root {
  width: 100%;
  padding: 2em 2.8em 1.2em;
  font-size: $main-font-size;
  background-color: $white;
  border: 1px solid $palette-stroke-primary;
  border-radius: 4px 4px 0 0;
  border-bottom: none;

  @include media-tablet() {
    width: auto;
    padding: 2em 2em 2.4em;
    margin-right: 0;
  }

  @include media-phone() {
    padding-bottom: 2em 1.6em 2.4em;
    margin-right: 0;
  }
}

.search {
  width: 100%;
  margin-bottom: 22px;

  @include media-tablet() {
    margin-bottom: 20px;
  }

  @include media-phone() {
    margin-bottom: 16px;
  }
}

.titleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 33px;
}

.actions {
  display: flex;
}

[class].button {
  padding: 0 28px;

  @include media-tablet() {
    height: 44px;

    &Filter {
      padding: 0 14px;
      margin-right: 20px;
    }
  }

  @include media-phone() {
    height: 44px;
    padding: 0 14px;

    &Filter {
      margin-right: 12px;
    }
  }
}
