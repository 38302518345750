@import '../../../../../assets/style/mixins';

.root {
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 4px;
  word-break: break-all;
  color: $palette-text-secondary-dark;
}

.center {
  text-align: center;
}
