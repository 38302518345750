@import '../../../../../../assets/style/mixins';

.list {
  font-size: $main-font-size;
  overflow: auto scroll;
  min-width: 0;

  @include drawerRightScrollPadding();
}

[class].emptyList {
  margin-top: 4em;
  justify-content: flex-start;

  &Icon {
    width: 32px;
    height: auto;
  }
}

@include media-phone() {

  .list {
    overflow: unset;
    margin-right: unset;
    padding-right: unset;
  }
}