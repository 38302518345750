@import '../../../../assets/style/colors';

.fileNameWrapper {
  flex-grow: 1;
}

.fileName {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
}

.disabled {
  color: $palette-disable-dark;
}
