@import '../../../assets/style/mixins';

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  font-size: $main-font-size;

  @include media-phone() {
    margin: initial;
  }
}

.content {
  display: flex;
  flex-grow: 1;
  min-height: 0;
  overflow: auto;
  position: relative;

  @include media-tablet() {
    flex-direction: column;
    min-height: unset;
    padding-bottom: 1.6em;
  }

  @include media-phone() {
    padding-bottom: 4em;

    @supports (padding: max(0px)) {
      padding-bottom: unquote('max(4em, calc(env(safe-area-inset-bottom) + 4em))');
    }
  }

  &Skeleton {
    overflow: hidden;
  }
}

.expanded {
  gap: 20px;
  flex-wrap: wrap;
  align-content: flex-start;

  .col {
    flex-grow: 0;
    min-height: 450px;
    max-height: 700px;

    @include media-tablet() {
      min-height: auto;
      height: auto;
    }

    @include media-phone() {
      height: auto;
      min-height: auto;
    }
  }
}

.col {
  position: relative;
  width: calc(50% - 1em);

  @include media-tablet {
    max-height: none;
    width: 100%;
    margin: 0 auto;
  }

  @include media-phone() {
    max-height: none;
    width: 100%;
    margin: 0 auto;
  }

  &Card {
    height: unset;
  }

  &Right {
    display: flex;
    flex-direction: column;
    margin-left: 2em;
    gap: 2em;

    @include media-tablet {
      margin-top: 1.6em;
      margin-left: 0;
      gap: 1.6em;
    }

    @include media-phone() {
      margin-left: auto;
    }
  }

  &Item {
    flex-basis: 50%;
    min-height: 0;
    flex-grow: 1;
    overflow: hidden;

    @include media-tablet() {
      flex-basis: auto;
    }

    @include media-phone() {
      flex-basis: auto;
      height: auto;
    }
  }
}

.info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1em;

  @include media-phone() {
    flex-wrap: wrap;
    margin-top: 0.8em;
    margin-bottom: 2em;
  }

  &Id {
    font-size: 1.2em;
    line-height: 1.33em;

    color: $palette-text-secondary-dark;
  }

  &Btn {
    width: 78px;
    padding: 0.75em 0 0.875em;

    text-align: center;
    color: $white;
    font-weight: 800;
    font-size: 0.8em;
    line-height: 1.375em;

    background-color: $palette-accent;
    border-radius: 4px;
    border: none;

    &:focus,
    &:hover {
      background-color: $palette-accent-light;
    }

    &:active {
      background-color: $palette-accent-dark;
    }
  }
}

.title {
  font-size: 1.6em;
  line-height: 1.375em;
  font-weight: 700;
  color: $palette-text-primary;

  @include media-phone() {
    line-height: 1.5em;
  }

  &Icon {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0 2em;
    margin-right: -2em;
    cursor: pointer;

    &Hide {
      display: none;
    }

    @include media-phone() {
      padding: 0.4em 0.4em 0 0;
    }
  }
}
