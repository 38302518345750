@import '../../../../assets/style/mixins';

.root {
  padding: 28px;
  height: 100%;
  overflow: auto;

  @include media-tablet() {
    padding: 20px 28px;
  }

  @include media-phone() {
    padding: 16px 28px;
  }
}

[class].skeleton {
  :global(.ant-skeleton-paragraph) {
    > * {
      height: 112px;
      width: 100% !important;
      margin-bottom: 17px;

      @include media-tablet() {
        margin-bottom: 12px;
      }

      @include media-phone() {
        margin-bottom: 8px;
      }

      &:nth-child(1),
      &:nth-child(3) {
        height: 19px;
        width: 129px !important;
        margin-bottom: 10px;
      }
      &:nth-child(2) {
        height: 44px;
        width: 362px !important;
        margin-bottom: 28px;

        @include media-tablet() {
          margin-bottom: 24px;
        }

        @include media-phone() {
          width: 100% !important;
          margin-bottom: 16px;
        }
      }
    }
  }
}

.name {
  width: 362px;

  @include media-phone() {
    width: 100%;
  }
}
