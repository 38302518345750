@import '../../../../../assets/style/colors';

$verticalGutter: 8px;
$horizontalGutter: 20px;

.root {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  width: 100%;
  padding: $verticalGutter $horizontalGutter;
  border: none;
  background-color: transparent;
}

.collapsePanel {
  :global(.ant-collapse-header) {
    font-weight: 400 !important;
    line-height: 19px !important;
    color: $palette-text-primary !important;
  }

  :global(.ant-collapse-content) {
    margin-top: $verticalGutter !important;
  }
}

.options {
  max-height: 204px;
  overflow-y: auto;
  overflow-x: hidden;
}

[class].checkbox {
  margin: 0 -#{$horizontalGutter} !important;
  padding: $verticalGutter $horizontalGutter;
  width: calc(100% + 2 * #{$horizontalGutter}) !important;

  &:hover {
    background-color: $palette-secondary-hover;
  }
}

.button {
  margin-top: $verticalGutter;
}
