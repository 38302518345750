@import '../../../../assets/style/mixins';

$itemVerticalPadding: 16px;

.panelTitle,
.title {
  font-size: 14px;
  line-height: 19px;
  font-weight: 700;
  color: $palette-text-primary;
}

.title {
  display: block;
  margin: 10px 0;
}

.itemWrapper:first-of-type .title {
  margin-top: 0;
}

.notCollapsedDivider {
  @include dividerBottom(0px);
}

.titleLess {
  padding: $itemVerticalPadding 0;
}

[class].collapse {
  font-size: $main-font-size;
  padding: 0 20px;
  row-gap: 0;

  &Panel {
    padding: $itemVerticalPadding 0;

    @include dividerBottom(0px);

    &:not(.select) :global(.ant-collapse-content-box) {
      max-height: 216px;
      overflow-y: auto;
      margin-right: -0.8em;
      padding-right: 0.8em;
    }
  }
}

[class].popover {
  padding: 0;

  :global {
    .ant-popover-inner-content {
      padding: 20px;
    }
  }

  &Reset {
    margin-top: 20px;
  }
}

[class].radio {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &Item:not(:last-child) {
    margin: 0 0 12px;
  }
}

@include media-tablet() {
  [class].collapse {
    padding: 0;
  }
}
