@import '../../../../../../assets/style/mixins';

[class].editorContainer {
  width: 100%;

  :global(.DraftEditor-root) {
    margin: 14px 12px 10px 0;
  }
}

.editorWrapper {
  display: flex;
  padding: 0px 16px;
  width: 100%;
}

.editorEndAdornment {
  padding: 10px 0;
  align-items: flex-end;
}

[class].column {
  & .editorWrapper {
    font-size: 16px;
    flex-direction: column;
    padding: 0 12px;
  }

  & .editorEndAdornment {
    justify-content: space-between;
    margin-left: 0;
    padding: 0 0 12px;
  }

  & .editorContainer :global(.DraftEditor-root) {
    margin: 12px 8px 16px 0;
  }
}

@include media-phone() {
  [class].editorWrapper {
    padding: 12px 12px 10px;
    flex-wrap: wrap;

    :global(.DraftEditor-root) {
      margin: 0 0 16px;
    }
  }

  .editorEndAdornment {
    justify-content: space-between;
    width: 100%;
    padding: 0;
  }
}

@include media-phone-landscape() {
  [class].editorWrapper {
    padding: 0px 16px;
    flex-wrap: nowrap;

    :global(.DraftEditor-root) {
      margin: 14px 12px 10px 0;
      order: unset;
    }
  }

  .editorEndAdornment {
    justify-content: flex-start;
    width: unset;
  }
}
