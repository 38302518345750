@import 'src/assets/style/mixins';

.infoWrapper {
  display: flex;
  flex-direction: column;
}

.infoList {
  padding: 0 0 0 14px;
  line-height: 28px;
  margin-bottom: 28px;
}

.infoIcon {
  align-self: center;
}

.infoTitle {
  align-self: center;
  margin: 28px 0 20px;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
}

.infoSubtitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 12px;
}

.infoButtonNext {
  @include button(253px, 0 auto);
}
