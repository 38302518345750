@import 'src/assets/style/mixins';

[class].root {
  width: 100%;
  padding: 0;
  overflow: hidden;

  &:nth-child(2n) {
    background-color: inherit;
  }
}

[class].checkbox {
  width: 100%;

  :global(.ant-checkbox + span) {
    flex-grow: 1;
  }
}
