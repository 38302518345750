@import '../../../../assets/style/mixins';

.root {
  margin-top: 16px;

  @include media-tablet() {
    margin-bottom: 1.6em;
    margin-top: 0;
  }
}
