@import '../../../../assets/style/colors';

.root {
  padding: 4px 8px;
  background-color: $palette-secondary;
  border-radius: 4px 4px 0 0;
  cursor: move;
  column-gap: 20px;
}

.root [class].goTo {
  color: $white;

  .icon {
    width: 20px;
    height: 20px;
  }
}

.icon {
  flex-shrink: 0;
  cursor: pointer;
  color: $white;
}
