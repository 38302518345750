@import '../../../../../assets/style/mixins';

.header {
  & > .back {
    font-weight: 600;
  }
}

.tabs {
  padding-left: 20px;

  :global(> .ant-tabs > .ant-tabs-nav) {
    margin-right: 20px;
  }
}

.tabPane {
  display: flex;
  flex-direction: column;
}

.menu {
  margin-bottom: 12px;
}

@include media-phone() {
  [class].tabs {
    & :global(.ant-tabs-nav) {
      display: none;
    }

    padding-left: 16px;
  }
}
