@import '../../../../assets/style/mixins';

.root {
  padding: 18px 28px;
  background-color: $white;
  border-radius: $main-border-radius;
  border: 1px solid $palette-stroke-primary;

  h3 {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 16px;
  }
}

.table {
  font-size: 11px;
  width: 100%;

  tbody {
    text-align: center;
    font-size: 11px;

    tr {
      border-width: 0 1px 0 0;
      background: rgba(246, 247, 250, 1);
    }
  }

  td,
  th {
    padding: 10px;
  }

  th sup {
    left: 4px;
  }

  td sup {
    top: 0;
    vertical-align: middle;
  }

  .header {
    position: sticky;
    top: 0;
    background-color: $white;
    z-index: 2;

    &Block {
      font-weight: 400;
      padding-top: 16px;

      strong {
        display: block;
        font-size: 16px;
      }
    }

    th {
      font-size: 14px;
      font-weight: 700;
      padding: 0 8px 16px;
    }

    .heading {
      font-size: 14px;
      line-height: 19px;
      color: $white;
      font-weight: 700;

      th {
        padding: 6px;
      }

      .professional {
        background-color: $palette-avatar-professional;
      }
      .business {
        background-color: $palette-avatar-business;
      }
    }
  }

  .headingRow {
    background-color: $white;
  }

  .title {
    text-align: left;
  }

  .controls {
    button {
      font-size: 14px;
    }

    button:disabled {
      width: 100%;
    }

    .info {
      padding: 0 30px;
      height: 33px;
      line-height: 33px;
      display: inline-flex;
      background-color: $palette-info-light;
      border-radius: $main-border-radius;
      color: $palette-info-dark;
    }
  }

  .highlighted {
    background-color: rgba(50, 153, 191, 0.08);
  }
}

.disabledHelp {
  pointer-events: all;
  cursor: auto;
}

@include media-tablet() {
  .root {
    padding: 20px;
  }

  .table {
    .header {
      top: $header-fixed-height-tablet;
    }

    .controls {
      .info {
        line-height: 44px;
        height: 44px;
      }

      button {
        font-size: 14px;
      }
    }
  }
}

@include media-phone() {
  .root {
    padding: 16px;
  }

  .table {
    .header {
      top: $header-fixed-height-phone;
    }

    .controls {
      button {
        font-size: 12px;
        padding: 0 36px !important;
      }
    }
  }
}
