@import '../../../../assets/style/mixins';

.root {
  width: 100%;
  height: 100%;
  min-height: 0;
  min-width: 0;
  padding-bottom: 20px;
  overflow: auto;
}

.images {
  position: relative;
}

.cover {
  margin-bottom: 42px;
  aspect-ratio: 2.2;
  border-bottom: 1px solid $palette-stroke-primary-light;
}

[class].avatar {
  position: absolute;
  left: 50px;
  bottom: -22px;
  z-index: 1;
  border-radius: 50%;
}

.content {
  padding: 0 50px;
  display: grid;
  width: 100%;
  grid-template-areas:
    'head aside'
    'user aside'
    'about aside';
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 290px;
  column-gap: 28px;
  grid-row-gap: 40px;
}

.user,
.head,
.aside,
.owner,
.actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.head {
  grid-area: head;
  row-gap: 20px;
  min-width: 0;
}

.aside {
  grid-area: aside;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.actions {
  grid-area: actions;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.owner {
  grid-area: owner;
  align-self: stretch;
  row-gap: 24px;
}

.user {
  grid-area: user;
  row-gap: 16px;
  min-width: 0;
}

.about {
  grid-area: about;
  font-size: 14px;
  line-height: 22px;
  max-width: 100%;
  overflow-wrap: anywhere;

  &Title {
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
  }
}

.action {
  align-self: stretch;
}

.title {
  color: $palette-primary-dark;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  margin: 0;
  max-width: 100%;
  overflow-wrap: anywhere;
}

[class].details {
  width: 100%;

  &Icon svg {
    color: $palette-primary-dark;
    width: 24px;
    height: 24px;
  }

  &Label {
    color: $palette-primary-dark;
    font-size: 14px;
    line-height: 19px;
    font-weight: 400;
    min-width: 0;
  }
}

.tags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}

[class].tag {
  border-radius: 4px;
  font-weight: 400;
}

@include media-tablet() {
  .content {
    padding: 0 24px;
  }

  [class].avatar {
    left: 30px;
  }
}

@include media-phone() {
  .root {
    padding: 24px 20px 40px 20px;
    overflow: unset;
  }

  .content {
    padding: 0;
    grid-template-rows: auto;
    grid-template-columns: 100%;
    grid-template-areas:
      'head'
      'user'
      'owner';

    &.withActions {
      grid-template-areas:
        'head'
        'actions'
        'user'
        'owner';
    }

    &.withAbout {
      grid-template-areas:
        'head'
        'user'
        'about'
        'owner';
    }

    &.complete {
      grid-template-areas:
        'head'
        'actions'
        'user'
        'about'
        'owner';
    }
  }

  .owner {
    row-gap: 40px;
  }

  [class].avatar {
    left: 4px;
    bottom: -20px;
    box-shadow: $white 0 0 0 3px;
  }
}
