@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  align-items: center;
  column-gap: 8px;
  font-weight: 600;
  color: $palette-text-secondary-dark;

  &Ascendant {
    color: $palette-secondary;
  }

  svg {
    width: 20px;
    height: 20px;
    color: $palette-secondary;
  }

  span {
    font-size: 12px;
    line-height: 22px;
  }

  &.disabled {
    svg {
      color: $palette-text-secondary-dark;
    }
  }

  &.gutter {
    margin-bottom: 12px;
  }
}
