@import '../../../../../assets/style/colors';

.root {
  top: 100%;
  width: 100%;
  margin-top: 4px;
  font-size: 12px;
  line-height: 16px;

  &.absolute {
    position: absolute;
  }

  &.static {
    position: static;
  }

  &.left {
    text-align: left;
    left: 0;
  }

  &.right {
    text-align: right;
    right: 0;
  }
}
