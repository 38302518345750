@import '../../../../assets/style/mixins';

.root {
  display: flex;
  align-items: center;
  min-width: 189px;
  padding: 4px 16px;
  margin-right: 50px;
  margin-left: auto;
  color: $white;
  border-radius: $main-border-radius;
  background-color: rgba($white-rgb, 0.1);
  cursor: pointer;

  &Active {
    background-color: $palette-secondary-light;
  }
}

.content {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  padding-bottom: 4px;
  min-width: 0;
}

@include media-tablet() {
  .root {
    min-width: 182px;
    margin-right: 40px;
  }
}

@include media-phone() {
  .root {
    min-width: 112px;
    padding: 8px;
    margin-right: 16px;
  }

  .content {
    padding: 0;
    margin-left: 8px;

    &CallWidget {
      flex-direction: row;
      align-items: center;
    }
  }

  .icon {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
  }
}
