@import '../../../../../assets/style/mixins';

[class].wrapper {
  flex-shrink: 0;
  width: 100%;
  padding: 16px 20px;
}

.avatarContainer {
  margin-right: 20px;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  overflow: hidden;
  min-width: 0;
}

.innerContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  &:first-child {
    margin-bottom: 4px;
  }
}

.innerRight {
  display: flex;
  align-items: center;
  padding-left: 4px;
}

.message,
.name {
  font-size: 1.4em;
  line-height: 1.357;
}

.message {
  padding-right: 50px;
  white-space: nowrap;
  text-align: left;

  @include ellipsis();
}

.name {
  max-width: 100%;
  font-weight: bold;
  text-transform: capitalize;
}

[class].typingIndicator {
  font-size: 14px;
  line-height: 19px;
  color: $palette-text-primary;

  &,
  * {
    font-weight: normal;
  }
}

.active {
  [class].time {
    color: $white;
  }

  [class].count {
    background-color: $white;
    color: $palette-primary;
  }

  .icon {
    color: $white;
  }

  [class].typingIndicator {
    color: $white;
  }
}

.badgeList {
  display: flex;
  align-items: center;
  column-gap: 4px;
  min-width: 0;
  flex-shrink: 0;
}

.skeleton {
  margin: 0;

  &:global(.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title) {
    margin-top: 0;

    & + :global(.ant-skeleton-paragraph) {
      margin-top: 0;
    }
  }

  :global(.ant-skeleton-content .ant-skeleton-paragraph) {
    margin-bottom: 0;
  }
}

.title {
  display: flex;
  align-items: center;
  min-width: 0;
  column-gap: 8px;
}

.icon {
  width: 20px;
  height: 20px;
  color: $palette-primary;
  flex-shrink: 0;

  &.coworker {
    color: $palette-text-secondary-dark;
  }
}

@include media-tablet() {
  .avatarContainer {
    margin-right: 12px;
  }

  .message,
  .name {
    font-size: 1.3em;
    line-height: 1.384;
  }

  .active {
    [class].time {
      font-size: 1.2em;
      line-height: 1.333;
    }
  }
}

@include media-phone() {
  [class].wrapper {
    padding: 12px 16px;
  }
}
