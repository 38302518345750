@import '../../../../../assets/style/mixins';

.popover {
  :global(> .ant-popover-content > .ant-popover-inner > .ant-popover-inner-content) {
    padding: 0;
    width: 320px;
    display: flex;
    flex-direction: column;
    height: calc(64vh);
  }

  :global(> .ant-popover-content > .ant-popover-inner) {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  }

  &FewComments {
    :global(> .ant-popover-content > .ant-popover-inner > .ant-popover-inner-content) {
      height: 404px;
    }
  }

  &OneFile {
    :global(> .ant-popover-content > .ant-popover-inner > .ant-popover-inner-content) {
      height: 72vh;
    }
  }
}

@include media-tablet() {
  .popover {
    :global(> .ant-popover-content > .ant-popover-inner > .ant-popover-inner-content) {
      height: calc(54vh);
    }

    &OneFile {
      :global(> .ant-popover-content > .ant-popover-inner > .ant-popover-inner-content) {
        height: 70vh;
      }
    }
  }
}

@include media-phone() {
  .popover {
    left: 0 !important;
    right: 0 !important;
    top: 0 !important;
    bottom: 0 !important;
    transform-origin: unset !important;
    position: fixed !important;
    height: 100%;
    width: 100%;

    :global(> .ant-popover-content) {
      width: 100%;
      height: 100%;
    }

    :global(> .ant-popover-content > .ant-popover-inner) {
      border-radius: unset;
      height: 100%;
      width: 100%;
    }

    &,
    &FewComments {
      :global(> .ant-popover-content > .ant-popover-inner > .ant-popover-inner-content) {
        height: 100%;
        width: 100%;
      }
    }
  }
}
