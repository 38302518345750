@import '../../../../../assets/style/mixins';

.root {
  color: $white;
  font-size: 10px;
  margin-bottom: 8px;
  display: block;
  width: 100%;
  padding-right: 1.6em;
}
