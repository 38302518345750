@import '../../../../assets/style/mixins';

.root {
  width: 400px;
  height: 60vh;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  padding: 16px 0 0;
}

[class].tabs {
  width: auto;

  :global(.ant-tabs-nav) {
    padding: 0 24px;
    margin-bottom: 12px;
  }

  :global(.ant-tabs-tab) {
    width: auto;
  }

  :global(.ant-tabs-tabpane) {
    display: flex;
    flex-direction: column;
  }
}

[class].readAllButton {
  margin: 0 16px 12px auto;
  font-weight: 600;
}

.tab {
  display: flex;
  align-items: baseline;
  justify-content: center;

  &Badge {
    position: relative;
    top: -4px;
  }
}

@include media-phone() {
  .root {
    width: 100%;
  }
}
