@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  font-size: 14px;
  line-height: 22px;
  gap: 42px;
}

.content {
  margin-bottom: 12px;
}

.title {
  font-size: 16px;
  line-height: 22px;
  margin: 0 0 8px;
  font-weight: 700;
}

.setUp {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  @include media-phone() {
    gap: 33px;
    flex-direction: row;
  }
}

.amount {
  font-weight: 700;
  color: $palette-secondary-dark;
}

.skeleton {
  li {
    height: 100px !important;
  }
}

@include media-phone() {
  .skeleton {
    li {
      width: 100% !important;
      height: 140px !important;
    }
  }
}
