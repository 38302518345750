@import '../../../../../assets/style/mixins';

.root {
  padding: 20px;
}

.title {
  margin-bottom: 12px;
}

.author {
  margin-bottom: 18px;
}

@include media-phone() {
  .root {
    padding: 16px;
  }
}
