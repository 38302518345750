@import 'src/assets/style/mixins';

.actions {
  margin-top: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

@include media-phone() {
  .actions {
    width: 100%;
    align-items: center;
  }
}
