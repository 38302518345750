.root {
  display: flex;
  flex-direction: column;

  p {
    text-align: center;
    margin-bottom: 28px;
  }
}

.content {
  margin-bottom: 50px;
}

.customContent {
  margin-bottom: 28px;
}
