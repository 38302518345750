$header-fixed-height: 70px;
$header-fixed-height-tablet: 64px;
$header-fixed-height-phone: 50px;
$sider-fixed-width: 100px;
$sider-fixed-width-tablet: 75px;
$sider-fixed-height-phone: 49px;
$bread-crumbs-height: 62px;
$bread-crumbs-height-tablet: 55px;
$nav-menu-height-tablet: 45px;

$chat-header-desktop: 64px;
$chat-header-phone: 50px;
$chat-files-tabs-height: 65px;

$main-font-size: 10px;
$main-padding: 3.1em;
$main-border-radius: 4px;
$secondary-border-radius: 2px;
$chat-message-border-radius: 20px;

$zIndex-secondary-menu-notification: 10;
$zIndex-suggestion-popover: 20;
$zIndex-nav: 100;
$zIndex-popup: 110;
$zIndex-context-nav: 150;
$zIndex-nav-bar: 2000;
$zIndex-header: 2010;
$zIndex-chat-header: 3010;
$zIndex-chat-header-content: 3011;
$zIndex-modal: 3090;
$zIndex-date-picker: 3091;
$zIndex-popover: 3092; // must be higher than modal
$zIndex-call: $zIndex-modal - 1;
$zIndex-push_notification: $zIndex-modal + 1;
$zIndex-received-call: $zIndex-modal + 2;

$chat-editor-margin-top: var(--chat-editor-margin-top);

$page-sub-navigation-width-tablet: 101px;
$layout-padding: 16px;

$chat-details-margin-desktop: 28px;
$chat-details-margin-tablet: 20px;
$chat-details-margin-mobile: 16px;
