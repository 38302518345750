@import '../../../../assets/style/colors';

[class].chip {
  font-weight: 400;
  padding: 0 8px;
  font-size: 14px;
  line-height: 31px;
  border-radius: 4px;
}

.icon {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin-left: 2px;
  cursor: pointer;
}
