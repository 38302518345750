@import '../../../../assets/style/mixins';

[class].root {
  display: flex;
  align-items: center;
  padding: 2px;  
}

:export {
  trailColor: $palette-stroke-primary;
  strokeColor: $palette-success-dark;
}
