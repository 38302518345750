@import "../../../../../assets/style/colors";
@import "../../../../../assets/style/variables";


.root {
  font-size: 14px;
  line-height: 1.571em;
  color: $palette-text-primary;
  white-space: pre-wrap;
}
