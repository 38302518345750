@import '../../../../assets/style/mixins';

.scrollContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 0;
}

.scrollInner {
  position: relative;
  padding-right: 0.8em;
  overflow: hidden scroll;
  display: flex;
  flex-direction: column;
  min-height: 0;
  flex-grow: 1;

  &Skeleton {
    overflow: hidden;
  }
}

@include media-phone() {
  .scrollInner {
    padding-right: 4px;
  }
}
