@import '../../../../../assets/style/mixins';

[class].root {
  display: flex;
  align-items: center;
  column-gap: 8px;
  padding: 9px 20px;
  cursor: pointer;
  font-size: 12px;

  &.active {
    background-color: $palette-secondary-dark;
    color: $white;

    .time,
    .mention {
      color: $white;
    }
  }

  &:hover:not(.active) {
    background-color: $palette-secondary-hover;
  }
}

.content {
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  flex-grow: 1;
  min-width: 0;
}

[class].mention {
  font-size: 12px;
}

.senderName {
  font-weight: 700;
}

.time {
  flex-shrink: 0;
  color: $palette-text-secondary-dark;
}
