@import '../../../../../assets/style/mixins';

.root {
  :global(.ant-skeleton) {
    overflow: hidden;
  }
}

.item {
  min-height: 550px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  background-color: $white;
  border-radius: $main-border-radius;
  border: 1px solid $palette-stroke-primary;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex-grow: 1;
}

.row {
  display: flex;
}

.iconButton {
  width: 24px;
  height: 24px;
  margin-right: 12px;

  @include media-tablet() {
    width: 28px;
    height: 28px;
  }
}

.avatar {
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  margin-right: 8px;
  border-radius: 50%;
}

.accountName {
  display: flex;
  gap: 4px;
  flex-direction: column;
}

.accountNameTop {
  display: flex;
  gap: 4px;
}

.text {
  width: 148px;
  height: 16px;
}

.outlinedIconButton {
  width: 33px;
  height: 33px;
  border-radius: 50%;
  flex-shrink: 0;
}

.icon {
  width: 16px;
  height: 16px;
}

.statusContainer {
  display: flex;
  gap: 16px;
  margin-left: auto;
}

.status {
  width: 93px;
  height: 24px;
  margin-left: auto;
}

.title {
  height: 96px;
  width: 100%;

  @include media-tablet() {
    height: 24px;
  }
}

.chip {
  width: 171px;
  height: 28px;
  margin-right: 12px;
}

.description {
  height: 193px;
  width: 100%;

  @include media-tablet() {
    height: 76px;
  }
}

.gallery {
  width: 100%;
  height: 100%;

  @include media-tablet() {
    height: 310px;
  }
}

.content {
  padding: 20px;
  width: 50%;
  max-width: 50%;

  @include media-tablet() {
    width: 100%;
    max-width: 100%;
    order: 2;
    padding-top: 0;
  }
}

.footer {
  margin-top: auto;
}
