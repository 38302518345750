@import '../../../../assets/style/mixins';

$max_links_length: 10;

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 76px;
  transition: height 0.75s;

  &:nth-last-child(n + 2) {
    border-bottom: 1px solid $palette-stroke-primary;
  }

  &:nth-of-type(#{$max_links_length}) {
    border-bottom: none;
  }

  &WErr {
    height: 86px;

    @include media-phone() {
      height: unset;
    }
  }

  @include media-phone() {
    flex-direction: column;
    height: unset;
  }
}

.info {
  display: flex;
  align-items: center;

  &WErr {
    align-self: flex-start;
    padding-top: 24px;

    @include media-phone() {
      padding: 0;
    }
  }

  @include media-phone() {
    width: 100%;
    justify-content: center;
    margin: 1.6em 0;
  }
}

.icon {
  margin-right: 8px;
  width: 32px;
  height: 32px;
}

.iconText {
  font-size: 1.4em;
  line-height: 1.357em;
  font-weight: bold;
  color: $palette-text-primary;
}

.details {
  display: flex;
  align-items: center;
  min-width: 0;

  &WErr {
    padding-top: 1.8em;
    align-self: flex-start;
    align-items: flex-start;

    @include media-phone() {
      padding: 0;
    }
  }

  @include media-phone() {
    position: relative;
    justify-content: center;
    width: 100%;
    margin-bottom: 24px;
  }
}

[class].closeIcon {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin-left: 16px;
  color: $palette-text-primary;
  cursor: pointer;

  &WErr {
    margin-top: 11px;

    @include media-phone() {
      margin: 0;
    }
  }

  @include media-phone() {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;

    &WForm {
      top: 13px;
      transform: unset;
    }
  }
}

.form {
  display: flex;
  align-items: flex-start;

  @include media-phone() {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}

.field {
  display: flex;
  flex-wrap: wrap;
  width: 227px;

  @include media-phone() {
    width: 100%;
    padding-right: 3.2em;
  }

  &WErr {
    justify-content: flex-end;
  }
}

.submitBtn {
  padding: 0.79em 0;
  margin-left: 0.79em;
  font-weight: 600;
  font-size: 1.4em;
  line-height: 1.357em;
  color: $palette-primary;
  border: none;
  background-color: transparent;
  cursor: pointer;

  @include media-phone() {
    margin: 1.25em 0 0;
    padding: 0;
    font-size: 1.6em;
    line-height: 1.375em;
  }

  &:disabled {
    color: $palette-text-secondary-dark;
    cursor: default;
  }
}

.urlText {
  width: 100%;
  max-width: 36ch;

  text-align: right;
  font-size: 1.4em;
  line-height: 1.357em;

  @include media-phone() {
    padding: 0 30px;
    text-align: center;
  }
}
