@import '../../../../../../assets/style/colors';

.root {
  color: $white;
  font-size: 10px;
  font-weight: 700;
  line-height: 14px;
}

.outgoing.messageOutgoing {
  color: $palette-secondary;
}

.count {
  margin-right: 4px;
}
