@import '../../../../assets/style/colors';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: rgba($palette-secondary-light-rgb, 0.1);
  width: 60px;
  height: 60px;

  svg {
    width: 32px;
    height: 32px;
    color: $palette-secondary-dark;
  }
}
