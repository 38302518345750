@import '../../../../../assets/style/mixins';

.guest {
  column-gap: 8px;
}

.organizer {
  gap: 12px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
}

.wrapper {
  display: inline-flex;
  align-items: center;
  padding: 8px 12px 8px 8px;
  border-radius: 30px;
  gap: 14px;
  background-color: rgba($white-rgb, 0.2);
}
