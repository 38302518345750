@import '../../../../../assets/style/colors';

.root {
  &:global(.ant-collapse) {
    border: none;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    row-gap: 12px;

    & > :global(.ant-collapse-item) {
      border: none;

      & > :global(.ant-collapse-header) {
        padding: 0;
        align-items: center;

        & > div:first-of-type {
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          order: 1;
          margin-left: auto;
        }

        :global(.ant-collapse-arrow) {
          position: relative;
          top: unset;
          right: unset;
          left: unset;
          padding: 0;
          transform: unset;
          transition: transform 0.24s;
        }
      }

      &:global(.ant-collapse-item-disabled > .ant-collapse-header) {
        & > div:first-of-type {
          display: none;
        }
      }

      .active:global(.ant-collapse-arrow) {
        transform: rotate(-180deg);
      }
    }
  }

  &.highlighted {
    :global(.ant-collapse-item-disabled .ant-collapse-arrow) {
      display: none !important;
    }
  }

  :global(.ant-collapse-content) {
    border-top: none;

    & > :global(.ant-collapse-content-box) {
      padding: 8px 0 0;
    }
  }
}
