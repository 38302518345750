@import 'src/assets/style/colors';

.wrapper {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
  margin-top: 10px;
}

.icon {
  margin-right: 5px;
  width: 18px;
  height: 18px;

  color: $palette-secondary-dark;
}
