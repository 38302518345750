@import 'src/assets/style/mixins';

.root {
  background-color: $white;
  padding: 16px 0 0;

  @include dividerBottom(0px);
}

@include media-tablet() {
  .root {
    border: 1px solid $palette-stroke-primary;
    border-top: none;
    border-bottom: none;
    padding: 16px 20px 0;

    @include dividerBottom(20px);
  }
}

@include media-phone() {
  .root {
    padding: 16px 16px 0;

    @include dividerBottom(16px);
  }
}
