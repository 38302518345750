.root {
  position: relative;

  &.collapsed {
    > svg {
      width: 16px;
      height: 16px;
    }
  }
}

.badge {
  position: absolute;
  top: 0;
  right: 0;
}
