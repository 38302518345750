@import 'src/assets/style/mixins';

.root {
  padding: 16px;
  font-size: $main-font-size;
  border: 1px solid $palette-stroke-primary;
  border-radius: 4px;
}

.rowGap {
  column-gap: 12px;
}

.header {
  justify-content: space-between;
  margin-bottom: 6px;
}

.link,
.unlink {
  display: flex;
  align-items: center;
  font-size: 1.2em;
  line-height: 1;
  font-weight: 600;
  cursor: pointer;

  svg {
    height: auto;
    margin-left: 6px;
  }
}

.unlink {
  color: $palette-text-secondary-dark;

  svg {
    width: 14px;
  }
}

.link {
  color: $palette-secondary;

  svg {
    width: 16px;
  }
}

.title {
  display: inline-block;
  font-size: 14px;
  line-height: 22px;
  font-weight: bold;
  color: $palette-text-primary;
  margin-bottom: 8px;
  word-break: break-word;
  word-wrap: break-word;

  &.clickable {
    cursor: pointer;
  }
}

.account {
  &Root {
    width: unset;
  }

  &Name {
    font-weight: 600;
  }

  &Owner {
    font-size: 12px;
    line-height: 16px;
    color: $palette-text-secondary-dark;
    font-weight: normal;
  }
}

[class].status {
  line-height: 1.333;
}

[class].skeleton {
  &:global(.ant-skeleton) {
    padding: 16px;
    border: 1px solid $palette-stroke-primary;
    border-radius: 4px;
  }

  & :global(.ant-skeleton-title) {
    margin-top: 0;
    margin-bottom: 8px;
  }

  & :global(.ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph) {
    margin-top: 0;
  }

  & :global(.ant-skeleton-paragraph) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0;

    & li {
      margin-top: 9px;

      &:nth-child(2) {
        width: 20px;
        height: 20px;
        margin-right: 8px;
        border-radius: 50%;
      }

      &:nth-child(3) {
        width: 90%;
      }

      &:nth-child(4) {
        width: 30%;
      }
    }
  }
}

.filesCount {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: $palette-text-secondary-dark;
}
