@import '../../../../../assets/style/colors';

.orderNumber {
  bottom: 8px;
  right: 8px;
}

.size {
  position: absolute;
  left: 8px;
  bottom: 8px;
  padding: 2px 4px;
  border-radius: 2px;
  background-color: rgba($palette-text-primary-rgb, 0.6);
  color: $white;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
}

.thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.placeholder {
  width: 100%;
  height: 100%;    
}

.play {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  color: $white;
  border-radius: 50%;
  background-color: $palette-secondary;

  svg {
    width: 16px;
    height: 16px;
  }
}

.fullScreenButton {
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 2;
}
