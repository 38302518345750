@import '../../../../assets/style/mixins';

.root {
  display: flex;
  height: 100%;
}

.content {
  width: 50%;
  flex-shrink: 0;
  overflow: auto;
  padding: 40px;
  background-color: $white;
  border-radius: $main-border-radius;
  border: 1px solid $palette-stroke-primary;

  header {
    margin-bottom: 30px;
  }

  h2 {
    font-weight: 700;
    font-size: 28px;
    margin-bottom: 20px;
  }

  h3 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 12px;
  }

  p {
    font-size: 14px;
    margin: 0;
  }
}

.list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 16px;

  svg,
  h3 {
    color: $palette-secondary;
  }

  svg {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
  }

  li {
    display: flex;
    flex-shrink: 0;
    padding: 24px;
    gap: 14px;
    border-radius: $main-border-radius;
    background-color: $palette-layout-background;
  }
}

.aside {
  width: 50%;
  height: 100%;

  > * {
    margin: 0 auto;
    width: 280px;
  }
}

.topBar {
  padding: 20px;
  background-color: $white;
  border-radius: $main-border-radius;
  border: 1px solid $palette-stroke-primary;

  &Title {
    text-align: left;
  }
}

@include media-tablet() {
  .root {
    gap: 24px;
    flex-direction: column;
  }

  .content {
    order: 1;
    width: 100%;
    padding: 30px;

    header {
      margin-bottom: 24px;
    }
  }

  .aside {
    height: auto;
    width: 100%;

    > * {
      margin: 0;
      width: auto;
    }
  }
}

@include media-phone() {
  .content {
    padding: 20px 16px;
  }

  .list {
    svg {
      width: 24px;
      height: 24px;
    }

    h3 {
      gap: 8px;
      width: 100%;
      justify-content: center;
      display: inline-flex;
      align-items: center;
    }
  }
}
