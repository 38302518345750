@import '../../../../assets/style/mixins';

.root {
  position: relative;
  display: flex;
  margin: 0 auto;
  min-width: 0;
  flex-grow: 1;
  width: 100%;
  min-height: 0;
  background: $white;
  border-radius: 4px;

  @include media-phone() {
    flex-direction: column;
    position: static;
  }
}

.listContainer {
  min-width: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-right: 1px solid $palette-stroke-primary;
  position: relative;

  @include media-phone() {
    border-right: 0;
  }
}

.roomContainer {
  position: relative;
  display: flex;
  width: 100%;
  min-width: 288px;

  @include media-phone() {
    position: static;
    flex-grow: 1;
  }
}

.backButton {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: $main-font-size;
  line-height: 1.4;
  color: $palette-secondary;
  margin: 0.8em 0;
  text-transform: uppercase;

  @include media-phone() {
    padding-left: 1.25em;
    margin: 0;
    justify-content: flex-start;
    font-size: 1.2em;
    line-height: 1.333;
  }

  &Icon {
    width: 15px;
    height: 15px;
    height: auto;
    margin-right: 2px;
    color: $palette-secondary;
  }
}
