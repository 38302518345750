@import '../../../../../assets/style/mixins';

:global(.ant-skeleton-content) {
  [class].paragraph {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;

    li {
      &:nth-last-child(1),
      &:nth-last-child(2) {
        width: 30% !important;
        margin-bottom: 0;
        height: 40px;
      }

      &:nth-last-child(1) {
        margin-left: auto;

        @include media-phone() {
          display: none;
        }
      }

      &:nth-last-child(2) {
        @include media-phone() {
          width: 100% !important;
        }
      }
    }
  }

  [class].title {
    margin-top: 0;
  }
}
