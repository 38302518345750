@import '../../../../assets/style/colors';
@import '../../../../assets/style/mixins';

.root {
  display: flex;
  justify-content: center;
  position: relative;
  flex-grow: 1;
  min-height: 0;
}

.container {
  width: 100%;
  height: 100%;
  overflow-y: hidden;

  canvas {
    margin: 0 auto;
  }

  :global(.react-pdf__message) {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

[class].placeholder {
  background: $white;
  padding: 0 20px;
}

[class].downloadButton {
  font-size: 14px;
  line-height: 24px;
}
