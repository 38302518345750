@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.icon {
  width: 20px;
  height: 20px;
  margin-right: 4px;
  color: $palette-text-secondary-dark;
  flex-shrink: 0;
}

.link {
  max-width: 19ch;

  font-size: 14px;
  line-height: 1.357;
  text-decoration-line: underline;
  color: $palette-primary;
  cursor: pointer;

  @include ellipsis();
}
