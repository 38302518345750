@import '../../../../../../../assets/style/mixins';

[class].root {
  display: flex;
  background-color: $white;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: $palette-text-primary;
  padding: 6px 16px;

  &.focused {
    background-color: $palette-secondary-hover;
  }

  &.selected {
    background-color: rgba($palette-secondary-rgb, 0.08);
  }

  &.disabled {
    color: $palette-text-secondary-dark;
    background: none;

    &:hover {
      background: none;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background-color: $palette-secondary-hover;
  }
}

.selectedIcon {
  margin-left: auto;
  color: $palette-secondary;
}

@include media-phone() {
  [class].root {
    padding: 10px 16px;
  }
}
