@import '../../../../assets/style/mixins';

.root {
  overflow: hidden scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &:hover {
    -ms-overflow-style: block;
    scrollbar-width: auto;
    padding-right: 0.4em;

    &::-webkit-scrollbar {
      display: block;
    }
  }
}

@supports (-moz-appearance: none) {
  .root:hover {
    padding-right: 1.2em;
  }
}

.main {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  margin-right: -1.2em;
  padding-right: 1.2em;

  &Empty {
    &:hover {
      padding-right: 0;
      overflow: unset;
    }
  }
}

.aside {
  min-width: 0;
  flex-shrink: 0;
  width: 23.8%;
  max-width: 286px;
  margin-right: 0.8em;
  padding-right: 1.2em;
}

@include media-tablet() {
  .root {
    -ms-overflow-style: block;
    scrollbar-width: auto;
    padding-right: 0;

    &:hover {
      padding-right: 0;
    }

    &::-webkit-scrollbar {
      display: block;
    }
  }

  @supports (-moz-appearance: none) {
    .root:hover {
      padding-right: 0;
    }
  }

  .main {
    margin-right: 0;
    // TODO: Remove margin-bottom as updates all mobile list design
    // add safe-area-inset-bottom to scrollable component
    margin-bottom: 1.6em;
    padding-right: 0;
    overflow: unset;
  }

  .aside {
    width: auto;
    max-width: unset;
    overflow: unset;
    margin-right: 0;
    padding-right: 0;
  }
}

@include media-phone() {
  .main {
    height: unset;
    margin-right: 0;
    padding-right: 0;
    overflow: unset;
  }

  .aside {
    min-width: 202px;
  }
}
