@import '../../../../../assets/style/colors';

.error {
  top: 8px;
  right: 8px;
  box-shadow: unset;
  color: $palette-error-dark;

  svg {
    width: 24px;
    height: 24px;
  }
}

.icon {
  width: 30px;
  height: 30px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 8px;
}

.button {
  font-weight: 600;
  flex-grow: 1;
}
