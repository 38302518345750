@import '../../../../assets/style/colors';

.root {
  &.allOpinions:first-child {
    .date {
      display: none;
    }

    :global(.ant-skeleton-content .ant-skeleton-title) {
      display: none;
    }
  }
}

.opinionCreator {
  font-weight: 600;
  margin-right: 8px;
}

.date {
  margin-right: 24px;
  text-align: right;
}

.info {
  padding: 12px 16px;
  margin-bottom: 12px;
  border-radius: 0 4px 4px;
  background-color: $palette-text-secondary-light;
  font-size: 13px;
  line-break: anywhere;

  .root:last-child & {
    margin-bottom: 0;
  }
}

.skeleton {
  margin-bottom: 16px;

  :global(.ant-skeleton-content .ant-skeleton-title) {
    margin-top: 0;
    margin-left: auto;
  }

  :global(.ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph) {
    margin-top: 0;
    margin-bottom: 8px;
  }
}

.divider {
  margin: 1.2em auto;

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }
}
