@import '../../../../../assets/style/mixins';

.root {
  margin-left: 16px;
  color: $palette-text-secondary-dark;
  cursor: pointer;
}

@include media-phone {
  .root {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
  }
}
