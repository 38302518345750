@import '../../../../assets/style/colors';

.root {
  display: flex;
  align-items: flex-end;
  flex-shrink: 0;
}

.price {
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  color: $palette-secondary-dark;
}

.duration {
  font-size: 12px;
  line-height: 20px;
  color: $palette-text-secondary-dark;
  text-transform: lowercase;
}
