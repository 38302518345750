@import '../../../assets/style/mixins';

.dropRoot {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 0;

  &::after {
    display: none;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: rgba(50, 153, 191, 0.08);

    border: 2px solid $palette-secondary;
  }

  &.dropRootActive::after {
    display: block;
  }

  .onDropAlert {
    position: absolute;
    bottom: -17px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 11;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    min-width: 407px;
    padding: 18px 50px;
    min-height: 84px;

    background: $palette-secondary-light;
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.15);
    border-radius: 4px;

    opacity: 0;
    visibility: hidden;
    transition: all 250ms;

    &.onDropAlertShow {
      opacity: 1;
      visibility: visible;
      transition: all 300ms;
    }
  }

  .onDropAlertCenter {
    bottom: unset;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .onDropAlertCenter.onDropAlertFixed {
    position: fixed;
  }

  .onDropIcon {
    margin-bottom: 10px;
    color: $white;
    width: 28px;
    height: 28px;
  }

  .onDropText {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;

    text-align: center;

    color: #ffffff;
  }

  &.fixedLayout {
    &::after {
      @include media-tablet() {
        z-index: $zIndex-header + 1;
        position: fixed;
        left: calc(#{$sider-fixed-width-tablet} + #{$layout-padding});
        right: $layout-padding;
        bottom: $layout-padding;
        top: calc(#{$header-fixed-height-tablet} + #{$layout-padding});
      }

      @include media-phone() {
        left: $layout-padding;
        right: $layout-padding;
        bottom: calc(#{$sider-fixed-height-phone} + #{$layout-padding});
        top: calc(#{$header-fixed-height-phone} + #{$layout-padding});
      }
    }

    .onDropAlert {
      min-width: 582px;

      @include media-tablet() {
        position: fixed;
        margin: 0 38px;
        left: calc(#{$sider-fixed-width-tablet} + #{$layout-padding});
        right: $layout-padding;
        transform: none !important;
      }

      @include media-phone() {
        min-width: unset;
        margin: 0 20px;
        left: $layout-padding;
      }
    }
  }
}
