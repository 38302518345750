@import '../../../../assets/style/mixins';

.root {
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  max-height: 100%;
  padding: 50px 50px 30px 14px;
  overflow-y: auto;
}

.wrapper {
  display: flex;
  column-gap: 12px;
  width: 100%;
}

.content {
  min-width: 0px;
  flex-grow: 1;
}

.alert {
  margin-bottom: 12px;
}

.submitButton {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 24px;
  margin-top: 36px;
}

@include media-tablet() {
  .root {
    padding: 16px 24px 30px 16px;
  }

  .alert {
    margin-bottom: 24px;
  }

  .avatar {
    margin-bottom: 30px;
  }
}

@include media-phone() {
  .root {
    padding: 12px 16px 24px;
  }

  .alert {
    margin-bottom: 8px;
  }

  .avatar {
    margin-bottom: 14px;
  }
}
