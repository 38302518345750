@import '../../../../assets/style/mixins';

.root {
  gap: 19px;
  font-size: 16px;
  line-height: 22px;
}

.footer {
  font-size: 14px;
  line-height: 19px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  column-gap: 16px;

  &Content {
    width: 100%;
  }
}

.buttons {
  display: flex;
  gap: 19px;

  button {
    padding: 0;
  }

  svg {
    width: 16px;
    height: 16px;
  }
}

.location {
  display: flex;  
  color: $palette-text-secondary-dark;
  column-gap: 8px;
  margin-bottom: 8px;

  span {
    display: block;
    min-width: 0;

    a {
      display: inline-block;
      max-width: 100%;
      overflow-wrap: break-word;
    }
  }

  &Icon {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
  }
}

@include media-phone() {
  .footer {
    row-gap: 12px;
    flex-direction: column;
    align-items: flex-start;
  }
}
