@import '../../../../../assets/style/mixins';

[class].root {
  height: unset;
  flex-grow: 1;

  & :global(.ant-tabs) {
    font-size: inherit;
    overflow: unset;
  }

  & :global(.ant-tabs-tabpane) {
    display: flex;
    flex-direction: column;
  }
}

@include media-phone() {
  [class].root {
    & :global(.ant-tabs-nav) {
      display: none;
    }

    & :global(.ant-tabs-tabpane) {
      margin-top: 20px;
      overflow: hidden scroll;
      min-width: 0;
      flex-grow: 1;

      @include drawerRightScrollPadding();
    }
  }
}
