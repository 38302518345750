@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  gap: 14px;
  align-items: center;
  width: 100%;
}

@include media-phone() {
  .root {
    gap: 8px;
    align-items: flex-start;
    flex-direction: column;
  }
}
