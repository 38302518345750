@import '../../../../assets/style/mixins';

.root {
  display: flex;
  flex-wrap: wrap;
  height: 100px;
  gap: 8px;
  overflow: auto;
  padding: 16px 12px;
  align-items: flex-start;
  border-radius: $main-border-radius;
  border: 1px solid $palette-stroke-primary-dark;
}

.error {
  margin-bottom: 4px;
  border-color: $palette-error;
}
