@import '../../../../assets/style/mixins';

[class].hide {
  visibility: hidden;
  opacity: 0;
}

.avatarContainer {
  align-self: flex-end;
  margin-right: 12px;
}

.message {
  position: relative;
  margin-right: 70px;
  padding: 8px 12px;
  font-size: $main-font-size;
  border-radius: 19px;
  background: $palette-stroke-primary-light;
  transition: max-width 0.3s;
  min-width: 0;

  &Outgoing {
    margin-left: 54px;
    margin-right: 0;
    background-color: $palette-secondary-dark;
  }

  &Deleted {
    padding: 8px 12px;
  }

  &Image {
    min-width: unset;
    padding: 0;
    margin: 0;
    background: transparent;
    cursor: pointer;
  }

  &Container {
    position: relative;
    display: flex;
    justify-content: space-between;
  }

  &Call {
    padding-top: 8px;
  }

  &System {
    background: transparent;
    margin: 12px auto;
    padding: 0;
    border: none;
  }

  &Pinned {
    background: transparent;
    margin: 12px auto;
    padding: 0;
    border: none;
  }

  &Request {
    padding: 0;
    width: 100%;
    max-width: 100%;
    background-color: inherit;
    margin: 8px 0;
  }

  &Meeting {
    padding: 20px;
    background: $white;
    border: solid 1px $palette-stroke-primary;
    max-width: 446px;

    &.messageOutgoing {
      background-color: $palette-secondary-dark;
      border-color: transparent;
    }

    &OwnPending {
      padding-right: 4px;
    }
  }

  &MeetingSystem {
    background: transparent;
    padding: 16px 20px 20px;
    border: solid 1px $palette-secondary-light;
    margin: 0;
    width: 100%;
  }

  &Tail::before {
    content: '';
    position: absolute;
    bottom: -2px;
    left: -9px;
    width: 24px;
    height: 18px;
    background: $palette-stroke-primary-light;
    clip-path: polygon(
      38.143% 0%,
      97.884% 0%,
      97.884% 0%,
      96.903% 16.221%,
      94.061% 31.608%,
      89.513% 45.956%,
      83.413% 59.059%,
      75.917% 70.711%,
      67.178% 80.706%,
      57.351% 88.838%,
      46.59% 94.902%,
      35.05% 98.691%,
      22.884% 100%,
      1.304% 100%,
      1.31% 99.934%,
      1.31% 99.934%,
      1.832% 99.683%,
      2.349% 99.425%,
      2.861% 99.159%,
      3.368% 98.885%,
      3.87% 98.604%,
      4.368% 98.315%,
      4.862% 98.018%,
      5.351% 97.713%,
      5.836% 97.4%,
      6.317% 97.078%,
      6.317% 97.078%,
      9.547% 94.71%,
      12.646% 92.085%,
      15.607% 89.214%,
      18.421% 86.107%,
      21.082% 82.775%,
      23.581% 79.227%,
      25.911% 75.475%,
      28.064% 71.527%,
      30.033% 67.395%,
      31.809% 63.088%,
      31.809% 63.088%,
      33.287% 58.757%,
      34.515% 54.146%,
      35.518% 49.196%,
      36.319% 43.846%,
      36.939% 38.037%,
      37.402% 31.708%,
      37.731% 24.8%,
      37.949% 17.253%,
      38.079% 9.006%,
      38.143% 0%
    );
  }

  &OutgoingTail::before {
    background-color: $palette-secondary-dark;
    right: -9px;
    left: unset;

    clip-path: polygon(
      61.857% 0%,
      2.116% 0%,
      2.116% 0%,
      3.098% 16.221%,
      5.939% 31.608%,
      10.487% 45.956%,
      16.587% 59.059%,
      24.083% 70.711%,
      32.822% 80.706%,
      42.649% 88.838%,
      53.41% 94.902%,
      64.95% 98.691%,
      77.116% 100%,
      98.696% 100%,
      98.69% 99.934%,
      98.69% 99.934%,
      98.168% 99.683%,
      97.651% 99.425%,
      97.139% 99.159%,
      96.632% 98.885%,
      96.13% 98.604%,
      95.632% 98.315%,
      95.138% 98.018%,
      94.649% 97.713%,
      94.164% 97.4%,
      93.683% 97.078%,
      93.683% 97.078%,
      90.453% 94.71%,
      87.354% 92.085%,
      84.393% 89.214%,
      81.579% 86.107%,
      78.918% 82.775%,
      76.419% 79.227%,
      74.089% 75.475%,
      71.936% 71.527%,
      69.967% 67.395%,
      68.191% 63.088%,
      68.191% 63.088%,
      66.713% 58.757%,
      65.485% 54.146%,
      64.482% 49.196%,
      63.682% 43.846%,
      63.061% 38.037%,
      62.598% 31.708%,
      62.269% 24.8%,
      62.051% 17.253%,
      61.921% 9.006%,
      61.857% 0%
    );
  }
}

.messageTail.messageMeeting {
  border-bottom-left-radius: 0;

  &::before {
    display: none;
  }

  &.messageOutgoing {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 19px;
  }
}

.footer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  column-gap: 16px;

  &WithEdit {
    margin-top: 0;
    margin-bottom: 4px;
  }

  &Right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    font-size: $main-font-size;
  }
}

.videoStatus {
  margin-right: 8px;
}

.contextMenu {
  margin-left: 16px;
}

.skeleton {
  @include evenChatMessageWrapper() {
    & > :global(.ant-skeleton-header) {
      display: none;
    }

    & > :global(.ant-skeleton-content .ant-skeleton-paragraph) {
      margin-left: auto;

      & > li {
        margin-left: auto;
      }
    }
  }

  :global(.ant-skeleton-content .ant-skeleton-paragraph) {
    margin-top: 0;
    width: 45%;
  }
}

.connection {
  margin-right: 8px;
}

.reactionsList {
  padding-top: 4px;
  flex: 6;
  max-width: 624px;
}

.image {
  position: relative;

  &TimeWrapper {
    display: flex;
    align-items: center;
    position: absolute;
    top: -28px;
    right: 4px;
  }
  &Time {
    color: $white;
  }
}

@include media-tablet() {
  .message {
    &Container {
      &System {
        margin: 0;
      }
    }

    &Deleted {
      padding-bottom: 8px;
    }

    &Image {
      padding: 0;
      max-width: 100%;
    }

    &Meeting {
      padding: 20px;
    }

    &MeetingSystem {
      padding: 16px 20px 20px;
    }
  }

  .footer {
    &WithEdit {
      margin-bottom: 0;
    }
  }

  .connection {
    margin-right: 4px;
  }

  .videoStatus {
    margin-right: 4px;
  }
}

@include media-phone() {
  .message {
    padding: 8px;

    &Container {
      margin: 0;
    }

    &Outgoing {
      margin-left: 20px;
    }

    &Deleted {
      padding-bottom: 8px;
    }

    &Image {
      padding: 0;
    }

    &Meeting {
      margin-right: 0;
      padding: 16px;

      &.messageOutgoing {
        margin-left: 0;
      }
    }

    &MeetingSystem {
      padding: 20px 12px;
      margin: 0;
    }
  }
}
