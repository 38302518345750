@import '../../../../assets/style/mixins';

.list {
  padding: 0 24px;
}

@include media-tablet() {
  .list {
    padding: 0 20px;
    margin-bottom: 16px;
  }

  .scrollable {
    margin-bottom: 0;
  }
}

@include media-phone() {
  .list {
    padding: 0 16px;
  }
}
