@import '../../../../assets/style/mixins';

.root {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  min-width: 286px;
  padding: 1.2em 0;
  margin-right: 2em;
  font-size: $main-font-size;
  top: 0;

  &Left {
    @include paper();
  }

  &Top {
    align-self: stretch;

    :global(.ant-tabs-nav) {
      top: 0 !important;
    }
  }
}

.endAdornment {
  margin-top: 12px;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
}

@include media-tablet() {
  .root {
    &Top {
      min-width: 0;
      overflow: hidden;
    }

    &Fixed {
      @include sub-navigation-tablet-view();

      display: block;
      margin-right: 0;
      padding: 1.8em 0 2em 0;

      border: none;
      background-color: transparent;
    }
  }
}

@include media-phone() {
  .root {
    width: 100%;

    &Top {
      overflow: unset;
    }
  }
}
