@import '../../../../../assets/style/colors';
@import '../../../../../assets/style/variables';

.root {
  font-size: $main-font-size;
}

.button {
  display: block;
  margin: 0 auto;
}

[class].fieldItem {
  margin-bottom: 2.4em;
}
