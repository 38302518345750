@import '../../../../../assets/style/mixins';

.root {
  display: inline-flex;
  color: $palette-secondary-dark;

  &.sm svg {
    width: 20px;
    height: 20px;
  }

  &.md svg {
    width: 24px;
    height: 24px;
  }

  &.outgoingMessage {
    color: $white;
  }
}

@include media-tablet() {
  .root {
    color: $palette-secondary;

    &.sm svg {
      width: 16px;
      height: 16px;
    }

    &.md svg {
      width: 20px;
      height: 20px;
    }
  }
}
