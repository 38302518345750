@import '../../../../../assets/style/colors';
@import '../../../../../assets/style/mixins';

.title {
  margin-bottom: 14px;
}

.general {
  margin-bottom: 16px;
}

@include media-phone() {
  .root {
    padding: 16px 16px 0;
  }
}
