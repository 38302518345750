@import '../../../../assets/style/mixins';

.root {
  display: flex;
  align-items: center;
  column-gap: 20px;
  padding: 16px;

  &Highlighted {
    background-color: rgba($palette-secondary-light-rgb, 0.08);
  }

  &Shifted {
    padding-left: 60px;
  }
}

.type {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  min-width: 130px;
}

.date {
  font-size: 12px;
  line-height: 16px;
  color: $palette-text-secondary-dark;
}

.description {
  flex-grow: 1;  
}

@include media-phone() {
  .root {
    padding: 16px;
    flex-direction: column;
    row-gap: 12px;
    align-items: flex-start;
  }
}
