@import '../../../../../../../assets/style/mixins';

[class].root {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  color: $palette-text-primary;

  & > div {
    margin: 0;
    padding: 0;
  }
}

@include media-phone() {
  [class].root {
    font-size: 16px;
    line-height: 20px;
  }
}
