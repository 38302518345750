@import '../../../../assets/style/colors';

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  color: $palette-text-primary;
  font-style: normal;
  font-weight: normal;

  &:last-child {
    margin-bottom: 0;
  }
}

.subtitle {
  font-size: 14px; // Because the ant design has a default h4 font-size
  margin-bottom: 0; // Because they have a default margin-bottom
  font-weight: bold;
  line-height: 21px;
}
