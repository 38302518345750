@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  row-gap: 12px;

  :global {
    .infinite-scroll-component__outerdiv {
      display: flex;
      flex-grow: 1;
    }

    .infinite-scroll-component {
      width: 100%;
      overflow: hidden !important;
    }
  }
}

.skeleton {
  height: 100%;
  overflow: hidden;

  &Item[class] {
    padding: 12px 16px 0 28px;

    & :global(.ant-skeleton-paragraph > li) {
      height: 30px;
    }
  }

  &[class] :global(.ant-skeleton-paragraph) {
    margin: 19px 28px 0;

    li {
      height: 30px;
      width: 100% !important;
    }
  }
}

@include media-phone() {
  .skeleton {
    &[class] :global(.ant-skeleton-paragraph) {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
}
