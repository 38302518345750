@import '../../../../../assets/style/colors';

.unreadCount {
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: solid 2px $white;
    background-color: $palette-error;
    transform: translate(25%, -25%);
  }

  &.muted::after {
    background-color: $palette-text-secondary-dark;
  }
}
