@import '../../../../../../assets/style/mixins';

[class].radio {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  row-gap: 12px;

  &Item {
    margin: 0;
    width: 100%;
    min-width: 0;

    :global(.ant-radio + span) {
      min-width: 0;
      display: flex;
    }
  }

  &ItemWrapper {
    margin-left: 2px;
    display: flex;
    align-items: center;
    width: 100%;
  }
}

.microphoneEntry {
  flex-grow: 1;
  margin-left: 18px;
}

.iconLabel {
  margin-bottom: 16px;

  &Icon {
    color: $palette-text-secondary-dark;
  }

  &Label {
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
    color: $palette-text-secondary-dark;
  }
}
