@import '../../../assets/style/mixins';

.button {
  margin-top: 16px;
  max-width: 100%;
}

@include media-tablet() {
  .button {
    max-width: unset;
    margin-top: 20px;
    padding: 0 60px;
  }
}
