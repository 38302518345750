@import '../../../../assets/style/mixins';

.root,
.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: 100%;
}

.root {
  padding: 0 28px 28px;
}

.wrapper {
  min-height: 230px;
}

.list {
  display: flex;
  flex-direction: column;
}

[class].pagination {
  margin-top: 28px;
}

[class].skeleton {
  :global {
    .ant-skeleton-header .ant-skeleton-avatar {
      width: 70px;
      height: 70px;
      line-height: 70px;
    }
  }
}

.popover {
  min-width: 204px;
}
