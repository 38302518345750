@import '../../../../../assets/style/colors';

.root {
  display: inline-flex;
  gap: 4px;
  min-width: 0;
  width: 100%;
  color: $palette-primary;
}

.name {
  flex-shrink: 1;
  font-weight: 400;
  min-width: 0;
  color: $palette-text-secondary-dark;
  text-decoration: none;
}
