@import '../../../../assets/style/mixins';

.root {
  @include modalTitleWithoutDescriptionGap();
}

.alert {
  margin-bottom: 24px;
}

.caseTitle {
  font-weight: bold;
  font-size: 14px;
  color: $palette-text-primary;
  margin-bottom: 8px;
}

.caseName {
  font-size: 14px;
  line-height: 19px;
  color: $palette-text-primary;
  margin-bottom: 24px;
}

.root.formStep :global(.steps-footer-actions) {
  @include modalButtonGapLG();
}

.icon {
  position: relative;
  left: 4px;
  transform: translateY(-50%);
  cursor: pointer;
}
