@import '../../../../assets/style/mixins';

.price {
  &Root {
    margin-bottom: 24px;
  }
}

.sameAsBasic {
  text-align: left;
}

.details {
  align-self: flex-start;
  margin-bottom: 32px;
}

.iconRoot {
  order: -1;
  margin-bottom: 16px;
}

@include media-phone() {
  .priceRoot,
  .iconRoot,
  .title {
    align-self: center;
  }

  .price {
    font-size: 18px;
    line-height: 25px;

    &Root {
      margin-bottom: 16px;
    }
  }

  .details {
    margin-bottom: 16px;
  }

  [class].icon {
    width: 20px;
    height: 20px;

    &Root {
      margin-bottom: 8px;
      width: 48px;
      height: 48px;
    }
  }
}
