@import '../../../../../assets/style/mixins';

.root {
  margin-top: 16px;
  margin-left: 20px;

  & :global(.ant-tabs-tabpane.ant-tabs-tabpane-active) {
    display: flex;
    flex-direction: column;
  }

  @include media-tablet() {
    & :global(.ant-tabs-content) {
      min-height: 393px;
    }
  }

  @include media-phone() {
    margin-left: 0;

    & :global(.ant-tabs-content) {
      min-height: 512px;

      @include media-tablet() {
        min-height: 0;
        flex-grow: 1;
      }
    }
  }

  & :global(.ant-tabs .ant-tabs-nav) {
    font-size: $main-font-size;
    margin-right: 2em;

    @include media-phone() {
      display: none;
    }
  }
}

.tab {
  display: inline-flex;
  align-items: center;
}

.title {
  text-align: center;

  &Text {
    font-weight: 700;
    font-size: 1.4em;
    line-height: 1.357;
    color: $palette-text-primary;
  }
}
