@import '../../../../assets/style/mixins';

.root {
  padding: 28px 28px 34px;
}

@include media-tablet() {
  .root {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@include media-tablet() {
  .root {
    padding-left: 20px;
    padding-right: 20px;
  }
}
