.root {
  position: relative;
  display: inline-flex;
}

.badge {
  position: absolute;
  top: -10px;
  left: 15px;
}

.badge.dot {
  top: -4px;
}
