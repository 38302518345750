@import '../../../../../assets/style/colors';

.button {
  display: inline-flex;
  align-items: center;
  outline: 0 !important;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 104px;
  height: 28px;
  padding: 7px 16px;
  position: relative;
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
  }

  &.unreadCount {
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      border: solid 2px $white;
      background-color: $palette-error;
      transform: translate(25%, -25%);
    }
  }

  &:hover {
    background: $palette-primary-light;
    box-shadow: none;
  }

  &:active {
    background: $palette-primary-dark;
    box-shadow: none;
  }
}

.fill {
  background: $palette-primary;
  color: $white;
  border: none;
}

.outline {
  background: $white;
  color: $palette-primary;
  border: solid 1px $palette-primary;

  &:hover {
    color: $white;
  }

  &:active {
    color: $white;
  }
}

.disabled {
  background: $palette-disable-light;
  box-shadow: none;
  cursor: not-allowed;

  svg {
    color: $palette-text-secondary-dark;
  }

  &:hover {
    background: $palette-disable-light;
  }
}
