@import '../../../../../assets/style/colors';

.root {
  font-size: 14px;
  line-height: 22px;
  color: $palette-warning-dark;
}

.autoRenewal {
  color: $palette-text-secondary-dark;
}
