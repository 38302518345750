@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: $zIndex-secondary-menu-notification;
  padding: 12px;

  &.static,
  &.fullscreen {
    position: static;
  }

  &.static {
    margin-top: auto;
  }
}

[class].contextIcon {
  color: $palette-text-primary;
  width: 100%;
  height: 100%;
  justify-content: center;
}

[class].button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  margin-right: 20px;
  padding: 0;
  border-radius: 50%;
  border: none;
  background: $white;
  color: $palette-text-primary;
  position: relative;

  &Small {
    width: 32px;
    height: 32px;
    margin-right: 12px;

    svg {
      width: 18px;
      height: 18px;
    }
  }

  &End {
    background-color: $palette-error;
    color: $white;

    svg {
      width: 28px;
      height: 28px;
    }

    &.buttonSmall {
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  &:last-child {
    margin-right: 0;
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
    color: inherit;
  }
}

.center,
.right {
  display: flex;
  align-items: center;
}

@include media-phone() {
  .root {
    justify-content: center;
  }
}
