@import 'src/assets/style/mixins';

.root {
  flex: 1 1 auto;
  height: 100%;
  font-size: $main-font-size;
  margin: 0;
  padding: 0 20px;
  overflow-y: auto;
  overflow-x: hidden;
  user-select: none;

  @include media-phone() {
    padding: 0 16px;
  }
}

.loading {
  overflow-y: hidden;
  margin-top: 20px;
}
