@import '../../../../assets/style/mixins';

.root {
  width: 100%;
  height: 100%;
  min-height: 0;
}

.autoSizer {
  width: auto !important;
  height: auto !important;
}

.item {
  &P2P {
    @include dividerBottom(20px, relative, -1px);
  }

  &Active:after {
    display: none;
  }
}

@include media-tablet() {
  .item {
    &P2P:after {
      width: calc(100% - 32px);
      left: 16px;
    }
  }
}
