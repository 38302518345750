@import '../../../../../assets/style/mixins';

.list,
.listEmpty {
  background: $white;
  border-radius: 4px;
  border: 1px solid $palette-stroke-primary;
}

[class].listEmpty {
  padding: 8em 0;

  &Icon {
    color: $palette-text-secondary;
    width: 30px;
    height: auto;
  }
}

.list {
  background: $white;
  border-radius: 4px;
  border: 1px solid $palette-stroke-primary;

  &Skeleton {
    height: 100%;
    overflow-y: hidden;
  }
}

[class].skeleton {
  display: flex;
  flex-direction: row-reverse;
  padding: 2.8em;

  :global(.ant-skeleton-header) {
    padding-right: 0;
    padding-left: 1.6em;
  }

  &:nth-last-child(n + 2) {
    border-bottom: 1px solid $palette-stroke-primary;
  }

  @include media-tablet() {
    padding: 2em;
  }

  @include media-phone() {
    padding: 2em 1.6em;
  }
}

.pagination {
  @include paginationWrapper();
}
