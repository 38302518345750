@import '../../../../assets/style/mixins';

.root {
  display: flex;
  align-items: center;
}

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 7px 0 7px 50px;
  border-left: 1px solid $palette-nav-border;
  min-width: 0;
}

.avatarWrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  line-height: initial;
  position: relative;

  &Badge {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.avatarIcon {
  margin-left: 8px;
  color: $white;
}

[class].popover {
  :global {
    .ant-popover-inner {
      box-shadow: none;
      background-color: transparent;
    }

    .ant-popover-inner-content {
      padding: 0;
    }
  }
}

.menu {
  width: 286px;
  padding: 20px;
  padding-left: 16px;
  color: $palette-text-primary;
  background: $white;
  border-radius: 4px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);

  &Item {
    display: flex;
    align-items: center;
    padding: 0;
    font-size: 14px;
    line-height: 1.357;
    color: $palette-text-primary;
    border: none;
    background: transparent;
    cursor: pointer;
    position: relative;
    width: 100%;

    &Badge {
      margin-left: auto;

      &Content {
        margin-left: 8px;
      }
    }

    &.nav {
      padding: 0;
      border: none;
    }

    &:nth-last-child(n + 2) {
      margin-bottom: 20px;
    }

    &:hover {
      color: $palette-text-primary;
    }

    &Icon {
      height: 20px;
      width: 20px;
      margin-right: 12px;
    }
  }

  &HeaderWrapper {
    display: flex;
    padding-bottom: 12px;
    border-bottom: 1px solid $palette-stroke-primary;
  }

  &HeaderContent {
    display: flex;
    flex-direction: column;
    min-width: 0;
    padding-left: 12px;
  }

  &MyProfileText {
    margin-top: 2px;
    color: $palette-secondary;
    font-size: 14px;
    line-height: 19px;
  }
}

.burger {
  position: relative;

  &Icon {
    width: 36px;
    height: 36px;
    color: $white;
  }

  &Notification {
    position: absolute;
    right: 1px;
    top: 5px;
  }
}

.userInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  margin-right: 10px;
  overflow: hidden;
}

.nameContainer {
  font-weight: bold;
  font-size: 14px;
  line-height: 1.357;
  text-align: right;
  color: $white;
  display: flex;
  align-items: center;
}

.menuCloudWrapper {
  margin-bottom: 20px;
}

[class].accountNameRoot {
  width: 100%;
  line-height: 14px;
}

.nav {
  padding: 16px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  &Link {
    display: flex;
    align-items: center;
    width: 100%;

    color: $white;
    font-size: 14px;
    line-height: 1.357;

    &:nth-last-child(n + 2) {
      margin-bottom: 20px;
    }
  }

  &Icon {
    display: flex;
    margin-right: 8px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &Group {
    flex-grow: 1;
  }
}

.section {
  padding: 16px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  &:last-child {
    border: none;
  }
}

[class].badge {
  top: 0;
  right: 0;
  left: unset;
  box-shadow: none !important;
}

[class].accountType {
  width: 24px;
  height: 24px;
  margin-right: 12px;
  flex-shrink: 0;
}

@include media-tablet() {
  .wrapper {
    padding-left: 40px;
  }

  .avatarIcon {
    margin-left: 12px;
  }

  [class].popover {
    z-index: $zIndex-popover;
  }
}

@include media-phone() {
  .wrapper {
    padding: 0 0 0 24px;

    &Open {
      border: 0;
    }
  }

  [class].popover {
    position: fixed;
    top: $header-fixed-height-phone !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    padding: 0;
    z-index: $zIndex-modal - 1;

    :global {
      .ant-popover-content,
      .ant-popover-inner,
      .ant-popover-inner-content {
        height: 100%;
      }
    }
  }

  .menu {
    width: auto;
    height: 100%;
    padding: 20px 16px 20px 12px;
    min-width: unset;
    border-radius: 0;
    box-shadow: none;
    background-color: $palette-primary;
    overflow-y: scroll;
    margin-bottom: 0;

    @supports (padding: max(0px)) {
      padding-left: unquote('max(12px, calc(env(safe-area-inset-left) + 12px))');
      padding-right: unquote('max(16px, calc(env(safe-area-inset-right) + 16px))');
      padding-bottom: unquote('max(20px, calc(env(safe-area-inset-bottom) + 20px))');
    }

    &Item {
      font-size: 14px;
      line-height: 19px;
      color: $white;

      &:hover {
        color: $white;
      }

      &Icon {
        margin-right: 8px;
      }
    }

    &HeaderWrapper {
      padding-bottom: 14px;
      border-color: rgba(255, 255, 255, 0.1);

      svg {
        color: $white;
      }
    }

    &MyProfileText {
      margin-top: 8px;
    }
  }

  .burger {
    display: flex;
  }

  .avatarIconClose {
    width: 27px;
    height: auto;
    color: $white;
  }

  [class].accountNameRoot {
    color: $white;
  }

  [class].accountNameIcon {
    color: $white;
  }

  .navLink {
    position: relative;
  }
}
