.root {
  column-gap: 4px;
  row-gap: 4px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-wrap: wrap;
  min-width: 0;
  width: 100%;
}
