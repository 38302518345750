@import "../../../../../assets/style/colors";
@import "../../../../../assets/style/mixins";

.root {
  display: flex;
  align-items: center;
  width: 86%;
  font-size: $main-font-size;

  :global(.fileIcon) {
    height: 30px;
    width: 30px;
    margin-right: 12px;
    font-size: 10px !important;
    line-height: 14px;
  }
}

.title {
  display: -webkit-box;
  max-height: 2.857em;
  font-weight: 600;
  font-size: 1.4em;
  line-height: 1.428em;
  color: $palette-text-primary;
  @include ellipsis(auto);
  line-break: anywhere;

  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
