@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  flex-direction: column;
}

.wrapper {
  @include paper(none);
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
}

[class].dropDownMenu {
  @include paper();
  padding: 14px 10px 14px 16px;
  margin-bottom: 16px;

  :global(.ant-popover) {
    top: 58px !important;
  }

  &Title {
    justify-content: space-between;
  }

  &Item {
    text-align: left;
    justify-content: space-between;
  }
}

@include media-phone() {
  .dropDownMenu {
    margin-bottom: 12px;
    padding: 16px 12px;
  }
}
