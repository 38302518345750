@import '../../../../assets/style/mixins';

.root {
  p {
    text-align: center;
    margin-bottom: 28px;
  }
}

@include media-phone() {
  .root {
    p {
      margin-bottom: 20px;
    }
  }
}
