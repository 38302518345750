@import 'src/assets/style/colors';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 10px;
  line-height: 18px;
  margin-right: 16px;
  flex-shrink: 0;
}

.button {
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 14px;
  
  &Wrapper {
    display: flex;
    align-items: center;
    column-gap: 20px;
  }
}

.secondary {
  color: $palette-secondary;
}

.warning {
  color: $palette-warning;
}

.error {
  color: $palette-error;
}
