@import '../../../../assets/style/mixins';

.root {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding-top: 30px;
}

.content {
  min-height: 0;
  display: flex;
  flex-direction: column;
  row-gap: 40px;
}

.selectedBlock {
  width: 100%;
  font-size: $main-font-size;

  @include media-phone() {
    padding: 0 0.4em;
  }
}

.subTitle {
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  color: $palette-text-primary;
}

.empty {
  text-align: center;
  padding-top: 10px;
}
