@import '../../../../../assets/style/colors';

.description {
  text-align: center;
  margin-bottom: 28px;
}

.field {
  margin-bottom: 20px;

  &:not(:last-of-type) {
    margin-bottom: 40px;
  }
}

.label {
  margin-bottom: 12px;
}

.members {
  margin-bottom: 50px;
}

[class].recommend {
  margin-top: 30px;

  &Link {
    display: flex;
    align-items: center;
    column-gap: 4px;
    font-weight: 700;
    color: $palette-secondary;
  }

  &Icon {
    width: 20px;
    height: 20px;
  }
}

.row {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 24px;
}

.text {
  text-align: center;
}
