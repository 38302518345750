@import '../../../../../assets/style/mixins';

.root {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 19px;
}

@include media-phone() {
  .root {
    font-size: 16px;
    line-height: 21px;
  }
}
