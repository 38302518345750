@import '../../../../../assets/style/mixins';

.avatar {
  margin-bottom: 16px;

  @include pulse();
}

.caseLabel {
  margin-bottom: 16px;
}

.title {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 10px;
  color: $white;
  cursor: default;
}

.owner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  cursor: default;
}

.name {
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  color: $white;
  margin-left: 9px;
}
