@import '../../../../../assets/style/mixins';

.content {
  display: flex;
  gap: 16px;
  flex-direction: column;
  margin-bottom: 24px;
}

.emptyList {
  height: 230px;
  background: $white;
  border-radius: $main-border-radius;
  border: 1px sokid $palette-stroke-primary;
}
