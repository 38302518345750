@import '../../../../assets/style/mixins';

.root {
  @include dividerTop(0px, relative, -16px)
}

[class].button {
  padding: 0;
  height: auto;
}

.wrapper {
  position: relative;
}

.lead {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(calc(-50% - 10px));
  column-gap: 12px;
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
  color: $palette-text-secondary-dark;
  max-width: 60%;
}
