@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  gap: 16px;
  padding: 16px 0;
  margin: 0 28px;
  font-size: $main-font-size;
  position: relative;

  &:nth-last-child(n + 2) {
    border-bottom: 1px solid $palette-stroke-primary;
  }
}

.infoWrapper {
  min-width: 0;
}

.headerWrapper {
  display: flex;
  align-items: center;
  margin: 0 1em 1em 0;
  padding-right: 10em;
}

.account {
  &Name {
    display: flex;
    min-width: 0;
    font-weight: bold;
    font-size: 1.8em;
    line-height: 1.388;

    color: $palette-text-primary;

    &:hover,
    &:focus {
      color: $palette-text-primary;
    }
  }

  &Role {
    margin-left: 12px;
  }
}

.professions {
  margin: 12px 0;
}

[class].contextMenuRoot {
  width: 250px;
}

[class].contextMenuIcon {
  position: absolute;
  top: 8px;
  right: 8px;
}

@include media-tablet() {
  .root {
    padding: 20px 0;
    margin: 0 20px;
  }
}

@include media-phone() {
  .root {
    padding: 14px 0;
    margin: 0 16px;
    position: relative;
    flex-direction: column;
  }

  .headerWrapper {
    padding-right: 0;
    margin-right: 0;
  }
}
