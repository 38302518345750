@import '../../../../assets/style/mixins';

.root {
  background: $white;
  border-radius: 4px;

  & + & {
    margin-top: 20px;

    @include media-tablet() {
      margin-top: 16px;
    }
  }

  &Empty {
    padding: 8em 0;
    border: 1px solid $palette-stroke-primary;

    &Icon {
      width: 30px;
      height: auto;
      color: $palette-stroke-primary-dark;
    }
  }
}
