@import '../../../assets/style/mixins';

$visibleHeight: 46;
$visibleHeightMobile: 36;

[class].text {
  font-size: 14px;
  line-height: calc(#{$visibleHeight}px / 2);
}

@include media-phone() {
  [class].text {
    font-size: 13px;
    line-height: calc(#{$visibleHeightMobile}px / 2);
  }
}

:export {
  visibleHeight: $visibleHeight;
  visibleHeightMobile: $visibleHeightMobile;
}