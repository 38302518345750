@import '../../../../assets/style/mixins';

.root {
  @include modalTitleWithoutDescriptionGap();
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 28px;
}
