@import 'src/assets/style/colors';

.root {
  display: flex;
  align-items: center;
  font-weight: 600;
  border-radius: 4px;
  flex-shrink: 0;
  cursor: default;
}

.lg {
  font-size: 11px;
  line-height: 15px;
  padding: 6px 12px;

  svg {
    width: 20px;
    height: 20px;
    margin-left: 8px;
  }
}

.md {
  font-size: 12px;
  line-height: 16px;
  padding: 6px 12px;

  svg {
    width: 16px;
    height: 16px;
    margin-left: 8px;
  }
}

.sm {
  font-size: 10px;
  line-height: 14px;
  padding: 4px 8px;

  svg {
    width: 14px;
    height: 14px;
    margin-left: 4px;
  }
}

.processing {
  background: $palette-info-light;
  color: $palette-info-dark;

  svg {
    color: $palette-secondary;
  }
}

.sd {
  background: $palette-warning-light;
  color: $palette-warning-dark;
}

.hd {
  background: $palette-success-light;
  color: $palette-success-dark;
}

.root.inline {
  background: none;
  padding: 0;
  font-weight: normal;
}

.root.icon {
  svg {
    margin-left: 0;
  }
}

.root.white {
  color: white;

  svg {
    color: $white;
  }
}
