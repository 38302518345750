@import '../../../../../../assets/style/mixins';

.root {
  display: flex;
  height: 100%;
  min-height: 0;
  flex-direction: column;
}

.search {
  padding: 12px 20px;
}

.list {
  min-height: 0;

  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
}

@include media-phone() {
  .search {
    padding: 12px 16px;
  }
}
