@import '../../../../assets/style/mixins';

.root {
  display: flex;
  flex-shrink: 0;
  padding: 48px 0;
  font-size: $main-font-size;
}

.avatar {
  margin-right: 29px;

  &Remove {
    font-size: 1em;
  }

  &RemoveIcon {
    width: 12px;
    height: 12px;
  }
}

@include media-tablet() {
  .root {
    flex-direction: column;
    padding-top: 0;
  }

  .avatar {
    margin: 2em 0 2.4em;
    flex-direction: row;

    &Skeleton {
      margin: 20px 0 24px;
    }

    &Remove {
      margin-top: 0;
      margin-left: 24px;
      font-size: 1.2em;
      line-height: 1.333;
    }

    &RemoveIcon {
      width: 20px;
      height: 20px;
      margin-right: 2px;
    }
  }
}

@include media-phone() {
  .root {
    padding-bottom: 4em;
  }

  .avatar {
    margin-bottom: 2.8em;
  }
}
