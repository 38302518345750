.skeleton {
  :global(.ant-skeleton-content .ant-skeleton-paragraph) {
    display: flex;
    align-items: center;
    column-gap: 8px;
    margin-bottom: 0;

    > li {
      height: 34px;
      max-width: 170px;
      margin: 0 !important;

      &:last-child {
        border-radius: 50%;
        width: 34px !important;
        flex-shrink: 0;
      }
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  min-width: 0;
}

.label {
  font-size: 14px;
}

.chipList {
  justify-content: stretch;
}

.popoverContent {
  width: 270px;
  max-height: 368px;
  overflow: auto;
}
