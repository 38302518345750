@import '../../../../assets/style/mixins';

$textLineHeight: 24;

$visibleHeight: $textLineHeight * 2;
$visibleHeightTablet: $textLineHeight * 3;

.root {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.reply {
  padding-left: 32px;
  padding-top: 12px;
}

.comment {
  margin-bottom: 12px;
  padding: 12px;
  @include paper(1px solid $palette-stroke-primary, 2px);
}

.root.relative {
  position: relative;
}

.text {
  font-size: 14px;
  line-height: #{$textLineHeight}px;

  &Expanded {
    max-height: 100% !important;
  }
}

.edited,
.time {
  font-weight: 600;
  line-height: 22px;
}

.edited {
  margin-left: 4px;
  font-weight: 400;
}

.animateHighlight {
  animation: highlightAnimation 3s 1 ease-out;
}

@keyframes highlightAnimation {
  50% {
    background-color: rgba($palette-secondary-dark-rgb, 0.2);
  }
}

:export {
  visibleHeight: $visibleHeight;
  visibleHeightTablet: $visibleHeightTablet;
}