@import '../../../../../assets/style/mixins';

.root {
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-left: auto;
  font-size: $main-font-size;

  @include media-phone() {
    position: static;
  }
}

.kebabIcon {
  color: $palette-secondary;
}

.iconButton {
  &.P2P:nth-last-child(n + 2) {
    margin-right: 2em;
  }

  &.CASE:nth-last-child(n + 2) {
    margin-right: 1.6em;
  }
}

.footerActions {
  display: flex;
  padding: 0 28px;

  button {
    width: 100%;
  }
}
