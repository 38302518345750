@import 'src/assets/style/mixins';

[class].root {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  height: 0;
  overflow: hidden;
  padding: 16px 16px 20px 20px;

  :global {
    .ant-skeleton {
      &-title {
        margin: 0 0 12px;
        max-width: 218px;
        border-radius: 2px;
      }

      &-input {
        height: 30px;
        margin-bottom: 16px;
      }

      &-button {
        width: 88px;
        height: 30px;
      }

      &-avatar {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.group {
  margin-bottom: 20px;
}

@include media-phone() {
  .root {
    padding-left: 16px;

    :global {
      .ant-skeleton {
        &-input,
        &-title {
          max-width: 150px;
        }

        &-button {
          max-width: 50px;
        }
      }
    }
  }
}
