@import '../../../../../assets/style/colors';

.root {
  font-size: 14px;
  color: $palette-secondary;

  &.clickable {
    cursor: pointer;
  }

  &.disabled {
    color: inherit;
  }
}
