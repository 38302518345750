@import '../../../../assets/style/colors';

.root {
  font-size: 1.4em;
  line-height: 1.571;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  color: $palette-text-primary;
  min-width: 0;

  &.outgoingMessage {
    color: $white;
  }
}

[class].mention.outgoingMessage {
  font-weight: 600;
  color: $white;
}
