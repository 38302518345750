@import '../../../../assets/style/colors';

.root {
  position: absolute;
  display: inline-flex;
  align-items: center;
  gap: 4px;

  cursor: pointer;
  top: 15px;
  right: 12px;
  z-index: 7;

  padding-right: 20px;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: $white;

  > svg {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 14.4px;
    height: auto;

    color: inherit;
  }

  &.fullScreen {
    bottom: 80px;
    right: 50%;
    top: auto;
  }
}
