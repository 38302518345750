@import '../../../../assets/style/mixins';

$button-size: 54;

.root {
  position: absolute;
  bottom: 59px;
  right: 47px;
  z-index: 3;
}

.button {
  width: #{$button-size + 'px'};
  height: #{$button-size + 'px'};
  border-radius: 50%;
  justify-content: center;
  flex-shrink: 0;

  svg {
    width: 42px;
    height: 42px;
  }
}

@include media-tablet() {
  .root {
    position: fixed;
    right: 27px;
    bottom: 103px;
  }
}
