.container {
  display: flex;
  align-items: flex-start;
}

.tooltip {
  display: flex;
}

$gutter: 20px;

.divider {
  margin: 8px $gutter;
  width: calc(100% - 2 * #{$gutter}) !important;
}
